<template>
	<td class="email_block_paragraph" :id="advanced.cssId" :class="[advanced.cssClasses, responsiveClasses]" v-html="details.content || '<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde, maxime.</p>'" :style="style">Lorem ipsum</td>
</template>

<script>
export default {
    name: 'EmailParagraph',
	props: ["details"],
	computed: {
		basic() {
			return this.details.attributes.basic;
		},
		advanced() {
			return this.details.attributes.advanced;
		},
        responsive() {
            return this.details.attributes.responsive
        },
        responsiveClasses() {
            let c = '';
            if (!this.responsive) return '';
            if (!this.responsive.desktop) c = 'no-desktop';
            if (!this.responsive.mobile) c += ' no-mobile';
            if (!this.responsive.tablet) c += ' no-tablet';
            return c;
        },
		textDecoration(){
            var fontStyling = this.basic.font ? this.basic.font.fontStyling : false;
            if(fontStyling['line-through'] && fontStyling.underline) {
                return 'underline line-through';
            } else if(fontStyling['line-through']) {
                return 'line-through';
            } else if(fontStyling.underline){
                return 'underline';
            } else {
                return 'inherit';
            }
        },
        style() {
            var border = this.basic.border;
            var borderTop,
                borderLeft,
                borderRight,
                borderBottom;

            if (border) {
                borderTop = !border.sides.top ? 'none' : border.width + 'px ' + border.style + ' ' + border.color;
                borderLeft = !border.sides.left ? 'none' : border.width + 'px ' + border.style + ' ' + border.color;
                borderRight = !border.sides.right ? 'none' : border.width + 'px ' + border.style + ' ' + border.color;
                borderBottom = !border.sides.bottom ? 'none' : border.width + 'px ' + border.style + ' ' + border.color;
            }

            var borderRadius = border ?
                border.radius.leftTop+'px '+
                border.radius.topRight+'px '+
                border.radius.bottomRight+'px '+
                border.radius.bottomLeft+'px' : false;

	        var marginBase = this.basic.margin || {};
	        var paddingBase = this.basic.padding || {};

	        var margin =
			        parseInt(marginBase.top || 0)+'px '+
			        parseInt(marginBase.right || 0)+'px '+
			        parseInt(marginBase.bottom || 0)+'px '+
			        parseInt(marginBase.left || 0)+'px';

	        var padding =
			        parseInt(paddingBase.top || 0)+'px '+
			        parseInt(paddingBase.right || 0)+'px '+
			        parseInt(paddingBase.bottom || 0)+'px '+
			        parseInt(paddingBase.left || 0)+'px';

            var backgroundColor = this.basic.backgroundColor || 'transparent';

            var fontFamily = this.basic.font ? this.basic.font.fontFamily : 'inherit';
            var fontSize = this.basic.font ? this.basic.font.fontSize : 'inherit';
            var textAlign = this.basic.font ? this.basic.font.textAlign : 'left';
            var fontStyling = this.basic.font ? this.basic.font.fontStyling : false;
            var color = this.basic.font ? this.basic.font.color : 'inherit';


            var obj = {
                margin,
                padding,
                borderRadius,
                borderTop,
                borderLeft,
                borderRight,
                borderBottom,
                backgroundColor,
                color,
                fontFamily,
                fontSize: fontSize + 'px',
                textAlign,
                fontStyling,
                fontWeight: fontStyling.bold ? 'bold' : 'inherit',
                fontStyle: fontStyling.italic ? 'italic' : 'inherit',
                textDecoration: this.textDecoration,
            };

            return obj
        },
    }
};
</script>
