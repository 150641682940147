import axios from "axios";
import config from "@/config/config";

export default (config, axios, token) => {

    var call = async function(path, method = 'GET', data) {
        data ? data = JSON.stringify(data) : '';
        const url = config.URL + path;
        try {
            const response = await axios({
                url,
                method,
                data,
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json',
                }
            })
            return response.data
        } catch (error) {
            return { error: true, info: error.response }
        }
    }

    return {

        getAll: async(page = 0, per_page = 25, query = '') =>
            call('discounts?page=' + page + '&per_page=' + per_page + '&query=' + query),

        get: async(id) =>
            call(id ? 'discounts/' + id : 'discounts'),

        create: async(data) =>
            call('discounts', 'POST', data),

        update: async(data) =>
            call('discounts', 'PUT', data),

        delete: async(id) =>
            call('discounts/' + id, 'DELETE'),

    }
}
