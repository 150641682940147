import {
    shadows,
    border,
    margin,
    cssId,
    cssClasses,
    id,
    clickAction,
    size,
    image,
    sticky,
} from './fields/'

export default {
    tag: 'image',
    labelTitle: '',
    labelColor: '',
    id,
    attributes: {
        basic: {
            align: { title: 'Alignment', comp: 'alignment', value: {mobile:null,desktop:'center',tablet:null} },
            margin,
            image,
            previewProductImage: { title: 'Preview product image', comp: 'previewImageProps', value: false },
            alt: { title: 'Alt text', comp: 'TextInput', value: '' },
            clickAction,
            fitImage: { title: 'Fit image', comp: 'selectItem', value: 'scale-down', options: [
                    { id: '', text: 'Default' },
                    { id: 'cover', text: 'Cover' },
                    { id: 'fill', text: 'Fill' },
                    { id: 'scale-down', text: 'Scale down' },
                    { id: 'contain', text: 'Contain' },
                    { id: 'none', text: 'None' },
                ] },
            size,
            lazyload: { title: 'Lazyload', comp: 'LazyloadBool', options:{recommended:true},
                value: {booleanValue:true} },
            maxWidth: {
                title:'Max width',
                comp: 'inputNumber',
                value: {
                    append: 'px',
                    placeholder: 'none',
                    number: null,
                }
            },
            border,
            shadows
        },
        advanced: {
            cssId,
            cssClasses,
            sticky
        },
    },
}
