import {findModel} from './../models'
import { applyDrag, clearUp } from '@/modules/page-editor/src/utils/helpers'
import twoStepForm from "@/modules/page-editor/src/models/twoStepForm";
import {shadows} from "@/modules/page-editor/src/models/fields";
import Vue from "vue";

var randomstring = require("randomstring");

export function generateId(numbers = []) {
    var min = 1,
        max = 1000000;
    var randomNumber = Math.floor(Math.random() * (max - min + 1) + min);
    if (numbers.length && numbers.indexOf(randomNumber) !== -1) generateId(numbers);
    else return randomNumber
}
var createCols = function(cols, oldCols = false) {
    var colsData = [];
    switch (cols) {
        case 'one':
            colsData = [{ width: 100, children: [] }]
            break;
        case 'two':
            colsData = [{ width: 50, children: [] }, { width: 50, children: [] }]
            break;
        case 'three':
            colsData = [{ width: 33.3333, children: [] }, { width: 33.3333, children: [] }, { width: 33.3333, children: [] }]
            break;
        case 'four':
            colsData = [{ width: 25, children: [] }, { width: 25, children: [] }, { width: 25, children: [] }, { width: 25, children: [] }]
            break;
        case 'five':
            colsData = [{ width: 75, children: [] }, { width: 25, children: [] }]
            break;
        case 'six':
            colsData = [{ width: 25, children: [] }, { width: 75, children: [] }]
            break;
    }
    if (oldCols !== false) {
        oldCols.forEach((col, index) => {
            if (index < colsData.length) {
                colsData[index].children = col.children
            } else {
                colsData[colsData.length - 1].children.push(...col.children);
            }
        });
    }

    return colsData
}

export function cloneContainer(context, block, allIds=[]) {
    let clone = JSON.parse(JSON.stringify(block));
    clone.id = generateId([...context.getters.numbers, ...allIds]);

    let children = [];
    let ids = []; // used to prevent collision

    if (block.type==='faq') {
            let chank = [];
            block.children && block.children.forEach(c => {

                // Clone each child and add to global children list.
                let oldChild = context.getters.page_findChild(c);
                if (!oldChild) return;

                if (oldChild.type==="container"||oldChild.type==="faq") {
                    let subRes = cloneContainer(context, oldChild, [...ids, ...allIds])
                    if (subRes) {
                        children.push(subRes.clone)
                        chank.push(subRes.clone.id)
                        children.push(...subRes.children)
                    }
                } else {
                    let newId = generateId([...context.getters.numbers, ...ids, ...allIds]);
                    ids.push(newId)
                    let newChild = JSON.parse(JSON.stringify(oldChild));
                    newChild.id = newId
                    newChild.parent = clone.id;

                    children.push(newChild)
                    chank.push(newId)
                }
            });
        // clone.attributes.children = chank
        Vue.set(clone, 'children', chank)
    } else {
        let newBoxes = [];
        block.attributes.basic.boxes.forEach(box => {
            let chank = [];
            let newbox = JSON.parse(JSON.stringify(box));
            box.children && box.children.forEach(c => {

                // Clone each child and add to global children list.
                let oldChild = context.getters.page_findChild(c);
                if (!oldChild) return;

                if (oldChild.type==="container"||oldChild.type==="faq") {
                    let subRes = cloneContainer(context, oldChild, [...ids, ...allIds])
                    if (subRes) {
                        children.push(subRes.clone)
                        chank.push(subRes.clone.id)
                        children.push(...subRes.children)
                    }
                } else {
                    let newId = generateId([...context.getters.numbers, ...ids, ...allIds]);
                    ids.push(newId)
                    let newChild = JSON.parse(JSON.stringify(oldChild));
                    newChild.id = newId
                    newChild.parent = clone.id;

                    children.push(newChild)
                    chank.push(newId)
                }
            });
            newbox.children = chank;
            newBoxes.push(newbox);
        });
        clone.attributes.basic.boxes = newBoxes
    }

    children.push(clone) // append the new container block itself.
    return {clone,children}
}

// FIXME: some bug caused corruption of the block.parent.
function repairChildrenParents(children=[], rows=[]) {

    const repairChild = (child, parentId, level=1) => {
        child.parent = parentId;

        if (child.type==='container') {
            child.attributes.basic.boxes && child.attributes.basic.boxes.forEach(box => {
                box.children && box.children.forEach(id=> {
                    let c2 = children.find(x => x.id === id)
                    if (!c2) return;
                    repairChild(c2, child.id, level+1)
                })
            })
        } else if (child.type==='faq') {
            child.children && child.children.forEach(id => {
                let c2 = children.find(x => x.id === id)
                if (!c2) return;
                repairChild(c2, child.id, level+1)
            })
        }
    }

    rows.forEach(row => {
        if (!row.cols) return;
        row.cols.forEach(col=> {
            col.children && col.children.forEach(id=>{
                let child = children.find(x=>x.id===id)
                if (!child) return;
                repairChild(child, row.id)
            })
        })
    })
}

export function applyTemplate(state, value) {
    state.currentTemplate.id = value.id || false;
    state.currentTemplate.name = value.name || false;
    state.currentTemplate.seo_description = value.seo_description || false;
    state.currentTemplate.seo_keywords = value.seo_keywords || '';
    state.currentTemplate.seo_title = value.seo_title || false;
    state.currentTemplate.url = value.url || "";

    if (!value.clear && value.config.page && value.config.editor && Object.keys(value.config.page).length && Object.keys(value.config.editor).length) {
        state.page = {...value.config.page };
        state.editor.content = value.config.editor.content;
        state.editor.rows = value.config.editor.rows;
        state.editor.children = value.config.editor.children;

        repairChildrenParents(state.editor.children, state.editor.rows)

        var { body_js, head_js, html, css } = value
        state.customCode = { body_js, head_js, html, css };
    } else {
        state.editor.content = [];
        state.editor.rows = [];
        state.editor.children = [];
        state.page = {
            basic: {
                backgroundColor: '#fff',
                backgroundImage: '',
                padding: { top: 68, left: 30, right: 30, bottom: 100 },
                font: {
                    fontStyling: { bold: false, italic: false, 'line-through': false, underline: false },
                    color: '#252A32',
                    fontFamily: 'Roboto',
                    fontSize: 14,
                },
                border: {
                    radius: { bottomLeft: 0, bottomRight: 0, analytics: 0, topRight: 0 },
                    sides: { bottom: true, left: true, right: true, top: true },
                    color: 'transparent',
                    style: 'solid',
                    width: 0
                }
            },
            advanced: { id: '', cssClasses: [], direction:'ltr' }
        }
    }
}

export default {
    state: {
        popup: null,
        content: [],
        rows: [],
        children: [],
        formChildren: [
            {
                tag: 'productList',
                type: 'productList',
                id: 0,
                step: 2,
                attributes: {
                    basic: {
                        margin: {top: 15, left: 0, right: 0, bottom: 0},
                        selectionType: 'single',
                        labelFont: { fontFamily: null, fontSize: 12, color: '#97AACD', fontStyling: { } },
                        font: { fontFamily: '', fontSize: 12, color: '#252A32', fontStyling: { }},
                        backgroundColor: { title: 'Background color', comp: 'colorPicker', value: 'white' },
                    },
                    advanced: {}
                }
            },
            {
                tag: 'paymentForm',
                type: 'paymentForm',
                id: 0,
                step: 2,
                attributes: {
                    basic: {
                        margin: { top: 20, left: 0, right: 0, bottom: 0 },
                    },
                    advanced: {}
                }
            },
            {
                tag: 'orderSummary',
                type: 'orderSummary',
                id: 0,
                step: 2,
                content: {
                    topTitles: ['Product', 'Price'],
                    bottomLeftText: 'Total',
                },
                attributes: {
                    basic: {
                        font: { fontFamily: null, fontSize: 12, color: '#97AACD', fontStyling: { } },
                        font2: { fontFamily: null, fontSize: 12, color: '#252A32', fontStyling: { }},

                        margin: {top: 20, left: 0, right: 0, bottom: 0}
                    },
                    advanced: {}
                },
            },
            {
                tag: 'orderBump',
                type: 'orderBump',
                id: 0,
                step: 2,
                attributes: {
                    basic: {
                        labelFont: { fontFamily: null, fontSize: 18, color: null, fontStyling: { }, textAlign:'left' },
                        margin: {top: 20, left: 0, right: 0, bottom: 0},
                        border: {width: 2, color: '#FDCC5E', style: 'dashed', sides: {top: true, left: true, right: true, bottom: true},
                            radius: {analytics: 6, topRight: 6, bottomRight: 6, bottomLeft: 6},
                        },
                    },
                    advanced: {
                        padding: { top: 15, left: 15, right: 15, bottom: 15 },
                    }
                }
            },
            {
                tag: 'formElement',
                type: 'formElement',
                id: 0,
                step: 1,
                attributes: {
                    basic: {
                        inputType: 'input',
                        props: {
                            required:true,
                            showLabel: true,
                            dataType: 'full_name',
                            dataType2: '',
                            placeholder: 'Full name',
                            placeholder2: '',
                            value: '',
                            optionsType: 'countries',
                            options: [],
                        },
                        label: 'Full name',
                        margin: { top: 10, left: 0, right: 0, bottom: 0 },
                    },
                    advanced: {}
                }
            },
            {
                tag: 'formElement',
                type: 'formElement',
                id: 0,
                step: 1,
                attributes: {
                    basic: {
                        inputType: 'input',
                        props: {
                            required:true,
                            showLabel: true,
                            dataType: 'email',
                            dataType2: '',
                            placeholder: 'Email',
                            placeholder2: '',
                            value: '',
                            optionsType: 'countries',
                            options: [],
                        },
                        label: 'Email',
                        margin: { top: 10, left: 0, right: 0, bottom: 0 },
                    },
                    advanced: {}
                }
            },
            {
                tag: 'formElement',
                type: 'formElement',
                id: 0,
                step: 1,
                attributes: {
                    basic: {
                        inputType: 'input',
                        props: {
                            required:true,
                            showLabel: true,
                            dataType: 'phone',
                            dataType2: '',
                            placeholder: 'Phone number',
                            placeholder2: '',
                            value: '',
                            optionsType: 'countries',
                            options: [],
                        },
                        label: 'Phone number',
                        margin: { top: 10, left: 0, right: 0, bottom: 0 },
                    },
                    advanced: {}
                }
            },
            {
                tag: 'formSpacer',
                type: 'formSpacer',
                id: 0,
                step: 1,
                attributes: {
                    basic: {
                        label: 'Shipping address',
                        margin: { top: 10, left: 0, right: 0, bottom: 0 },
                    },
                    advanced: {}
                }
            },
            {
                tag: 'formElement',
                type: 'formElement',
                id: 0,
                step: 1,
                attributes: {
                    basic: {
                        inputType: 'input',
                        props: {
                            required:true,
                            showLabel: true,
                            dataType: 'shipping_address',
                            dataType2: '',
                            placeholder: 'Shipping address',
                            placeholder2: '',
                            value: '',
                            optionsType: 'countries',
                            options: [],
                        },
                        label: 'Address line',
                        margin: { top: 10, left: 0, right: 0, bottom: 0 },
                    },
                    advanced: {}
                }
            },
            {
                tag: 'formElement',
                type: 'formElement',
                id: 0,
                step: 1,
                attributes: {
                    basic: {
                        inputType: 'input',
                        props: {
                            required:true,
                            showLabel: true,
                            dataType: 'shipping_city',
                            dataType2: '',
                            placeholder: 'Shipping city',
                            placeholder2: '',
                            value: '',
                            optionsType: 'countries',
                            options: [],
                        },
                        label: 'City',
                        margin: { top: 10, left: 0, right: 0, bottom: 0 },
                    },
                    advanced: {}
                }
            },
            {
                tag: 'formElement',
                type: 'formElement',
                id: 0,
                step: 1,
                attributes: {
                    basic: {
                        inputType: 'select',
                        props: {
                            required:true,
                            showLabel: true,
                            dataType: 'shipping_country',
                            dataType2: '',
                            placeholder: 'Shipping country',
                            placeholder2: '',
                            value: '',
                            optionsType: 'countries',
                            options: [],
                        },
                        label: 'Country',
                        margin: { top: 10, left: 0, right: 0, bottom: 0 },
                    },
                    advanced: {}
                }
            },
            {
                tag: 'formElement',
                type: 'formElement',
                id: 0,
                step: 1,
                attributes: {
                    basic: {
                        inputType: 'double',
                        label: 'State / Province',
                        label2: 'ZIP code',
                        props: {
                            required:true,
                            showLabel: true,
                            dataType: 'shipping_state',
                            dataType2: 'shipping_zip',
                            placeholder: 'Shipping state',
                            placeholder2: 'Shipping zip code',
                            value: '',
                            optionsType: 'countries',
                            options: [],
                        },
                        margin: { top: 10, left: 0, right: 0, bottom: 0 },
                    },
                    advanced: {}
                }
            },
            {
                tag: 'formButton',
                type: 'formButton',
                id: 0,
                step: 1,
                attributes: {
                    basic: {
                        content: 'Go to step #2',
                        subHeadline: '',
                        margin: { top: 20, left: 0, right: 0, bottom: 0 },
                        font: { fontFamily: null, fontSize: 18, color: '#F6F9FE', fontStyling: { bold: false, italic: false, 'line-through': false, underline: false, }, textAlign:'center' },
                        linkUrl: { mainToggle:"submit-1st-step", target:"_parent", url:"#submit-1st-step" },
                    },
                    advanced: {
                        displayType: 'block',
                        padding: { top: 15, left: 30, right: 30, bottom: 15 },
                    }
                }
            },
            {
                tag: 'paragraph',
                type: 'paragraph',
                id: 0,
                step: 1,
                content: '<p>We respect your privacy & information</p>',
                attributes: {
                    basic: {
                        subHeadline: '',
                        margin: { top: 10, left: 0, right: 0, bottom: 0 },
                        font: { fontFamily: null, fontSize: 12, color: '#97aacd', fontStyling: { bold: false, italic: false, 'line-through': false, underline: false, }, textAlign:'center' },
                    },
                    advanced: {
                        displayType: 'block',
                        padding: { top: 5, left: 0, right: 0, bottom: 5 },
                    }
                }
            },
            {
                tag: 'formButton',
                type: 'formButton',
                id: 0,
                step: 2,
                attributes: {
                    basic: {
                        content: 'Complete order',
                        subHeadline: '',
                        margin: { top: 20, left: 0, right: 0, bottom: 0 },
                        font: { fontFamily: null, fontSize: 18, color: '#F6F9FE', fontStyling: { bold: false, italic: false, 'line-through': false, underline: false, }, textAlign:'center' },
                        linkUrl: { mainToggle:"submit", target:"_parent", url:"#submit-step" },
                    },
                    advanced: {
                        displayType: 'block',
                        padding: { top: 15, left: 30, right: 30, bottom: 15 },
                    }
                }
            },
            {
                tag: 'paragraph',
                type: 'paragraph',
                id: 0,
                step: 2,
                content: '<p>100% secure and safe payment</p>',
                attributes: {
                    basic: {
                        subHeadline: '',
                        margin: { top: 10, left: 0, right: 0, bottom: 0 },
                        font: { fontFamily: null, fontSize: 12, color: '#97aacd', fontStyling: { bold: false, italic: false, 'line-through': false, underline: false, }, textAlign:'center' },
                    },
                    advanced: {
                        displayType: 'block',
                        padding: { top: 5, left: 0, right: 0, bottom: 5 },
                    }
                }
            },
        ],
        editorAuth:{
            page:'',
            token:''
        }
    },
    getters: {
        editorAuth({editor}) {
            return editor.editorAuth;
        },
        current_popup({ editor }) {
            return editor.popup
        },
        // Checks if the popup content has changed.
        popupChanged({ editor }) {
            if (!editor.popup) return;

            let popup = JSON.parse(JSON.stringify(editor.popup));
            let configForSave = {
                children: popup.children,
                content: popup.content,
                rows: popup.rows,
                attributes: popup.attributes
            };
            let newConfig = JSON.stringify(configForSave);
            return editor.popup.config !== newConfig;

        },
        page_numbers({ editor }) {
            let numbers = []
            editor.content.forEach(item => numbers.push(item.id));
            editor.rows.forEach(item => numbers.push(item.id));
            editor.children.forEach(item => numbers.push(item.id));
            return numbers
        },
        popup_numbers({ editor }) {
            let numbers = [];
            editor.popup.content.forEach(item => numbers.push(item.id));
            editor.popup.rows.forEach(item => numbers.push(item.id));
            editor.popup.children.forEach(item => numbers.push(item.id));
            return numbers
        },
        numbers({ editor }, getters) {
            let isPopup = !!editor.popup; // if there is an active popup
            return isPopup ? getters.popup_numbers : getters.page_numbers;
        },
        page_findOne({ editor }) {
            return (id,isPopup=false) => {
                let context = (isPopup ? editor.popup : editor)

                return (context.content ? context.content.find(item => item.id === id) : null) ||
                    (context.rows ? context.rows.find(item => item.id === id) : null) ||
                    (context.children ? context.children.find(item => item.id === id) : null)
                    // editor.formChildren.find(item => item.id === id)
            }
        },
        page_findChild({ editor }) {
            return (id, isPopup=false) => {
                return (isPopup ? editor.popup : editor).children.find(item => item.id === id);
            }
        },
        page_findChildren({ editor }) {
            return (ids, isPopup=false) => {
                let blocks = [];
                if (!ids) return [];

                // maintain source ids indices
                ids.forEach(id => {
                    let block = (isPopup ? editor.popup : editor).children.find(item => item.id === id)
                    if (block) blocks.push(block);
                });
                return blocks;
            }
        },
        page_findRows({ editor }) {
            return (ids, isPopup=false) => {
                let rows = [];
                if (!ids) return [];
                // maintain source ids indices
                ids.forEach(id => {
                    let row = (isPopup ? editor.popup : editor).rows.find(item => item.id === id)
                    if (row) rows.push(row);
                });
                return rows;
            }
        },
        page_findRow({ editor }) {
            return (id, isPopup=false) => {
                if (!id) return null;
                return (isPopup ? editor.popup : editor).rows.find(item => item.id === id)
            }
        },
        page_findAll({ editor }) {
            return (isPopup=false) => {
                return (isPopup ? editor.popup : editor).content
            }
        },
        page_sections({ editor }) {
            // console.log("page_sections")
            return editor.content
        },
    },
    mutations: {
        PAGE_SET_EDITOR_AUTH({editor}, value) {
            editor.editorAuth = JSON.parse(JSON.stringify(value || {}))
        },
        emptyState(state, doneState) {
            var editor = doneState ? JSON.parse(doneState.editor) : {
                content: [{id:9}],
                rows: [],
                children: [],
                formChildren: state.formChildren
            };
            var page = doneState.page ? JSON.parse(doneState.page) : {
                basic: {
                    pagePopup: {path:'',showWhen:'exitIntent',param1:'',param2:''},
                    backgroundColor: '#fff',
                    backgroundImage: '',
                    backgroundStyle: 'cover',
                    backgroundPosition: 'top',
                    padding: { top: 0, left: 0, right: 0, bottom: 0 },
                    font: {
                        fontStyling: { bold: false, italic: false, 'line-through': false, underline: false },
                        color: '#252A32',
                        fontFamily: 'Roboto',
                        fontSize: 14,
                    },
                    border: {
                        radius: { bottomLeft: 0, bottomRight: 0, analytics: 0, topRight: 0 },
                        sides: { bottom: true, left: true, right: true, top: true },
                        color: 'transparent',
                        style: 'solid',
                        width: 0
                    }
                },
                advanced: {
                    id: '',
                    cssClasses: [],
                    direction:'ltr',
                }
            }
            var currentTemplate = doneState ? JSON.parse(doneState.currentTemplate) : {
                id: false,
                name: false,
                seo_description: false,
                seo_keywords: '',
                seo_title: false,
            };
            // this.replaceState({editor,page,currentTemplate})
            // applyTemplate(state, JSON.parse(JSON.stringify(state.emptyStateData)))

            state.editor = editor;
            state.page = page;
            state.currentTemplate = currentTemplate;
            // state.editor = JSON.parse(JSON.stringify(editor));
            // state.page = JSON.parse(JSON.stringify(page));
            // state.currentTemplate = JSON.parse(JSON.stringify(currentTemplate));
        },
        //SECTIONS
        PAGE_ADD_BLOCK({ editor }, { item, index/*, isPopup*/ }) {
            item.children = [];
            let context = editor.popup || editor;
            context.content.splice(index, 0, item)
        },
        PAGE_REMOVE_BLOCK({ editor }, value) {
            let context = editor.popup || editor;

            var target = context.content.findIndex(item => item.id === value);
            var targetRows = context.rows.filter(row => row.parent === value);
            var targetChildren = [];
            targetRows.forEach(item => item.cols.forEach(col => targetChildren.push(...col.children)));
            context.children = context.children.filter(child => targetChildren.indexOf(child.id) === -1);
            context.rows = context.rows.filter(row => row.parent !== value);
            context.content.splice(target, 1);

            //CLEAR CHILDREN
            context.children = clearUp(context).children
        },
        PAGE_CLONE_BLOCK({ editor }, { clone, id, newRows, newChildren }) {
            let context = editor.popup || editor;

            context.children.push(...newChildren);
            context.rows.push(...newRows);

            let sectionIndex = context.content.findIndex(item => item.id === id);
            context.content.splice(sectionIndex + 1, 0, clone);
        },

        PAGE_ADD_ELEMENT({ editor }, {newEl,parent,index,col,toBoxIndex,newSection,newRow,newChildren,newBlock,newRows}) {
            let context = editor.popup || editor;

            console.log("newChildren", newChildren)
            console.log("newRows", newRows)
            console.log("newEl", newEl)

            context.children.push(...(newChildren || []));
            context.rows.push(...(newRows || []));

            if (newEl.type==='section') {
                context.content.splice(index, 0, newEl);
            } else if (newEl.type==='row') {
                let parentSection = context.content.find(x=>x.id===parent)
                newEl.parent = parentSection.id
                context.rows.push(newEl);
                parentSection.children.splice(index, 0, newEl.id);
            } else {
                let parentEl = context.rows.find(x=>x.id===parent)
                if (!parentEl) {
                    parentEl = context.children.find(x => x.id === parent)
                    if (parentEl.type==='container') {
                        let box = parentEl.attributes.basic.boxes[toBoxIndex]
                        if (!box.children) Vue.set(box, "children", []);

                        if (box.children.length) box.children.splice(index, 0, newEl.id);
                        else box.children.push(newEl.id);
                    } else
                        parentEl.children.splice(index, 0, newEl.id);
                } else {
                    parentEl.cols[col||0].children.splice(index, 0, newEl.id);
                }
            }
        },
        PAGE_EDIT_BLOCK({ editor }, value) {
            let context = editor.popup || editor;
            let target = context.children.findIndex(item => item.id === value.id);

            context.children.splice(target, 1, value);
        },
        PAGE_EDIT_FIELD({ editor }, { id, field, value, tab, type }) {
            let context = editor.popup || editor;

            if (type === 'section') {
                let targetIndex = context.content.findIndex(item => item.id === id);

                /// WHY JSON this?
                // var saveStr = JSON.stringify(editor.content[targetIndex]);
                // var updatedItem = JSON.parse(saveStr);
                // if (tab) updatedItem.attributes[tab][field] = value;
                // else updatedItem[field] = value;
                // editor.content.splice(targetIndex, 1, updatedItem);

                /// AND NOT THIS?
                // Instead of stringify we update the ref element directly!
                let child = context.content[targetIndex];
                if (tab) {
                    if (!child.attributes[tab]) {
                        Vue.set(child.attributes, tab, {})
                    } // Init tab if null, eg. "hover"
                    Vue.set(child.attributes[tab], field, value)
                } else {
                    Vue.set(child, field, value)
                }
            } else if (type === 'row') {
                let targetIndex = context.rows.findIndex(item => item.id === id);

                /// WHY JSON this?
                // var saveStr = JSON.stringify(editor.rows[targetIndex]);
                // var updatedItem = JSON.parse(saveStr);
                // if (tab) updatedItem.attributes[tab][field] = value;
                // else updatedItem[field] = value;
                //
                // if (field === 'cols') {
                //     var newCols = createCols(value, editor.rows[targetIndex].cols);
                //     updatedItem.cols = newCols;
                // }
                // editor.rows.splice(targetIndex, 1, updatedItem);

                /// AND NOT THIS?
                // Instead of stringify we update the ref element directly!
                let child = context.rows[targetIndex];
                if (tab) {
                    if (!child.attributes[tab]) {
                        Vue.set(child.attributes, tab, {})
                    } // Init tab if null, eg. "hover"
                    Vue.set(child.attributes[tab], field, value)
                } else {
                    Vue.set(child, field, value)
                }
                if (field === 'cols') {
                    var newCols = createCols(value, child.cols);
                    child.cols = newCols;
                }
            } else {
                var targetIndex = context.children.findIndex(item => item.id === id);

                /// WHY JSON this?
                // var saveStr = JSON.stringify(editor.children[targetIndex]);
                // if (saveStr) {
                //     let updatedItem = JSON.parse(saveStr);
                //     if (tab) {
                //         if (!updatedItem.attributes[tab]) updatedItem.attributes[tab] = {}; // Init tab if null, eg. "hover"
                //         updatedItem.attributes[tab][field] = value;
                //     }
                //     else updatedItem[field] = value;
                //     editor.children.splice(targetIndex, 1, updatedItem);
                // }

                /// AND NOT THIS?
                // Instead of stringify we update the ref element directly!
                let child = context.children[targetIndex];
                if (tab) {
                    if (!child.attributes[tab]) {
                        Vue.set(child.attributes, tab, {})
                    } // Init tab if null, eg. "hover"

                    Vue.set(child.attributes[tab], field, value)
                    // child.attributes[tab][field] = value;
                } else {
                    Vue.set(child, field, value)
                }
            }
        },
        PAGE_DROP_BLOCK({ editor }, dropResult) {
            let context = editor.popup || editor;
            context.content = applyDrag(context.content, dropResult);
        },
        //ROWS
        PAGE_ADD_ROW({ editor }, { type, parent, id, cols = false, index }) {
            var colsData = createCols(cols);
            var target;
            var newRow = { id, type, labelTitle: 'My Row', labelColor: '#449CF4',
                attributes: {
                    basic: { cols,
                        shadows:[],
                        backgroundImage:'',
                        backgroundColor:'',
                        padding:{ top: 0, left: 0, right: 0, bottom: 0 },
                        margin:{ top: 0, left: 0, right: 0, bottom: 0 },
                        font: { fontFamily: '', fontSize: 14, color: '#303030', fontStyling: { bold: false, italic: false, 'line-through': false, underline: false, }, textAlign: 'initial' },
                        border: {
                            width: 0,
                            color: 'transparent',
                            style: 'solid',
                            sides: { top: true, left: true, right: true, bottom: true },
                            radius: { analytics: 0, topRight: 0, bottomRight: 0, bottomLeft: 0 },
                        }
                    },
                    advanced: {
                        cssId:'',
                        title:'My Row',
                        cssClasses:[],
                    }
                },
                cols: colsData, };

            // get current active context (popup or editor)
            let context = editor.popup || editor;

            if (parent) target = context.content.find(item => item.id === parent);
            else target = context.content[0] || false;

            if (target) {
                newRow.parent = target.id;
                if (target.children.indexOf(newRow.id) === -1) target.children.splice(index, 0, newRow.id);
                context.rows.push(newRow);
                //router.push({ path: '/basic/' + newRow.id });
            } else alert('Place at least one section please');
        },
        PAGE_CHANGE_COLS({ editor }, { row, cols }) {
            let colsData = [];
            let context = editor.popup || editor;

            switch (cols) {
                case 'one':
                    colsData = [{ width: 100, children: [] }]
                    break;
                case 'two':
                    colsData = [{ width: 50, children: [] }, { width: 50, children: [] }]
                    break;
                case 'three':
                    colsData = [{ width: 33.3333, children: [] }, { width: 33.3333, children: [] }, { width: 33.3333, children: [] }]
                    break;
                case 'four':
                    colsData = [{ width: 25, children: [] }, { width: 25, children: [] }, { width: 25, children: [] }, { width: 25, children: [] }]
                    break;
                case 'five':
                    colsData = [{ width: 75, children: [] }, { width: 25, children: [] }]
                    break;
                case 'six':
                    colsData = [{ width: 25, children: [] }, { width: 75, children: [] }]
                    break;
            }
            let target = context.rows.find(r => r.id === row);
            target.cols = colsData
        },
        PAGE_DROP_ROW({ editor }, { removedIndex, addedIndex, payload }) {
            let context = editor.popup || editor;
            let section = context.content.find(s => s.id === payload.sectionId);
            section.children = applyDrag(section.children, { removedIndex, addedIndex, payload });
        },
        PAGE_DROP_ROW_ADD({ editor }, { from, to, id, item, index }) {
            let context = editor.popup || editor;

            item.parent = to;
            let sectionFrom = context.content.find(s => s.id === from);
            let sectionTo = context.content.find(s => s.id === to);
            let targetIndex = sectionFrom.children.findIndex(t => t === id);
            sectionFrom.children.splice(targetIndex, 1);
            sectionTo.children.splice(index, 0, id);
        },
        PAGE_RESIZE_BOX({ editor }, { containerId, index, width }) {
            let context = editor.popup || editor;

            let targetContainer = context.content.find(r => r.id === containerId);
            let delta = targetContainer.attributes.basic.boxes[index].value - width;
            targetContainer.attributes.basic.boxes[index].value = width
            targetContainer.attributes.basic.boxes[index + 1].value += delta
        },
        PAGE_RESIZE_COL({ editor }, { row, col, width }) {
            let context = editor.popup || editor;

            let targetRow = context.rows.find(r => r.id === row);
            let delta = targetRow.cols[col].width - width;
            targetRow.cols[col].width = width
            targetRow.cols[col + 1].width += delta
        },
        PAGE_REMOVE_ROW({ editor }, { row, index }) {
            let context = editor.popup || editor;

            let trashRow = context.rows.find(r => r.id === row);
            let targetRow = context.rows.findIndex(r => r.id === row);
            let targetSection = context.content.findIndex(item => item.id === context.rows[targetRow].parent);

            context.content[targetSection].children.splice(index, 1);
            context.rows.splice(targetRow, 1);

            //CLEAR CHILDREN
            context.children = clearUp(context).children
        },
        PAGE_CLONE_ROW({ editor }, { clone, id, newChildren }) {
            let context = editor.popup || editor;

            context.children.push(...newChildren);
            context.rows.push(clone);

            let section = context.content.find(item => item.id === clone.parent);
            let ancestorIndex = section.children.findIndex(item => item === id);

            section.children.splice(ancestorIndex+1, 0, clone.id);
        },
        //COMMON BLOCKS

        PAGE_ADD_COMMON({ editor }, { type, row, col, index, id, containerId=0,
            toBoxIndex=0, isBox=false }) {
            let newChild = {};
            let context = editor.popup || editor;

            if (type == "twoStepForm") {
                newChild = {
                    id,
                    type,
                    parent: row,
                    step1Children: [],
                    step2Children: [],
                    labelTitle: 'My Block',
                    labelColor: '#449CF4',
                    attributes: {
                        basic: {
                            margin: {top: 0, left: 0, right: 0, bottom: 0}, previewSteps: 1, font: {
                                fontFamily: '',
                                fontSize: 18,
                                color: '#252A32',
                                fontStyling: {bold: false, italic: false, 'line-through': false, underline: false,},
                                textAlign: 'left'
                            }, font2: {
                                fontFamily: '',
                                fontSize: 13,
                                color: '#C5C7D2',
                                fontStyling: {bold: false, italic: false, 'line-through': false, underline: false,},
                                textAlign: 'left'
                            }, backgroundColor: '#FFFFFF', border: {
                                width: 2,
                                color: '#449CF4',
                                style: 'solid',
                                sides: {top: true, left: true, right: true, bottom: true},
                                radius: {analytics: 16, topRight: 16, bottomRight: 16, bottomLeft: 16},
                            }, shadows: [],
                        }, advanced: {
                            cssId: '', cssClasses: [], padding: {top: 15, left: 15, right: 15, bottom: 15},
                        },
                    },
                };

                for (let index = 0; index < editor.formChildren.length; index++) {
                    var cloneStr = JSON.stringify(editor.formChildren[index])
                    var clone = JSON.parse(cloneStr)
                    clone.id = parseInt(randomstring.generate({length: 5, charset: 'numeric'}))

                    if (clone.step === 1) newChild.step1Children.push(clone.id); else newChild.step2Children.push(clone.id);

                    context.children.push(clone);
                }
            } else {
                newChild = { id, type, parent: isBox ? containerId : row, labelTitle: 'My Block', labelColor: '#449CF4', attributes: { basic: {}, advanced: {} }, };
                let model = findModel(type)
                if (model) {
                    // copy over default content if any (eg. used by Paragraph, Headline...etc).
                    newChild.content = model.content || '';

                    // Apply default props
                    Object.keys(model.attributes.basic).forEach(key => {
                        let value = model.attributes.basic[key];
                        if (typeof value.value === 'object') newChild.attributes.basic[key] = JSON.parse(JSON.stringify(value.value));
                        else newChild.attributes.basic[key] = value.value;
                    });
                    Object.keys(model.attributes.advanced).forEach(key => {
                        let value = model.attributes.advanced[key];
                        if (typeof value.value === 'object') newChild.attributes.advanced[key] = JSON.parse(JSON.stringify(value.value));
                        else newChild.attributes.advanced[key] = value.value;
                    });
                }
            }

            let targetRow = context.rows.find(r => r.id === row);
            context.children.push(newChild);
            if (isBox) {
                let container = context.children.find(c => c.id === containerId)
                if (container.type==='faq') {
                    if (!container.children)
                        Vue.set(container, "children", []);

                    if (container.children.length)
                        container.children.splice(index, 0, id);
                    else container.children.push(id);
                } else {
                    if (!container.attributes.basic.boxes[toBoxIndex].children)
                        Vue.set(container.attributes.basic.boxes[toBoxIndex], "children", []);

                    if (container.attributes.basic.boxes[toBoxIndex].children.length)
                        container.attributes.basic.boxes[toBoxIndex].children.splice(index, 0, id);
                    else container.attributes.basic.boxes[toBoxIndex].children.push(id);
                }
            } else {
                if (targetRow.cols[col].children.length) targetRow.cols[col].children.splice(index, 0, id);
                else targetRow.cols[col].children.push(id);
            }
        },
        PAGE_DROP_COMMON({ editor }, { removedIndex, addedIndex, payload, containerId=0,
            toBoxIndex=0, isBox=false }) {
            let context = editor.popup || editor;

            if (isBox) {
                let container = context.children.find(c => c.id === containerId)
                if (container.type==='faq') {
                    if (!container.children)
                        Vue.set(container, "children", []);

                    container.children =
                        applyDrag(container.children, { removedIndex, addedIndex, payload });
                } else {
                    if (!container.attributes.basic.boxes[toBoxIndex].children)
                        Vue.set(container.attributes.basic.boxes[toBoxIndex], "children", []);

                    container.attributes.basic.boxes[toBoxIndex].children =
                        applyDrag(container.attributes.basic.boxes[toBoxIndex].children, { removedIndex, addedIndex, payload });
                }
            } else {
                let row = context.rows.find(r => r.id === payload.rowFrom);
                let col = payload.colFrom;
                row.cols[col].children = applyDrag(row.cols[col].children, { removedIndex, addedIndex, payload });
            }
        },
        PAGE_DROP_COMMON_ADD({ editor }, { from, colFrom, indexFrom, to, colTo, indexTo, item,
            fromContainerId=-1, toContainerId=-1, fromBoxIndex=-1, toBoxIndex=-1}) {
            let context = editor.popup || editor;

            let rowFrom = from > 0 ? context.rows.find(r => r.id === from) : null;
            let rowTo = to > 0 ? context.rows.find(r => r.id === to) : null;
            let fromContainer = fromContainerId > 0 ? context.children.find(c => c.id === fromContainerId) : null
            let toContainer = toContainerId > 0 ? context.children.find(c => c.id === toContainerId) : null

            let block = context.children.find(c => c.id === item)
            if (!block) {
                console.error("invalid block", item)
                return
            }
            if (fromContainer) {
                if (fromContainer.type==='faq') {
                    if (!fromContainer.children)
                        Vue.set(fromContainer, "children", []);
                    fromContainer.children.splice(indexFrom, 1);
                } else {
                    if (!fromContainer.attributes.basic.boxes[fromBoxIndex].children)
                        Vue.set(fromContainer.attributes.basic.boxes[fromBoxIndex], "children", []);
                    fromContainer.attributes.basic.boxes[fromBoxIndex].children.splice(indexFrom, 1);
                }
            } else {
                rowFrom.cols[colFrom].children.splice(indexFrom, 1);
            }

            if (toContainer) {
                if (toContainer.type==='faq') {
                    if (!toContainer.children)
                        Vue.set(toContainer, "children", []);
                    toContainer.children.splice(indexTo, 0, item);
                } else {
                    if (!toContainer.attributes.basic.boxes[toBoxIndex].children)
                        Vue.set(toContainer.attributes.basic.boxes[toBoxIndex], "children", []);
                    toContainer.attributes.basic.boxes[toBoxIndex].children.splice(indexTo, 0, item);
                }
                block.parent = toContainerId
            } else {
                rowTo.cols[colTo].children.splice(indexTo, 0, item);
                block.parent = to
            }
        },
        PAGE_REMOVE_COMMON({ editor }, { row, col, index, id, containerId=-1, boxIndex=-1 }) {
            let context = editor.popup || editor;
            let targetChild = context.children.findIndex(c => c.id === id);

            if (containerId >= 0) {
                let container = context.children.find(r => r.id === containerId)
                if (!container) return;
                if (container.type==='faq')
                    container.children.splice(index, 1)
                else
                    container.attributes.basic.boxes[boxIndex].children.splice(index, 1)
            } else {
                let targetRow = context.rows.find(r => r.id === row);
                targetRow.cols[col].children.splice(index, 1);
            }
            context.children.splice(targetChild, 1);
        },
        PAGE_CLONE_COMMON({ editor }, { row, col, index, clone, containerId=-1,
            boxIndex=-1, children=[]  }) {
            let context = editor.popup || editor;

            if (containerId >= 0) {
                let container = context.children.find(r => r.id === containerId)
                if (!container) return;
                if (container.type==='faq')
                    container.children.splice(index, 0, clone.id)
                else
                    container.attributes.basic.boxes[boxIndex].children.splice(index, 0, clone.id)
            } else {
                let targetRow = context.rows.find(r => r.id === row);
                targetRow.cols[col].children.splice(index, 0, clone.id);
            }

            if (clone.type==="container"||clone.type==='faq') {
                // append all subsequent children (children includes all container blocks as well)
                context.children.push(...children)
            } else
                context.children.push(clone);
        },
        // FORM CHILDREN
        PAGE_ADD_FORM_CHILDREN({ editor }, { type, form, index, id, step = 1, attributes = false, content='' }) {
            let newFormChild = {};
            let context = editor.popup || editor;

            if (step && attributes) {
                newFormChild = { id, type, tag: type, step, labelTitle: 'My Block', content:content, labelColor: '#449CF4', attributes };
            } else {
                newFormChild = { id, type, tag: type, labelTitle: 'My Block', labelColor: '#449CF4', attributes: { basic: {}, advanced: {} }, };
            }

            context.children.push(newFormChild);

            let targetForm = context.children.find(r => r.id === form);
            if (step===1) {
                if (targetForm.step1Children.length && index) targetForm.step1Children.splice(index, 0, id);
                else targetForm.step1Children.push(id);
            } else {
                if (targetForm.step2Children.length && index) targetForm.step2Children.splice(index, 0, id);
                else targetForm.step2Children.push(id);
            }

        },
        PAGE_MOVE_FORM_CHILD({ editor }, { dropResult, form, step }) {
            let context = editor.popup || editor;
            let targetForm = context.children.find(r => r.id === form);

            if (step===1) targetForm.step1Children = applyDrag(targetForm.step1Children, dropResult)
            else targetForm.step2Children = applyDrag(targetForm.step2Children, dropResult)
        },
        PAGE_REMOVE_FORM_CHILDREN({ editor }, { itemId, itemIndex, form, stepNumber }) {
            let context = editor.popup || editor;
            let targetForm = context.children.find(r => r.id === form);
            let targetChild = context.children.findIndex(c => c.id === itemId);

            if (stepNumber===2) {
                let index = targetForm.step2Children.findIndex(x => x===itemId);
                if (index>=0) targetForm.step2Children.splice(index, 1);
            } else {
                let index = targetForm.step1Children.findIndex(x => x===itemId);
                if (index>=0) targetForm.step1Children.splice(index, 1);
            }
            context.children.splice(targetChild, 1);
        },
        PAGE_APPLY_CUSTOM_CODE({ editor }, newItems) {
            //update children content
            newItems.children.forEach((child, index) => {
                var target = editor.children.find(c => c.id === child.id);

                // {type: "button", row: 55292, col: 0, index: 0, id: 97691}
                if (target === undefined) {
                    console.log('PAGE_APPLY_CUSTOM_CODE child:', child);
                    // PAGE_ADD_COMMON({type: "button", row: 55292, col: 0, index: 0, id: 97691});
                    return
                }

                child.content ? target.content = child.content : '';

                if (target.type === 'link') {
                    target.attributes.basic.content = child.basic.content
                    if (target.attributes.basic.linkUrl.url !== undefined) {
                        target.attributes.basic.linkUrl.url = child.basic.linkUrl.url
                    }
                }

                if (child.basic) {
                    for (var prop in child.basic) {
                        target.attributes.basic[prop] = child.basic[prop]
                    }
                }

                if (child.advanced) {
                    for (var prop in child.advanced) {
                        target.attributes.advanced[prop] = child.advanced[prop];
                    }
                }
            });
        },
        PAGE_CLEARUP({ editor }) {
            let alive = [];
            let context = editor.popup || editor;

            context.rows.forEach(row => {
                row.cols.forEach(col => alive = [...alive, ...col.children]);
            })
            context.children = context.children.filter(child => alive.includes(child.id));
        }
    },
    actions: {
        //SECTIONS
        PAGE_SET_EDITOR_AUTH(context, value) {
            context.commit('PAGE_SET_EDITOR_AUTH', value);
        },
        PAGE_ADD_BLOCK(context, value) {
            let index = value.addedIndex;
            let width = value.width || 'full';
            let item = { type: 'section' };

            item.id = generateId(context.getters.numbers);
            item.attributes = { basic: { width }, advanced: {} };
            item.children = [];
            //console.log({ item });
            context.commit('PAGE_ADD_BLOCK', { item, index });
        },
        PAGE_ADD_ELEMENT(context,
                         value={
            element:null,index:0,col:0,
                             containerId: null,
                             toBoxIndex: 0,
                             parent:null}) {
            let element = value.element;

            const cloneSection = (el) => {
                let newRows = [], newChildren = [];
                let newSection = JSON.parse(el.structure);
                let oldChildren = JSON.parse(el.children);
                let rows = JSON.parse(el.rows);

                console.log("cloneSection newSection", newSection)
                console.log("cloneSection oldChildren", oldChildren)
                console.log("cloneSection rows", rows)

                newSection.id = generateId(context.getters.numbers);

                let allIds = [newSection.id];
                let newRowIds = [];

                newSection.children.forEach(oldRowId => {
                    let oldRow = rows.find(x=>x.id===oldRowId);
                    if (!oldRow) return;

                    //! HINT: no need for dereferencing (it's done above for all).
                    let newRow = oldRow
                    newRow.id = generateId([...context.getters.numbers, ...allIds])
                    newRow.parent = newSection.id;

                    allIds.push(newRow.id);
                    newRows.push(newRow)
                    newRowIds.push(newRow.id)

                    newRow.cols.forEach(col => {
                        let colChildrenIds = [];

                        col.children.forEach(childId => {
                            let child = oldChildren.find(x=>x.id===childId)
                            if (!child) return; // nothing to do

                            let res = cloneBlock(child, oldChildren, allIds)
                            res.newBlock.parent = newRow.id;
                            colChildrenIds.push(res.newBlock.id);
                            newChildren.push(...res.newChildren);
                            res.newChildren.forEach(x=>allIds.push(x.id))
                        });
                        // add ids into col.children.
                        col.children = colChildrenIds;
                    });
                });
                newSection.children = newRowIds;

                return {newSection,newChildren,newRows}
            }
            const cloneRow = (el) => {
                let newChildren = [];

                let newRow = JSON.parse(el.structure);
                let oldChildren = JSON.parse(el.children);

                newRow.id = generateId(context.getters.numbers);

                let allIds = [newRow.id];

                newRow.cols.forEach(col => {
                    let colChildrenIds = [];

                    col.children.forEach(childId => {
                        let child = oldChildren.find(x=>x.id===childId)
                        if (!child) return; // nothing to do

                        let res = cloneBlock(child, oldChildren, allIds)
                        res.newBlock.parent = newRow.id;
                        colChildrenIds.push(res.newBlock.id);
                        newChildren.push(...res.newChildren);
                        res.newChildren.forEach(x=>allIds.push(x.id))
                    });
                    // add ids into col.children.
                    col.children = colChildrenIds;
                });

                return {newRow,newChildren}
            }
            const cloneEl = (el) => {
                let block = JSON.parse(el.structure);
                let oldChildren = JSON.parse(el.children);

                return cloneBlock(block, oldChildren, [])
            }

            const cloneBlock = (block, oldChildren, allIds=[]) => {
                let newBlock = JSON.parse(JSON.stringify(block));
                newBlock.id = generateId([...context.getters.numbers, ...allIds]);

                let newChildren = [];
                let ids = []; // used to prevent collision

                if (block.type==='twoStepForm') {
                    let chank1 = [], chank2 = [];
                    block.step1Children && block.step1Children.forEach(c => {

                        // Clone each child and add to global children list.
                        let oldChild = oldChildren.find(x=>x.id===c);
                        if (!oldChild) return;

                        let res = cloneBlock(oldChild, oldChildren, [...ids, ...allIds])
                        if (res) {
                            ids.push(res.newBlock.id)
                            res.newBlock.parent = newBlock.id;
                            chank1.push(res.newBlock.id)
                            newChildren.push(...res.newChildren) // it includes newBlock too.
                        }
                    });
                    block.step2Children && block.step2Children.forEach(c => {

                        // Clone each child and add to global children list.
                        let oldChild = oldChildren.find(x=>x.id===c);
                        if (!oldChild) return;

                        let res = cloneBlock(oldChild, oldChildren, [...ids, ...allIds])
                        if (res) {
                            ids.push(res.newBlock.id)
                            res.newBlock.parent = newBlock.id;
                            chank2.push(res.newBlock.id)
                            newChildren.push(...res.newChildren) // it includes newBlock too.
                        }
                    });
                    newBlock.step1Children = chank1;
                    newBlock.step2Children = chank2;
                } else if (block.type==='faq') {
                    let chank = [];
                    block.children && block.children.forEach(c => {

                        // Clone each child and add to global children list.
                        let oldChild = oldChildren.find(x=>x.id===c);
                        if (!oldChild) return;

                        let res = cloneBlock(oldChild, oldChildren, [...ids, ...allIds])
                        if (res) {
                            ids.push(res.newBlock.id)
                            res.newBlock.parent = newBlock.id;
                            chank.push(res.newBlock.id)
                            newChildren.push(...res.newChildren) // it includes newBlock too.
                        }
                    });
                    newBlock.children = chank;
                } else if (block.type==='container') {
                    let newBoxes = [];
                    block.attributes.basic.boxes.forEach(box => {
                        let chank = [];
                        let newbox = JSON.parse(JSON.stringify(box));
                        box.children && box.children.forEach(c => {

                            // Clone each child and add to global children list.
                            let oldChild = oldChildren.find(x=>x.id===c);
                            if (!oldChild) return;

                            let res = cloneBlock(oldChild, oldChildren, [...ids, ...allIds])
                            if (res) {
                                ids.push(res.newBlock.id)
                                res.newBlock.parent = newBlock.id;
                                chank.push(res.newBlock.id)
                                newChildren.push(...res.newChildren) // it includes newBlock too.
                            }
                        });
                        newbox.children = chank;
                        newBoxes.push(newbox);
                    });
                    newBlock.attributes.basic.boxes = newBoxes
                }
                else {
                    // normal block do nothing else.
                }

                newChildren.push(newBlock) // append the new container block itself.
                return {newBlock,newChildren}
            }

            if (!element.element_type || !element.structure) {
                throw "bad element " + value
            }

            let res = {parent:{},newSection:{}, newRow:{}, newChildren:[],newBlock:{},newRows:[]}
            let newEl = null;
            if (element.element_type==='section') {
                res = cloneSection(element)
                newEl = res.newSection
            } else if (element.element_type==='row') {
                res = cloneRow(element)
                newEl = res.newRow
            } else {
                res = cloneEl(element)
                res.newBlock.parent = value.parent;
                newEl = res.newBlock
            }

            context.commit('PAGE_ADD_ELEMENT', { index: value.index,
                containerId: value.containerId, toBoxIndex: value.toBoxIndex,
                col:value.col, parent:value.parent, newEl, ...res });

            context.commit('PAGE_SET_TABID', {tab:'basic',id:newEl.id,block:newEl});
        },
        PAGE_REMOVE_BLOCK(context, value) {
            context.commit('PAGE_REMOVE_BLOCK', value);
        },

        // section.
        PAGE_CLONE_BLOCK(context, value) {
            let newSection = null;
            let newRows = [];
            let newChildren = [];
            let id = value.id;

            if (value.element_type && value.structure) {
                newSection = JSON.parse(value.structure);
                let children = JSON.parse(value.children);
                let rows = JSON.parse(value.rows);

                newSection.id = generateId(context.getters.numbers);

                let allIds = [newSection.id];
                let newRowIds = [];

                newSection.children.forEach(oldRowId => {
                    let oldRow = rows.find(x=>x.id===oldRowId);
                    if (!oldRow) return;

                    //! HINT: no need for dereferencing.
                    let newRow = oldRow //JSON.parse(JSON.stringify(oldRow))
                    newRow.id = generateId([...context.getters.numbers, ...allIds])
                    newRow.parent = newSection.id;

                    allIds.push(newRow.id);
                    newRows.push(newRow)
                    newRowIds.push(newRow.id)

                    newRow.cols.forEach(col => {
                        let colChildrenIds = [];

                        col.children.forEach(childId => {
                            let child = children.find(x=>x.id===childId)
                            if (!child) return; // nothing to do

                            if (child.type==='container'||child.type==='faq') {
                                let {clone,children} = cloneContainer(context, child, allIds)
                                clone.parent = newRow.id;
                                colChildrenIds.push(clone.id);
                                newChildren.push(...children);
                                children.forEach(x=>allIds.push(x.id))
                            } else {
                                let newChild = JSON.parse(JSON.stringify(child));
                                newChild.id = generateId([...context.getters.numbers,...allIds]);
                                newChild.parent = newRow.id;
                                colChildrenIds.push(newChild.id);
                                newChildren.push(newChild);
                                allIds.push(newChild.id)
                            }
                        });
                        // add ids into col.children.
                        col.children = colChildrenIds;
                    });
                });
                newSection.children = newRowIds;
            } else {
                newSection = JSON.parse(JSON.stringify(value));
                newSection.id = generateId(context.getters.numbers);

                let allIds = [newSection.id];
                let newRowIds = [];

                newSection.children.forEach(oldRowId => {
                    let oldRow = context.getters.page_findOne(oldRowId, !!context.getters.current_popup);
                    if (!oldRow) return;

                    let newRow = JSON.parse(JSON.stringify(oldRow))
                    newRow.id = generateId([...context.getters.numbers, ...allIds])
                    newRow.parent = newSection.id;

                    allIds.push(newRow.id);
                    newRows.push(newRow)
                    newRowIds.push(newRow.id)

                    newRow.cols.forEach(col => {
                        let colChildrenIds = [];

                        col.children.forEach(c => {
                            let child = context.getters.page_findChild(c, !!context.getters.current_popup)
                            if (!child) return; // nothing to do

                            if (child.type==='container'||child.type==='faq') {
                                let {clone,children} = cloneContainer(context, child, allIds)
                                clone.parent = newRow.id;
                                colChildrenIds.push(clone.id);
                                newChildren.push(...children);
                                children.forEach(x=>allIds.push(x.id))
                            } else {
                                let newChild = JSON.parse(JSON.stringify(child));
                                newChild.id = generateId([...context.getters.numbers,...allIds]);
                                newChild.parent = newRow.id;
                                colChildrenIds.push(newChild.id);
                                newChildren.push(newChild);
                                allIds.push(newChild.id)
                            }
                        });
                        // add ids into col.children.
                        col.children = colChildrenIds;
                    });
                });
                newSection.children = newRowIds;
            }

            context.commit('PAGE_CLONE_BLOCK', { clone:newSection, id, newRows, newChildren });

            context.commit('PAGE_SET_TABID', {tab:'basic',id:newSection.id,block:newSection});
        },
        PAGE_EDIT_BLOCK(context, value) {
            context.commit('PAGE_EDIT_BLOCK', value);
        },
        PAGE_EDIT_FIELD(context, value) {
            context.commit('PAGE_EDIT_FIELD', value);
        },
        PAGE_DROP_BLOCK(context, value) {
            context.commit('PAGE_DROP_BLOCK', value);
        },
        //ROWS
        PAGE_ADD_ROW(context, value) {
            value.id = generateId(context.getters.numbers);
            context.commit('PAGE_ADD_ROW', value);
        },
        PAGE_CHANGE_COLS(context, value) {
            context.commit('PAGE_CHANGE_COLS', value)
        },
        PAGE_DROP_ROW(context, value) {
            context.commit('PAGE_DROP_ROW', value);
        },
        PAGE_DROP_ROW_ADD(context, value) {
            context.commit('PAGE_DROP_ROW_ADD', value);
        },
        PAGE_CLONE_ROW(context, value) {
            let newRow = JSON.parse(JSON.stringify(value));
            let id = value.id;

            newRow.id = generateId(context.getters.numbers);
            // newRow.parent = // it's the same parent.

            let newChildren = [];
            let allIds = [newRow.id]; // used to prevent collision.

            newRow.cols.forEach(col => {
                let colChildrenIds = [];

                col.children.forEach(c => {
                    let child = context.getters.page_findChild(c, !!context.getters.current_popup)
                    if (!child) return; // nothing to do

                    if (child.type==='container'||child.type==='faq') {
                        let {clone,children} = cloneContainer(context, child, allIds)
                        clone.parent = newRow.id;
                        colChildrenIds.push(clone.id);
                        newChildren.push(...children);

                        children.forEach(x=>allIds.push(x.id))
                    } else {
                        let newChild = JSON.parse(JSON.stringify(child));
                        newChild.id = generateId([...context.getters.numbers,...allIds]);
                        newChild.parent = newRow.id;
                        colChildrenIds.push(newChild.id);
                        newChildren.push(newChild);
                        allIds.push(newChild.id)
                    }
                });
                // add ids into col.children.
                col.children = colChildrenIds;
            });

            context.commit('PAGE_CLONE_ROW', { clone:newRow, id, newChildren });

            context.commit('PAGE_SET_TABID', {tab:'basic',id:newRow.id,block:newRow});

        },
        //COMMON BLOCKS
        PAGE_ADD_COMMON(context, value) {
            value.id = generateId(context.getters.numbers);
            context.commit('PAGE_ADD_COMMON', value);
            let el = context.getters.page_findOne(value.id)
            context.commit('PAGE_SET_TABID', {tab: 'basic', id: value.id, block:el});
        },
        PAGE_DROP_COMMON(context, value) {
            context.commit('PAGE_DROP_COMMON', value);
        },
        PAGE_DROP_COMMON_ADD(context, value) {
            context.commit('PAGE_DROP_COMMON_ADD', value);
        },
        PAGE_REMOVE_COMMON(context, value) {
            context.commit('PAGE_REMOVE_COMMON', value);
        },
        PAGE_CLONE_COMMON(context, value) {
            // Clone container recursively.
            if (value.item.type==="container"||value.item.type==='faq') {
                let {clone, children} = cloneContainer(context, value.item)
                // clone.parent = // it's the same.
                value.children = children
                value.clone = clone
            } else {
                let newBlock = JSON.parse(JSON.stringify(value.item));
                newBlock.id = generateId(context.getters.numbers);
                value.clone = newBlock;
            }
            context.commit('PAGE_CLONE_COMMON', value);
            // PAGE_SET_TABID(state, { tab, id, block })
            context.commit('PAGE_SET_TABID', {tab:'basic',id:value.clone.id,block:value.clone});
        },
        // FORM CHILDREN
        PAGE_ADD_FORM_CHILDREN(context, value) {
            value.id = generateId(context.getters.numbers);
            context.commit('PAGE_ADD_FORM_CHILDREN', value);
        },
        PAGE_DROP_FORM_CHILDREN(context, value) {
            context.commit('PAGE_DROP_COMMON_ADD', value);
        },
        PAGE_REMOVE_FORM_CHILDREN(context, value) {
            context.commit('PAGE_REMOVE_COMMON', value);
            // context.commit('PAGE_REMOVE_FORM_CHILDREN', value);
        },
        PAGE_APPLY_CUSTOM_CODE(context, value) {
            context.commit('PAGE_APPLY_CUSTOM_CODE', value);
        },
    }
}
