import button from './button.js'
import custom from './custom.js'
import headline from './headline.js'
import image from './image.js'
import link from './link.js'
import menu from './menu.js'
import paragraph from './paragraph.js'
import row from './row.js'
import section from './section.js'
import social from './social.js'
import spacer from './spacer.js'

export default {
    button,
    custom,
    headline,
    image,
    link,
    menu,
    paragraph,
    row,
    section,
    social,
    spacer,
}