<template>
	<div id="app">
		<global-header />

		<notifications classes="notification" group="main" position="top center">
			<template v-slot:body="props" >
				<div :class="{'notification layout-row':true, 'warn':props.item.type==='warn', 'error':props.item.type==='error'}"
					 @click="notificationClicked(props)" >
					<div class="icon flex-none" >
						<inline-svg v-if="props.item.type==='warn'" :src="require('@/assets/img/alert-circle-outline.svg')"/>
						<inline-svg v-else-if="props.item.type==='error'" :src="require('@/assets/img/alert_icon_red.svg')"/>
						<inline-svg v-else :src="require('@/assets/img/circle-checked-nc.svg')"/>
					</div>
					<div class="flex title" v-html="props.item.title">
					</div>
					<a @click="props.close" class="close " v-if="!props.item.data || !props.item.data.noClose">
						<inline-svg :src="require('@/assets/img/popup_close.svg')"/>
					</a>
				</div>
			</template>
		</notifications>


    <loading v-if="loading" />
		<component v-else :is="layout">
			<router-view></router-view>
		</component>

		<cloning-funnel-popup v-if="createShareFunnel" :funnel="share_funnel" :code="share_code"
		                      @done="shareDone" @failed="shareFailed" />
		<new-trial-popup v-else-if="onboarding" />

		<portal-target name="popup">
			<!--
			popups are rendered here
			-->
		</portal-target>

		<hard-refresh-popup />
	</div>
</template>


<script>
import MainLayout from "@/layouts/MainLayout.vue"
import EmptyLayout from "@/layouts/EmptyLayout.vue"
import StepsLayout from "@/layouts/StepsLayout.vue"
import StepsChildrenLayout from "@/layouts/StepsChildrenLayout.vue"
import EmailLayout from "@/layouts/EmailLayout.vue"
import PageLayout from "@/layouts/PageLayout.vue"

import API from "@/api"
import {mapGetters} from "vuex";
import NewTrialPopup from "@/components/popups/new-trial-popup";
import {capitalize} from "@/helpers/string";
import CloneFunnelPopup from "@/components/popups/clone-funnel-popup";
import CloningFunnelPopup from "@/views/funnels/funnel-plus/funnel/cloning-funnel-popup";

import CancelOrder from '@/views/orders/cancel-order';
import FulfillOrder from '@/views/orders/fulfill-order';
import DeleteOrder from '@/views/orders/delete-order';
import OrderDetailsNew from '@/views/orders/order-details-new';
import AddTrackingInfo from '@/views/orders/add-tracking-info';
import {identify} from "@/helpers/trackers";
import {validateUser} from "@/helpers/roles";
import HardRefreshPopup from "@/components/hard-refresh-popup";
import GlobalHeader from "@/components/layout/global-header";
import Loading from "@/components/Loading.vue";
import moment from "moment-timezone";

export default {
	name: "App",
	title:"Funnelish™",
	components: {
    Loading,
		GlobalHeader,
		HardRefreshPopup,
		CancelOrder,
		FulfillOrder,
		DeleteOrder,
		OrderDetailsNew,
		AddTrackingInfo,

		CloningFunnelPopup,
		CloneFunnelPopup,
		NewTrialPopup,
		EmptyLayout,
		MainLayout,
		StepsLayout,
		StepsChildrenLayout,
		EmailLayout,
		PageLayout,
	},
	mixins: [API],
	data(){
		return {
      loading:true,
			createShareFunnel: false,
			notificationOpen: false,
			share_funnel: 0,
			share_code: '',
			user: {},
		}
	},
	computed: {
		onboarding() {
			if (!this.$store.state.userInfo || !this.$store.state.userInfo.id) return false;

			// if there is a shopify redirect query then prevent default onboarding for now.
			// let shopifyQuery = localStorage.getItem("shopifyQuery");
			// if (shopifyQuery) return false

			return this.$store.state.userInfo.onboarding_finished === false;
		},
		layout() {
			return (this.$route.meta.layout || "empty") + "-layout"
		},
		routeName() {
			return this.$route.name
		},
		...mapGetters['getUserInfo']
	},
  methods: {
	  shareDone(funnel) {
		  this.$router.push({path: `/wfunnel/${funnel.id}`});

		  this.createShareFunnel = false;
		  this.share_funnel = '';
		  this.share_code = "";
	  },
	  shareFailed() {
		  this.createShareFunnel = false;
		  this.share_funnel = '';
		  this.share_code = "";
	  },
	  setupIntercom(user) {
      if (window.location.host.includes('localhost') || this.$route.path.startsWith("/email-editor")) {
        return // don't run intercom on email editor.
      }
      if (user && user.member) {
	      let created = new Date(user.created_at).getTime() / 1000;

	      window.Intercom("boot", {
		      app_id: "htv3ae46",
		      name: user.member.first_name, // Full name
		      email: user.member.email, // Email address
		      created_at: created // Signup date as a Unix timestamp
	      });
      } else {
	      window.Intercom("boot", {
		      app_id: "htv3ae46",
	      });
      }
    },
	  notificationClicked(props) {
	  	if (this.notificationOpen) {
	  		if (this.$route.path.indexOf("/settings/billing") < 0) this.$router.push('/settings/billing#upgrade')
		  } else
			  props.close();
	  },
	  showNotification(msg, type) {
		  this.notificationOpen = true;

		  this.$notify({
			  group: 'main',
			  data:{
				  noClose: true,
			  },
			  title: msg,
			  type: type,
			  duration: -1,
		  })
	  },
	  checkExpiry() {
		  let expiryS = this.user.plan_expiry_date;
		  if (expiryS) {
			  let msg = 'Your plan has expired, <b><u>update your billing details</u></b>.';
			  let today = moment((new Date()).toUTCString());
			  let expiry = moment(expiryS);
			  let daysLeft = expiry.diff(today, 'days', false)+1;

			  if ((this.user.plan_key || '').includes('trial')) {
				  console.log("daysLeft", daysLeft, ", expiry:", expiry, ", today:",today)
				  if (daysLeft <= 1) {
					  console.error("trial expired, ", this.user.id, expiry)

					  msg = 'Your trial has expired. <b><u>Select a plan</u></b> to continue using the platform..';
				  } else if (daysLeft < 3) {
					  msg = "Your trial is about to expire, <b><u>Select a plan</u></b> to continue using our platform.";
				  } else {
					  return; // do nothing...
				  }
          this.showNotification(msg, "warn");
			  } else {
          if (daysLeft <= 1) {
            msg = 'Your plan has expired, <b><u>Select a new plan</u></b> to continue using the platform.';
            console.error("subscription expired, ", this.user.id, expiry)
            this.showNotification(msg, "error");
          } else {
            msg = 'Your plan is set to expire, <b><u>update your billing details</u></b> to continue using the platform.';
            console.error("subscription about to expire, ", this.user.id, expiry)
            this.showNotification(msg, "warn");
          }

        }
		  }
	  },
	  checkRateLimit() {
		  let rateLimit = this.user.rate_limit;
		  if (rateLimit) {
		  	let msg = capitalize(rateLimit) + ', <b><u>upgrade your account</u></b> to continue using our platform.'
			  this.showNotification(msg, "warn");
		  }
	  },
	  checkShareFunnel() {
	  	// only check for share funnel on dashboard (after login or sign up).
		  // console.log("this.$route.path", this.$route.path)
		  if (this.$route.path !== '/') {
		  	return
		  }

		  this.share_funnel = localStorage.getItem("share_funnel");
		  this.share_code = localStorage.getItem("share_code");
			if (this.share_code && this.share_funnel) {
				// clone funnel....
				localStorage.removeItem("share_funnel");
				localStorage.removeItem("share_code");

				this.createShareFunnel = true;
			}
	  },

	  async setup(){
      this.setupIntercom(this.user);

      identify(this.user)

      if (!this.user) return;

		  this.checkExpiry();

		  this.checkRateLimit();

		  this.checkShareFunnel();

		  if (this.onboarding && this.$route.path !== '/get-started') {
			  this.$router.push('/get-started')
		  }

	  },
    forceLogout() {
      localStorage.removeItem('user-token');
      localStorage.removeItem("accountId");

      this.$store.dispatch('SET_TOKEN', false);
      this.$store.dispatch('SET_USER', false);
      this.$store.dispatch('setUserInfo', false);

      this.$router.replace("/log-in?src=invalid-token")
    },

    async loadMe() {
      const moment = require('moment-timezone');

      try {
        this.loading = true

        if (!localStorage.getItem("user-token")) {
          return
        }

        let user = await this.api.users.me();
        if (!user || user.error) {
          this.forceLogout()
          return
        }

        this.user = user;
        this.$store.dispatch('setUserInfo', user);

        this.$store.state.accountId = user.id;
        localStorage.setItem("accountId", user.id);

        if (!this.user) return;

        // set default timezone.
        console.log("tz set:", this.user.time_zone)
        moment.tz.setDefault(this.user.time_zone || "Europe/London");

      } catch (e) {
        moment.tz.setDefault("Europe/London");
        this.$error(e)
      } finally {
        this.loading = false
      }
    }
  },
  watch: {
    '$route': function () {
	    this.checkShareFunnel();

	    if (!validateUser(this.user, this.$route)) {
		    this.user = null;
		    this.$store.dispatch('setUserInfo', {});

		    this.$store.state.accountId = 0;
		    localStorage.setItem("accountId", 0);
		    return
	    }

	    if (this.$route.path.startsWith("/email-editor")) {
      } else {
        window.Intercom("update");
      }
    }
  },
  async created() {
    await this.loadMe();

    await this.setup();

    // There is a logged in user.
    // if (this.user && this.user.id) {
    //   if (!this.verified) {
    //     this.$warn("", "Please verify your account email before you proceed.")
    //   }
    // }

		this.$gtag.linker({
			'domains': ['funnelish.com','app.funnelish.com','docs.funnelish.com','community.funnelish.com','go.funnelish.com']
		});
	},
}
</script>

<style lang="scss" scoped>
#app {
	height: 100%;
	overflow: hidden;
	margin: 0;
	width: 100%;
	display: flex;
	flex-direction: column;
}
</style>
<style lang="scss">
	@import 'styles/app.scss';

	body .HW_badge_cont {
		position: absolute;
		top: 0;
		right: 0;
		width: 23px;
		height: 23px;
	}
	body .HW_badge {
		background: #fb595b;
		height: 14px;
		width: 14px;
		position: absolute;
		top: 0;
		left: auto;
		right: 0;
		transform-origin: right top;
	}
	body .HW_badge.HW_softHidden {
		background: #c5c7d2;
	}
</style>
