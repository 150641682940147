import {
    shadows, border, margin, cssId, cssClasses, id, size, backgroundColor, sticky
} from './fields/'

export default {
    tag: 'imageSlider',
    labelTitle: '',
    labelColor: '',
    id,
    attributes: {
        basic: {
            margin,
            previewProductImage: { title: 'Preview product image', comp: 'previewImageProps', value: false },
            fitImage: { title: 'Fit image', comp: 'selectItem', value: 'scale-down', options: [
                    { id: '', text: 'Default' },
                    { id: 'cover', text: 'Cover' },
                    { id: 'fill', text: 'Fill' },
                    { id: 'scale-down', text: 'Scale down' },
                    { id: 'contain', text: 'Contain' },
                    { id: 'none', text: 'None' },
                ] },
            size: {
                title: 'Image size',
                comp: 'SizeValue',
                placeholder: 'auto',
                value: {
                    width: null,
                    height: 300
                }
            },
            thumbSize: {
                title: 'Thumbnails size',
                comp: 'SizeValue',
                placeholder: 'auto',
                value: {
                    width: 60,
                    height: 60
                }
            },
            imageBorder: {
                title: 'Image border',
                comp: 'border',
                value: {
                    width: null,
                    color: null,
                    style: 'solid',
                    sides: { top: true, left: true, right: true, bottom: true },
                    radius: { topLeft: null, topRight: null, bottomRight: null, bottomLeft: null },
                }
            },
            // thumbnailsBorder: {
            //     title: 'Thumbnails border',
            //     comp: 'border',
            //     value: {
            //         width: null,
            //         color: null,
            //         style: 'solid',
            //         sides: { top: true, left: true, right: true, bottom: true },
            //         radius: { topLeft: null, topRight: null, bottomRight: null, bottomLeft: null },
            //     }
            // },
        },
        advanced: {
            cssId,
            cssClasses,
            backgroundColor,
            border,
            shadows,
            sticky

        },
    },
}
