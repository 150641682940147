<template>
	<div class="layout-column flex">
		<div id="app-email-editor" class="app-email-editor">
			<v-style>{{ styleInTag }}</v-style>
			<component :is="layout">
				<router-view></router-view>
			</component>
		</div>
	</div>

</template>

<script>

import MainLayout from "./src/layouts/MainLayout.vue"
import PreviewLayout from "./src/layouts/PreviewLayout.vue"

import coreCss from './src/models/coreCss.js';

export default {
	name: "email-editor",
	components: {
		MainLayout,
		PreviewLayout,
	},
	data(){
		return {
			haveFontStyles: false,
		}
	},
	computed: {
		styleInTag() {
			return coreCss.css
		},
		layout() {
            var result;
            (this.$route.meta.layout && this.$route.meta.layout !== 'email') ? result = this.$route.meta.layout : result = 'main'
			return result + "-layout"
		},
	},
	beforeDestroy() {
		//console.log('emailEditor beforeDestroy');
		localStorage.setItem('email-funnel', null);
		localStorage.setItem('email-automation', null);
		localStorage.setItem('email-user', null);
		localStorage.setItem('email-key', null);
		localStorage.setItem('email-template', -1);

		this.$store.dispatch('EMAIL_APPLY_TEMPLATE', { clear: true });
		this.$store.dispatch( 'EMAIL_SET_INITIAL', { funnel: null, automation: null, token: null, user: null, key: null } );
	},
	methods:{
		addCss(fileName) {

			var head = document.head;
			var link = document.createElement("link");

			link.rel = "stylesheet";
			link.href = fileName;

			head.appendChild(link);
		}
	},
}
</script>
<style lang="scss" >
.app-email-editor {
	@import "./src/styles/main";
}
</style>
