<template>
  <popup title="Are you sure you'd like to delete this customer?" @close="$emit('close')">
	  <template v-slot:content>
		  <p>{{ customer.optin_email }}</p>
	  </template>
    <template v-slot:actions>
      <v-button red border :loading="loading" label="Delete" @click="deleteCustomer"></v-button>
      <a @click="$emit('close')" class="btn">Cancel</a>
    </template>
  </popup>
</template>

<script>
import API from "@/api"
import Popup from "@/components/base/popup";
import VButton from "@/components/base/v-button";

export default {
	name: "CustomerDeletePopup",
	components: {VButton, Popup},
	mixins: [API],
	props: ['customer'],
	data() {
		return {
			loading: false,
		}
	},
	methods: {
		async deleteCustomer() {
			try {
				this.loading = true;
				let result = await this.api.customers.delete(this.customer.id);
				if (result.error) throw result.info.data;
				else {
					this.$store.commit("REMOVE_CUSTOMER", this.customer.id);
					// this.$router.push({path: '/customers'}); // The caller decides what to do with it
				}
				this.$emit("done", this.customer.id);
			} catch (e) {
				console.error("ERROR", e);
				this.$notify({
					type: "error",
					group: "main",
					title: "Something went wrong... Please try again later",
				});
			} finally {
				this.loading = false;
			}
		},
	},
}
</script>

<style>
    .brdr_box_w_checkbox h3 b {
        margin-right: 3px;
    }
</style>
