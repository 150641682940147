export default (config, axios, token) => {

    var call = async function(path, method = 'GET', data) {
        // data ? data = JSON.stringify({ data }) : '';
        const url = config.URL + path;
        try {
            const response = await axios({
                url,
                method,
                data,
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json',
                }
            })
            return response.data
        } catch (error) {
            return { error: true, info: error.response }
        }
    }

    return {

        get: async() =>
            call('gateways'),

        connect: async(gateId, data) =>
            call('gateways/' + gateId + '/connect', 'POST', data),

        disconnect: async(gateId, id, data) =>
            call('gateways/' + gateId + '/disconnect/' + id, 'POST', data),

        getGroupGateways: async(id) =>
            call('gateways?filter%5Bmethod_id%5D=' + id),

        getPaymentMethods: async() =>
            call('payment-method-groups'),

        getUser: async(id) =>
            call(id ? 'user-gateways/' + id : 'user-gateways'),

        patch: async(id, data) =>
            call('user-gateways/' + id, 'PATCH', data),

        create: async(data) =>
            call('user-gateways', 'POST', data),

        delete: async(id) =>
            call('user-gateways/' + id, 'DELETE')

    }
}
