<template>

	<popup-backdrop @close="$emit('close')">
<!--		<loading v-if="loading || loadingCustomer" />-->

		<component :is="panel" :level="0" :customer="customer" :order="order"
		           :fulfillment="fulfillment" :loading="loadingFulfillment || loading"
		           @close="$emit('close')" @done="$emit('close')"/>

		<!--		<slot :level="level+1" :customer="customer" :order="order"-->
<!--		      @close="$emit('close')" @done="$emit('done')"></slot>-->
	</popup-backdrop>
</template>

<script>
import API from "@/api"
import {mapGetters} from "vuex";
import PopupBackdrop from "@/components/base/popup-backdrop";
import Loading from "@/components/Loading";
import CancelOrder from "@/views/orders/cancel-order";
import CustomerDetailsNew from "@/views/customers/customer-details-new";
import FulfillOrder from "@/views/orders/fulfill-order";
import DeleteOrder from "@/views/orders/delete-order";
import AddTrackingInfo from "@/views/orders/add-tracking-info";
import RefundOrder from "@/views/orders/refund-order";

export default {
	name: "intermediate-panel",
	mixins: [API],
	components: {
		RefundOrder,
		AddTrackingInfo, DeleteOrder, FulfillOrder, CustomerDetailsNew, CancelOrder, Loading, PopupBackdrop},
	props:{
		panel:{
			default: String,
		},
		orderId:{
			type: Number,
		},
	},
	data() {
		return {
			loadingImages: false,
			loadingFulfillment: false,
			loading: true,
			loadingCustomer: true,
			fulfillment: {},
			customer: {
				first_name: '',
				last_name: ''
			},
			order: {
				items: [],
			},
		}
	},
	computed:{
		...mapGetters(['getOrdersStore']),
	},
	methods: {
		async loadFulfillment() {
			try {
				this.loadingFulfillment = true;
				this.fulfillment = {};
				let c = await this.api.orders.getFulfillment(this.orderId);
				if (c.error) this.fulfillment = {};
				else this.fulfillment = c;
			} catch (e) {
				this.$warn(e, 'Failed to load fulfillment details! try again later.')
			} finally {
				this.loadingFulfillment = false;
			}
		},
		async loadCustomerBrief() {
			try {
				this.loadingCustomer = true;
				this.customer = {};
				let c = await this.api.customers.get(this.order.customer_id, true);
				if (c.error) this.customer = {};
				else this.customer = c;
				this.$store.dispatch("setCurrentCustomer", this.customer)
			} catch (e) {
				this.$warn(e, 'Failed to load customer details! try again later.')
			} finally {
				this.loadingCustomer = false;
			}
		},
		async loadImages() {
			try {
				this.loadingImages = true;
				let imgs = await this.api.wsteps.getProductImages(this.order.items.map(x=>x.product_id));
				if (imgs.error) throw imgs;
				else {
					imgs.forEach(img=> {
						let item = this.order.items.find(x=>x.product_id===img.id);
						if (item&&img.image_url) {
							this.$set(item, 'image_url', img.image_url);
						}
					})
				}
			} catch (e) {
				this.$warn(e, 'Failed to load product images! try again later.')
			} finally {
				this.loadingImages = false;
			}
		},
		//API
		async init() {
			try {
				this.loading = true;
				this.loadingCustomer = true;
				this.order = {};

				this.order = await this.api.orders.get(this.orderId);
				this.$store.dispatch("setCurrentOrder", this.order)
				this.loadCustomerBrief(); // async
				this.loadFulfillment();
				this.loadImages();

				// if (this.order.is_fulfilled) this.loadFulfillment();
			} catch (e) {
				this.$error(e);
			} finally {
				this.loading = false;
			}
		},
	},
	created() {
		this.init();
	},
}
</script>
<style lang="scss" scoped>
.intermediate-panel {
	padding: 0;
	position: relative;
	max-width: 600px;

	&.archived {
		.title {
			color: $dark-grey;
		}
	}
	&.canceled {
		.title {
			text-decoration: line-through;
		}
	}
	&.level-1 {
		max-width: 580px;
		box-shadow: -5px 0 20px 15px rgba(0, 0, 0, 0.10);
	}
	&.level-2 {
		max-width: 560px;
		box-shadow: -5px 0 20px 15px rgba(0, 0, 0, 0.05);
	}
	.popup_high_right {
		padding: 0;
	}

}
</style>
