import axios from "axios";
import config from "@/config/config";

export default (config, axios, token) => {

    var call = async function(path, method = 'GET', data) {
        data ? data = JSON.stringify(data) : '';
        const url = config.URL + path;
        try {
            const response = await axios({
                url,
                method,
                data,
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json',
                }
            })
            return response.data
        } catch (error) {
            return { error: true, info: error.response }
        }
    }

    return {

        getAll: async() => call('saved-assets'),

        getAllGroups: async() => call('saved-asset-groups'),

        get: async(id) => call('saved-assets/' + id),

        createAsset: async(data) => call('saved-assets', 'POST', data),
        createGroup: async(data) => call('saved-asset-groups', 'POST', data),


        deleteGroup: async(id) => call(`saved-asset-groups/${id}`, 'DELETE'),

        delete: async(id) => call(`saved-assets/${id}`, 'DELETE'),

    }
}
