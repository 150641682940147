<template>
	<div v-if="isVisible" class="global-header">

		<div class="__top_bar">
			<span class="logo-flat">
				<inline-svg class="" :src="require('@/assets/img/logo-flat.svg')" />
			</span>

			<MainSearch class="search-box" />

			<div class="__buttons">
				<topbar-icon :name="`help_btn${id}`" class="topbar_icon" label="Help">
					<inline-svg :src="require('@/assets/img/help_icon.svg')" />
					<tippy :to="`help_btn${id}`" :ref="'tippyMenuHelp'+id" theme="transparent" trigger="click" placement="bottom-end"
					       interactive interactiveBorder="0" hideOnClick >
						<div class="account_menu_dropdown __profile_dropdown">
							<ul>
								<li>
									<a @click="goToLink('https://docs.funnelish.com', true)">
										<span class="icon"><inline-svg :src="require('@/assets/img/check-tutorials.svg')" /></span> Docs & tutorials
										<span class="icon" style="margin-left: 5px;"><inline-svg :src="require('@/assets/img/external_link_icon.svg')" /></span>
									</a>
								</li>
								<li>
									<a  @click="goToLink('https://community.funnelish.com', true)">
										<span class="icon"><inline-svg :src="require('@/assets/img/community_icon.svg')" /></span> Community forum
										<span class="icon" style="margin-left: 5px;"><inline-svg :src="require('@/assets/img/external_link_icon.svg')" /></span>
									</a>
								</li>
								<li>
									<a  @click="goToLink('https://www.facebook.com/groups/funnelish', true)" >
										<span class="icon"><inline-svg :src="require('@/assets/img/facebook-group-logo.svg')" /></span> Facebook group
										<span class="icon" style="margin-left: 5px;"><inline-svg :src="require('@/assets/img/external_link_icon.svg')" /></span>
									</a>
								</li>
								<li class="__sep"></li>
								<li>
									<a @click="openIntercom">
										<span class="icon">
											<inline-svg :src="require('@/assets/img/message-text.svg')" />
										</span>
										Chat support
									</a>
								</li>
							</ul>
						</div>
					</tippy>
				</topbar-icon>
				<topbar-icon class="topbar_icon notifications_icon" label="Updates">
					<inline-svg :src="require('@/assets/img/notification.svg')" />
				</topbar-icon>
				<topbar-icon :name="`settings_btn${id}`" class="topbar_icon" label="Settings">
					<inline-svg :src="require('@/assets/img/settings.svg')" />
					<tippy :to="`settings_btn${id}`" :ref="'tippyMenuSettings'+id" theme="transparent" trigger="click" placement="bottom-end"
					       interactive interactiveBorder="0" hideOnClick >
						<div class="account_menu_dropdown __profile_dropdown">
							<ul>
								<li>
									<a @click="goToLink('store/discounts')">
										<span class="icon"><inline-svg :src="require('@/assets/img/discounts.svg')" /></span> Discounts
									</a>
								</li>
								<li class="__sep"></li>
								<li>
									<a @click="goToLink('integrations')">
										<span class="icon"><inline-svg :src="require('@/assets/img/integrations_icon.svg')" /></span> Integrations
									</a>
								</li>
								<li v-if="isAdmin">
									<a @click="goToLink('settings/account')">
										<span class="icon"><inline-svg :src="require('@/assets/img/settings.svg')" /></span> Account settings
									</a>
								</li>
								<li v-if="isAdmin">
									<a @click="goToLink('settings/billing')">
										<span class="icon"><inline-svg :src="require('@/assets/img/credit-card-edit.svg')" /></span> Billing
									</a>
								</li>
								<li v-if="isAdmin">
									<a @click="goToLink('settings/staff')">
										<span class="icon"><inline-svg :src="require('@/assets/img/users.svg')" /></span> Team
									</a>
								</li>
							</ul>
						</div>
					</tippy>
				</topbar-icon>


				<topbar-icon :name="`profile_btn${id}`" class="topbar_icon" label="Profile">
					<inline-svg :src="require('@/assets/img/account.svg')" />
					<tippy :to="`profile_btn${id}`" :ref="'tippyMenu'+id" theme="transparent" trigger="click" placement="bottom-end"
					       interactive interactiveBorder="0" hideOnClick >
						<div class="account_menu_dropdown __profile_dropdown">
							<ul>
								<li class="__profile_details">
									<span class="__username">{{username}}</span>
									<span>{{ role }}</span>
								</li>
								<li>
									<a @click="goToLink('settings/profile')">
										<span class="icon"><inline-svg :src="require('@/assets/img/account.svg')" /></span> Profile
									</a>
								</li>
								<li>
									<a target="_blank"
									   href="https://funnelish.tapfiliate.com/">
										<span class="icon"><inline-svg :src="require('@/assets/img/cup.svg')" /></span> Become an affiliate
										<span class="icon" style="margin-left: 5px;"><inline-svg :src="require('@/assets/img/external_link_icon.svg')" /></span>
									</a>
								</li>
								<li class="__sep"></li>

								<li>
									<a @click="goToLink('log-in')">
										<span class="icon"><inline-svg :src="require('@/assets/img/logout.svg')" /></span>
										Logout
									</a>
								</li>
							</ul>
						</div>
					</tippy>
				</topbar-icon>
			</div>
		</div>

		<div class="__tabs">
			<div class="__tabs_left">
				<topbar-item to="/get-started" label="Get started" :img="require('./rocket-launch-outline.svg')" />

				<topbar-item to="/" class="dashboard" label="Dashboard"  />

				<topbar-item to="/funnels" label="Funnels"  />

				<topbar-item to="/orders" label="Orders"  />

				<topbar-item to="/customers" label="Customers" />

        <topbar-item to="/automations" label="Automations" />

        <topbar-item to="/analytics" label="Analytics" tag="NEW" />

      </div>
			<div class="__tabs_right">
				<topbar-item to="/apps" :img="require('./apps.svg')" label="Apps" />
			</div>
		</div>

	</div>
</template>

<script>
import MainSearch from "@/components/layout/MainSearch";
import TopbarItem from "@/components/layout/topbar-item";
import TopbarIcon from "@/components/layout/topbar-icon";
import {isAdmin} from "@/helpers/roles";
let id=0;
export default {
	name: "global-header",
	components: {TopbarIcon, TopbarItem, MainSearch},
	data() {
		return {
			id:++id,
		}
	},
	computed:{
		isAdmin(){
			return isAdmin()
		},
		isVisible() {
			return !['empty','page','email'].includes(this.$route.meta.layout)
		},
		username(){
			let u = this.$store.state.userInfo
			if (!u || !u.member) return "unknown"
			let m = u.member;
			return (m.first_name || m.last_name) ? `${m.first_name} ${m.last_name}` : m.email || 'unknown';
		},
		role() {
			let u = this.$store.state.userInfo
			if (!u || !u.member_account) return "unknown"
			let m = u.member_account;
			try {
				let j = JSON.parse(m.permissions)
				if (j.admin) return "Administrator"
				else if (j.funnels) return "Funnels admin"
				else if (j.editor) return "Editor"
				else if (j.automations) return "Automations master"
				else if (j.orders) return "Orders master"
				else return "Readonly"
			} catch (e) {
				console.error(e)
				return "unknown"
			}
		}
	},
	methods:{
		openIntercom() {
			let t = this.$refs['tippyMenuHelp'+this.id];
			if (t) t.tippy().hide();
			window.Intercom('show');
		},
		goToLink(val, external=false){
			let t = this.$refs['tippyMenu'+this.id];
			let t2 = this.$refs['tippyMenuSettings'+this.id];
			let t3 = this.$refs['tippyMenuHelp'+this.id];
			if (t) t.tippy().hide();
			if (t2) t2.tippy().hide();
			if (t3) t3.tippy().hide();

			if (external) window.open(val, '_blank')
			else this.$router.push({path: '/'+val}).catch(()=>{});
		},
		loadHeadway() {
			//Inject Headway app script
			let headway = document.getElementById("headway-script");
			if (headway) return; //Script already appended

			//Create promise which resolves on script load
			// let promise = new Promise((resolve, reject) => {
			let script = document.createElement("script");
			script.src = "//cdn.headwayapp.co/widget.js";
			script.id = "headway-script";
			script.setAttribute("async", true);
			document.head.appendChild(script);
			script.onload = () => {
				Headway.init({
					selector: ".topbar_icon.notifications_icon",
					trigger: ".topbar_icon.notifications_icon",
					account: "yB8mn7",
				});
			}; //Resolve when loaded
		}
	},
	mounted() {
		this.loadHeadway()
	}
}
</script>
<style lang="scss">
.__profile_dropdown {
	ul {
		display: flex;
		flex-direction: column;
		gap: 3px;
		li.__sep {
			border-bottom: 1px solid $light-grey;
			height: 1px;
			margin: 2px 0;
		}
	}
	.__profile_details {
		display: flex;
		flex-direction: column;
		border-bottom: 1px solid $light-grey;
		font-size: 12px;
		gap: 3px;
		padding: 10px 5px;
		color: $darker-grey;
		font-weight: 400;
		.__username {
			font-weight: 600;
			font-size: 14px;
		}

	}
}
</style>
<style lang="scss" scoped>
.global-header {
	display: flex;
	flex-direction: column;
	.__top_bar {
		background: #F6F8FA;
		border-bottom: 1px solid #E4E6F1;
		display: flex;
		flex-direction: row;
		gap: 10px;
		padding: 6px 25px;
		align-items: center;
		height: 42px;
		align-items: center;
		justify-content: space-between;

		.logo-flat {
			svg {
				height: 18px;
			}
		}

		.search-box {
			align-self: center;
		}
	}

	.__buttons {
		display: flex;
		flex-direction: row;
		gap: 14px;

		.topbar_icon {
			svg {
				height: 18px;
				width: 18px;
			}
		}
	}
	.__tabs {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
		border-bottom: 1px solid #E4E6F1;
		padding: 4px 25px;

		//height: 40px;

		.__tabs_left, .__tabs_right {
			display: flex;
			flex-direction: row;
			gap: 10px;
		}
	}
}
</style>
