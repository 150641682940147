var currencies = [
    { id: "AED", symbol: "د.إ;", text: "<b>AED</b> UAE dirham" },
    { id: "AFN", symbol: "Afs", text: "<b>AFN</b> Afghan afghani" },
    { id: "ALL", symbol: "L", text: "<b>ALL</b> Albanian lek" },
    { id: "AMD", symbol: "AMD", text: "<b>AMD</b> Armenian dram" },
    { id: "ANG", symbol: "NAƒ", text: "<b>ANG</b> Netherlands Antillean gulden" },
    { id: "AOA", symbol: "Kz", text: "<b>AOA</b> Angolan kwanza" },
    { id: "ARS", symbol: "ARS", text: "<b>ARS</b> Argentine peso" },
    { id: "AUD", symbol: "A$", text: "<b>AUD</b> Australian dollar" },
    { id: "AWG", symbol: "ƒ", text: "<b>AWG</b> Aruban florin" },
    { id: "AZN", symbol: "AZN", text: "<b>AZN</b> Azerbaijani manat" },
    { id: "BAM", symbol: "KM", text: "<b>BAM</b> Bosnia and Herzegovina konvertibilna marka" },
    { id: "BBD", symbol: "Bds$", text: "<b>BBD</b> Barbadian dollar" },
    { id: "BDT", symbol: "৳", text: "<b>BDT</b> Bangladeshi taka" },
    { id: "BGN", symbol: "BGN", text: "<b>BGN</b> Bulgarian lev" },
    { id: "BHD", symbol: ".د.ب", text: "<b>BHD</b> Bahraini dinar" },
    { id: "BIF", symbol: "FBu", text: "<b>BIF</b> Burundi franc" },
    { id: "BMD", symbol: "BD$", text: "<b>BMD</b> Bermudian dollar" },
    { id: "BND", symbol: "B$", text: "<b>BND</b> Brunei dollar" },
    { id: "BOB", symbol: "Bs.", text: "<b>BOB</b> Bolivian boliviano" },
    { id: "BRL", symbol: "R$", text: "<b>BRL</b> Brazilian real" },
    { id: "BSD", symbol: "B$", text: "<b>BSD</b> Bahamian dollar" },
    { id: "BTN", symbol: "Nu.", text: "<b>BTN</b> Bhutanese ngultrum" },
    { id: "BWP", symbol: "P", text: "<b>BWP</b> Botswana pula" },
    { id: "BYR", symbol: "Br", text: "<b>BYR</b> Belarusian ruble" },
    { id: "BZD", symbol: "BZ$", text: "<b>BZD</b> Belize dollar" },
    { id: "CAD", symbol: "$", text: "<b>CAD</b> Canadian dollar" },
    { id: "CDF", symbol: "F", text: "<b>CDF</b> Congolese franc" },
    { id: "CHF", symbol: "Fr.", text: "<b>CHF</b> Swiss franc" },
    { id: "CLP", symbol: "$", text: "<b>CLP</b> Chilean peso" },
    { id: "CNY", symbol: "¥", text: "<b>CNY</b> Chinese/Yuan renminbi" },
    { id: "COP", symbol: "$", text: "<b>COP$</b> Colombian peso" },
    { id: "CRC", symbol: "₡", text: "<b>CRC</b> Costa Rican colon" },
    { id: "CUC", symbol: "$", text: "<b>CUC</b> Cuban peso" },
    { id: "CVE", symbol: "Esc", text: "<b>CVE</b> Cape Verdean escudo" },
    { id: "CZK", symbol: "Kč", text: "<b>CZK</b> Czech koruna" },
    { id: "DJF", symbol: "Fdj", text: "<b>DJF</b> Djiboutian franc" },
    { id: "DKK", symbol: "Kr", text: "<b>DKK</b> Danish krone" },
    { id: "DOP", symbol: "RD$", text: "<b>DOP</b> Dominican peso" },
    { id: "DZD", symbol: "د.ج", text: "<b>DZD</b> Algerian dinar" },
    { id: "EEK", symbol: "KR", text: "<b>EEK</b> Estonian kroon" },
    { id: "EGP", symbol: "£", text: "<b>EGP</b> Egyptian pound" },
    { id: "ERN", symbol: "Nfa", text: "<b>ERN</b> Eritrean nakfa" },
    { id: "ETB", symbol: "Br", text: "<b>ETB</b> Ethiopian birr" },
    { id: "EUR", symbol: "€", text: "<b>EUR</b> European Euro" },
    { id: "FJD", symbol: "FJ$", text: "<b>FJD</b> Fijian dollar" },
    { id: "FKP", symbol: "£", text: "<b>FKP</b> Falkland Islands pound" },
    { id: "GBP", symbol: "£", text: "<b>GBP</b> British pound" },
    { id: "GEL", symbol: "GEL", text: "<b>GEL</b> Georgian lari" },
    { id: "GHS", symbol: "GH₵", text: "<b>GHS</b> Ghanaian cedi" },
    { id: "GIP", symbol: "£", text: "<b>GIP</b> Gibraltar pound" },
    { id: "GMD", symbol: "D", text: "<b>GMD</b> Gambian dalasi" },
    { id: "GNF", symbol: "FG", text: "<b>GNF</b> Guinean franc" },
    { id: "GQE", symbol: "CFA", text: "<b>GQE</b> Central African CFA franc" },
    { id: "GTQ", symbol: "Q", text: "<b>GTQ</b> Guatemalan quetzal" },
    { id: "GYD", symbol: "GY$", text: "<b>GYD</b> Guyanese dollar" },
    { id: "HKD", symbol: "HK$", text: "<b>HKD</b> Hong Kong dollar" },
    { id: "HNL", symbol: "L", text: "<b>HNL</b> Honduran lempira" },
    { id: "HRK", symbol: "kn", text: "<b>HRK</b> Croatian kuna" },
    { id: "HTG", symbol: "G", text: "<b>HTG</b> Haitian gourde" },
    { id: "HUF", symbol: "Ft", text: "<b>HUF</b> Hungarian forint" },
    { id: "IDR", symbol: "Rp", text: "<b>IDR</b> Indonesian rupiah" },
    { id: "ILS", symbol: "₪", text: "<b>ILS</b> Israeli new sheqel" },
    { id: "INR", symbol: "₹", text: "<b>INR</b> Indian rupee" },
    { id: "IQD", symbol: "د.ع", text: "<b>IQD</b> Iraqi dinar" },
    { id: "IRR", symbol: "IRR", text: "<b>IRR</b> Iranian rial" },
    { id: "ISK", symbol: "kr", text: "<b>ISK</b> Icelandic króna" },
    { id: "JMD", symbol: "J$", text: "<b>JMD</b> Jamaican dollar" },
    { id: "JOD", symbol: "JOD", text: "<b>JOD</b> Jordanian dinar" },
    { id: "JPY", symbol: "¥", text: "<b>JPY</b> Japanese yen" },
    { id: "KES", symbol: "KSh", text: "<b>KES</b> Kenyan shilling" },
    { id: "KGS", symbol: "сом", text: "<b>KGS</b> Kyrgyzstani som" },
    { id: "KHR", symbol: "៛", text: "<b>KHR</b> Cambodian riel" },
    { id: "KMF", symbol: "KMF", text: "<b>KMF</b> Comorian franc" },
    { id: "KPW", symbol: "W", text: "<b>KPW</b> North Korean won" },
    { id: "KRW", symbol: "W", text: "<b>KRW</b> South Korean won" },
    { id: "KWD", symbol: "KWD", text: "<b>KWD</b> Kuwaiti dinar" },
    { id: "KYD", symbol: "KY$", text: "<b>KYD</b> Cayman Islands dollar" },
    { id: "KZT", symbol: "T", text: "<b>KZT</b> Kazakhstani tenge" },
    { id: "LAK", symbol: "KN", text: "<b>LAK</b> Lao kip" },
    { id: "LBP", symbol: "£", text: "<b>LBP</b> Lebanese lira" },
    { id: "LKR", symbol: "Rs", text: "<b>LKR</b> Sri Lankan rupee" },
    { id: "LRD", symbol: "L$", text: "<b>LRD</b> Liberian dollar" },
    { id: "LSL", symbol: "M", text: "<b>LSL</b> Lesotho loti" },
    { id: "LTL", symbol: "Lt", text: "<b>LTL</b> Lithuanian litas" },
    { id: "LVL", symbol: "Ls", text: "<b>LVL</b> Latvian lats" },
    { id: "LYD", symbol: "LD", text: "<b>LYD</b> Libyan dinar" },
    { id: "MAD", symbol: "MAD", text: "<b>MAD</b> Moroccan dirham" },
    { id: "MDL", symbol: "MDL", text: "<b>MDL</b> Moldovan leu" },
    { id: "MGA", symbol: "FMG", text: "<b>MGA</b> Malagasy ariary" },
    { id: "MKD", symbol: "MKD", text: "<b>MKD</b> Macedonian denar" },
    { id: "MMK", symbol: "K", text: "<b>MMK</b> Myanma kyat" },
    { id: "MNT", symbol: "₮", text: "<b>MNT</b> Mongolian tugrik" },
    { id: "MOP", symbol: "P", text: "<b>MOP</b> Macanese pataca" },
    { id: "MRO", symbol: "UM", text: "<b>MRO</b> Mauritanian ouguiya" },
    { id: "MUR", symbol: "Rs", text: "<b>MUR</b> Mauritian rupee" },
    { id: "MVR", symbol: "Rf", text: "<b>MVR</b> Maldivian rufiyaa" },
    { id: "MWK", symbol: "MK", text: "<b>MWK</b> Malawian kwacha" },
    { id: "MXN", symbol: "MXN", text: "<b>MXN</b> Mexican peso" },
    { id: "MYR", symbol: "RM", text: "<b>MYR</b> Malaysian ringgit" },
    { id: "MZM", symbol: "MTn", text: "<b>MZM</b> Mozambican metical" },
    { id: "NAD", symbol: "N$", text: "<b>NAD</b> Namibian dollar" },
    { id: "NGN", symbol: "₦", text: "<b>NGN</b> Nigerian naira" },
    { id: "NIO", symbol: "C$", text: "<b>NIO</b> Nicaraguan córdoba" },
    { id: "NOK", symbol: "kr", text: "<b>NOK</b> Norwegian krone" },
    { id: "NPR", symbol: "NRs", text: "<b>NPR</b> Nepalese rupee" },
    { id: "NZD", symbol: "NZ$", text: "<b>NZD</b> New Zealand dollar" },
    { id: "OMR", symbol: "OMR", text: "<b>OMR</b> Omani rial" },
    { id: "PAB", symbol: "B./", text: "<b>PAB</b> Panamanian balboa" },
    { id: "PEN", symbol: "S/.", text: "<b>PEN</b> Peruvian nuevo sol" },
    { id: "PGK", symbol: "K", text: "<b>PGK</b> Papua New Guinean kina" },
    { id: "PHP", symbol: "₱", text: "<b>PHP</b> Philippine peso" },
    { id: "PKR", symbol: "Rs.", text: "<b>PKR</b> Pakistani rupee" },
    { id: "PLN", symbol: "zł", text: "<b>PLN</b> Polish zloty" },
    { id: "PYG", symbol: "₲", text: "<b>PYG</b> Paraguayan guarani" },
    { id: "QAR", symbol: "QR", text: "<b>QAR</b> Qatari riyal" },
    { id: "RON", symbol: "LEI", text: "<b>RON</b> Romanian leu" },
    { id: "RSD", symbol: "din.", text: "<b>RSD</b> Serbian dinar" },
    { id: "RUB", symbol: "R", text: "<b>RUB</b> Russian ruble" },
    { id: "SAR", symbol: "SR", text: "<b>SAR</b> Saudi riyal" },
    { id: "SBD", symbol: "SI$", text: "<b>SBD</b> Solomon Islands dollar" },
    { id: "SCR", symbol: "SR", text: "<b>SCR</b> Seychellois rupee" },
    { id: "SDG", symbol: "SDG", text: "<b>SDG</b> Sudanese pound" },
    { id: "SEK", symbol: "kr", text: "<b>SEK</b> Swedish krona" },
    { id: "SGD", symbol: "S$", text: "<b>SGD</b> Singapore dollar" },
    { id: "SHP", symbol: "£", text: "<b>SHP</b> Saint Helena pound" },
    { id: "SLL", symbol: "Le", text: "<b>SLL</b> Sierra Leonean leone" },
    { id: "SOS", symbol: "Sh.", text: "<b>SOS</b> Somali shilling" },
    { id: "SRD", symbol: "$", text: "<b>SRD</b> Surinamese dollar" },
    { id: "SYP", symbol: "LS", text: "<b>SYP</b> Syrian pound" },
    { id: "SZL", symbol: "E", text: "<b>SZL</b> Swazi lilangeni" },
    { id: "THB", symbol: "฿", text: "<b>THB</b> Thai baht" },
    { id: "TJS", symbol: "TJS", text: "<b>TJS</b> Tajikistani somoni" },
    { id: "TMT", symbol: "m", text: "<b>TMT</b> Turkmen manat" },
    { id: "TND", symbol: "DT", text: "<b>TND</b> Tunisian dinar" },
    { id: "TRY", symbol: "TRY", text: "<b>TRY</b> Turkish new lira" },
    { id: "TTD", symbol: "TT$", text: "<b>TTD</b> Trinidad and Tobago dollar" },
    { id: "TWD", symbol: "NT$", text: "<b>TWD</b> New Taiwan dollar" },
    { id: "TZS", symbol: "TZS", text: "<b>TZS</b> Tanzanian shilling" },
    { id: "UAH", symbol: "UAH", text: "<b>UAH</b> Ukrainian hryvnia" },
    { id: "UGX", symbol: "USh", text: "<b>UGX</b> Ugandan shilling" },
    { id: "USD", symbol: "$", text: "<b>USD</b> United States dollar" },
    { id: "UYU", symbol: "$U", text: "<b>UYU</b> Uruguayan peso" },
    { id: "UZS", symbol: "UZS", text: "<b>UZS</b> Uzbekistani som" },
    { id: "VEB", symbol: "Bs", text: "<b>VEB</b> Venezuelan bolivar" },
    { id: "VND", symbol: "₫", text: "<b>VND</b> Vietnamese dong" },
    { id: "VUV", symbol: "VT", text: "<b>VUV</b> Vanuatu vatu" },
    { id: "WST", symbol: "WS$", text: "<b>WST</b> Samoan tala" },
    { id: "XAF", symbol: "CFA", text: "<b>XAF</b> Central African CFA franc" },
    { id: "XCD", symbol: "EC$", text: "<b>XCD</b> East Caribbean dollar" },
    { id: "XDR", symbol: "SDR", text: "<b>XDR</b> Special Drawing Rights" },
    { id: "XOF", symbol: "CFA", text: "<b>XOF</b> West African CFA franc" },
    { id: "XPF", symbol: "F", text: "<b>XPF</b> CFP franc" },
    { id: "YER", symbol: "YER", text: "<b>YER</b> Yemeni rial" },
    { id: "ZAR", symbol: "R", text: "<b>ZAR</b> South African rand" },
    { id: "ZMK", symbol: "ZK", text: "<b>ZMK</b> Zambian kwacha" },
    { id: "ZWR", symbol: "Z$", text: "<b>ZWR</b> Zimbabwean dollar" }
]

export function getSymbol(code) {
    var result = currencies.find(item => item.id === code);
    return result ? result.symbol : "$"
}

export function formatPrice(code, price) {
    if (code==='RON') return `${price} lei`;
    else return `${getSymbol(code)} ${price}`
}

export function formatPriceSymbol(symbol, price) {
    if (symbol==='RON' || symbol==='lei') return `${price} lei`;
    else return `${symbol} ${price}`
}

export default currencies
