const EMPTY_STATE = 'emptyState';
import { deepCopy, clearUp } from '@/modules/page-editor/src/utils/helpers';

export default {
    install(Vue, options = {}) {
        if (!Vue._installedPlugins.find(plugin => plugin.Store)) {
            throw new Error("VuexUndoRedo plugin must be installed after the Vuex plugin.")
        }
        Vue.mixin({
            data() {
                return {
                    ignoreMutations: options.ignoreMutations || [],
                };
            },
        });
    },
}