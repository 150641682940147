<template>
    <header class="editor_header"  @click.self="resetView" v-if="$route.name !== 'PreviewEmail'">

	    <v-button label="Back to automation" class="go_back_btn"
	              flat slim @click="redirectToAutomations"
	              :icon="require('@/assets/img/back_bt_arrow.svg')" />

        <input class="email_name" style="flex:1" placeholder="Please enter template title"
            v-model="emailTemplateName"
            @focus="nameInputFocused = true"
            @blur="nameInputFocused = false"
            :class="{'input_focused': nameInputFocused, 'input_empty': !emailTemplateName.length}"
        />

        <div class="right">

            <ul class="sm_btns_group devices_btns">
	            <li title="Mobile View">
		            <a class="small-btn" @click="switchView('mobile')" :class="{active: view === 'mobile'}">
			            <inline-svg :src="require('@/assets/icons/mobile.svg')" />
		            </a>
	            </li>
	            <li title="Tablet View">
		            <a class="small-btn" @click="switchView('tablets')" :class="{active: view === 'tablets'}">
			            <inline-svg :src="require('@/assets/icons/tablet.svg')" />
		            </a>
	            </li>
	            <li title="Desktop View">
		            <a class="small-btn" @click="switchView('desktop')" :class="{active: view === 'desktop'}">
			            <inline-svg :src="require('@/assets/icons/desktop.svg')" />
		            </a>
	            </li>
            </ul>

            <ul class="sm_btns_group undo_redo_group">
                <li>
	                <v-button class="small-btn" flat v-tippy="{theme:'small-black'}" content="Undo" :disabled="!email_canUndo"
	                          :icon="require('@/assets/img/redo.svg')" @click="undoMe" />
                </li>
                <li>
	                <v-button class="small-btn" flat v-tippy="{theme:'small-black'}" content="Redo" :disabled="!email_canRedo"
	                          :icon="require('@/assets/img/undo.svg')" @click="redoMe" />
                </li>
            </ul>

	        <v-button new-style border label="Preview" target="_blank" :href="previewUrl"/>

            <v-button new-style
                @click="sendTestEmailFunc"
                label="Send test email"
                :icon="require('@/assets/email/img/send_email_btn_icon.svg')"
                border />

          <v-button new-style @click="generateAndSave(false)" :loading="savingLoading"
                    label="Save changes" :disabled="localEmailSavedStore === emailSavedStore"/>
        </div>

        <div class="popup_wrpr select_image_popup_wrpr" :class="{'active' : popupOpen}">
            <div class="popup_wrpr_inner">
                <SendTestEmailPopup @closePopup="closePopup" v-if="popupOpen" :template="templateId"/>
            </div>
        </div>

        <div class="popup_wrpr" :class="{'active' : savePopupOpen}">
            <div class="popup_wrpr_inner">
                <SaveTemplatePopup
                    @closePopup="savePopupClose"

                    @saveFunc="generateAndSave(sendTestPopupProp, localRedirect)"
                    @noSave="noSaveBtnFunc"

                    v-if="savePopupOpen"
                />
            </div>
        </div>
    </header>
    <a v-else class="btn back_to_editor_btn" @click="linkTo('/email-editor')">Back to editor</a>
</template>

<script>
import SendTestEmailPopup from "./popups/SendTestEmailPopup.vue"
import SaveTemplatePopup from "./popups/SaveTemplatePopup.vue"
import API from "@/api"
import VButton from "@/components/base/v-button";
import { mapGetters } from "vuex"

export default {
    name: 'EditorHeader',
    components: {
        VButton,
        SendTestEmailPopup,
        SaveTemplatePopup,
    },
    props: ['goBackTo'],
    mixins: [API],
    data: function() {
        return {
          savingLoading: false,
            popupOpen: false,
            renderedTemplate: '',
            templateId: false,
            emailTemplateName: 'Email Template',
            savingState: 'Save changes',
            nameInputFocused: false,
            entries: [],
            savedContentState: false,
            savePopupOpen: false,
            localEmailSavedStore: 0,
            localRedirect: false,
            sendTestPopupProp: false,
        };
    },
    computed: {
        ...mapGetters(['email_id', 'email_tab']),
        emailSavedStore(){
            return this.email_saved
        },
      previewUrl() {
        let userId = this.$store.state.userInfo.id;
        let key = this.$store.state.email.userTemplate.access_key;
        let host = window.location.origin;
        if (host.indexOf('localhost') >= 0) {
        	host = 'https://beta.funnelish.com';
        }
        return `${host}/preview-user-template/${this.currentTemplate.id}?uid=${userId}&key=${key}`
      },
        view() {
            return this.$store.state.email.view
        },
        currentTemplate() {
            return this.$store.state.email.currentTemplate
        },
        currentTemplateName() {
            return this.$store.state.email.currentTemplate.name || 'Email Template';
        },
        initialData() {
            return this.$store.state.email.initial
        },
	    isFPlus() {
		    return this.$route.name === "FPlusEmailEditor";
	    }
    },
    watch: {
        goBackTo(val) {
            if(val) {
                this.generateAndSave(false, true);
            }
        },
        currentTemplateName(val) {
            this.emailTemplateName = val;
        }
    },
    methods: {
        savePopupOpenChange(){
            this.savePopupOpen = true;
        },
        linkTo(path) {
            this.$router.replace({ path })
        },
        onInputName(ev) {
            this.emailTemplateName = ev.target.innerText
        },
        redirectToAutomations(){
            this.sendTestPopupProp = false;
            if(this.localEmailSavedStore !== this.emailSavedStore){
                this.localRedirect = true;
                this.savePopupOpen = true;
            } else {
							if (this.$route.meta.is_global) {
								this.$router.replace({
									path:`/automations/${this.initialData.automation}`
								});
							} else {
								this.$router.replace({
									path:`/${this.isFPlus?'w':''}funnel/${this.initialData.funnel}/automations/${this.initialData.automation}`
								});
							}
            }
        },
        sendTestEmailFunc(){
            this.localRedirect = false;
            this.sendTestPopupProp = true;
            if(this.localEmailSavedStore !== this.emailSavedStore){
                this.savePopupOpen = true;
            } else {
                this.popupOpen = true;
            }
        },
        noSaveBtnFunc(){
            if(this.localRedirect){
	            if (this.$route.meta.is_global) {
		            this.$router.replace({
			            path:`/automations/${this.initialData.automation}`
		            });
	            } else {
		            this.$router.replace({
			            path:`/${this.isFPlus?'w':''}funnel/${this.initialData.funnel}/automations/${this.initialData.automation}`
		            });
	            }
            }

            if(this.sendTestPopupProp){
                this.templateId = this.currentTemplate.id;

                this.popupOpen = true;
            }
        },
        async generateAndSave(open = false, redirect = false) {
            if(this.emailTemplateName.length) {
              this.$emit('saving', true);
              this.savingLoading = true;

              setTimeout(async ()=> {
								try {
									var target = document.getElementById('render-table') || false;
									var targetStyles = document.getElementById('render-style');

									this.renderedTemplate = target.outerHTML;

									var userId = this.initialData.user //Temp
									var data = {
										name: this.emailTemplateName,
										html:
`<!DOCTYPE html>
<html>
								                                <head>
								                                    <meta name="viewport" content="width=device-width" />
								                                    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
								                                    <title>{{ title }}</title>
								                                    <style>${targetStyles.innerHTML}</style>
								                                    <style>${this.$store.state.email.customCode.css}</style>
								                                </head>
								                                <body>
								                                    ${this.renderedTemplate}
								                                </body>
								                            </html>`,
										id: this.currentTemplate.id || 0,
										css: this.$store.state.email.customCode.css,
										tags: "TestTag",
										config: JSON.stringify({
											editor: this.$store.state.email.editor || {content: [], rows: [], children: []},
											page: this.$store.state.email.page
										})
									}

									if (data.id) {
										var result = await this.api.automations.userPatch({userId, id: data.id, data});
									} else {
										var result = await this.api.automations.userCreate({userId, data});
										data.id = result.id;
									}

									// this.entries = await this.api.automations.entriesGet(this.initialData.funnel, this.initialData.automation);
									//console.log('thisEntry:', thisEntry);

									// let thisEntry = this.entries.filter(item => {
									// 	return parseInt(item.index) === parseInt(this.initialData.key)
									// })[0];
									// console.log('data.id:', data.id);

									// thisEntry.params = JSON.parse(thisEntry.params);
									// thisEntry.params.value.templateId = data.id;
									// thisEntry.params = JSON.stringify(thisEntry.params);

									// var resultEntry = await this.api.automations.entriesPatch(this.initialData.funnel, this.initialData.automation, thisEntry.id, thisEntry)
									//console.log('Editor Header resultEntry:', resultEntry);

									this.$store.dispatch('EMAIL_SET_CURRENT_ID', result.id);
									this.templateId = result.id;

									result = null;
									target = null;
									targetStyles = null;

									if (open) this.popupOpen = true;

									if (redirect) {
										if (this.$route.meta.is_global) {
											this.$router.replace({
												path: '/automations/' + this.initialData.automation
											});
										} else if (this.$route.meta.isPlus) {
											this.$router.replace({
												path: '/wfunnel/' + this.initialData.funnel +
														'/automations/' + this.initialData.automation
											});
										} else if (this.initialData.funnel && this.initialData.automation) {
											this.$router.replace({
												path:
														'/funnel/' + this.initialData.funnel +
														'/automations/' + this.initialData.automation
											});
										}
									}

									this.savedContentState = true;
									this.$emit('saving', false);

								} catch (e) {
									this.$error(e);
								} finally {
									this.savingLoading = false;
								}
              }, 300);
            } else {
                alert('Please enter a template name');
            }

            // this.localRedirect = false;
            this.sendTestPopupProp = false;
            this.localEmailSavedStore = this.emailSavedStore;
        },
        resetView() {
            var id = this.email_id;
            if (id !== null) this.$store.commit('EMAIL_SET_TABID', { tab: null, id: null });
        },
        undoMe() {
            this.resetView()
            this.email_undo()
        },
        redoMe() {
            this.resetView()
            this.email_redo()
        },
        switchView(mode) {
            this.$store.dispatch('EMAIL_SET_VIEW', mode)
        },
        closePopup(){
            this.popupOpen = false;
        },
        savePopupClose(){
            this.savePopupOpen = false;
        }
    },
    created(){
        window.onbeforeunload = function(event){
            return "Save the result before refreshing the page";
        };
        this.localEmailSavedStore = this.emailSavedStore;
        this.localRedirect = false;
        this.sendTestPopupProp = false;

        console.log('this.$store.state.email.currentTemplate:', this.$store.state.email.currentTemplate);
    },
    destroyed(){
        window.onbeforeunload = null;
    }
}
</script>
<style lang="scss" scoped>
.devices_btns {
	gap: 5px;
	margin: 0 20px;
	.small-btn {

	}
}
.go_back_btn {
	margin-right: 20px !important;
}
.right {
	.button {
		margin-right: 15px;
	}
}
</style>
<style lang="scss">
.app-email-editor {
    .back_to_editor_btn {
        position: fixed;
        left: 20px;
        top: 20px;
        z-index: 9999;
    }
    .undo_redo_btn {
        opacity: 0.7;
        pointer-events: none;
        &.active {
            opacity: 1;
            pointer-events: all;
        }
    }

    .switch_view_btn.active {
        pointer-events: none;
    }

	.go_back_btn {
		margin-right: 50px;
		padding: 4px 10px 4px 4px !important;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		height: 30px;
		cursor: pointer;
		color: $black !important;
		font-weight: 500;
		font-size: 12px;
		white-space: nowrap;
		svg.icon, svg path {
			fill: $black !important;
		}
	}
}
</style>
