
function getSourceLogo(appId, gatewayId) {
    let g = getGatewayNameById(gatewayId);
    switch (appId) {
        case 1:  // PayPal Plugin
            return `<img src="/img/sources/paypal-plugin-logo.png" style="width: 100px;">`;
        case 8: // Funnelish Pay
            return `<div style="vertical-align: middle;margin-bottom: auto;display: flex;">
                        <img src="/img/logo.svg" style="height: 20px;">
                        <span style="font-size: 12px;line-height: 20px;vertical-align: middle;font-weight: 600;margin-left: 4px;">• ${g.acronym} </span>
                    </div>`;
        default:
            return `<img src="/img/sources/clickfunnels-logo.png" style="width: 100px;">`;
    }
}

function getImgUrl(img) {
    return require(img)
//    return images(img)
}

const gateways = [
    {id: 1, acronym:"Stripe", name:"Stripe", canRefund: true},
    {id: 2, acronym:"PayPal", name:"PayPal Express (Deprecated)", canRefund: false},
    {id: 3, acronym:"PayFast", name:"PayFast", canRefund: false},
    {id: 4, acronym:"Coinbase", name:"Coinbase", canRefund: false},
    {id: 5, acronym:"COD", name:"Cash On Delivery", canRefund: false},
    {id: 6, acronym:"Clickfunnels", name:"Clickfunnels", canRefund: false},
    {id: 7, acronym:"PayPal", name:"PayPal", canRefund: false},
    {id: 8, acronym:"ideal", name:"iDEAL", canRefund: true},
    {id: 9, acronym:"nmi", name:"NMI", canRefund: true},
    {id: 10, acronym:"stripe-klarna", name:"Klarna (through Stripe)", canRefund: true},
    {id: 11, acronym:"Bancontact", name:"Bancontact", canRefund: true},
    {id: 12, acronym:"Sofort", name:"SOFORT", canRefund: true},
    {id: 13, acronym:"sepa_direct_debit", name:"SEPA Direct Debit", canRefund: true},
    {id: 14, acronym:"checkout.com", name:"Checkout.com", canRefund: true},
    {id: 15, acronym:"p24", name:"Przelewy24", canRefund: true},
    {id: 16, acronym:"razorpay", name:"Razorpay", canRefund: true},
    {id: 17, acronym:"eps", name:"EPS", canRefund: true},
    {id: 18, acronym:"paypal-cc", name:"PayPal Card Payments", canRefund: true},
    {id: 19, acronym:"klarna", name:"Klarna", canRefund: true},
    {id: 20, acronym:"mollie", name:"Mollie", canRefund: true},
    {id: 21, acronym:"mollie-ideal", name:"iDEAL (Mollie)", canRefund: true},
    {id: 22, acronym:"mollie-bancontact", name:"Bancontact (Mollie)", canRefund: true},
    {id: 23, acronym:"airwallex", name:"Airwallex", canRefund: true},
    {id: 24, acronym:"paypal3", name:"PayPal (new)", canRefund: true},
    {id: 25, acronym:"airwallex-klarna", name:"Klarna (Airwallex)", canRefund: true},
    {id: 26, acronym:"airwallex-ideal", name:"iDeal (Airwallex)", canRefund: true},
    {id: 27, acronym:"airwallex-bancontact", name:"Bancontact (Airwallex)", canRefund: true},
]

export const GATEWAY_STRIPE = 1;
export const GATEWAY_MOLLIE = 20;
export const GATEWAY_MOLLIE_IDEAL = 21;
export const GATEWAY_MOLLIE_BANCONTACT = 22;

function getGatewayNameById(id) {
    if (id >= 1) {
        for (let i = 0; i < gateways.length; i++) {
            if (gateways[i].id == id) return gateways[i];
        }
    }
    return {id: 0, name:"Unknown", acronym:"Unknown", canRefund: false};
}

function getGatewayById(id) {
    if (id >= 1) {
        for (let i = 0; i < gateways.length; i++) {
            if (gateways[i].id == id) return gateways[i];
        }
    }
    return {id: 0, name:"Unknown", acronym:"Unknown", canRefund: false};
}

export {
    getGatewayNameById,
    getSourceLogo,
    getGatewayById
}
