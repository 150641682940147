<template>
	<div class="popup_high_right customer-details-popup"
	     :class="['level-'+level]">

	    <a @click="closePopup" v-if="!level" class="close_popup_btn">
	        <inline-svg :src="require('@/assets/img/popup_close.svg')"/>
	    </a>

			<loading v-if="loading" />
	    <div v-else class="popup-container scroll">
	      <h3 class="title" >
		      <back-btn v-if="level" class="back-btn" @click="$emit('close')" />
		      {{
		        customer.first_name || customer.last_name ? customer.first_name||''+' '+customer.last_name||'' :
				        customer.optin_email
		      }}
	      </h3>

	      <div class="order-status-row">
<!--	        <span class="label">Status:</span>-->
<!--	        <inline-svg :src="require('@/assets/img/circle-checked.svg')"/>-->
<!--	        <span class="value">Buyer</span>-->

	        <span class="label">First seen:</span>
	        <span class="value">{{ localDate }}</span>
		      <div class="status-tags">
			      <span class="status-tag" v-if="customer.is_auto_optin">
				      <inline-svg :src="require('@/assets/img/account-convert.svg')" />
				      Auto-optin</span>
		      </div>
	        <div class="flex"></div>
		      <quick-actions-btn :items="['Delete customer']" @menuClick="deleteCustomerPopup=true" />
		      <customer-delete-popup v-if="deleteCustomerPopup" :customer="customer"
		                             @done="$emit('deleted', customer)"
		                             @close="deleteCustomerPopup=false" />
	      </div>

	      <expandable-area title="Basic details" name="customer-basic-details">
	        <div class="basic-details">
	          <div class="layout-row flex margin-b-20">
	            <input-field class="flex" small label="First name" v-model="customer.first_name" />
		          <div class="flex-none margin-r-30"></div>
	            <input-field class="flex" small label="Last name" v-model="customer.last_name" />
	          </div>

	          <input-field class="flex margin-b-20" small readonly label="Optin email"
	                       info="<b>Readonly, </b>Email address used to optin to your funnel"
	                       v-model="customer.optin_email" />

	          <input-field class="flex margin-b-20" small label="Paying email"
	                       info="Email address used to pay (it may be different from optin email)"
	                       v-model="customer.payer_email" />

	          <input-field class="flex" small label="Phone number" v-model="customer.phone">
		          <template v-slot:append>
			          <div class="phone-actions">
				          <img class="whatsapp"  v-if="customer.phone" @click="whatsapp"
				               :src="require('@/assets/img/whatsapp.png')" v-tippy="{theme:'small-black'}" content="Message them  through Whatsapp" />
<!--				          <v-button  :icon="require('@/assets/img/whatsapp.svg')" slim flat  />-->
			          </div>
		          </template>
	          </input-field>
	        </div>
	      </expandable-area>

		    <expandable-area title="Shipping address" name="customer-shipping-address">
			    <div class="address">
				    <input-field class="flex margin-b-20" small label="Address" v-model="customer.shipping_address" />

				    <div class="layout-row margin-b-20" style="gap: 10px">
					    <input-field class="flex" small label="State/Province" v-model="customer.shipping_state" />
					    <input-field class="flex" small label="City" v-model="customer.shipping_city" />
					    <input-field class="flex" small label="Zip code" v-model="customer.shipping_zip" />
				    </div>
				    <select-field :options="countries" caption="Country" label="text" :value="customer.shipping_country"
				                  searchable clearable small v-model="customer.shipping_country" />
			    </div>
		    </expandable-area>

		    <expandable-area title="Billing address" name="customer-billing-address">
			    <div class="address">
				    <input-field class="flex margin-b-20" small label="Address" v-model="customer.address" />

				    <div class="layout-row margin-b-20" style="gap: 10px">
					    <input-field class="flex" small label="State/Province" v-model="customer.state" />
					    <input-field class="flex" small label="City" v-model="customer.city" />
					    <input-field class="flex" small label="Zip code" v-model="customer.zip" />
				    </div>
				    <select-field :options="countries" caption="Country" label="text" :value="customer.country"
				                  searchable clearable small v-model="customer.country" />
			    </div>
		    </expandable-area>

		    <customer-orders v-if="customer&&customer.id" :customer-id="customer.id"
		                     @click="currentOrderId=$event" />

	      <expandable-area title="Meta"
	                       :tag="customer.metadata && customer.metadata.length?`(${customer.metadata.length})`:''"
	                       name="customer-meta">
	        <div class="customer-meta layout-column">
	          <table v-if="customer.metadata && customer.metadata.length > 0" style="white-space:nowrap;width:100%;">
	            <tr v-for="(meta,index) in customer.metadata" :key="index">
		            <td class="" style="width: 40%">
			            <input-field readonly select-all flat small v-model="meta.key" />
		            </td>
		            <td class="" style="width: 60%">
			            <input-field readonly select-all flat small v-model="meta.value" :placeholder="(!!meta.value) ? '' : 'Empty'" />
		            </td>
	            </tr>
	          </table>
	          <div class="layout-column" v-else>
	            <p class="info-message" style="margin: auto">
		            This order/customer has not metadata.
	            </p>
	          </div>
	        </div>
	      </expandable-area>

	    </div>

		<popup-backdrop v-if="currentOrderId" @close="currentOrderId=null">
			<order-details-new :level="level+1" :orderId="currentOrderId" @close="currentOrderId=null" />
		</popup-backdrop>

		<div class="popup-actions layout-row">
			<v-button label="Save changes" @click="saveCustomer" :loading="saving" />
			<v-button label="Cancel" border @click="$emit('close')" />
		</div>

	</div>
</template>

<script>
import API from "@/api"
import countries from "@/helpers/countries"
import Loading from "@/components/Loading.vue"
import VButton from "@/components/base/v-button";
import InputField from "@/components/base/input-field";
import SelectField from "@/components/base/select-field";
import ExpandableArea from "@/components/base/expandable-area";
import CustomerEntry from "@/views/orders/customer-entry";
import BackBtn from "@/components/base/back-btn";
import CustomerOrders from "@/views/customers/customer-orders";
import PopupBackdrop from "@/components/base/popup-backdrop";
import QuickActionsBtn from "@/components/base/quick-actions-btn";
import CustomerDeletePopup from "@/components/popups/delete/CustomerDeletePopup";
import {mapGetters} from "vuex";
import {getCountryPhoneCode} from '@/helpers/countries'
const moment = require('moment-timezone');

export default {
	name: "CustomerDetailsNew",
	title: "Customer details | Funnelish™",
	mixins: [API],
	components: {
		CustomerDeletePopup,
		QuickActionsBtn,
		PopupBackdrop,
		OrderDetailsNew:() => import("@/views/orders/order-details-new"),
		CustomerOrders,
		BackBtn,
		CustomerEntry,
		ExpandableArea,
		SelectField,
		InputField,
		VButton,
		Loading
	},
	props:{
		customerId:{
			type: Number,
		},
		level:{
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			saving: false,
			loading: true,
			currentOrderId: null,
			deleteCustomerPopup: false,
			customer: {
				first_name: '',
				last_name: ''
			},
			countries: countries,
		}
	},
	computed:{
		...mapGetters(['getCustomers']),
		localDate(){
			return moment(this.customer.created_time).format("DD MMMM YYYY, HH:mm:ss")
		},
	},
	methods: {
		whatsapp(){
			let phone = this.customer.phone;
			phone = phone.trim()
			if (phone.startsWith('+')) { // has international code.
				phone = phone.replace("+", "")
				if (phone.startsWith('0')) {
					phone = phone.slice(1)
				}
			} else if (phone.startsWith('00')) { // country code with 00...
				phone = phone.slice(2) // remove first 00
			} else { // no country code...
				let phoneCode = getCountryPhoneCode(this.customer.shipping_country)
				if (!phoneCode) phoneCode = getCountryPhoneCode(this.customer.country)
				if (phoneCode) {
					phone = phoneCode + phone
					phone = phone.replace("+", "")
					phone = phone.replace("-", "")
				}
			}
			phone = phone.replaceAll(" ", "")

			window.open(`https://wa.me/${phone}`, '_blank')
		},
		closePopup() {
			this.$emit('close')
		},

		//API
		async saveCustomer() {
			try {
				this.saving = true;
				let res = await this.api.customers.patch(this.customer.id, this.customer);
				if (res.error) throw res;
				else {
					this.$store.dispatch('EDIT_CUSTOMER', res);

					this.$emit('done');
				}
			} catch (e) {
				this.$error(e)
			} finally {
				this.saving = false;
			}
		},
		async init() {
			try {
				this.loading = true;
				this.customer = {};
				this.customer = await this.api.customers.get(this.customerId);
				this.$store.dispatch("setCurrentCustomer", this.customer)

			} catch (e) {
				this.$error(e,'Couldn\'t load customer details! try again later.')
			} finally {
				this.loading = false;
			}
		},
	},
	created() {
		this.init();
	},
}
</script>
<style lang="scss" scoped>
.customer-details-popup {
	.status-tags {
		display: flex;
		flex-direction: row;
		margin-right: 25px;
	}
	.status-tag {
		border-radius: 5px;
		background: $light-silver;
		padding: 3px 10px;
		display: flex;
		flex-direction: row;
		align-items: center;
		.value {
			margin: 0 0 0 5px;
		}
		svg {
			//color: #18d7ae;
			height: 18px;
			&.refund {
				color: $black;
			}
		}
		margin-right: 3px;
	}
	.phone-actions {
		display: flex;
		//border: 2px solid red;
		.whatsapp {
			width: 40px;
			object-fit: scale-down;
			cursor: pointer;
			background: #1da10f;
			border-radius: 0 3px 3px 0;
			&:hover {
				box-shadow: 0px 0 5px 1px rgba(29, 161, 15, 0.69);
			}
		}
	}
	.popup-actions {
		padding: 10px 30px;
		border-top: 1px solid $light-grey;
		.button {
			margin-right: 25px;
		}
	}
	padding: 0;
	&.level-1 {
		max-width: 580px;
		box-shadow: -5px 0 20px 15px rgba(0, 0, 0, 0.10);
	}
	&.level-2 {
		max-width: 560px;
		box-shadow: -5px 0 20px 15px rgba(0, 0, 0, 0.05);
	}
	.basic-details, .address {
		margin-top: 15px;
	}
	.title {
		display: flex;
		flex-direction: column;
		align-items: start;
		position: relative;
		.back-btn {
			position: absolute;
			top: -30px;
		}
	}
	.order-item {
		display: flex;
		flex-direction: row;
		padding: 4px;
		&:first-child {
			margin-top: 10px;
		}
		&.total {
			margin-top: 10px;
		}
		.item-img {
			margin-right: 10px;
			border: 1px solid $light-grey;
			background: $light-blue;
			width: 40px;
			height: 40px;
			border-radius: 5px;
			overflow: hidden;
			position: relative;
			align-items: center;
			display: flex;
			img {
				display: flex;
				align-self: center;
				flex: 1;
				&.placeholder {
					width: 30px;
					height: 30px;
					opacity: .6;
				}
			}
		}
		.price {
			font-weight: 600;
			font-size: 14px;
			color: $black;
		}
		.item-name {
			font-weight: 600;
			font-size: 14px;
			color: $black;
		}
		.item-variant {
			font-weight: normal;
			font-size: 13px;
			color: $grey;
		}
	}
	.popup_high_right {
		padding: 0;
	}
	.title {
		font-size: 22px;
		line-height: 150%;
		font-weight: 600;
		letter-spacing: 0.02em;
		color: #252A32;
		margin-bottom: 5px;
		background: white;
		min-height: 40px;
	}
	.popup-container {
		display: flex;
		flex-direction: column;
		flex: 1;
		padding: 35px 30px 20px;
	}
}
.order-status-row {
	margin-top: 10px;
	margin-bottom: 10px;
	display: flex;
	flex-direction: row;
	font-size: 13px;
	align-items: center;
	color: #26252e;
	.label {
		font-weight: bold;
		margin-right: 10px;
	}
	.value {
		display: flex;
		flex-direction: row;
		margin-right: 30px;
		margin-left: 5px;
	}
}
.popup_title {
	font-size: 20px;
}
.brdr_content_wrpr {
	margin: 20px 15px 20px 2px;
}
</style>
