import { render, staticRenderFns } from "./new-trial-popup.vue?vue&type=template&id=5f822506&scoped=true"
import script from "./new-trial-popup.vue?vue&type=script&lang=js"
export * from "./new-trial-popup.vue?vue&type=script&lang=js"
import style0 from "./new-trial-popup.vue?vue&type=style&index=0&id=5f822506&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f822506",
  null
  
)

export default component.exports