<template>
<div class="select-a-filter">
	<div class="menu-items">
		<span class="select-filter">Select a filter</span>
		<menu-item v-for="(item,index) in items" :label="item.label || item" :src="item.icon || ''"
		           :active="item.active||false" :disabled="item.disabled||false"
		           :key="'menu_item_'+index" @click="$emit('change', item)" />
	</div>
</div>
</template>

<script>
import MenuItem from "@/components/base/menu-item";
export default {
	name: "select-a-filter", components: {MenuItem},
	props: ['items', 'condition'],

}
</script>

<style lang="scss" scoped>
.select-a-filter {
	.select-filter {
		display: flex;
		font-size: 13px;
		color: grey;
		margin: 5px 15px;
	}
}
</style>
