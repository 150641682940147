<template>
				<a class="ab-funnel funnel-entry" >
					<div class="ab-linkage">
						<div class="ab-value-wrapper">
							<v-select v-model="funnel.ab_geo"
							          :options="countries"
							          @input="geoChange"
							          label="text"
							          class="small"
							          :class="{'empty':!funnel.ab_geo}"
							          :reduce="x=>x.id"
							          :searchable="true"
							          :clearable="false"
							          placeholder="N/A"
							          :selectable="(o)=>o.id!=='--'"
							          append-to-body>
								<template #selected-option="{ id }">
									<i v-if="!id || id==='all'"><b style="color: grey">--</b></i>
									<b v-else>{{id}}</b>
								</template>
								<template #option="o">
									<span style="margin: 2px 0;display: flex;border-bottom: 1px solid lightgrey;" v-if="o.id==='--'">
									</span>
									<span style="font-weight: bold" v-else-if="o.id===''">{{o.text}}</span>
									<span v-else>{{o.text}}</span>
								</template>
							</v-select>
							<v-spinner v-if="saving" />
						</div>
					</div>

					<div class="ab-funnel-inner" @click.prevent="redirectToFunnel">
						<div class="name">
							<h3  @click.stop>
								<span :ref="'funnel_entry_name_'+id" contenteditable="true" @focus="nameEditing=true" @blur="nameBlur" @input="updateName">{{ funnel.name }}</span>
								<v-button v-if="nameEditing || !saved" flat  class="save-btn" v-tippy="{theme:'small-black'}" content="Save changes"
								          :icon="require('@/assets/page/img/save_dismiss_circle_btns_save.svg')" :loading="saving"
								          @click="saveName">
								</v-button>
							</h3>

						</div>

						<div class="btns" @click.stop>
							<a class="panel_item_btn copy_btn" v-tippy="{theme:'small-black'}" content="Edit Geo-funnel" :href="`/wfunnel/${funnel.id}`">
								<inline-svg :src="require('@/assets/img/pencil_edit_icon.svg')" />
							</a>

							<a class="panel_item_btn copy_btn" v-tippy="{theme:'small-black'}" content="Duplicate Geo-funnel" @click="$emit('startSplitting')" >
								<v-spinner v-if="splitting||loading" />
								<inline-svg v-else :src="require('@/assets/img/copy_icon.svg')" />
							</a>

							<a class="panel_item_btn delete_btn" v-tippy="{theme:'small-black'}" content="Delete Geo-funnel" @click="showFunnelDeletePopup">
								<inline-svg :src="require('@/assets/img/delete_icon.svg')" />
							</a>
						</div>
					</div>
				</a>
</template>

<script>
import API from "@/api";
import {getSymbol} from "@/helpers/currencies";

import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import VSpinner from "@/components/base/v-spinner";
import VButton from "@/components/base/v-button";
import Loading from "@/components/Loading";
import countries from "@/helpers/countries";

TimeAgo.addLocale(en);
const timeAgo = new TimeAgo("en-US");
let id = 0;

export default {
	name: "ABFunnelEntry",
	components: {Loading, VButton, VSpinner},
	props: ["funnel", "noactions", "loading"],
	mixins: [API],
	data() {
		return {
			id: id++,
			countries: [{ text: "Unset", id: "" },{ text: "Unset", id: "--" },...countries],
			progressNumber: 60,
			closed: false,
			splitting: false,
			nameEditing: false,
			saved: true,
			saving: false,
			isDeletePageOpen: false,
		}
	},
	computed: {
		abFunnels() {
			if (!this.subFunnels) return [];
			return this.subFunnels.filter(f => f.funnel_group_id===this.funnel.funnel_group_id)
		}
	},
	methods: {
		async geoChange(ev){
			this.funnel.ab_geo = ev;
			await this.saveProps();
		},
		async redirectToFunnel() {
			this.$router.push('/wfunnel/' + this.funnel.id);
		},
		showFunnelDeletePopup() {
			console.log("showFunnelDeletePopup", this.funnel)
			this.$emit('showFunnelDeletePopup', this.funnel.id, this.funnel);
		},
		updateName(ev){
			this.saved=false
		},
		async saveName(ev) {
			this.funnel.name = this.$refs['funnel_entry_name_'+this.id].innerText;
			await this.saveProps();
		},
		async saveProps() {
			try {
				this.saving = true
				let result = await this.api.funnels.saveProps(this.funnel.id, {
					name: this.funnel.name,
					// ab_percent: this.page.ab_percent,
					// ab_type: this.step.ab_type,
					ab_geo: this.funnel.ab_geo,
				});
				if (result.error) throw result;
				this.saved = true;
			} catch (e) {
				this.$warn(e);
			} finally {
				this.saving = false
			}
		},
		nameBlur(){
			this.nameEditing = false;
		},
		startSplitting() {
			this.splitting = true;
			this.progressNumber = 0;
			this.splitFunnel();
			this.progressIncrement();
		},
		progressIncrement() {
			let progressIncrementInterval = setInterval(() => {
				if (this.progressNumber < 100) {
					this.progressNumber += 1;
				} else {
					clearInterval(progressIncrementInterval);
				}
			}, 50);
		},
		date(string) {
			var date = new Date(string);
			var monthArr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
			var month = monthArr[date.getMonth()];
			var day = date.getDate();
			var hours = date.getHours();
			var minutes = date.getMinutes();

			var format = `${month} ${day} ${hours}:${minutes}`;
			var diff = timeAgo.format(date);
			//Feb 26 16:58
			return {format, diff};
		},
		async splitFunnel() {
			try {
				let newFunnel = await this.api.funnels.abSplit(this.funnel.id);
				if (newFunnel.error) throw newFunnel;
				console.log("newFunnel", newFunnel);
				this.subFunnels.push(newFunnel);
			} catch (e) {
				this.$error(e);
			} finally {
				this.splitting = false;
			}
		},
	}
};
</script>

<style lang="scss">
.ab-funnel {
	.ab-value-wrapper {
		position: relative;
		background: white;
		box-shadow: 0 0 2px #e4e6f1;
		border-radius: 10px;
		top: 12px;
		left: 20px;
		padding: 0;
		max-width: 65px;
		.v-select {
			font-size: 12px;
		}
		.v-select.empty .vs__dropdown-toggle {
			border: 1px solid red;
		}
		.vs__dropdown-toggle {
			height: 24px;
			background: white;
			border-radius: 5px;
			border: 1px solid #E4E6F1; // border causes unwanted shift.
			.vs__selected-options {
				padding: 0 2px;
			}
			.vs__search, .vs__search:focus {
				margin: 0;
			}
			.vs__selected {
				justify-content: center;
			}
		}
	}
	.save-btn {
		svg {
			margin: auto !important;
		}
	}
	.btns {
		.copy_btn, .pinn_btn {
			svg path {
				fill: #636A7B;
			}
			&:hover {
				background: #d5e5ff;
				svg path {
					fill: #456bdf;
				}
			}
		}

		.button {
			svg {
				margin: auto;
			}
			svg, path {
				color: #C5C7D2;
			}
		}
	}
}
</style>
<style lang="scss" scoped>

.funnel-entry {
	.name {
		margin-right: 20px;
		h3 {
			position: relative;
			width: fit-content;
			padding: 2px 5px;
			word-break: break-all;
			margin-right: 20px;
		}
	}
	.save-btn {
		position: absolute;
		width: 26px;
		height: 26px;
		right: -26px;
		top: 0;
		padding: 4px;
		border-radius: 20px;
		border: 1px solid $light-grey;
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
	}
}

.ab-funnel {
	display: flex;
	flex-direction: row;
	align-items: center;
	border: 1px solid $light-grey;
	margin-bottom: 10px;
	margin-left: 120px;
	border-radius: 4px;
	position: relative;

	.ab-funnel-inner {
		padding: 5px 20px;
		display: flex;
		flex-direction: row;
		align-items: center;
		flex: 1;
		&:hover {
			background: $light-blue;
		}
	}

	&.new {
		left: 1px;
		border: none;
		margin-top: 10px;
		.button {
			display: flex;
			flex-direction: row;
			border: 1px solid $light-grey;
			color: #40454d;
			padding: 4px 15px;
			border-radius: 8px;

			&.splitting {
				pointer-events: none;
			}
			svg {
				margin-right: 10px;
				height: 16px;
				width: 16px;
				path {
					fill: #40454d;
				}
			}
			&:hover {
				color: $blue;
				svg path {
					fill: $blue;
				}
			}
		}
	}

	.ab-linkage {
		position: absolute;
		left: -100px;
		border-left: 1px solid $light-grey;
		border-bottom: 1px solid $light-grey;
		top: 0;
		height: 50%;
		width: 100px;
		border-radius: 0 0 0 10px;

		&:before {
			height: 60px;
			width: 1px;
			background: $light-grey;
			top: -50px;
			content: "";
			position: absolute;
			left: -1px;
			z-index: -1;
		}

	}
	.img {
		border: 1px solid #E4E6F1;
		border-radius: 5px;
		overflow: hidden;
		width: 40px;
		display: flex;
		align-items: flex-start;
		height: 40px;
		margin: 4px;
		min-width: 40px;
		img {
			object-fit: cover;
			width: 100%;
		}
	}
	.name {
		flex: 1;
		margin-left: 5px;
		//h3 {
		//	margin: 2px 0;
		font-weight: 500;
		font-size: 14px;
		line-height: 150%;
		letter-spacing: 0.02em;
		color: #252A32;
		//}
	}
	.btns {
		flex: none;
		display: flex;
		flex-direction: row;
		align-items: center;
		.button {
			margin-right: 15px;
			padding: 10px;
			color: #C5C7D2;
		}
	}
}

@media (max-width: 1300px) {
	.funnel-entry {
		.stats-entry {
			display: none;
		}
	}
}
</style>
