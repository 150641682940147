<template>
	<div class="popup_high_right cancel-order"
	     :class="['level-'+level]">

		<a @click="$emit('close')" v-if="!level" class="close_popup_btn">
			<inline-svg :src="require('@/assets/img/popup_close.svg')"/>
		</a>

		<loading v-if="loading" />
		<div v-else class="popup-container scroll">
			<h3 class="title" >
				<back-btn v-if="level" class="back-btn" @click="$emit('close')" />
				<span class="layout-row">
				Cancel order
				<span class="order-number">
					#{{ order ? order.order_number : '--' }}
				</span>
				</span>
			</h3>

			<div class="order-status-row">
				<span class="label">Customer:</span>
				<span class="value" v-if="customer.first_name||customer.last_name">
					{{customer.first_name + ' ' + customer.last_name}}
				</span>
				<span class="value" v-else>
					{{customer.email}}
				</span>
				<div class="flex"></div>
<!--				<quick-actions-btn :items="['Delete customer']" @menuClick="deleteCustomerPopup=true" />-->
			</div>

			<order-summary :order="order" />

			<div class="layout-column" v-if="!canRefund">
				<div class="alert_box orange">
					<inline-svg class="icon" :src="require('@/assets/img/alert_icon_yellow.svg')" />
					<p class="desc">
						Canceling an order will make it unfulfillable. <br>
						<span v-if="order.is_refunded">
							Order refunded already.
						</span>
						<span v-else-if="!order.reference">
							This order cannot be refunded through Funnelish.
						</span>
						<span v-else>
						Orders processed using <b>{{gateway.name}}</b> gateway cannot be refunded through Funnelish.
						</span>
					</p>
				</div>
			</div>
			<div class="layout-column" v-else>
				<div class="alert_box orange">
					<inline-svg class="icon" :src="require('@/assets/img/alert_icon_yellow.svg')" />
					<p class="desc">
						Canceling an order will make it unfulfillable. You can also choose to refund your customer as well.
					</p>
				</div>
				<div class="layout-column margin-t-10 margin-b-20">
					<v-switch label="Do you want to refund your customer as well?"
					          :value="refund" v-model="refund" />
					<input-field v-if="refund"
					             class="reason-box" textarea rows="3"
					             placeholder="Reason for refund (optional)"
					             v-model="reason" label="Reason for refund (optional)" />
				</div>
			</div>
		</div>

		<div class="popup-actions layout-row">
			<v-button label="Cancel order" red border @click="cancelOrder" :loading="saving" />
			<v-button label="Keep Order" border @click="$emit('close')" />
		</div>

	</div>
</template>

<script>
import Popup from "@/components/base/popup";
import VButton from "@/components/base/v-button";
import Loading from "@/components/Loading";
import BackBtn from "@/components/base/back-btn";
import QuickActionsBtn from "@/components/base/quick-actions-btn";
import ExpandableArea from "@/components/base/expandable-area";
import InputField from "@/components/base/input-field";
import SelectField from "@/components/base/select-field";
import VSwitch from "@/components/base/v-switch";
import numbers from "@/helpers/numbers";
import {getSymbol} from "@/helpers/currencies";
import API from '@/api'
import {mapGetters} from "vuex";
import {getGatewayById} from "@/helpers/gateways";
import OrderSummary from "@/views/orders/order-summary";

export default {
	name: "cancel-order",
	components: {
		OrderSummary,
		VSwitch, SelectField, InputField, ExpandableArea, QuickActionsBtn, BackBtn, Loading, VButton, Popup},
	mixins:[API],
	props:{
		order:{
			type: Object,
		},
		customer:{
			type: Object,
		},
		loading: {
			type: Boolean,
		},
		level:{
			type: Number,
			default: 0,
		},
	},
	data(){
		return {
			saving: false,
			reason: '',
			refund: false,
		}
	},
	computed:{
		...mapGetters(['getOrdersStore']),
		canRefund(){
			return this.gateway.canRefund && !this.order.is_refunded && this.order.reference;
		},
		gateway(){
			return getGatewayById(this.order.gateway_id);
		},
	},
	methods:{
		async cancelOrder(){
			try {
				this.saving = true;

				let order = await this.api.orders.cancel(this.order.id, this.refund, this.reason);
				if (order.error) this.$error(order.error);
				else {
					this.order.is_canceled = order.is_canceled;
					this.order.is_refunded = order.is_refunded;
					this.order.amount = order.amount;

					let o = this.getOrdersStore.find(x=>x.id===this.order.id);
					if (o) {
						o.amount = order.amount;
						o.is_refunded = order.is_refunded;
						o.is_canceled = order.is_canceled;
					}

					this.$emit('done');

					this.$info('','Order canceled')
				}
			} catch (e) {
				this.$error(e)
			} finally {
				this.saving = false;
			}
		},
		roundPrice(n) {
			return numbers.round_to_two(n)
		},

	}
}
</script>

<style lang="scss" scoped>
.cancel-order {
	padding: 0;
	max-width: 600px;

	.reason-box {
		margin-top: 15px;
		display: flex;
		height: auto;
		padding: 10px 0 0 10px;
	}
	.desc {
		line-height: 25px;
	}
	.popup-actions {
		padding: 10px 30px;
		border-top: 1px solid $light-grey;
		.button {
			margin-right: 25px;
		}
	}
	.basic-details {
		margin: 15px 0;
	}
	.order-number {
		color: $black;
		margin-left: 5px;
	}
		&.archived {
			.title {
				color: $dark-grey;
			}
		}
		&.canceled {
			.title {
				text-decoration: line-through;
			}
		}
		.status-tags {
			display: flex;
			flex-direction: row;
			margin-right: 25px;
		}
		.status-tag {
			border-radius: 5px;
			background: $light-silver;
			padding: 3px 10px;
			display: flex;
			flex-direction: row;
			align-items: center;
			.value {
				margin: 0 0 0 5px;
			}
			svg {
				//color: #18d7ae;
				height: 18px;
				&.refund {
					color: $black;
				}
			}
			margin-right: 3px;
		}
		&.level-1 {
			max-width: 580px;
			box-shadow: -5px 0 20px 15px rgba(0, 0, 0, 0.10);
		}
		&.level-2 {
			max-width: 560px;
			box-shadow: -5px 0 20px 15px rgba(0, 0, 0, 0.05);
		}
		.title {
			display: flex;
			flex-direction: column;
			align-items: start;
			position: relative;
			.back-btn {
				position: absolute;
				top: -30px;
			}
		}

		.popup_high_right {
			padding: 0;
		}
		.title {
			font-size: 22px;
			line-height: 150%;
			font-weight: 600;
			letter-spacing: 0.02em;
			color: #252A32;
			margin-bottom: 5px;
			background: white;
			min-height: 40px;
		}
		.popup-container {
			display: flex;
			flex-direction: column;
			flex: 1;
			padding: 35px 30px 20px;
		}

	.order-status-row {
		margin-top: 10px;
		margin-bottom: 10px;
		display: flex;
		flex-direction: row;
		font-size: 13px;
		align-items: center;
		color: #26252e;
		.label {
			font-weight: bold;
			margin-right: 10px;
		}
		.value {
			display: flex;
			flex-direction: row;
			margin-right: 30px;
			margin-left: 5px;
		}
	}
}
</style>
