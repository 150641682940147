<template>
    <div class="wrapper steps_layout">
        <div class="main">
            <router-view />
        </div>
    </div>
</template>

<script>
export default {
    name: "StepsChildrenLayout",
    components: {}
}
</script>
