import posthog from "posthog-js";

export default {
    install(Vue, options) {
        const _posthog = posthog.init(
            "phc_gLWKpwqxSj1rHarAtJQyXia8m9N3ZZ9xFogF2xELURJ",
            {
                api_host: "https://eu.posthog.com"
            }
        );
        Vue.prototype.$posthog = _posthog;
        window.$posthog = _posthog;
    }
};
