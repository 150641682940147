<template>
  <a class="external-link" target="_blank" :style="{pointerEvents:!href?'none':'auto'}"
     :href="href">
    {{ label }}
    <inline-svg :src="require('@/assets/img/external_link_icon.svg')"/>
  </a>
</template>

<script>
    export default {
      name: "ExternalLink",
      data: function (){
          return {}
      },
      props: ["label", "href"],
      methods: {
      }
    };
</script>
<style lang="scss">
.external-link {
  position: relative;
  display: flex;
  font-weight: 400;
  font-size: 15px;
  color: #252A32;
  margin: 15px 0;
  >svg {
    margin-left: 10px;
    height: 17px;
    width: 17px;
    path {
      fill: #97AACD;
    }
  }
  &:hover {
    color: #4368E0;
    >svg path {
      fill: #4368E0;
    }
  }
}

</style>
