import {
    shadows, sticky,
} from './fields/'

export default {
    tag: 'productList',
    labelTitle: '',
    labelColor: '',
    id: { value: null },
    content: {
        selecta:'Select a',
        headerTitles: [
            'Product',
            'Price',
        ],
        productsList: [
            { title: 'Dynamically updated', pcs: 1, price: '0.00' },
            // { title: 'Air Jordan 1 Top', pcs: 2, price: '2998.00' },
            // { title: 'Dell Computer Monitor', pcs: 1, price: '30 / 2 weeks' },
            // { title: 'Heimer Miller Sofa (Mint Condition)', pcs: 1, price: '30.00' },
        ],
    },
    attributes: {
        basic: {
            margin: { title: 'Margin', comp: 'marginPadding', value: { top: 20, left: 0, right: 0, bottom: 0 } },
            products: {comp: "manageProducts"},
            selectionType: { title: 'Selection type', comp: 'selectItem', value: 'single', options: [{ id: 'single', text: 'Single checkout' }, { id: 'multi', text: 'Multi-checkout' }, { id: 'qty', text: 'Multi-Quantity' }, ] },
            labelFont: { title: 'Label font', comp: 'FontPanel', value: { fontFamily: '', fontSize: 12, color: '#97AACD', fontStyling: { bold: false, italic: false, 'line-through': false, underline: false, } } },
            font: { title: 'Entries font', comp: 'FontPanel', value: { fontFamily: '', fontSize: 12, color: '#252A32', fontStyling: { bold: false, italic: false, 'line-through': false, underline: false, } } },
            backgroundColor: { title: 'Background color', comp: 'colorPicker2', value: 'white' },
            border: {
                title: 'Border',
                comp: 'border',
                value: {
                    width: 0,
                    color: null,
                    style: 'solid',
                    sides: { top: true, left: true, right: true, bottom: true },
                    radius: { analytics: 0, topRight: 0, topLeft: 0, bottomRight: 0, bottomLeft: 0 },
                }
            },
            shadows
        },
        advanced: {
            cssId: { title: 'ID', comp: 'TextInput', value: '' },
            cssClasses: { title: 'CSS Classes', comp: 'MultiSelectItem', value: [], options: [{ id: 'product-list', text: 'product-list' }] },
            padding: { title: 'Padding', comp: 'marginPadding', value: { top: 10, left: 10, right: 10, bottom: 10 } },
            sticky

            // animations: {
            //     title: 'Animations',
            //     comp: 'AnimationsInput',
            //     value: {
            //         property: '',
            //         duration: 0,
            //         easing: '',
            //     }
            // },
            // transformation: {
            //     title: 'Transformation',
            //     comp: 'TransformationInput',
            //     value: {
            //         rotateX: 0,
            //         rotateY: 0,
            //         rotateZ: 0,
            //         scaleX: 1,
            //         scaleY: 1,
            //         scaleZ: 1,
            //     }
            // },
        },
    },
}
