<template>
<div class="filters-panel">

	<tippy :ref="'quick_action_btn_tippy_'+id"
	       interactive
	       :animate-fill="false"
	       placement="bottom-end"
	       distant="7"
	       theme="menu"
	       animation="fade"
	       trigger="click">
		<template v-slot:trigger>
			<v-button class="add-filter" :icon="require('@/assets/img/filter_list.svg')"
			          slim flat label="Add filter" />
		</template>
		<div class="">
			<filter-condition v-if="step===1" :condition="condition"
			                  :items="items" @back="step=0" @save="save" @cancel="cancel" />
			<select-a-filter v-else :condition="condition"
			                 @change="filterChanged" :items="items" />
		</div>
	</tippy>

	<div class="filters-list">
		<condition_entry :items="items" v-for="c in conditions" :c="c"
		                 :conditions="conditions" @change="$emit('change')"/>
	</div>

</div>
</template>

<script>
import VButton from "@/components/base/v-button";
import QuickActionsBtn from "@/components/base/quick-actions-btn";
import MenuItem from "@/components/base/menu-item";
import SelectAFilter from "@/views/funnels/funnel-plus/funnel/analytics/select-a-filter";
import FilterCondition from "@/views/funnels/funnel-plus/funnel/analytics/filter_condition";
import Condition_entry from "@/views/funnels/funnel-plus/funnel/analytics/condition_entry";
let id = 1;
export default {
	name: "filters_panel",
	components: {Condition_entry, FilterCondition, SelectAFilter, MenuItem, QuickActionsBtn, VButton},

	data() {
		return {
			id: id++,
			conditions: [],
			condition: {id:'',key:'',value:'',values:[],operand:''},
			step: 0,
			tippy: null,
			items: [
				{label:"Currency", id:'currency'},
				{label:"Referrer", id:'referrer'},
				{label:"Referrer domain", id:'referrer_domain'},
				"",
				{label:"UTM Campaign", id:'utm_campaign'},
				{label:"UTM Medium", id:'utm_medium'},
				{label:"UTM Source", id:'utm_source'},
				{label:"UTM Content", id:'utm_content'},
				{label:"UTM Term", id:'utm_term'},
				"",
				{label:"Country", id:'country'},
			],
		}
	},
	methods:{
		cancel(){
			this.step = 0;
			this.condition.key = ''
			this.condition.value = ''
			this.tippy.tippy().hide();
		},
		save(cond) {
			let conditions = [];
			let s = localStorage.getItem("filterConditions")
			if (s) {
				try {
					conditions = JSON.parse(s)
				} catch (e) {console.error(e)}
			}
			let c = JSON.parse(JSON.stringify(cond))
			conditions.push(c)

			// regenerate all ids.
			conditions.forEach((x,index)=>{
				x.id = index+1;
			})
			let ss = JSON.stringify(conditions)
			localStorage.setItem("filterConditions", ss)
			this.conditions = conditions;
			this.tippy.tippy().hide();
			this.step = 0;

			this.$emit('change');
		},
		filterChanged(item){
			this.condition.key = ''
			this.condition.value = ''
			if (!item || !item.id) return;
			this.condition.key = item.id;
			this.step = 1;
		}
	},
	created() {
		let conditions = [];
		let s = localStorage.getItem("filterConditions")
		if (s) {
			try {
				conditions = JSON.parse(s)
			} catch (e) {console.error(e)}
		}
		// regenerate all ids just in case.
		conditions.forEach((x,index)=>{
			x.id = index+1;
		})

		this.conditions = conditions;
	},
	mounted() {
		this.tippy = this.$refs[`quick_action_btn_tippy_${this.id}`]
	}
}
</script>

<style lang="scss" scoped>
.filters-panel {
	display: flex;
	flex-direction: row;
	.filters-list {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}
	.add-filter {
		white-space: nowrap;
		margin-right: 20px;
	}
}

</style>
