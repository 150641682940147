import {
    shadows, sticky,
} from './fields/'

export default {
    tag: 'paymentForm',
    labelTitle: '',
    labelColor: '',
    id: { value: null },
    attributes: {
        basic: {
            // color: { title: 'Color', comp: 'colorPicker', value: '#252A32' },
            // backgroundColor: { title: 'Background color', comp: 'colorPicker', value: '#FFFFFF' },
            margin: { title: 'Margin', comp: 'marginPadding', value: { top: 10, left: 0, right: 0, bottom: 0 } },
            openPopup: { title: 'Edit payment options', comp: 'PopupOpenBtn', value: 'payment-methods' },
            infoMessage: { title: '', comp: 'infoMessage', value: { text: 'You can change the payment options and the visual appearance of your credit card form in your payment app settings as well.' } },
            shadows
        },
        advanced: {
            cssId: { title: 'ID', comp: 'TextInput', value: '' },
            cssClasses: { title: 'CSS Classes', comp: 'MultiSelectItem', value: [], options: [] },
            sticky

            // transformation: {
            //     title: 'Transformation',
            //     comp: 'TransformationInput',
            //     value: {
            //         rotateX: 0,
            //         rotateY: 0,
            //         rotateZ: 0,
            //         scaleX: 1,
            //         scaleY: 1,
            //         scaleZ: 1,
            //     }
            // },
        },
    },
}
