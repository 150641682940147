import axios from "axios";
import config from "@/config/config";

export default (config, axios, token) => {

    var call = async function(path, method = 'GET', data) {
        data ? data = JSON.stringify(data) : '';
        const url = config.URL + path;
        try {
            const response = await axios({
                url,
                method,
                data,
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json',
                }
            })
            return response.data
        } catch (error) {
            return { error: true, info: error.response }
        }
    }

    return {

        getAll: async() => call('compositions'),

        getAllGroups: async() => call('composition-groups'),

        get: async(id) => call('compositions/' + id),

        createComposition: async(data) => call('compositions', 'POST', data),
        createGroup: async(data) => call('composition-groups', 'POST', data),


        deleteGroup: async(id) => call(`composition-groups/${id}`, 'DELETE'),

        delete: async(id) => call(`compositions/${id}`, 'DELETE'),

    }
}
