<template>
    <td>
        <ul class="timer_element" v-if="basic.hours && basic.minutes && basic.seconds" :style="style">
            <li>{{checkHaveNull(basic.hours.number, 'hours')}}</li>
            <li class="sprt_symbol">:</li>
            <li>{{checkHaveNull(basic.minutes.number, 'minutes')}}</li>
            <li class="sprt_symbol">:</li>
            <li>{{checkHaveNull(basic.seconds.number, 'seconds')}}</li>
        </ul>
        <ul class="timer_element" v-else>
            <li>01</li>
            <li class="sprt_symbol">:</li>
            <li>45</li>
            <li class="sprt_symbol">:</li>
            <li>15</li>
        </ul>
    </td>
</template>

<script>
export default {
    name: 'EmailTimer',
    props: ['details'],
    computed: {
        basic() {
            return this.details.attributes.basic
        },
        advanced() {
            return this.details.attributes.advanced
        },
        style() {
	        var marginBase = this.basic.margin || {};
	        var paddingBase = this.basic.padding || {};

	        var margin =
			        parseInt(marginBase.top || 0)+'px '+
			        parseInt(marginBase.right || 0)+'px '+
			        parseInt(marginBase.bottom || 0)+'px '+
			        parseInt(marginBase.left || 0)+'px';

	        var padding =
			        parseInt(paddingBase.top || 0)+'px '+
			        parseInt(paddingBase.right || 0)+'px '+
			        parseInt(paddingBase.bottom || 0)+'px '+
			        parseInt(paddingBase.left || 0)+'px';

            var fontFamily = this.basic.font ? this.basic.font.fontFamily : 'Arial';
            var fontSize = this.basic.font ? this.basic.font.fontSize : '14';
            var textAlign = this.advanced.align || 'center';

            var obj = {
                margin,
                padding,
                backgroundColor: this.basic.backgroundColor || '#ffffff',
                color: this.basic.color || '#636A7B',
                fontFamily: fontFamily,
                fontSize: fontSize + 'px',
                textAlign: textAlign,
            };
            return obj
        }
    },
    methods: {
        checkHaveNull(val, type){
            if(val < 10) {
                return ('0' + val)
            } else if((type == 'hours') && (val > 23)) {
                return '00';
            } else if (((type == 'minutes') || (type == 'seconds')) && (val > 60)) {
                return '60';
            } else {
                return val;
            }
        }
    },
}
</script>
