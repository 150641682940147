<template>
	<div class="popup-backdrop" :class="{'second-level':secondLevel}" @click.self="closePopup">
		<slot></slot>
	</div>
</template>

<script>
export default {
	name: "popup-backdrop",
	props: {
		secondLevel: {
			type: Boolean,
			default: false,
		}
	},
	methods:{
		closePopup() {
			this.$emit('close')
		},
	}
}
</script>

<style lang="scss" scoped>
.popup-backdrop {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: 0.3s all ease-in-out;
	background: rgba(37, 42, 50, 0.5);
	z-index: 1000;
	opacity: 1;
	visibility: visible;
	&.second-level {
		background: rgba(37, 42, 50, 0.15);
	}
}
</style>
