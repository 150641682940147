import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueGtag from "vue-gtag";

import { InlineSvgPlugin } from 'vue-inline-svg'
import Select2 from 'v-select2-component'
import Paginate from 'vuejs-paginate'
import VueCookies from 'vue-cookies'
import VueTippy, { TippyComponent } from "vue-tippy";
import vSelect from 'vue-select'
import Notifications from 'vue-notification'
import titleMixin from './helpers/titleMixin'
import resize from "vue-element-resize-detector";
import PortalVue from 'portal-vue'

import posthogPlugin from "./plugins/posthog"; // import the plugin


Vue.use(posthogPlugin); // install the plugin, before new Vue()

Vue.use(PortalVue)

Vue.config.performance =  process.env.NODE_ENV !== "production";

Vue.config.productionTip = process.env.NODE_ENV === "production";

Vue.prototype.$trimSrc = function(url) {
    return '//' + url.replace(/https*:\/\//mgi, '')
}

Vue.prototype.$warn = function(exception, msg=''){
    if (exception) console.warn(exception);
    this.$notify({
        type:"warn",
        // duration: 10000000000,
        group: "main",
        title: msg || "Something went wrong... Please try again later",
    });
}
Vue.prototype.$error = function(exception,msg=''){
    if (exception) console.error(exception);

    if (exception.error && exception.info && exception.info.data && typeof exception.info.data === "string" &&
        exception.info.data.indexOf("restricted area: access denied") >= 0) {
        msg = "Access denied!"
    }
    this.$notify({
        type:"error",
        duration: 10000, // 10000
        group: "main",
        title: msg || "Something went wrong... Please try again later",
    });
}
Vue.prototype.$info = function(exception,msg=''){
    if (exception) console.log(exception);
    this.$notify({
        type:"info",
        // duration: 10000, // 10000
        group: "main",
        title: msg || "Something went wrong... Please try again later",
    });
}

// TODO: Sentry slows down performance a lot.
// Sentry.init({
//     Vue: Vue,
//     attachProps: false,
//     logErrors: true,
//     dsn: "https://e80bcf12eab540e68cf073ead4c89974@o242803.ingest.sentry.io/1417681",
// });

Vue.use(resize); // used to resize TrendChart manually.

Vue.mixin(titleMixin)

Vue.use(VueCookies)
Vue.use(InlineSvgPlugin);
Vue.use(VueTippy);
Vue.use(Notifications)

Vue.directive('click-outside', {
    bind: function(el, binding, vnode) {
        el.eventOnClick = function(event) {
            if (!(el == event.target || el.contains(event.target))) {
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener('click', el.eventOnClick);
    },
    unbind: function(el) {
        document.body.removeEventListener('click', el.eventOnClick);
    },
});

Vue.directive('mousedown-outside', {
    bind: function(el, binding, vnode) {
        el.eventOnMousedown = function(event) {
            if (!(el == event.target || el.contains(event.target))) {
                vnode.context[binding.expression](event, binding.arg);
            }
        };
        document.body.addEventListener('mousedown', el.eventOnMousedown);
    },
    unbind: function(el) {
        document.body.removeEventListener('mousedown', el.eventOnMousedown);
    },
});

Vue.directive('draw-line', {
    inserted: function(el) {
        var previous = document.querySelectorAll('.add_block_line');
        if (previous.length > 1) {
            previous.forEach(element => {
                element.style.opacity = 0;
            });
        }
        el.generateDrawLine = function (ev) {
            if (!el.parentElement) return;
            var parent = el.parentElement.getBoundingClientRect();
            el.style.top = (parent.y - 1) + parent.height / 2 + 'px';
            el.style.width = (parent.x - 320) + 'px';
            el.style.opacity = 1;
        }

        el.generateDrawLine();

        let wrapper = document.querySelector('.main-center-content-wrpr');
        wrapper.addEventListener('scroll', el.generateDrawLine)
    },
    unbind(el) {
        document.removeEventListener('mousedown', el.eventOnMousedown);
    },
});

Vue.component('v-style', {
    render: function(createElement) {
        return createElement('style', this.$slots.default)
    }
});

Vue.component('v-script', {
    render: function(createElement) {
        return createElement('script', this.$slots.default)
    }
});

Vue.component("Select2", Select2)
Vue.component('paginate', Paginate)
Vue.component("tippy", TippyComponent);
Vue.component('v-select', vSelect);

Vue.use(VueGtag, {
    config: { id: "G-YWLR93RBFY" },
    includes: [{
        id: 'AW-823430941'
    }]
}, router);

window.app = new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')

// window.app.config = {performance: true};
