<template>
    <td class="button-wrapper" :class="responsiveClasses">
        <a
            :href="(basic.linkUrl ? basic.linkUrl.url : '')"
            :id="advanced.cssId"
            :class="['email_btn', advanced.cssClasses]"
            :style="style"
        >
        <span class="btn-headline">{{basic.content || 'Button'}}</span>
        <div
            v-if="basic.subHeadline"
            class="btn_sub_headline"
            style="display: block; font-size: 12px; opacity: 0.8;"
            :style="subStyle"
        >
            {{basic.subHeadline}}
        </div>
    </a>
    </td>
</template>

<script>
export default {
    name: 'EmailButton',
    props: ['details'],
    computed: {
        basic() {
            return this.details.attributes.basic
        },
        advanced() {
            return this.details.attributes.advanced
        },
        responsive() {
            return this.details.attributes.responsive
        },
        responsiveClasses() {
            let c = '';
            if (!this.responsive) return '';
            if (!this.responsive.desktop) c = 'no-desktop';
            if (!this.responsive.mobile) c += ' no-mobile';
            if (!this.responsive.tablet) c += ' no-tablet';
            return c;
        },
        textDecoration2(){
            var fontStyling = this.basic.font2 ? this.basic.font2.fontStyling : false;
            if(fontStyling['line-through'] && fontStyling.underline) {
                return 'underline line-through';
            } else if(fontStyling['line-through']) {
                return 'line-through';
            } else if(fontStyling.underline){
                return 'underline';
            } else {
                return 'inherit';
            }
        },
        subStyle() {
            var fontFamily = this.basic.font2 ? this.basic.font2.fontFamily : 'inherit';
            var fontSize = this.basic.font2 ? this.basic.font2.fontSize + 'px' : 'inherit';
            var textAlign = this.basic.font2 ? this.basic.font2.textAlign : 'center';
            var fontStyling = this.basic.font2 ? this.basic.font2.fontStyling : false;
            var color = this.basic.font2 ? this.basic.font2.color : 'inherit';

            var obj = {
                color,
                fontFamily,
                fontSize,
                textAlign,
                fontStyling,
                fontWeight: fontStyling.bold ? 'bold' : 'inherit',
                fontStyle: fontStyling.italic ? 'italic' : 'inherit',
                textDecoration: this.textDecoration2,
            }
            return obj
        },
        textDecoration(){
            var fontStyling = this.basic.font ? this.basic.font.fontStyling : false;
            if(fontStyling['line-through'] && fontStyling.underline) {
                return 'underline line-through';
            } else if(fontStyling['line-through']) {
                return 'line-through';
            } else if(fontStyling.underline){
                return 'underline';
            } else {
                return 'inherit';
            }
        },
        style() {
            var border = this.advanced.border;
            var borderTop,
                borderLeft,
                borderRight,
                borderBottom;

            if (border) {
                borderTop = !border.sides.top ? 'none' : border.width + 'px ' + border.style + ' ' + border.color;
                borderLeft = !border.sides.left ? 'none' : border.width + 'px ' + border.style + ' ' + border.color;
                borderRight = !border.sides.right ? 'none' : border.width + 'px ' + border.style + ' ' + border.color;
                borderBottom = !border.sides.bottom ? 'none' : border.width + 'px ' + border.style + ' ' + border.color;
            }

            var borderRadius = border ?
                border.radius.leftTop+'px '+
                border.radius.topRight+'px '+
                border.radius.bottomRight+'px '+
                border.radius.bottomLeft+'px' : false;

            var margin = this.basic.margin ?
                this.basic.margin.top+'px '+
                this.basic.margin.right+'px '+
                this.basic.margin.bottom+'px '+
                this.basic.margin.left+'px' : false;

            var padding = this.advanced.padding ?
                this.advanced.padding.top+'px '+
                this.advanced.padding.right+'px '+
                this.advanced.padding.bottom+'px '+
                this.advanced.padding.left+'px' : false;

            var fontFamily = this.basic.font ? this.basic.font.fontFamily : 'inherit';
            var fontSize = this.basic.font ? this.basic.font.fontSize + 'px' : 'inherit';
            var textAlign = this.basic.font ? this.basic.font.textAlign : 'center';
            var fontStyling = this.basic.font ? this.basic.font.fontStyling : false;
            var color = this.basic.font ? this.basic.font.color : 'inherit';

            var boxShadow = this.advanced.shadows ?
                this.advanced.shadows.color+ ' ' +
                this.advanced.shadows.angle+'px '+
                this.advanced.shadows.distance+'px '+
                this.advanced.shadows.blur+'px '+
                this.advanced.shadows.spread+'px '+
                this.advanced.shadows.type : false;

            var textShadow = this.advanced.textShadows ?
                this.advanced.textShadows.color+ ' ' +
                this.advanced.textShadows.angle+'px '+
                this.advanced.textShadows.distance+'px '+
                this.advanced.textShadows.blur+'px' : false;

            var transform = this.advanced.transformation ?
                'rotateX('+ this.advanced.transformation.rotateX +'deg) ' +
                'rotateY('+ this.advanced.transformation.rotateY +'deg) ' +
                'rotateZ('+ this.advanced.transformation.rotateZ +'deg) ' +
                'scale3d('+this.advanced.transformation.scaleX+', '+this.advanced.transformation.scaleY+', '+this.advanced.transformation.scaleZ+')' : false;

            var transition = this.advanced.animations ?
                this.advanced.animations.duration + 's ' +
                this.advanced.animations.property + ' ' +
                this.advanced.animations.easing : 'initial';

            var obj = {
                margin,
                padding: padding || '8px 30px',
                borderRadius: borderRadius || '4px',
                borderTop,
                borderLeft,
                borderRight,
                borderBottom,
                backgroundColor: this.basic.backgroundColor || '#4368E0',
                display: this.advanced.displayType || 'inline-block',
                color,
                fontFamily,
                fontSize,
                textAlign : textAlign  || 'center',
                fontStyling,
                fontWeight: fontStyling.bold ? 'bold' : 'inherit',
                fontStyle: fontStyling.italic ? 'italic' : 'inherit',
                textDecoration: this.textDecoration,
                boxShadow,
                textShadow,
                transform,
                transition,
            };
            return obj
        }
    }
}
</script>
