<template>
    <div :class="['main-search with_notif', {'notif_panel_open' : notificationsOpen}, {'searched': searchInput.length > 0 },]">
        <input type="text" placeholder="Type to search..." class="main-search-input" v-model="searchInput">
        <!-- <input type="text" placeholder="Type to search..." class="main-search-input" :value="value" @input="$emit('input', $event.target.value)"> -->
        <transition-group name="fade">
            <div class="search_result_box" v-if="searchInput.length > 0" :key="1">
                <div class="search_result_panels_wrpr">
                    <Loading v-show="loading"/>

                    <div class="items_found_number" v-if="searchSumm > 0">{{ searchSumm }} items found</div>

                    <div class="search_result_panel" v-if="funnels.length > 0">
                        <div class="result_panel_header">
                            <h3>Funnels</h3>
                            <router-link :to="'/funnels'">View all</router-link>
                        </div>
                        <div class="result_panel_body">
                            <ul>
                                <li v-for="(item, index) in funnels" :key="index">
                                    <a v-if="item['funnel_type'] === 0" @click="goToLink('/funnel/'+item.id+'/')" v-html="textBolded(item.name)"></a>
                                    <a v-if="item['funnel_type'] === 1" @click="goToLink('/wfunnel/'+item.id+'/')" v-html="textBolded(item.name)"></a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="search_result_panel" v-if="apps.length > 0">
                        <div class="result_panel_header">
                            <h3>Apps</h3>
                            <router-link :to="'/apps'">View all</router-link>
                        </div>
                        <div class="result_panel_body">
                            <ul>
                                <li v-for="(item, index) in apps" :key="index">
                                    <a @click="goToLink('/apps/'+item.id)" v-html="textBolded(item.name)"></a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="search_result_panel" v-if="customers.length > 0">
                        <div class="result_panel_header">
                            <h3>Customers</h3>
                            <router-link :to="'/customers'">View all</router-link>
                        </div>
                        <div class="result_panel_body">
                            <ul>
                                <li v-for="(item, index) in customers" :key="index">
                                    <a @click="goToLink(`/customers?id=${item.id}`)" v-html="textBolded(item['first_name'] + ' ' + item['last_name'])"></a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="search_result_panel" v-if="integrations.length > 0">
                        <div class="result_panel_header">
                            <h3>Integrations</h3>
                            <router-link :to="'/integrations'">View all</router-link>
                        </div>
                        <div class="result_panel_body">
                            <ul>
                                <li v-for="(item, index) in integrations" :key="index">
                                    <a @click="goToLink('/integrations/'+item.id)" v-html="textBolded(item.name)"></a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="search_result_panel" v-if="products.length > 0">
                        <div class="result_panel_header">
                            <h3>Products</h3>
                            <!-- <router-link :to="'/integrations'">View all</router-link> -->
                        </div>
                        <div class="result_panel_body">
                            <ul>
                                <li v-for="(item, index) in integrations" :key="index">
                                    <a @click="goToLink('/funnels')" v-html="textBolded(item.name)"></a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="search_no_result" v-if="searchSumm < 1">
                        <h3>No results for “{{searchInput}}”</h3>
                        <p><b>Search tips:</b> Some search terms require an exact match. <br>
                        Try typing the entire term, or use a different word or phrase.</p>
                    </div>
                </div>

                <div class="search_result_box_footer">
                    <div class="left">
                        <span>Explore other Funnelish resources:</span>
                    </div>
                    <div class="right">
                        <a>
                            <inline-svg :src="require('@/assets/img/community_icon.svg')"/> Community
                        </a>
                        <router-link :to="'/help'">
                            <inline-svg :src="require('@/assets/img/help_icon.svg')"/> Help
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="search_overlay" v-if="searchInput.length > 0" :key="2" @click="searchInput = ''"></div>
        </transition-group>
    </div>
</template>

<script>
import API from "@/api"
import Loading from "@/components/Loading.vue"

export default {
    name: "MainSearch",
    components: {
        Loading
    },
	props:{},
    mixins: [API],
    // props: ['value'],
    data: function() {
        return {
            loading: true,
            searchInput: '',
            funnels: [],
            apps: [],
            customers: [],
            integrations: [],
            products: [],
        };
    },
    watch:{
        async searchInput(val){
            this.funnels = [];
            this.apps = [];
            this.customers = [];
            this.integrations = [];
            this.products = [];

            this.loading = true;
            var result = await this.api.search.get(val);
            // console.log(result);
            this.funnels = result.funnels;
            this.apps = result.apps;
            this.customers = result.customers;
            this.integrations = result.integrations;
            this.products = result.products;

            this.$nextTick(()=> {
                this.loading = false;
            });
        }
    },
    computed: {
        notificationsOpen() {
            return this.$store.state.notificationsOpen;
        },
        searchSumm(){
            return (this.funnels.length + this.apps.length + this.customers.length + this.integrations.length + this.products.length);
        },
    },
    methods: {
        notifToggle: function() {
            this.$store.dispatch('notificationsOpenToggle');
        },
        goToLink(val){
            document.location.href = window.location.origin + val;
        },
        textBolded(val) {
            var strRegExp = new RegExp(this.searchInput, 'g');
            return val.replace(strRegExp, '<b>'+this.searchInput+'</b>');
        },
    },
    created() {
    },
}
</script>

<style>
.main-search.with_notif.searched {
    z-index: 15;
}
</style>
