import {
    margin, cssId, cssClasses, id, padding, shadows, sticky

} from './fields/'

export default {
    tag: 'formSpacer',
    labelTitle: '',
    labelColor: '',
    id,
    attributes: {
        basic: {
            margin,
            widthNumber: { title: 'Width', comp: 'InputNumber', value: { number: 2 } },
            color: { title: 'Color', comp: 'colorPicker2', value: '#D5E5FF' },
            style: { title: 'Border style', comp: 'selectItem', value: 'solid', options: [{ id: "dotted", text: "Dotted" }, { id: "dashed", text: "Dashed" }, { id: "solid", text: "Solid" }, { id: "double", text: "Double" }, { id: "groove", text: "Groove" }, { id: "ridge", text: "Ridge" }, ] },

            font: { title: 'Label', comp: 'FontPanel', value: { fontFamily: '', fontSize: 15, color: '', fontStyling: { bold: false, italic: false, 'line-through': false, underline: false, }, textAlign: 'left' } },
            padding,
            shadows
        },
        advanced: {
            cssId,
            cssClasses,
            sticky

        },
    },
}
