<template>
	<popup class="clone-funnel-popup" title="Clone funnel" @close="closePopup">
		<template v-slot:content>
			<div class="content" v-if="step == 1">
				<label class="form_steps_label step_1">
					<span class="icon"></span> Select what to clone
				</label>

				<div v-if="funnel.funnel_type===1" class="brdr_content_wrpr">
					<v-switch disabled :value="true">
						Clone funnel <b>"{{ funnel.name || 'No name funnel' }}"</b>
					</v-switch>
					<v-switch label="Clone Geo-funnels" v-model="cloneGeoFunnels" :value="cloneGeoFunnels"/>
				</div>

				<div v-else class="brdr_content_wrpr">
					<v-switch disabled v-model="checkboxModel1" :value="checkboxModel1">
						Clone funnel <b>[{{ funnel.name || 'No name funnel' }}]</b>.
					</v-switch>

					<v-switch label="Clone funnel steps." v-model="checkboxModel2" :value="checkboxModel2"/>
					<v-switch label="Clone funnel steps' products." v-model="checkboxModel3" :value="checkboxModel3"/>
				</div>
			</div>
			<div class="content" v-if="step == 2">
				<label class="form_steps_label step_1">
					<span class="icon"></span> Your new funnel details
				</label>
				<div>
					<input-field label="Name" v-model="funnelName" />
				</div>
			</div>

			<cloning-funnel-popup v-if="cloning" :funnel="funnel.id" :code="funnel.share_code"
			                      :clone-geo-funnels="cloneGeoFunnels"
			                      :name="funnelName" @done="doneCloneFunnel" @failed="cloning=false"/>

		</template>

		<template v-slot:actions>

			<v-button border label="Back" @click="step = 1" v-if="step == 2"/>

			<v-button label="Continue" @click="step = 2" v-if="step == 1"/>
			<v-button :loading="loading" label="Done" @click="clone" v-if="step == 2"/>

			<v-button border label="Cancel" @click="closePopup"/>
		</template>
	</popup>
</template>

<script>
import API from "@/api"
import VButton from "@/components/base/v-button";
import Popup from "@/components/base/popup";
import CloningFunnelPopup from "@/views/funnels/funnel-plus/funnel/cloning-funnel-popup";
import InputField from "@/components/base/input-field";
import VSwitch from "@/components/base/v-switch";

export default {
	name: "CloneFunnelPopup",
	components: {VSwitch, InputField, CloningFunnelPopup, Popup, VButton},
	props: ['funnel'],
	mixins: [API],
	data() {
		return {
			cloneGeoFunnels: true,
			funnelToClone:{
				share_code:'',
				funnel_id:0,
			},
			cloning: false,
			loading: false,
			step: 1,
			deleteBtnState: false,
			checkboxModel1: true,
			checkboxModel2: true,
			checkboxModel3: true,
			progressNumber: 0,
			funnelName: this.funnel.name + ' - [clone]'
		}
	},
	methods: {
		doneCloneFunnel(funnel) {
			this.$router.push({path: `/wfunnel/${funnel.id}`});

			this.funnelToClone= {share_code:'', funnel_id:0};
			this.cloning=false;
		},
		closePopup() {
			this.$emit("close")
		},
		async clone() {
			try {
				if (this.funnel.funnel_type === 1) {
					console.log("clone F+ funnel")
					this.cloning = true;
					return;
				}
				this.loading = true;
				let clonedFunnelData = {
					"funnel_to_clone": parseInt(this.funnel.id),
					"new_name": this.funnelName,
					"clone_steps": this.checkboxModel2,
					"clone_products": this.checkboxModel3
				}
				let data = await this.api.funnels.clone(clonedFunnelData);

				let pathString = '/funnel/'+data.id;
				if(data.funnel_type) { //Funnel+
					pathString = '/wfunnel/'+data.id
				}
				this.$router.push({path: pathString});

				// this.$emit('done', f.data);
			} catch (e) {
				this.$error(e);
			} finally {
				this.$store.commit("closeCloneFunnelPopup")
				this.loading = false;
			}
		}
	},
}
</script>

<style lang="scss" scoped>
.clone-funnel-popup {
	.content {
		padding: 0;
	}
}
.brdr_box_w_checkbox h3 b {
	margin-right: 3px;
}
</style>
