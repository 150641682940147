export default {
    tag: 'section', // div, h1-h6, p, img, video ...
    labelTitle: '', // String
    labelColor: '', // String => #hex || rgba()
    id: { value: null },
    attributes: {
        basic: {
            margin: { title: 'Margin', comp: 'marginPadding', value: { top: 0, left: 0, right: 0, bottom: 0 } },
            width: {
                title: 'Width',
                comp: 'selectItem',
                value: 'full',
                options: [{ id: "full", text: "Full" }, { id: "wide", text: "Wide" }, { id: "half", text: "Half" }]
            },
            backgroundColor: { title: 'Background color', comp: 'colorPicker', value: 'transparent' },
            backgroundImage: { title: 'Background image', comp: 'imageUploader', value: 'url' },
            font: { title: 'Font', comp: 'FontPanel', value: { fontFamily: 'Roboto', fontSize: 14, color: '#F6F9FE', fontStyling: { bold: false, italic: false, 'line-through': false, underline: false, }, } },
            padding: { title: 'Padding', comp: 'marginPadding', value: { top: 0, left: 0, right: 0, bottom: 0 } },
            border: {
                title: 'Border',
                comp: 'border',
                value: {
                    width: 0, // Integer
                    color: 'transparent', // String => #hex || rgba()
                    style: 'solid', // String => solid || dashed || dotted,
                    sides: { top: true, left: true, right: true, bottom: true }, // Object => Boolean
                    radius: { leftTop: 0, topRight: 0, bottomRight: 0, bottomLeft: 0 }, // Object => Integers
                }
            },
        },
        advanced: {
            cssId: { title: 'ID', comp: 'TextInput', value: '' }, // !!!Unique!!!
            cssClasses: { title: 'CSS Classes', comp: 'MultiSelectItem', value: [], options: [{id: 'class1', text: 'class1'}, {id: 'class2', text: 'class2'}, {id: 'class3', text: 'class3'}] }, // Array of Strings
        },
    },
}
