import {
    shadows,
    textShadows,
    border,
    margin,
    padding,
    linkUrl,
    cssId,
    cssClasses,
    id,
    font4,
    hoverColor,
    buttonShadows, sticky
} from './fields/'

export default {
    tag: 'link',
    labelTitle: '',
    labelColor: '',
    id,
    attributes: {
        basic: {
            align: { title: 'Alignment', comp: 'alignment', value: {mobile:null,desktop:'center',tablet:null} },
            margin,
            content: { title: 'Link text', comp: 'TextInput', value: '' },
            linkUrl,
            font: {title: 'Font', comp: 'FontPanel', value: {
                    fontFamily: '',
                    fontSize: 14,
                    color: '#4368e0',
                    fontStyling: {
                        bold: false,
                        italic: false,
                        'line-through': false,
                        underline: false,
                    },
                }
            },
            textShadows: buttonShadows,
        },
        hover: {
            fontStyling: {title:'Text Style', comp: 'FontStyling', value:{bold: false, italic: false, 'line-through': false, underline: false}},
            color: hoverColor,
            textShadows: buttonShadows
        },
        advanced: {
            cssId,
            cssClasses,
            padding,
            border,
            shadows,
            sticky

        },
    },
}
