import axios from "axios";
import config from "@/config/config";

export default (config, axios, token) => {

    var call = async function(path, method = 'GET', data) {
        const url = config.URL + path;
        try {
            const response = await axios({
                url,
                method,
                data,
                headers: {
                    'Content-Type':'application/json',
                    'Authorization': 'Bearer ' + token }
            })
            return response.data
        } catch (error) {
            throw error.response
                //return { error: true, info: error.response }
        }
    }

    return {
        getUserIntegration: async(id) => call('user-integrations/' + id),

        patchUserIntegration: async(id, data) => call('user-integrations/' + id, 'PATCH', data),

        get: async(integration = false, smtpOnly = "false") =>
            call(integration ? 'integrations/' + integration : 'integrations' + ('?smtp=' + smtpOnly)),

        patch: async(integration, data) =>
            call('integrations/' + integration, 'PATCH', data),

        updateUserIntegration: async(userIntegrationId, data) =>
            call('user-integrations/' + userIntegrationId, 'PATCH', data),

        connect: async(method, data) =>
            call(`call-integration-method/${method}/connect`, 'POST', data), //{ arg1: domain }

        customConnect: async(method, data) =>
            call(`call-integration-method/${method}/custom-connect`, 'POST', data), //{ arg1: domain }

        disconnect: async(method, data) =>
            call(`call-integration-method/${method}/disconnect`, 'POST', data), //{ arg1: "", arg2: "", id: 0 }

        //
        method: async(integrationName, method, data = { "id": 0, "arg1": "1234", "arg2": "arg22234" }) => {
            return await call(`call-integration-method/${integrationName}/${method}`, 'POST', data)
        },

    }
}
