<template>
    <!-- <div v-html="details.content"></div> -->
    <td
        :id="advanced.cssId"
        :class="['custom_render_block', advanced.cssClasses, responsiveClasses]"
        :style="style"
    >
        <!-- <v-style >
            {{elementStyle}}
        </v-style> -->
        <div class="custom_css_inner"
            v-html="details.content"
            :class="'custom_css_inner_'+details.id"
        >
        </div>
    </td>
</template>

<script>
export default {
    name: 'EmailCustom',
    props: ['details'],
    computed: {
        basic() {
            return this.details.attributes.basic
        },
        advanced() {
            return this.details.attributes.advanced
        },
        responsive() {
            return this.details.attributes.responsive
        },
        responsiveClasses() {
            let c = '';
            if (!this.responsive) return '';
            if (!this.responsive.desktop) c = 'no-desktop';
            if (!this.responsive.mobile) c += ' no-mobile';
            if (!this.responsive.tablet) c += ' no-tablet';
            return c;
        },
        style() {
	        var marginBase = this.basic.margin || {};
	        var paddingBase = this.basic.padding || {};

	        var margin =
			        parseInt(marginBase.top || 0)+'px '+
			        parseInt(marginBase.right || 0)+'px '+
			        parseInt(marginBase.bottom || 0)+'px '+
			        parseInt(marginBase.left || 0)+'px';


            var obj = {
                margin,
                // padding,
                // boxShadow,
                // transform,
                // transition,
            };
            return obj
        },
    }
}
</script>
