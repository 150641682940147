<template>

    <div class="select-field " :class="{small}">
      <div class="select_search_icon">
        <span class="form_group_top_block __caption" v-if="caption">
          {{caption}}
          <span v-if="required" style="color: red;">*</span>
          <div v-if="!!info" class="info_icon" v-tippy="{interactive:interactive}" :content="info">
            <inline-svg :src="require('@/assets/img/info_icon.svg')"/>
          </div>

          <tippy v-else-if="!!$slots.info" :interactive="interactive">
            <template v-slot:trigger>
              <div class="info_icon">
                <inline-svg :src="require('@/assets/img/info_icon.svg')"/>
              </div>
            </template>
            <slot name="info"></slot>
          </tippy>
        </span>
        <v-select
		        class="__select"
		        :class="{small}"
            v-bind="$attrs"
            :options="options"
		        :readonly="false"
            @input="onInput"
            @change="onChange"
            :value="value"
            :label="label"
            :disabled="loading || disabled"
            :searchable="searchable || taggable"
		        :multiple="multiple"
		        :taggable="taggable"
            :clearable="clearable"
            :reduce="item => (taggable||noReduce) ? item : item.id"
            :append-to-body="appendToBody"
        >
	        <template v-slot:option="option">
		        <slot name="option" :option="option" />
	        </template>
	        <template v-slot:selected-option="option">
		        <slot name="selected-option" :option="option" />
	        </template>
        </v-select>
	      <v-spinner class="spinner" v-if="loading" />
      </div>
    </div>

</template>

<script>

import VSpinner from "@/components/base/v-spinner";
export default {
	components: {VSpinner},
	inheritAttrs: false,
  name: "SelectField",

  data: function (){
      return {
      }
  },
  // model: {
  //   prop: 'v-model',
  //   event: 'change'
  // },
  props: {
	  loading: {
		  default: false,
		  type: Boolean,
	  },
	  noReduce: {
		  default: false,
		  type: Boolean,
	  },
	  small: {
		  default: false,
		  type: Boolean,
	  },
	  value: {
		  default: "",
	  },
    required:{
      type:Boolean,
      default:false,
    },
    options: {
      type: Array,
      default: ()=>[],
    },
    interactive: {
      type: Boolean,
      default: () => false,
    },
    info: {
      type: Object,
    },
	  appendToBody: {
		  type: Boolean,
		  default: () => true,
	  },
    searchable: {
      type: Boolean,
      default: () => false,
    },
    clearable: {
      type: Boolean,
      default: () => false,
    },
	  multiple:{
		  type: Boolean,
		  default: () => false,
	  },
	  taggable:{
		  type: Boolean,
		  default: () => false,
	  },
	  label: {
      type: String,
    },
    caption: {
      type: String,
    },
	  disabled:{
	  	type: Boolean,
		  default: ()=>false,
	  }
  },
  methods: {
    onInput($event) {
      this.$emit('input', $event)
    },
    onChange($event) {
	    this.$emit('input', $event)
      this.$emit('change', $event)
    }
  },
  computed: {
    hasError() {
        return !!this.error && this.wasActive;
    }
  }
};
</script>
<style lang="scss">
.select-field {

  //border: 2px solid #E4E6F1;
  border-radius: 8px;
	min-height: 41px;
	//height: 41px;
  //margin-bottom: 30px;
  position: relative;
  transition: 0.15s all ease-in-out;
  display: block;
	width: auto;
	&.small {
		height: 26px;
		max-height: 26px;
		min-height: 26px;
		display: flex;
		flex-direction: column;
		.select_search_icon {
			width: 100%;
			height: 100%;
		}
	}
	.spinner {
		z-index: 2;
	}
	.__caption {
		pointer-events: none;
		position: absolute;
		top: -12px;
		left: 10px;
		background: white;
		z-index: 1;
		padding: 1px 5px;
		font-weight: 600;
		color: $dark-grey;
	}
	&:hover {
		.__caption {
			color: $blue;
		}
	}
}
</style>
