export default [
    {
        id: 1,
        img: require('@/assets/img/Order-Form.svg'),
        title: 'Checkout'
    },
    {
        id: 2,
        img: require('@/assets/img/upsell-2.svg'),
        title: 'Upsell'
    },
    {
        id: 3,
        img: require('@/assets/img/Thank You Page.svg'),
        title: 'Thank you page'
    },
    {
        id: 4,
        img: require('@/assets/img/Downsell.svg'),
        title: 'Downsell'
    },
    {
        id: 5,
        img: require('@/assets/img/Optin Page.svg'),
        title: 'Landing page'
    },
    {
        id: 6,
        img: require('@/assets/img/Other.svg'),
        title: 'Other'
    },
]


export const types = {
    ORDER_FORM: 1,
    UPSELL: 2,
    TYP: 3,
    DOWNSELL: 4,
    OPTIN: 5,
    OTHER: 6,
}
