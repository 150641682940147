<template>
	<expandable-area title="Orders" :tag="orders?'('+orders.length+')':''" name="customer-orders">
		<div class="customer-orders">
			<loading v-if="loading" />
			<template v-else-if="orders">
				<div class="order" v-for="order in orders" :key="order.id" @click="$emit('click',order.id)">
				<div class="layout-row flex">
					<div class="img">
						<img class="placeholder" :src="require('@/assets/img/placeholder-img.svg')" />
					</div>
					<div class="layout-column flex">
						<span class="label">{{order.name}}
						<span class="more" v-if="order.items_count>1">+ {{order.items_count-1}} more</span>
						</span>
						<span class="sub_label time">{{time(order.created_at)}}</span>
					</div>
				</div>
				<div class="value">
					 {{currency(order.currency)}} {{order.amount || 0.00}}
				</div>
<!--				<div class="layout-column flex-none" >-->
<!--					<v-button slim flat class="retry-btn small-btn" v-tippy content="Quick actions"-->
<!--					          :icon="require('@/assets/img/ellipsis.svg')" />-->
<!--				</div>-->
			</div>
			</template>
		</div>
	</expandable-area>
</template>

<script>
import ExpandableArea from "@/components/base/expandable-area";
import moment from "moment";
import {getSymbol} from "@/helpers/currencies";
import VButton from "@/components/base/v-button";
import CustomerAvatar from "@/components/base/customer-avatar";
import API from '@/api'
import Loading from "@/components/Loading";

export default {
	name: "customer-orders",
	components: {Loading, CustomerAvatar, VButton, ExpandableArea},
	props: ['customerId'],
// {
// 		customerId:{
// 			type: Number,
// 		}
// 	},
	mixins:[API],
	data(){
		return {
			loading: false,
			orders:[],
		}
	},
	computed:{
	},
	methods:{
		currency(c){
			return getSymbol(c);
		},
		time(t){
			return moment(t).format("DD MMM YYYY, HH:mm:ss")
		},
		async init(){
			try {
				this.loading = true;
				this.orders = [];
				this.orders = await this.api.customers.getOrders(this.customerId)
			} catch (e) {
				this.$warn(e)
			} finally {
				this.loading = false;
			}
		}
	},
	created() {
		this.init();
	}
}
</script>

<style lang="scss" scoped>
.customer-orders {
	display: flex;
	flex-direction: column;
	margin-top: 10px;
	margin-left: -15px;
	margin-right: -15px;

	.order {
		display: flex;
		flex-direction: row;
		margin-left: 10px;
		margin-right: 10px;
		margin-bottom: 10px;
		padding: 8px 15px;
		background: transparent;
		border-radius: 8px;
		cursor: pointer;

		&:hover {
			background: #f7f7f7;
		}

		.more {
			font-weight: normal;
			color: $dark-grey;

		}

		.img {
			margin-right: 10px;
			position: relative;
			display: flex;
			flex-direction: column;
			align-items: center;
			border: 1px solid $light-grey;
			background: $light-blue;
			width: 40px;
			height: 40px;
			border-radius: 5px;
			overflow: hidden;
			img {
				display: flex;
				align-self: center;
				flex: 1;
				&.placeholder {
					width: 30px;
					height: 30px;
					opacity: .6;
				}
			}
		}
		.label {
			font-size: 14px;
			font-weight: 600;
			color: $black;
		}
		.sub_label{
			font-size: 14px;
			font-weight: normal;
			color: $dark-grey;
			&.time {
				margin-top: 5px;
			}
			&.error {
				color: #fc585b;
			}
		}

		.retry-btn {
			margin-left: 5px;
		}
		.value {
			text-align: right;
			font-size: 14px;
			color: $black;
			font-weight: bold;
			margin-left: 30px;
		}
	}

}
</style>
