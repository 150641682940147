<template>
	<div class="popup_high_right fulfill-order"
	     :class="['level-'+level]">

		<a @click="$emit('close')" v-if="!level" class="close_popup_btn">
			<inline-svg :src="require('@/assets/img/popup_close.svg')"/>
		</a>

		<loading v-if="loading" />
		<div v-else class="popup-container scroll">
			<h3 class="title" >
				<back-btn v-if="level" class="back-btn" @click="$emit('close')" />
				<span class="layout-row">
				Fulfill order
				<span class="order-number">
					#{{ order ? order.order_number : '--' }}
				</span>
				</span>
			</h3>

			<div class="order-status-row">
				<span class="label">Customer:</span>
				<span class="value" v-if="customer.first_name||customer.last_name">
					{{customer.first_name + ' ' + customer.last_name}}
				</span>
				<span class="value" v-else>
					{{customer.email}}
				</span>
				<div class="flex"></div>
<!--				<quick-actions-btn :items="['Delete customer']" @menuClick="deleteCustomerPopup=true" />-->
			</div>

			<order-summary :order="order" />

			<expandable-area title="Tracking details" tag="(optional)" name="customer-basic-details">
				<div class="basic-details">
					<div class="layout-row margin-b-20">
						<input-field class="margin-r-30 flex" small label="Tracking number" v-model="tracking_number" />

						<select-field class="flex" :options="shipping_carriers" caption="Shipping carrier" label="name"
						              :value="shipping_carrier" v-model="shipping_carrier"
						              :reduce="x=>x.id"
						              searchable clearable small />
					</div>

					<div class="layout-row" v-if="!shipping_carrier||shipping_carrier==='other'">
						<input-field class="flex" small label="Tracking URL" v-model="tracking_url" />
					</div>

					<v-switch label="Trigger fulfillment automations" :value="trigger_automations" v-model="trigger_automations"
					          info="When checked your customer will be taken through any fulfillment automations or sequences you've setup." />
				</div>
			</expandable-area>
		</div>

		<div class="popup-actions layout-row">
			<v-button label="Fulfill order" @click="fulfill" :loading="saving" />
			<v-button label="Cancel" border @click="$emit('close')" />
		</div>

	</div>
</template>

<script>
import Popup from "@/components/base/popup";
import VButton from "@/components/base/v-button";
import Loading from "@/components/Loading";
import BackBtn from "@/components/base/back-btn";
import QuickActionsBtn from "@/components/base/quick-actions-btn";
import ExpandableArea from "@/components/base/expandable-area";
import InputField from "@/components/base/input-field";
import SelectField from "@/components/base/select-field";
import VSwitch from "@/components/base/v-switch";
import numbers from "@/helpers/numbers";
import {getSymbol} from "@/helpers/currencies";
import API from '@/api'
import {mapGetters} from "vuex";
import OrderSummary from "@/views/orders/order-summary";

export default {
	name: "fulfill-order",
	components: {
		OrderSummary,
		VSwitch, SelectField, InputField, ExpandableArea, QuickActionsBtn, BackBtn, Loading, VButton, Popup},
	mixins:[API],
	props:{
		order:{
			type: Object,
		},
		customer:{
			type: Object,
		},
		loading:{
			type: Boolean,
		},
		level:{
			type: Number,
			default: 0,
		},
	},
	data(){
		return {
			saving: false,
			tracking_number: '',
			shipping_carrier: '',
			tracking_url: '',
			trigger_automations: true,
			shipping_carriers: [
				{id:'other', name:'Other'},
				{id:'fedex', name:'FedEx'},
				{id:'usps', name:'USPS'},
			],
		}
	},
	computed:{
		...mapGetters(['getOrdersStore']),
	},
	methods:{
		async fulfill(){
			try {
				this.saving = true;

				let order = await this.api.orders.fulfill(this.order.id, {
					order_id: this.order.id,
					tracking_number: this.tracking_number,
					shipping_carrier: this.shipping_carrier,
					tracking_url: this.tracking_url,
					trigger_automations: this.trigger_automations,
				});
				if (order.error) this.$error(order.error);
				else {
					this.order.is_fulfilled = order.is_fulfilled;

					let o = this.getOrdersStore.find(x=>x.id===this.order.id);
					if (o) o.is_fulfilled = order.is_fulfilled;

					this.$emit('done');
				}
			} catch (e) {
				this.$error(e)
			} finally {
				this.saving = false;
			}
		},
		roundPrice(n) {
			return numbers.round_to_two(n)
		},

	}
}
</script>

<style lang="scss" scoped>
.fulfill-order {
		padding: 0;
	.popup-actions {
		padding: 10px 30px;
		border-top: 1px solid $light-grey;
		.button {
			margin-right: 25px;
		}
	}
	.basic-details {
		margin: 15px 0;
	}
	.order-number {
		color: $black;
		margin-left: 5px;
	}
		&.archived {
			.title {
				color: $dark-grey;
			}
		}
		&.canceled {
			.title {
				text-decoration: line-through;
			}
		}
		.status-tags {
			display: flex;
			flex-direction: row;
			margin-right: 25px;
		}
		.status-tag {
			border-radius: 5px;
			background: $light-silver;
			padding: 3px 10px;
			display: flex;
			flex-direction: row;
			align-items: center;
			.value {
				margin: 0 0 0 5px;
			}
			svg {
				//color: #18d7ae;
				height: 18px;
				&.refund {
					color: $black;
				}
			}
			margin-right: 3px;
		}
		&.level-1 {
			max-width: 580px;
			box-shadow: -5px 0 20px 15px rgba(0, 0, 0, 0.10);
		}
		&.level-2 {
			max-width: 560px;
			box-shadow: -5px 0 20px 15px rgba(0, 0, 0, 0.05);
		}
		.title {
			display: flex;
			flex-direction: column;
			align-items: start;
			position: relative;
			.back-btn {
				position: absolute;
				top: -30px;
			}
		}

		.popup_high_right {
			padding: 0;
		}
		.title {
			font-size: 22px;
			line-height: 150%;
			font-weight: 600;
			letter-spacing: 0.02em;
			color: #252A32;
			margin-bottom: 5px;
			background: white;
			min-height: 40px;
		}
		.popup-container {
			display: flex;
			flex-direction: column;
			flex: 1;
			padding: 35px 30px 20px;
		}

	.order-status-row {
		margin-top: 10px;
		margin-bottom: 10px;
		display: flex;
		flex-direction: row;
		font-size: 13px;
		align-items: center;
		color: #26252e;
		.label {
			font-weight: bold;
			margin-right: 10px;
		}
		.value {
			display: flex;
			flex-direction: row;
			margin-right: 30px;
			margin-left: 5px;
		}
	}
}
</style>
