export default (config, axios, token) => {

    var call = async function(path, method = 'GET', data, isJsonApi = true) {
        data ? data = JSON.stringify((isJsonApi ? { data } : data)) : '';
        const url = config.URL + path;
        try {
            const response = await axios({
                url,
                method,
                data,
                headers: { 'Authorization': 'Bearer ' + token }
            })
            return response.data
        } catch (error) {
            return { error: true, info: error.response }
        }
    }

    return {

        get: async() =>
            call('apps'),

        callMethod: async(appId, funnelId, method='', data={}) =>
            call(`apps/${appId}/call-method?method=${method}&funnelId=${funnelId}`, 'POST', data, false),

        getFiltered: async(id = '', appId) =>
            call('funnel-apps?filter[app_id]=' + appId + '&filter[funnel_id]=' + id),

        getFunnel: async(id) =>
            call('funnels/' + id + '/apps'),

        getHowto: async(id) =>
            call('app-howtos/' + id),

        patch: async(id, data) =>
            call('funnel-apps/' + id, 'PATCH', data),

        patchUser: async(id, data) =>
            call('user-apps/' + id, 'PATCH', data),


        getFunnelPlusApps: async(funnelId) =>
            call('wfunnels/' + funnelId + '/apps', 'GET',null, false),

        patchFunnelApp: async(funnelId, data, isPlus=true) => {
            let r = isPlus ? 'wfunnels' : 'funnels';
            return call(`${r}/${funnelId}/apps`, 'PATCH', data, false)
        },

    }
}
