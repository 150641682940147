import axios from "axios";
import config from "@/config/config";

export default (config, axios, token) => {

    var call = async function(path, method = 'GET', data, string = true) {
        // if (string) data ? data = JSON.stringify(data) : '';

        const url = config.URL + path;
        try {
            const response = await axios({
                url,
                method,
                data,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
            })
            return response.data
        } catch (error) {
            return { error: true, info: error.response }
        }
    }

    return {

        get: async(id) =>
            call('popups/' + id),
        getAll: async(brief=false) =>
            call(`popups?brief=${brief}`),

        create: async(data) =>
            call(`popups`, 'POST', data),

        update: async(data) =>
            call('popups', 'PUT', data),

        clone: async(id, data) =>
            call('popups/' + id + '/clone', 'POST', data),

        delete: async(id) =>
            call('popups/' + id, 'DELETE'),
    }
}
