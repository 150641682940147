export const applyDrag = (arr, dragResult) => {
    const { removedIndex, addedIndex, payload } = dragResult
    if (removedIndex === null && addedIndex === null) return arr

    const result = [...arr]
    let itemToAdd = payload

    if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0]
    }

    if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd)
    }

    return result
}

const deepFunc = (inObject) => {
    let outObject, value, key
    if (typeof inObject !== "object" || inObject === null) {
        return inObject
    }

    outObject = Array.isArray(inObject) ? [] : {};

    for (key in inObject) {
        value = inObject[key]
        outObject[key] = deepFunc(value)
    }
    return outObject
}

export const deepCopy = (inOb) => new Promise((resolve, reject, _inObj = inOb) => resolve(deepFunc(_inObj)))

export const clearUp = (editor) => {
    var { rows, children } = editor;
    var exist = [];
    rows.forEach((row, index) => {
        row.cols.forEach((col, index) => {
            exist = [...exist, ...col.children];
        });
    });

    children.forEach(child => {
        if (child.type === 'twoStepForm') {
            exist = [...exist, ...child.children];
        } else if (child.type === 'container') {
            let boxes = child.attributes && child.attributes.basic ? child.attributes.basic.boxes : [];

            boxes && boxes.forEach(box => {
                let ar = box.children;
                if (!ar || !Array.isArray(ar)) return; // during deletion this causes a bug.
                exist = [...exist, ...ar];
            })
        } else if (child.type === 'faq') {
            if (!child.children || !Array.isArray(child.children)) return; // during deletion this causes a bug.
            exist = [...exist, ...child.children];
        }
    });

    var newChildren = children.filter(child => exist.includes(child.id));
    editor.children = newChildren;
    return editor
}

/** DO NOT EXECUTE THIS TOO MUCH! IT'S AN EXPENSIVE RECURSIVE LOOP
 *
 * @param element to start hierarchy loop from.
 * @param horizontal should calculate horizontal offset, or vertical.
 * @returns {number} the offset.
 */
export function getOffset(element, horizontal = false) {
    if (!element) return 0;
    return getOffset(element.offsetParent, horizontal) + (horizontal ? element.offsetLeft : element.offsetTop);
}

export function setFontProp(basic, view = 'desktop', prop, value) {
    switch (view) {
        case "mobile":
            if (!basic.mobileFont) basic.mobileFont = JSON.parse(JSON.stringify(basic.font));
            basic.mobileFont[prop] = value;
            break;
        case "tablet":
        case "tablets":
            if (!basic.tabletFont) basic.tabletFont = JSON.parse(JSON.stringify(basic.font));
            basic.tabletFont[prop] = value;
            break;
        default:
            basic.font[prop] = value;
            break;
    }
}
