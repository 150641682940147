<template>

    <div v-if="!!info" class="info_icon" :class="[$style.base, {warn, small}]" :content="info" v-tippy="{theme,boundary:'viewport'}">
	    <inline-svg v-if="warn" :src="require('@/assets/img/warning_icon.svg')"/>
	    <inline-svg v-else :src="require('@/assets/img/information-2-line.svg')"/>
    </div>
		<tippy v-else-if="!!$slots" :interactive="interactive" :theme="theme" placement="bottom-start" boundary="viewport">
			<template v-slot:trigger>
				<div class="info_icon" :class="[$style.base, {warn, small}]">
					<inline-svg v-if="warn" :src="require('@/assets/img/warning_icon.svg')"/>
					<inline-svg v-else :src="require('@/assets/img/information-2-line.svg')"/>
				</div>
			</template>
			<slot ></slot>
		</tippy>
</template>

<script>
export default {
  inheritAttrs: false,
  name: "InfoIcon",

  data: function (){
      return {

      }
  },
    props: {
		small:{
			type:Boolean,
			default:false
		},
			theme:{
				type: String,
				default:'small-black'
			},
      warn:{
        type: Boolean,
        default: false,
      },
      interactive: {
        type: Boolean,
        default: false,
      },
      info: {
        type: String,
        default: () => null,
      },
    },
};
</script>
<style lang="scss" module>
:global {
:local(.base) {
  background: none;
  position: relative;
  display: flex;
  z-index: 1;
  margin-left: 3px;
  outline: none;
  cursor: pointer;

  &.small {
    svg {
      width: 14px;
      height: 14px;
    }
  }

      &.warn:hover {
        svg, svg path {
          fill: #fdc036;
        }
      }

  svg {
    width: 20px;
    height: 20px;
  }
}
}
</style>
