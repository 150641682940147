export default {
    tag: 'image',
    labelTitle: '',
    labelColor: '',
    id: { value: null },
    attributes: {
        basic: {
            margin: { title: 'Margin', comp: 'marginPadding', value: { top: 0, left: 0, right: 0, bottom: 0 } },
            image: { title: 'Image', comp: 'imageUploader', value: '' },
            // padding: { title: 'Padding', comp: 'marginPadding', value: { top: 0, left: 0, right: 0, bottom: 0 } },
            alternateText: { title: 'Alternate text', comp: 'TextInput', value: '' },
            clickAction: { title: 'Click action', comp: 'ClickAction', value: { url: '', toggle: 'no action' } },
            height: { title: 'Height', comp: 'TextInput', value: 'auto' },
            width: { title: 'Width', comp: 'TextInput', value: 'auto' },
            border: {
                title: 'Border',
                comp: 'border',
                value: {
                    width: 0,
                    color: 'transparent',
                    style: 'solid',
                    sides: { top: true, left: true, right: true, bottom: true },
                    radius: { leftTop: 0, topRight: 0, bottomRight: 0, bottomLeft: 0 },
                }
            },
        },
        advanced: {
            cssId: { title: 'ID', comp: 'TextInput', value: '' },
            cssClasses: { title: 'CSS Classes', comp: 'MultiSelectItem', value: [], options: [{id: 'class1', text: 'class1'}, {id: 'class2', text: 'class2'}, {id: 'class3', text: 'class3'}] },
        },
    },
}