export default (config, axios, token) => {

    var call = async function(path, method = 'GET', data) {
        // data ? data = JSON.stringify(data) : '';
        const url = config.URL + 'developers/' + path;
        try {
            const response = await axios({
                url,
                method,
                data,
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            })
            return response.data
        } catch (error) {
            return { error: true, info: error.response }
        }
    }

    var upload = async function(path, method = 'POST', data) {
        const url = config.URL + 'developers/' + path;
        try {
            const response = await axios({
                url,
                method,
                headers: { 'Authorization': 'Bearer ' + token },
                data,
                redirect: 'follow'
            })
            return response.data
        } catch (error) {
            return { error: true, info: error.response }
        }
    }

    return {

        createApp: async(formData) =>
            upload('me/apps', 'POST', formData),
        updateApp: async(formData) =>
            upload('me/apps', 'PUT', formData),

        getAllApps: async() =>
            call('me/apps'),

        deleteApp: async(id) =>
            call(`me/apps/${id}`,'DELETE'),


    }
}
