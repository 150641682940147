import models from "@/modules/email-editor/src/models";

var generateId = function(numbers = []) {
    var min = 1,
        max = 100000;
    var randomNumber = Math.floor(Math.random() * (max - min + 1) + min);
    if (numbers.length && numbers.indexOf(randomNumber) !== -1) generateId(numbers);
    else return randomNumber
}
var createCols = function(cols, oldCols = false) {
    var colsData = [];
    switch (cols) {
        case 'one':
            colsData = [{ width: 100, children: [] }]
            break;
        case 'two':
            colsData = [{ width: 50, children: [] }, { width: 50, children: [] }]
            break;
        case 'three':
            colsData = [{ width: 33.3333, children: [] }, { width: 33.3333, children: [] }, { width: 33.3333, children: [] }]
            break;
        case 'four':
            colsData = [{ width: 25, children: [] }, { width: 25, children: [] }, { width: 25, children: [] }, { width: 25, children: [] }]
            break;
        case 'five':
            colsData = [{ width: 75, children: [] }, { width: 25, children: [] }]
            break;
        case 'six':
            colsData = [{ width: 25, children: [] }, { width: 75, children: [] }]
            break;
    }
    if (oldCols !== false) {
        oldCols.forEach((col, index) => {
            if (index < colsData.length) {
                colsData[index].children = col.children
            } else {
                colsData[colsData.length - 1].children.push(...col.children);
            }
        });
    }
    return colsData
}

export default {
    state: {
        content: [],
        rows: [],
        children: []
    },
    getters: {
        email_numbers({ editor }) {
            var numbers = []
            editor.content.forEach(item => numbers.push(item.id));
            editor.rows.forEach(item => numbers.push(item.id));
            editor.children.forEach(item => numbers.push(item.id));
            return numbers
        },
        email_findOne({ editor }) {
            return id => {
                return editor.content.find(item => item.id === id) ||
                    editor.rows.find(item => item.id === id) ||
                    editor.children.find(item => item.id === id)
            }
        },
        email_findChild({ editor }) {
            return id => {
                return editor.children.find(item => item.id === id);
            }
        },
        email_findAll({ editor }) {
            return editor.content
        }
    },
    mutations: {
        email_emptyState(state, doneState) {
            var editor = doneState.editor ? JSON.parse(doneState.editor) : {
                content: [],
                rows: [],
                children: []
            };
            var page = doneState.page ? JSON.parse(doneState.page) : {
                basic: {
                    bgColor: '#fff',
                    bgImage: '',
                    padding: { top: 68, left: 30, right: 30, bottom: 200 },
                    font: {
                        fontStyling: { bold: false, italic: false, 'line-through': false, underline: false },
                        color: '#252A32',
                        fontFamily: 'Roboto',
                        fontSize: 14,
                    },
                    width: {
                        fullWidth: false,
                        width: 650,
                    },
                    border: {
                        radius: { bottomLeft: 0, bottomRight: 0, leftTop: 0, topRight: 0 },
                        sides: { bottom: true, left: true, right: true, top: true },
                        color: 'transparent',
                        style: 'solid',
                        width: 0
                    }
                },
                advanced: {
                    id: '',
                    cssClasses: []
                }
            }
            state.editor = editor;
            state.page = page;
        },
        //SECTIONS
        EMAIL_ADD_BLOCK({ editor }, { item, index }) {
            item.children = [];
            editor.content.splice(index, 0, item);
        },
        EMAIL_REMOVE_BLOCK({ editor }, value) {
            var target = editor.content.findIndex(item => item.id === value);
            var targetRows = editor.rows.filter(row => row.parent === value);
            var targetChildren = [];
            targetRows.forEach(item => item.cols.forEach(col => targetChildren.push(...col.children)));
            editor.children = editor.children.filter(child => targetChildren.indexOf(child.id) === -1);
            editor.rows = editor.rows.filter(row => row.parent !== value);
            editor.content.splice(target, 1);
        },
        EMAIL_CLONE_BLOCK({ editor }, { clone, id, newRows, newChildren }) {
            clone.children.forEach((row, index) => {
                var rowTarget = editor.rows.find(r => r.id === row);
                var rowCloneStr = JSON.stringify(rowTarget);
                var rowClone = JSON.parse(rowCloneStr);
                rowClone.parent = clone.id;
                rowClone.id = newRows[index];
                rowClone.cols.forEach((col, colIndex) => {
                    col.children.forEach((child, childIndex) => {
                        var childTarget = editor.children.find(c => c.id === child);
                        var childCloneStr = JSON.stringify(childTarget);
                        var childClone = JSON.parse(childCloneStr);
                        childClone.id = newChildren[index][colIndex][childIndex];
                        editor.children.push(childClone);
                        col.children[childIndex] = childClone.id;
                    });
                });
                editor.rows.push(rowClone);
                clone.children[index] = rowClone.id;
            });

            var target = editor.content.findIndex(item => item.id === id);
            editor.content.splice(target + 1, 0, clone);
        },
        EMAIL_EDIT_BLOCK({ editor }, value) {
            var target = editor.children.findIndex(item => item.id === value.id);
            editor.children.splice(target, 1, value);
        },
        EMAIL_EDIT_FIELD({ editor }, { id, field, value, tab, type }) {
            // var start = performance.now();
            if (type === 'section') {
                var targetIndex = editor.content.findIndex(item => item.id === id);
                var saveStr = JSON.stringify(editor.content[targetIndex]);
                var updatedItem = JSON.parse(saveStr);
                if (tab) updatedItem.attributes[tab][field] = value;
                else updatedItem[field] = value;
                editor.content.splice(targetIndex, 1, updatedItem);
            } else if (type === 'row') {
                var targetIndex = editor.rows.findIndex(item => item.id === id);
                var saveStr = JSON.stringify(editor.rows[targetIndex]);
                var updatedItem = JSON.parse(saveStr);
                if (tab) updatedItem.attributes[tab][field] = value;
                else updatedItem[field] = value;

                if (field === 'cols') {
                    var newCols = createCols(value, editor.rows[targetIndex].cols);
                    updatedItem.cols = newCols;
                }
                editor.rows.splice(targetIndex, 1, updatedItem);
            } else {
                var targetIndex = editor.children.findIndex(item => item.id === id);
                var saveStr = JSON.stringify(editor.children[targetIndex]);
                var updatedItem = JSON.parse(saveStr);
                if (tab) updatedItem.attributes[tab][field] = value;
                else updatedItem[field] = value;
                editor.children.splice(targetIndex, 1, updatedItem);
            }
            // var end = performance.now();
            // console.log('EMAIL_EDIT_FIELD took ' + (end - start) + ' milliseconds to execute.');
        },
        EMAIL_DROP_BLOCK({ editor }, { removedIndex, addedIndex }) {
            var temp1 = editor.content[removedIndex];
            editor.content[removedIndex] = editor.content[addedIndex];
            editor.content[addedIndex] = temp1;
        },
        //ROWS
        EMAIL_ADD_ROW({ editor }, { type, parent, id, cols = false, index }) {
            var colsData = createCols(cols);
            var target;
            var newRow = { id, type, labelTitle: 'My Row', labelColor: '#449CF4', attributes: { basic: { cols }, advanced: {} }, cols: colsData, };

            if (parent) target = editor.content.find(item => item.id === parent);
            else target = editor.content[0] || false;

            if (target) {
                newRow.parent = target.id;
                if (target.children.indexOf(newRow.id) === -1) target.children.splice(index, 0, newRow.id);
                editor.rows.push(newRow);
            } else alert('Place at least one section please');
        },
        EMAIL_CHANGE_COLS({ editor }, { row, cols }) {
            var colsData = [];
            switch (cols) {
                case 'one':
                    colsData = [{ width: 100, children: [] }]
                    break;
                case 'two':
                    colsData = [{ width: 50, children: [] }, { width: 50, children: [] }]
                    break;
                case 'three':
                    colsData = [{ width: 33.3333, children: [] }, { width: 33.3333, children: [] }, { width: 33.3333, children: [] }]
                    break;
                case 'four':
                    colsData = [{ width: 25, children: [] }, { width: 25, children: [] }, { width: 25, children: [] }, { width: 25, children: [] }]
                    break;
                case 'five':
                    colsData = [{ width: 75, children: [] }, { width: 25, children: [] }]
                    break;
                case 'six':
                    colsData = [{ width: 25, children: [] }, { width: 75, children: [] }]
                    break;
            }
            var target = editor.rows.find(r => r.id === row);
            target.cols = colsData
        },
        EMAIL_DROP_ROW({ editor }, { removedIndex, addedIndex, payload }) {
            var section = editor.content.find(s => s.id === payload.sectionId);
            var temp1 = section.children[removedIndex];
            section.children[removedIndex] = section.children[addedIndex];
            section.children[addedIndex] = temp1;
        },
        EMAIL_DROP_ROW_ADD({ editor }, { from, to, id, item, index }) {
            item.parent = to;
            var sectionFrom = editor.content.find(s => s.id === from);
            var sectionTo = editor.content.find(s => s.id === to);
            var targetIndex = sectionFrom.children.findIndex(t => t === id);
            sectionFrom.children.splice(targetIndex, 1);
            sectionTo.children.splice(index, 0, id);
        },
        EMAIL_RESIZE_COL({ editor }, { row, col, width }) {
            var targetRow = editor.rows.find(r => r.id === row);
            var delta = targetRow.cols[col].width - width;
            targetRow.cols[col].width = width
            targetRow.cols[col + 1].width += delta
        },
        EMAIL_REMOVE_ROW({ editor }, { row, index }) {
            var targetRow = editor.rows.findIndex(r => r.id === row);
            var targetSection = editor.content.findIndex(item => item.id === editor.rows[targetRow].parent);
            editor.content[targetSection].children.splice(index, 1);
            editor.rows.splice(targetRow, 1);
        },
        EMAIL_CLONE_ROW({ editor }, { clone, id, newChildren }) {
            clone.cols.forEach((col, colIndex) => {
                col.children.forEach((child, childIndex) => {
                    var childTarget = editor.children.find(c => c.id === child);
                    var childCloneStr = JSON.stringify(childTarget);
                    var childClone = JSON.parse(childCloneStr);
                    childClone.id = newChildren[0][colIndex][childIndex];
                    editor.children.push(childClone);
                    col.children[childIndex] = childClone.id;
                });
            });
            editor.rows.push(clone);
            var target = editor.content.find(item => item.id === clone.parent);
            var ancestorIndex = target.children.findIndex(item => item === id);

            target.children.splice(ancestorIndex, 0, clone.id);
        },
        //COMMON BLOCKS
        EMAIL_ADD_COMMON({ editor }, { type, row, col, index, id }) {
            // Apply some default values.
            // TODO: make it dynamic and apply all existing properties of the element.
            let model = JSON.parse(JSON.stringify(models[type]));
            let basic = {
                padding: model.attributes.basic.padding ? model.attributes.basic.padding.value : { top: 0, left: 0, right: 0, bottom: 0 },
                margin: model.attributes.basic.margin ? model.attributes.basic.margin.value : { top: 0, left: 0, right: 0, bottom: 0 },
            };
            let advanced = {
                cssClasses: model.attributes.advanced.cssClasses.value,
                cssId: model.attributes.advanced.cssId.value,
            };
            var newChild = {
                id,
                type,
                parent: row,
                labelTitle: 'My Block',
                labelColor: '#449CF4',
                attributes: { basic: basic, advanced: advanced },
            };
            var targetRow = editor.rows.find(r => r.id === row);
            editor.children.push(newChild);
            if (targetRow.cols[col].children.length) targetRow.cols[col].children.splice(index, 0, id);
            else targetRow.cols[col].children.push(id);
        },
        EMAIL_DROP_COMMON({ editor }, { removedIndex, addedIndex, payload }) {
            var row = editor.rows.find(r => r.id === payload.rowFrom);
            var col = payload.colFrom;

            var temp1 = row.cols[col].children[removedIndex];
            var temp2 = row.cols[col].children[addedIndex];

            row.cols[col].children.splice(removedIndex, 1, temp2);
            row.cols[col].children.splice(addedIndex, 1, temp1);
        },
        EMAIL_DROP_COMMON_ADD({ editor }, { from, colFrom, indexFrom, to, colTo, indexTo, item }) {
            var rowFrom = editor.rows.find(r => r.id === from);
            var rowTo = editor.rows.find(r => r.id === to);

            rowFrom.cols[colFrom].children.splice(indexFrom, 1);
            rowTo.cols[colTo].children.splice(indexTo, 0, item);
        },
        EMAIL_REMOVE_COMMON({ editor }, { row, col, index, id }) {
            var targetRow = editor.rows.find(r => r.id === row);
            var targetChild = editor.children.findIndex(c => c.id === id);
            targetRow.cols[col].children.splice(index, 1);
            editor.children.splice(targetChild, 1);
        },
        EMAIL_CLONE_COMMON({ editor }, { row, col, index, clone }) {
            var targetRow = editor.rows.find(r => r.id === row);
            editor.children.push(clone);
            targetRow.cols[col].children.splice(index, 0, clone.id);
        },
        EMAIL_APPLY_CUSTOM_CODE({ editor }, newItems) {
            //update children content
            newItems.children.forEach((child, index) => {
                var target = editor.children.find(c => c.id === child.id);
                child.content ? target.content = child.content : '';
                if (target.type === 'link') {
                    target.attributes.basic.content = child.basic.content
                    target.attributes.basic.linkUrl.url = child.basic.linkUrl.url
                } else if (child.basic) {
                    for (var prop in child.basic) {
                        target.attributes.basic[prop] = child.basic[prop]
                    }
                }
            });
        },
    },
    actions: {
        //SECTIONS
        EMAIL_ADD_BLOCK(context, value) {
            var index = value.addedIndex;
            var width = value.width;
            var item = { type: 'section' };
            item.id = generateId(context.getters.email_numbers);
            item.attributes = { basic: { width }, advanced: {} };
            item.children = [];
            context.commit('EMAIL_ADD_BLOCK', { item, index });
        },
        EMAIL_REMOVE_BLOCK(context, value) {
            context.commit('EMAIL_REMOVE_BLOCK', value);
        },
        EMAIL_CLONE_BLOCK(context, value) {
            var cloneStr = JSON.stringify(value);
            var clone = JSON.parse(cloneStr);
            var id = value.id;
            clone.id = generateId(context.getters.email_numbers);

            var newRows = [];
            var newChildren = [];

            clone.children.forEach(child => {
                newRows.push(generateId(context.getters.email_numbers));
                var row = context.getters.findOne(child);
                var rowChank = [];
                row.cols.forEach(col => {
                    var chank = [];
                    col.children.forEach(c => {
                        chank.push(generateId(context.getters.email_numbers))
                    });
                    rowChank.push(chank);
                });
                newChildren.push(rowChank);
            });

            context.commit('EMAIL_CLONE_BLOCK', { clone, id, newRows, newChildren });
        },
        EMAIL_EDIT_BLOCK(context, value) {
            context.commit('EMAIL_EDIT_BLOCK', value);
        },
        EMAIL_EDIT_FIELD(context, value) {
            context.commit('EMAIL_EDIT_FIELD', value);
        },
        EMAIL_DROP_BLOCK(context, value) {
            context.commit('EMAIL_DROP_BLOCK', value);
        },
        //ROWS
        EMAIL_ADD_ROW(context, value) {
            value.id = generateId(context.getters.email_numbers);
            context.commit('EMAIL_ADD_ROW', value);
        },
        EMAIL_CHANGE_COLS(context, value) {
            context.commit('EMAIL_CHANGE_COLS', value)
        },
        EMAIL_DROP_ROW(context, value) {
            context.commit('EMAIL_DROP_ROW', value);
        },
        EMAIL_DROP_ROW_ADD(context, value) {
            context.commit('EMAIL_DROP_ROW_ADD', value);
        },
        EMAIL_CLONE_ROW(context, value) {
            var cloneStr = JSON.stringify(value);
            var clone = JSON.parse(cloneStr);
            var id = value.id;
            clone.id = generateId(context.getters.email_numbers);

            var newChildren = [];
            var rowChank = [];

            clone.cols.forEach(col => {
                var chank = [];
                col.children.forEach(c => {
                    chank.push(generateId(context.getters.email_numbers))
                });
                rowChank.push(chank);
            });
            newChildren.push(rowChank);

            context.commit('EMAIL_CLONE_ROW', { clone, id, newChildren });
        },
        //COMMON BLOCKS
        EMAIL_ADD_COMMON(context, value) {
            value.id = generateId(context.getters.email_numbers);
            context.commit('EMAIL_ADD_COMMON', value);
        },
        EMAIL_DROP_COMMON(context, value) {
            context.commit('EMAIL_DROP_COMMON', value);
        },
        EMAIL_DROP_COMMON_ADD(context, value) {
            context.commit('EMAIL_DROP_COMMON_ADD', value);
        },
        EMAIL_REMOVE_COMMON(context, value) {
            context.commit('EMAIL_REMOVE_COMMON', value);
        },
        EMAIL_CLONE_COMMON(context, value) {
            var cloneStr = JSON.stringify(value.item);
            var clone = JSON.parse(cloneStr);
            clone.id = generateId(context.getters.email_numbers);
            value.clone = clone;
            context.commit('EMAIL_CLONE_COMMON', value);
        },
        EMAIL_APPLY_CUSTOM_CODE(context, value) {
            context.commit('EMAIL_APPLY_CUSTOM_CODE', value);
        },
    }
}