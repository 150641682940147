<template>
	<td>
    <a
        :href="basic.linkUrl ? basic.linkUrl.url : ''"
        :id="advanced.cssId"
        :class="['link_watch', advanced.cssClasses, responsiveClasses]"
        :style="style"
    >
        {{ basic.content || 'Link'}}
    </a>
	</td>
</template>

<script>
export default {
    name: 'EmailLink',
    props: ['details'],
    computed: {
        basic() {
            return this.details.attributes.basic
        },
        advanced() {
            return this.details.attributes.advanced
        },
        responsive() {
            return this.details.attributes.responsive
        },
        responsiveClasses() {
            let c = '';
            if (!this.responsive) return '';
            if (!this.responsive.desktop) c = 'no-desktop';
            if (!this.responsive.mobile) c += ' no-mobile';
            if (!this.responsive.tablet) c += ' no-tablet';
            return c;
        },
        textDecoration(){
            var fontStyling = this.basic.font ? this.basic.font.fontStyling : false;
            if(fontStyling['line-through'] && fontStyling.underline) {
                return 'underline line-through';
            } else if(fontStyling['line-through']) {
                return 'line-through';
            } else if(fontStyling.underline){
                return 'underline';
            } else {
                return 'inherit';
            }
        },
        style() {
            var border = this.advanced.border;
            var borderTop,
                borderLeft,
                borderRight,
                borderBottom;

            if (border) {
                borderTop = !border.sides.top ? 'none' : border.width + 'px ' + border.style + ' ' + border.color;
                borderLeft = !border.sides.left ? 'none' : border.width + 'px ' + border.style + ' ' + border.color;
                borderRight = !border.sides.right ? 'none' : border.width + 'px ' + border.style + ' ' + border.color;
                borderBottom = !border.sides.bottom ? 'none' : border.width + 'px ' + border.style + ' ' + border.color;
            }

            var borderRadius = border ?
                border.radius.leftTop+'px '+
                border.radius.topRight+'px '+
                border.radius.bottomRight+'px '+
                border.radius.bottomLeft+'px' : false;

	        var marginBase = this.basic.margin || {};
	        var paddingBase = this.basic.padding || {};

	        var margin =
			        parseInt(marginBase.top || 0)+'px '+
			        parseInt(marginBase.right || 0)+'px '+
			        parseInt(marginBase.bottom || 0)+'px '+
			        parseInt(marginBase.left || 0)+'px';

	        var padding =
			        parseInt(paddingBase.top || 0)+'px '+
			        parseInt(paddingBase.right || 0)+'px '+
			        parseInt(paddingBase.bottom || 0)+'px '+
			        parseInt(paddingBase.left || 0)+'px';

            var fontFamily = this.basic.font ? this.basic.font.fontFamily : 'inherit';
            var fontSize = this.basic.font ? this.basic.font.fontSize : 'inherit';
            var textAlign = this.basic.font ? this.basic.font.textAlign : 'center';
            var fontStyling = this.basic.font ? this.basic.font.fontStyling : false;
            var color = this.basic.font ? this.basic.font.color : 'inherit';

            var boxShadow = this.advanced.shadows ?
                this.advanced.shadows.color+ ' ' +
                this.advanced.shadows.angle+'px '+
                this.advanced.shadows.distance+'px '+
                this.advanced.shadows.blur+'px '+
                this.advanced.shadows.spread+'px '+
                this.advanced.shadows.type : false;

            var textShadow = this.advanced.textShadows ?
                this.advanced.textShadows.color+ ' ' +
                this.advanced.textShadows.angle+'px '+
                this.advanced.textShadows.distance+'px '+
                this.advanced.textShadows.blur+'px' : false;

            var transform = this.advanced.transformation ?
                'rotateX('+ this.advanced.transformation.rotateX +'deg) ' +
                'rotateY('+ this.advanced.transformation.rotateY +'deg) ' +
                'rotateZ('+ this.advanced.transformation.rotateZ +'deg) ' +
                'scale3d('+this.advanced.transformation.scaleX+', '+this.advanced.transformation.scaleY+', '+this.advanced.transformation.scaleZ+')' : false;

            var transition = this.advanced.animations ?
                this.advanced.animations.duration + 's ' +
                this.advanced.animations.property + ' ' +
                this.advanced.animations.easing : 'initial';

            var obj = {
                width: '100%',
                padding,
		            margin,
                borderRadius,
                borderTop,
                borderLeft,
                borderRight,
                borderBottom,
                backgroundColor: this.advanced.backgroundColor || 'transparent',
                display: this.advanced.displayType || 'block',
                color,
                fontFamily,
                fontSize: fontSize + 'px',
                textAlign,
                fontStyling,
                fontWeight: fontStyling.bold ? 'bold' : 'inherit',
                fontStyle: fontStyling.italic ? 'italic' : 'inherit',
                textDecoration: this.textDecoration,
                boxShadow,
                textShadow,
                transform,
                transition,
            };
            return obj
        }
    },
}
</script>
