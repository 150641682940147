<template>
	<div class="popup_wrpr popup-backdrop active">
		<div class="popup cloning-funnel-popup">
			<label>Cloning funnel...</label>
			<div v-if="progressNumber < 100" class="saving-process">
				<div class="prog-line">
					<div class="prog-line-inner" v-bind:style="{width: progressNumber +'%'}"></div>
				</div>
			</div>
			<v-spinner v-else />

		</div>

		<subdomain-popup v-if="subdomainOpen" :canClose="false" @close="subdomainOpen=false;startCloning()" />

	</div>
</template>

<script>
import API from '@/api';
import VSpinner from "@/components/base/v-spinner";
import SubdomainPopup from "@/components/popups/subdomain-popup";

export default {
	name: "cloning-funnel-popup",
	components: {SubdomainPopup, VSpinner},
	props: ["funnel","code", "name", 'cloneGeoFunnels','folder'],
	mixins: [API],
	data() {
		return {
			subdomainOpen: false,
			progressNumber: 0,
			done: false,
		}
	},
	computed:{
		user() {
			return this.$store.state.userInfo;
		}
	},
	mounted() {
		// check if user has a subdomain already if not ask them to create one first!
		if (!this.user.subdomain) {
			this.subdomainOpen = true;
		} else {
			this.startCloning();
		}
	},
	methods: {
		startCloning() {
			this.done = false;
			this.progressNumber = 0;
			this.cloneFunnel();
			this.progressIncrement();
		},
		async cloneFunnel() {
			try {
				console.log("cloning funnel...", this.funnel, this.code, this.name)
				let funnel = await this.api.funnels.share(this.funnel||'0', this.code||'', this.name||'',
						this.cloneGeoFunnels||false, this.folder?this.folder.id:'');
				if (funnel.error) {
					this.$error(funnel, funnel.info.data);
					this.$emit("failed");
				} else
					this.$emit("done", funnel);
			} catch (e) {
				this.$warn(e);
				this.$emit("failed");
			} finally {
				this.done = true;
				if (this.progressNumber < 60)
					this.progressNumber = 80;
			}
		},
		progressIncrement() {
			let progressIncrementInterval = setInterval(() => {
				if (this.progressNumber < 100) {
					this.progressNumber += 1;
				} else {
					clearInterval(progressIncrementInterval);
				}
			}, 30);
		}
	},
}
</script>

<style lang="scss" scoped>
.cloning-funnel-popup {
	width: 300px;
	height: 60px;
	padding: 10px 20px;
	border-radius: 5px;
	max-width: 300px !important;
}
.saving-process {
	margin-top: 8px;
	.prog-line-inner {
		background: #344989;
	}
}
.spinner { margin-top: 5px;}
</style>
