import axios from "axios";
import config from "@/config/config";

export default (config, axios, token) => {

    var call = async function(path, method = 'GET', data, string = true) {
        // if (string) data ? data = JSON.stringify(data) : '';

        const url = config.URL + path;
        try {
            const response = await axios({
                url,
                method,
                data,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
            })
            return response.data
        } catch (error) {
            return { error: true, info: error.response }
        }
    }

    return {
        getAutomations: async(id) =>
            call('wfunnels/automation-briefs?productId=' + id), // JSON

        getProductAutomations: async(id) =>
            call('wfunnels/product-automations/' + id),

        patchAutomation: async(id, data) =>
            call('wfunnels/product-automations/' + id, 'PATCH', data),

        createAutomation: async(data) =>
            call('wfunnels/product-automations', 'POST', data),

        deleteAutomation: async(id) =>
            call('wfunnels/automation-briefs/' + id, 'DELETE'), // JSON


        patchVariants: async(id, data) =>
            call(`wfunnels/product-variants/${id}`, "PATCH", data),
        getVariants: async(funnelId, stepId, id) =>
            call(`wfunnels/${funnelId}/steps/${stepId}/products/${id}?include=variants`),

        getOptions: async(id) =>
            call('wfunnels/product-variant-options?productId=' + id),

        saveOptions: async(id, rows) =>
            call('wfunnels/product-variant-options?productId=' + id, 'PUT', rows),

        getFunnelTemplates: async(template = false) =>
            call(template ? `wfunnels/templates/${template}` : `wfunnels/templates`),

        getTemplateSteps: async(funnelId) =>
            call(`wfunnels/templates/${funnelId}/steps`),
        likeTemplate: async(templateId,liked) =>
            call(`wfunnels/templates/${templateId}/like?liked=${liked}`, 'PUT'),

        getShareFunnel: async(id, code) =>
            call(`wfunnels/${id}/share?code=${code}`),

        //STEPS
        saveStepProps: async(funnelId, stepId, props) =>
            call(`wfunnels/${funnelId}/steps/${stepId}/save-props`, 'PUT', props),

        get: async(id, step, products = true, variants=false) => {
            let path = `wfunnels/${id}/steps/${step}`
            if (variants) path = `wfunnels/${id}/steps/${step}?include=products_variants`
            else if (products) path = `wfunnels/${id}/steps/${step}?include=products_pages`

            return await call(path);
        },

        getAll: async(id) =>
            call('wfunnels/' + id + '/steps'),

        create: async(id, data, isUserTemplate=false) =>
            call(`wfunnels/${id}/steps?is_user_template=`+isUserTemplate, 'POST', data),

        patch: async(id, data) =>
            call('wfunnels/' + id + '/steps', 'PUT', data),

        applyTemplate: async(funnelId, stepId, pageId, templateId) =>
            call(`wfunnels/${funnelId}/steps/${stepId}/pages/${pageId}/apply-template?template-id=${templateId}`, 'POST'),

        clone: async(id, data) =>
            call('wfunnels/' + id + '/clone-step', 'POST', data),

        delete: async(id, step) =>
            call('wfunnels/' + id + '/steps/' + step, 'DELETE'),

        //DOMAINS
        changeSubdomain: async(data) =>
            call('domains/change-subdomain', 'PATCH', data, true),

        getDomain: async(id) =>
            call('domains/' + id),

        getAllDomains: async() =>
            call('domains'),

        createDomain: async(data) =>
            call('domains', 'POST', data),

        updateDomain: async(data) =>
            call('domains', 'PUT', data),

        deleteDomain: async(id) =>
            call('domains/' + id, 'DELETE'),

        validateDomain: async(data) =>
            call('domains/validate', 'POST', data),

        verifyDomain: async(data) =>
            call('domains/verify', 'POST', data),

        //PAGES
        getPages: async(id, step, page = false) =>
            call(page ? `wfunnels/${id}/steps/${step}/pages/${page}` : `wfunnels/${id}/steps/${step}/pages`),

        createPage: async(funnelId, stepId, data, isUserTemplate=false) =>
            call(`wfunnels/${funnelId}/steps/${stepId}/pages?is_user_template=${isUserTemplate}`, 'POST', data),

        createPageTemplate: async(data) =>
            call(`users/page-templates`, 'POST', data),

        patchPage: async(id, step, data) =>
            call(`wfunnels/${id}/steps/${step}/pages`, 'PUT', data),

        clonePage: async(id, step, page) =>
            call(`wfunnels/${id}/steps/${step}/pages/${page}/clone`, 'POST'),

        savePageProps: async(funnelId, stepId, pageId, props) =>
            call(`wfunnels/${funnelId}/steps/${stepId}/pages/${pageId}/save-props`, 'PUT', props),

        deletePage: async(funnelId, stepId, pageId) =>
            call(`wfunnels/${funnelId}/steps/${stepId}/pages/${pageId}`, 'DELETE'),

        //PAGE TEMPLATES
        getSavedTemplates: async(template = false) =>
            call(template ? `users/page-templates/${template}` : `users/page-templates`),
        deleteSavedTemplate: async(template) =>
            call(`users/page-templates/${template}`, 'DELETE'),

        getTemplates: async(template = false) =>
            call(template ? `page-templates/${template}` : `page-templates`),

        createTemplate: async(data) =>
            call(`page-templates`, 'POST', data),

        patchTemplate: async(data) =>
            call(`page-templates`, 'PUT', data),

        deleteTemplate: async(template) =>
            call(`page-templates/${template}`, 'DELETE'),


        // Page Revisions
        getPageRevisions: async(funnelId, stepId, pageId) =>
            call(`wfunnels/${funnelId}/steps/${stepId}/pages/${pageId}/revisions`),
        restorePageRevision: async(funnelId, stepId, pageId, revisionId) =>
            call(`wfunnels/${funnelId}/steps/${stepId}/pages/${pageId}/revisions/${revisionId}/restore`,'POST'),


        //PRODUCTS
        getProductImages: async(productIds) =>
            call(`products/images?ids=${productIds.join('|')}`),

        getProducts: async(funnelId, step, product = false) =>
            call(product ? `wfunnels/${funnelId}/steps/${step}/products/${product}` : `wfunnels/${funnelId}/steps/${step}/products`),

        patchProduct: async(id, step, data) =>
            call(`wfunnels/${id}/steps/${step}/products`, 'PUT', data),

        createProduct: async(id, step, data) =>
            call(`wfunnels/${id}/steps/${step}/products`, 'POST', data),

        cloneProduct: async(id, step, data) =>
            call(`wfunnels/${id}/steps/${step}/products`, 'POST', data),

        deleteProduct: async(id, step, product) =>
            call(`wfunnels/${id}/steps/${step}/products/${product}`, 'DELETE'),

        //VARIANTS
        // getVariants: async(id) =>
        //     call('products/' + id + '?include=variants'),

        // patchVariants: async(id, data) =>
        //     call('product-variants/' + id, 'PATCH', data),

        // getOptions: async(id) =>
        //     call('product-variant-options?productId=' + id),

        //AUTOMATIONS
        // getAutomations: async(id) =>
        //     call('automation-briefs?productId=' + id),

        // getProductAutomations: async(id) =>
        //     call('product-automations/' + id),

        // getFunnelAutomations: async(id) =>
        //     call('funnels/' + id + '/automations'),

        // patchAutomation: async(id, data) =>
        //     call('product-automations/' + id, 'PATCH', data),

        // createAutomation: async(data) =>
        //     call('product-automations', 'POST', data),

        // deleteAutomation: async(id) =>
        //     call('automation-briefs/' + id, 'DELETE'),

        //INTEGRATIONS
        // getIntegrations: async() =>
        //     call('integrations'),

        // patchIntegration: async(id, data) =>
        //     call('user-integrations/' + id, 'PATCH', data),

        // getActions: async(caption, action, args = "{ \"id\": 0, \"arg1\": \"1234\", \"arg2\": \"arg22234\" }") =>
        //     call('call-integration-method/' + caption + '/' + action, 'POST', args, false)

    }
}
