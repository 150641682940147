
function round_to_two(n) {
    return Math.round(n * 100) / 100;
}
function round_to_zero(n) {
    return Math.round(n);
}
function numberWithCommas(x) {
    return x.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
}
export default {
    numberWithCommas,
    round_to_two,
    round_to_zero,
}
