<template>
    <div class="wrapper page-wrapper">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: "PageLayout"
}
</script>

<style scoped>
.wrapper.page-wrapper {
    z-index: 999;
    position: relative;
}
</style>