<template>
			<div class="note">
				<template v-if="editing">
					<div class="new-note">
						<div class="img">
							<customer-avatar :first-name="user.member.first_name" small />
						</div>
						<textarea :rows="5" class="flex" placeholder="Leave a note..."
						          @blur="noteFocused=false" @focus="noteFocused=true" v-model="editingNote" />
						<div class="actions">
							<v-button label="Cancel" border slim @click="editing=false" />
							<v-button label="Save" :loading="saving" @click="saveNote" slim :disabled="!editingNote" />
						</div>
					</div>
				</template>
				<template v-else>
					<div class="layout-row flex-center flex">
						<div class="img">
							<customer-avatar :first-name="user.member.first_name" small />
						</div>
						<div class="layout-row flex flex-center">
							<span class="label">{{user.member.first_name}}</span>
							<span class="sub_label time">{{time(note.created_at)}}

								<span class="edited" v-if="note.created_at !== note.updated_at">(Edited)</span>
							</span>
						</div>
						<div class="layout-column flex-none" >
							<quick-actions-btn :items="['Edit note','Delete note']" @menuClick="menuClick($event)" />
						</div>
					</div>
					<div class="note-body">
						{{note.value}}
						<v-spinner v-if="deleting" />
					</div>
				</template>
			</div>
</template>

<script>
import moment from "moment";
import {getSymbol} from "@/helpers/currencies";
import VButton from "@/components/base/v-button";
import CustomerAvatar from "@/components/base/customer-avatar";
import QuickActionsBtn from "@/components/base/quick-actions-btn";
import VSpinner from "@/components/base/v-spinner";
import API from '@/api'

export default {
	name: "order-note",
	components: {VSpinner, QuickActionsBtn, CustomerAvatar, VButton},
	props: ['note'],
	mixins: [API],
	data(){
		return {
			saving: false,
			deleting: false,
			editing: false,
			editingNote: '',
			noteFocused: false,
		}
	},
	computed:{
		user(){
			return this.$store.state.userInfo;
		}
	},
	methods:{
		menuClick(e){
			if (e.action==="Edit note") {
				this.editing = true;
				this.editingNote = this.note.value;
			} else if (e.action==="Delete note") {
				this.$emit('delete', this.note)
			}
		},

		time(t){
			return moment(t).format("DD MMM YYYY, HH:mm:ss")
		},
		async saveNote(){
			try {
				this.saving = true;

				let newNote = JSON.parse(JSON.stringify(this.note))
				newNote.value = this.editingNote;
				let res = await this.api.orders.updateNote(this.note.order_id, newNote);
				if (res.error) this.$error(res.error);
				else {
					this.note.updated_at = res.updated_at;
					this.note.value = res.value;
					this.editing = false;
				}
			} catch (e) {
				this.$error(e)
			} finally {
				this.saving = false;
			}
		},
	},
	created() {
		if (this.note) this.editingNote = this.note.value;
	}
}
</script>

<style lang="scss" scoped>
	.new-note {
		display: flex;
		flex-direction: row;
		padding: 10px 10px;
		background: $light-blue;
		border-radius: 4px;
		margin-top: 10px;
		textarea {
			transition: .2s ease-in-out all;
			background: transparent;
		}
	}
	.note {
		display: flex;
		flex-direction: column;
		padding: 8px 0;
		margin-left: 10px;
		margin-right: 10px;

		.actions {
			display: flex;
			flex-direction: column;
			align-items: stretch;
			.button {
				margin-bottom: 5px;
				justify-content: center;
				display: flex;
			}
		}
		.note-body {
			user-select: text;
			margin-left: 35px;
			font-size: 14px;
			color: $black;
			font-weight: normal;
			position: relative;
		}
		.img {
			margin-right: 5px;
			position: relative;
			display: flex;
			flex-direction: column;
			align-items: center;
		}
		.label {
			font-size: 14px;
			font-weight: 600;
			color: $black;
		}
		.sub_label{
			font-size: 14px;
			font-weight: normal;
			color: $dark-grey;
			&.time {
				margin-left: 10px;
			}
			&.error {
				color: #fc585b;
			}
			.edited {
				margin-left: 3px;
			}
		}

		.retry-btn {
			margin-left: 5px;
		}
		.value {
			text-align: right;
		}
	}
</style>
