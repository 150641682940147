import axios from "axios";
import config from "@/config/config";

export default (config, axios, token) => {

    // noData used to prevent embedding in {data} struct
    var call = async function(path, method = 'GET', data) {
        data ? data = JSON.stringify(data) : '';
        const url = config.URL + path;
        try {
            const response = await axios({
                url,
                method,
                data,
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            })
            return response.data
        } catch (error) {
            return { error: true, info: error.response }
        }
    }

    return {
        signup: async(state, shopName, data) =>
            call(`users/signup?state=${state}&shop=${shopName}`, "POST", data),

        get: async() =>
            call('users'),

        me: async() =>
            call('users/me'),

        accounts: async() =>
            call('users/me/accounts'),

        setCurrentAccount: async(accountId) =>
            call(`users/me/accounts/${accountId}`, 'POST'),

        // authLogin: async(provider) =>
        //     call(`auth/${provider}-login`, 'GET'),

        sendVerificationEmail: async() =>
            call('users/me/send-verification', 'POST'),

        changePassword: async(id, data) =>
            call('users/me/change-password', 'POST', data),

        verifyProfileNewEmail: async(data) =>
            call('profiles/me/verify-new-email', 'POST', data),
        changeProfileEmail: async(data) =>
            call('profiles/me/change-email', 'POST', data),

        verifyAccountNewEmail: async(data) =>
            call('accounts/me/verify-new-email', 'POST', data),
        changeAccountEmail: async(data) =>
            call('accounts/me/change-email', 'POST', data),

        updateTimezone: async(tz="UTC", force=false) =>
            call(`users/me/update-timezone?tz=${encodeURIComponent(tz)}&force=${force}`, 'PUT'),

        requestPasswordReset: async(email, captcha) =>
            call('request-password-reset', 'POST', {email:email, captcha:captcha}, true),

        resetApiKey: async() =>
            call('users/me/reset-api-key', 'PATCH'),

        newPassword: async(email, vcode, password) =>
            call('update-password', 'POST', {email:email, vcode:vcode, password:password}, true),

        patch: async(id, data) =>
            call('users/' + id, 'PATCH', data),

    }
}
