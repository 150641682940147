export default (config, axios, token) => {

    var call = async function(path, method = 'GET', data) {
        const url = config.URL + path;
        try {
            const response = await axios({
                url,
                method,
                data,
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json',
                }
            })
            return response.data
        } catch (error) {
            return { error: true, info: error.response }
        }
    }

    return {

        get: async(id) =>
            call(`sms-numbers/${id}`),

        getSmsAccount: async() =>
            call(`sms-account`),

        updateSmsAccount: async(data) =>
            call(`sms-account`, 'PUT', data),

        getAll: async() =>
            call(`sms-numbers`),

        buyNumber: async(data) =>
            call(`sms-numbers`, 'POST', data),

        topUp: async(data) =>
            call('sms-account/top-up', 'POST', data),

        listCountryTariffs: async() =>
            call(`sms-numbers/country-tariffs`),

        listAvailableNumbers: async(countryCode) =>
            call(`sms-numbers/available-numbers?country=${countryCode}`),

        delete: async(id) =>
            call(`sms-numbers/${id}`,'DELETE'),

        updateFunnelSmsNumber: async(funnelId, smsNumberReq) =>
            call(`funnels/${funnelId}/sms-numbers`,'PUT', smsNumberReq),

    }
}
