<template>
    <div class="wrapper">
		<LeftBar @goBack="allowGoback = true" @saving="onSaving"/>
        <div class="main email_editor_page" style="overflow: hidden;">
            <div class="main-center">
				<EditorHeader :goBackTo="allowGoback" @saving="onSaving"/>
                <router-view></router-view>
<!--	            v-if="saving" causes problems. -->
                <renderTemplate styleId="render-style" tableId="render-table" rawClass="render-raw" />
            </div>
        </div>
    </div>
</template>

<script>

import EditorHeader from "../components/EditorHeader.vue"
import LeftBar from "../components/LeftBar.vue"
import renderTemplate from "../components/RenderTemplate.vue";

export default {
    name: "MainLayout",
    components: {
        EditorHeader,
        LeftBar,
        renderTemplate
    },
    data() {
        return {
            allowGoback: false,
            saving: false,
        }
    },
    methods: {
        onSaving(ev) {
            this.saving = ev;
        }
    }
}
</script>
