import {
    margin, cssId, cssClasses, id, sticky
} from './fields/'

export default {
    tag: 'custom',
    labelTitle: '',
    labelColor: '',
    id,
    content: '',
    blockStyles: '',
    attributes: {
        basic: {
            margin,
            html: { title: 'Edit custom HTML', comp: 'CustomCodeButton' },
            textInfo: { title: 'For HTML experts', comp: 'InfoMessage', value: { text: "Click the button above to use your own custom HTML code, make sure it's correct and meets the HTML5 standard requirements." } }
        },
        advanced: {
            cssId,
            cssClasses,
            sticky

        },
    },
}
