const moment = require('moment-timezone');
import numbers from "@/helpers/numbers";


export function percentage(sub, total) {
    if (!sub) return 0;
    if (!total) return 100;
    if (total < sub) return 100;
    return ((sub*100)/total);
}

export function changePercentage(x, y, fixed = 2) {
    const percent = ((x - y) / y) * 100;

    if (!isNaN(percent)){
        return Number(percent.toFixed(fixed));
    } else {
        return 0;
    }
}

function unitToBrief(unit, date)  {
    let newDate = moment.unix(date).utc(true);
    switch (unit) {
        case "week": {
            let from_date = newDate.startOf('day');
            let to_date = moment(from_date).add(6, 'day').endOf('day');

            // console.log("unitToBrief", newDate.format(), from_date.format(), to_date.format())
            return from_date.format("DD MMM") + " - " + to_date.format("DD MMM");
        }
        case "month":
            // console.log("unitToBrief", unit, date, moment.unix(date).utc(false).format())
            return moment.unix(date).utc(false).format("MMM YYYY");
        case "hour": {
            if (moment().utc(true).isSame(newDate, "day")) {
                return "Today - "+newDate.format("HH:00");
            } else if (moment().utc(true).subtract(1, "days").isSame(newDate, "day")) {
                return "Yesterday - "+newDate.format("HH:00");
            }
            return newDate.format("ddd DD - HH:00");
        }
        default:
            return newDate.format("DD MMM")
    }
}

function getDataFromTrends(unit, trends, startDate, endDate) {
    let data = [];

    let date = moment(startDate).add(1, 'hour').startOf('day');

    // take min date either NOW or endDate.
    let endD = moment.min([endDate, moment()]);

    if (!trends || trends.length === 0) {
        data = [
            {
                value: 0,
                date: moment(date).unix(),
                brief: unitToBrief(unit, moment(date).unix())
            },
            {
                value: 0,
                date: moment(endD).unix(),
                brief: unitToBrief(unit, moment(endD).unix())
            }];
    } else {
        for (let i = 0; i < trends.length; i++) {
            let t = trends[i];

            // console.log("trend:", t.date, unitToBrief(unit, t.date), moment.unix(t.date).utc(true).format())
            data.push({
                value: t.value,
                brief: unitToBrief(unit, t.date),
                date: t.date,
            })
        }
    }
    // cleanData(unit, data, startDate, endDate);
    return data;
}

// Adds missing days...
function cleanData(unit, data, startDate, endDate) {
    // console.log("cleanData", JSON.parse(JSON.stringify(data)))

    // cap endDate to today.
    if (moment().diff(endDate, "hours") < 0) endDate = moment()
    if (moment().diff(startDate, "hours") < 0) startDate = moment()

    let unitsDiff = Math.abs(moment(endDate).diff(startDate, unit)) + 1;

    // console.log('cleanData', unitsDiff, startDate.format(), endDate.format(), unit)

    for (let i = 0; i < unitsDiff; i++) {
        let date = moment(startDate).add(i, unit).startOf(unit);
        let entry = findEntryInData(date, data, unit);

        // console.log(">>> ", i, "date:", date.unix(), 'entry:',entry, unit, )

        if (!entry) {
            data.splice(i, 0, {
                value: 0,
                date: moment(date).utcOffset(0,false).utc(true).unix(),
                brief: unitToBrief(unit, moment(date).utcOffset(0,false).utc(true).unix()),
            })
        }
    }
}

function findEntryInData(date, data, unit) {
    for (let i = 0; i < data.length; i++) {
        if (moment.unix(data[i].date).utc(true).isSame(date.clone().utc(true), unit)) {
            // console.log(">>", i, 'date:', date.format(), 'unixDate', moment.unix(data[i].date).utc(true).format(), 'iDate:', data[i].date, unit)
            return data[i];
        }
    }
    // console.log("not found.", ">>", 'date:', date.format(), "unix:", date.unix(), unit)
    return null;
}

function getConditions() {
    let s = localStorage.getItem("filterConditions")
    if (s) {
        try {
            return JSON.parse(s)
        } catch (e) {
            console.warn(e)
        }
    }
    return []
}

function generateRates(visitorsData, subData) {
    let data = [];

    for (let i = 0; i < visitorsData.length; i++) {
        let visitors = visitorsData[i].value;
        let sub = subData[i] ? subData[i].value : 0;
        let per_ = percentage(sub, visitors);

        data.push({value: per_,
            brief: visitorsData[i].brief,
            date: visitorsData[i].date,
            day: visitorsData[i].day,
            month: visitorsData[i].month,
            week: visitorsData[i].week,
            year: visitorsData[i].year})
    }
    return data;
}

function formatChartDate(unit, sourceDate) {
    let date = moment(sourceDate);
    switch (unit) {
        case "week":
            return date.format("DD MMM");
        case "month":
            return date.format("MMM YYYY");
        case "hour": {
            if (moment().isSame(date, "day")) {
                return "Today - " + date.format("HH:00");
            } else if (moment().subtract(1, "days").isSame(date, "day")) {
                return "Yesterday - " + date.format("HH:00");
            }
            return date.format("ddd DD - HH:00");
        }
        default:
            return date.format("DD MMM")
    }
}
function storeRange(currentRange, startDate, endDate) {
    localStorage.setItem('analytics-range', currentRange)
    localStorage.setItem('analytics-start', moment(startDate).toISOString())
    localStorage.setItem('analytics-end', moment(endDate).toISOString())
}
function loadRange() {
    let currentRange = "Last 30 days";
    let startDate = moment(new Date()).subtract(29, "days");
    let endDate = new Date();

    let range = localStorage.getItem('analytics-range');
    if (range) currentRange = range;

    let s = localStorage.getItem('analytics-start');
    if (s) startDate = moment(s);

    let e = localStorage.getItem('analytics-end');
    if (e) endDate = moment(e);

    return {currentRange, startDate, endDate}
}
function rangeToTime(currentRange, startDate, endDate, minDate) {

    let ranges = {
        'Today': [()=>moment().startOf('day'), ()=>moment().endOf('day')],
        'Yesterday': [()=>moment().subtract(1, 'days').startOf('day'), ()=>moment().subtract(1, 'days').endOf('day')],
        'Last 7 days': [()=>moment().subtract(6, 'days').startOf('day'), ()=>moment().endOf('day')],
        'Last 30 days': [()=>moment().subtract(29, 'days').startOf('day'), ()=>moment().endOf('day')],
        'This month': [()=>moment().startOf('month'), ()=>moment().endOf('month')],
        'Last month': [()=>moment().subtract(1, 'month').startOf('month'), ()=>moment().subtract(1, 'month').endOf('month')],
        'Last 12 months': [()=>moment().subtract(11, 'month').startOf('month'), ()=>moment().endOf('month')],
        'All time': [()=>moment(minDate).startOf('day'), ()=>moment().endOf('day')],
    };

    for (const [key, value] of Object.entries(ranges)) {
        if (key === currentRange) {
            startDate = value[0]();
            endDate = value[1]();
            return {startDate, endDate};
        }
    }

    return {startDate, endDate};
}

function formatPrice(value) {
    let val = (value/1).toFixed(2)//.replace('.', ',')
    return numbers.numberWithCommas(val)
}

function formatValue(value, roundToZero=false, isMoney=true, prefix='', suffix='') {
    let t = value || 0;
    let v = roundToZero ? numbers.round_to_zero(t) : numbers.round_to_two(t);
    t = isMoney ? formatPrice(v) : v;
    return `${prefix} ${t}${suffix}`
}

async function loadChart(api, chart='revenue', startDate, endDate, oldStartDate, oldEndDate, unit, funnelId=0, testMode='') {

    const [data, data2, summary] = await Promise.all([
        api.analytics.getTrends_v2(chart, funnelId, startDate, endDate, unit, this.getConditions(), testMode),
        api.analytics.getTrends_v2(chart, funnelId, oldStartDate, oldEndDate, unit, this.getConditions(), testMode),
        api.analytics.getSummary_v2(chart, funnelId, startDate, endDate, oldStartDate, oldEndDate, this.getConditions(), testMode)
    ])

    let values = this.getDataFromTrends(unit, data, startDate, endDate)
    let oldValues = this.getDataFromTrends(unit, data2, oldStartDate, oldEndDate)

    return {values, oldValues, summary}
}

async function loadSummaries(api, chart='revenue', minDate, funnelId=0, testMode=false) {
    const timeFrames = [
        { // 30 days
            startDate:moment().subtract(30, 'days').startOf('day'),
            endDate:moment().endOf('day'),
            oldStartDate:moment().subtract(60, 'days').startOf('day'),
            oldEndDate:moment().subtract(30, 'days').endOf('day')
        },
        { // 90 days
            startDate:moment().subtract(90, 'days').startOf('day'),
            endDate:moment().endOf('day'),
            oldStartDate:moment().subtract(180, 'days').startOf('day'),
            oldEndDate:moment().subtract(90, 'days').endOf('day')
        },
        { // 365 days
            startDate:moment().subtract(11, 'months').add(1, 'day').startOf('month'),
            endDate:moment().endOf('month'),
            oldStartDate:moment().subtract(23, 'months').add(1,'day').startOf('month'),
            oldEndDate:moment().subtract(12, 'months').endOf('month')
        },
        {
            // all time
            startDate: moment(minDate),
            endDate:moment(),
            oldStartDate:0,
            oldEndDate:0
        }
    ]

    let promises = []

    timeFrames.forEach(t => {
        promises.push(api.analytics.getSummary_v2(chart, funnelId, t.startDate, t.endDate, t.oldStartDate, t.oldEndDate,
            this.getConditions(), testMode))
    })


    const [_30days, _90days, _12months, allTimes] =
        await Promise.all(promises)

    return {_30days, _90days, _12months, allTimes}
}

export default {
    loadSummaries,
    loadChart,
    percentage,
    getDataFromTrends,
    generateRates,
    formatValue,
    findEntryInData,
    cleanData,
    formatChartDate,
    loadRange,
    storeRange,
    rangeToTime,
    getConditions

}
