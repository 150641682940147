// import moment from "moment";


export function identify(user) {
    try {
        if (!user || !user.member) return;
        let name = user.business_name || ''
        if (!name) {
            name = [user.member.first_name, user.member.last_name].join(' ') || ''
        }

        // remove sensitive details from being tracked.
        const localUser = JSON.parse(JSON.stringify(user))
        localUser.private_api_key=''
        localUser.api_key = ''
        localUser.member_account.v_code = ''
        localUser.member.v_code = ''
        localUser.v_code = ''

        window.$posthog.identify(
            user.id, // Required. Replace 'distinct_id' with your user's unique identifier
            { email: user.member.email, name: name, user:localUser, },  // $set, optional
            { } // $set_once, optional
        );

        // FS.identify(user.id, {
        //     displayName: user.member.first_name || '',
        //     email: user.member.email,
        // });
    } catch (e) {
        console.warn(e)
    }
}



export function trackEvent(eventName='$pageview', set={}, setOnce={}) {
    try {
        window.$posthog.capture(eventName, {
            $set: set,
            $set_once: setOnce,
        });
    } catch (e) {
        console.warn(e)
    }
}

