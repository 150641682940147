<template>
	<div>
    <div class="popup_high_right order-details-popup"
         :class="['level-'+level,{archived:order.is_archived,canceled:order.is_canceled}]">
            <Loading v-show="loading"/>

	    <a @click="closePopup" v-if="!level" class="close_popup_btn">
		    <inline-svg :src="require('@/assets/img/popup_close.svg')"/>
	    </a>

            <div class="popup-container scroll">
	            <h3 class="title" >
		            <back-btn v-if="level" class="back-btn" @click="$emit('close')" />
		            Order #{{ order ? order.order_number : '--' }}
	            </h3>

	            <div class="order-status-row">
		            <span class="label">Status:</span>
		            <div class="status-tags">
			            <div class="status-tag" v-if="order.payment_status==='processing'">
				            <inline-svg class="refund" :src="require('@/assets/img/clock-outline.svg')"/>
				            <span class="value">Pending payment</span>
			            </div>
			            <div class="status-tag" v-else-if="order.payment_status==='failed'">
				            <inline-svg class="refund" :src="require('@/assets/img/circle-error.svg')"/>
				            <span class="value">Payment failed</span>
			            </div>
			            <div class="status-tag" v-else-if="order.is_refunded">
				            <inline-svg class="refund" :src="require('@/assets/img/refund-2.svg')"/>
				            <span class="value">Refunded</span>
			            </div>
			            <div class="status-tag" v-else>
				            <inline-svg :src="require('@/assets/img/circle-checked.svg')"/>
				            <span class="value">Paid</span>
			            </div>
			            <div class="status-tag" v-if="order.is_fulfilled">
				            <span class="value" v-tippy content="Order has been fulfilled">Fulfilled</span>
			            </div>
			            <div class="status-tag" v-if="order.is_canceled">
				            <span class="value" v-tippy content="Order has been canceled">Canceled</span>
			            </div>
			            <div class="status-tag" v-if="order.is_archived">
				            <span class="value" v-tippy content="Order has been archived">Archived</span>
			            </div>
		            </div>

		            <span class="label">Date:</span>
		            <span class="value">{{ localDate }}</span>
		            <div class="flex"></div>

		            <quick-actions-btn :items="[
		            		{label:'Fulfill',icon:require('@/assets/img/finish.svg'),
		            		disabled: order.is_fulfilled||order.is_canceled},
		            		{label:'Update tracking info', disabled: order.is_canceled || !order.is_fulfilled},'',
		            		{label:order.is_archived?'Unarchive':'Archive',icon:require('@/assets/img/trash.svg')},
		            		{label:'Cancel order', disabled: order.is_canceled},
		            		{label:'Refund',disabled:!canRefund},
		            		'',{label:'Delete order',disabled:false,icon:require('@/assets/img/cross.svg')}]"
		                               @menuClick="menuClick" :loading="orderMenuLoading" />
	            </div>

	            <order-summary :order="order" />

	            <order-fulfillment v-if="order.is_fulfilled" :order="order" :fulfillment="fulfillment" />

	            <expandable-area v-if="!level" title="Customer" name="order-customer">
		            <customer-entry v-if="order&&order.customer_id" class="margin-t-10" :customer="customer"
		                            @click="customerOpen=true" :loading="loadingCustomer" />
	            </expandable-area>

	            <order-timeline :customer="customer" :order="order" :order-id="orderId" />

	            <order-notes :customer="customer" :order="order" :order-id="orderId" />
	            <div style="min-height: 50px"></div>
            </div>
        </div>

<!--		<portal to="popup">-->
			<popup-backdrop v-if="customerOpen" @close="customerOpen=false">
				<customer-details-new :level="level+1" :customer-id="order.customer_id"
				                      @deleted="customerOpen=false"
				                      @close="customerOpen=false" @done="customerOpen=false" />
			</popup-backdrop>

			<popup-backdrop v-if="cancelOrderOpen" @close="cancelOrderOpen=false">
				<cancel-order :level="level+1" :customer="customer" :order="order" :order-id="orderId"
				              @close="cancelOrderOpen=false" @done="cancelOrderOpen=false;" />
			</popup-backdrop>
			<popup-backdrop v-if="deleteOrderOpen" @close="deleteOrderOpen=false">
				<delete-order :level="level+1" :customer="customer" :order="order" :order-id="orderId"
				              @close="deleteOrderOpen=false" @done="deleteOrderOpen=false;$emit('close')" />
			</popup-backdrop>
			<popup-backdrop v-if="fulfillOrderOpen" @close="fulfillOrderOpen=false">
				<fulfill-order :level="level+1" :customer="customer" :order="order" :order-id="orderId"
				               @done="fulfillOrderOpen=false;loadFulfillment();"
				               @close="fulfillOrderOpen=false" />
			</popup-backdrop>
			<popup-backdrop v-if="addTrackingInfoOpen" @close="addTrackingInfoOpen=false">
				<add-tracking-info :level="level+1" :customer="customer" :order="order" :fulfillment="fulfillment"
				                   @done="addTrackingInfoOpen=false;loadFulfillment()"
				                   @close="addTrackingInfoOpen=false" />
			</popup-backdrop>
		<popup-backdrop v-if="refundOpen" @close="refundOpen=false">
			<refund-order :level="level+1" :customer="customer" :order="order"
			              @done="refundOpen=false"
			              @close="refundOpen=false" />
		</popup-backdrop>

		<!--		</portal>-->
	</div>
</template>

<script>
import API from "@/api"
import countries from "@/helpers/countries"
import Tooltip from "@/components/Tooltip.vue"
import Loading from "@/components/Loading.vue"
import VButton from "@/components/base/v-button";
import InfoIcon from "@/components/base/info-icon";
import InputField from "@/components/base/input-field";
import SelectField from "@/components/base/select-field";
import {getSymbol} from "@/helpers/currencies";
import numbers from "@/helpers/numbers";
import ExpandableArea from "@/components/base/expandable-area";
import CustomerEntry from "@/views/orders/customer-entry";
import OrderTimeline from "@/views/orders/order-timeline";
import OrderNotes from "@/views/orders/order-notes";
import PopupBackdrop from "@/components/base/popup-backdrop";
import BackBtn from "@/components/base/back-btn";
import QuickActionsBtn from "@/components/base/quick-actions-btn";
import CancelOrder from "@/views/orders/cancel-order";
import FulfillOrder from "@/views/orders/fulfill-order";
import OrderFulfillment from "@/views/orders/order-fulfillment";
import AddTrackingInfo from "@/views/orders/add-tracking-info";
import DeleteOrder from "@/views/orders/delete-order";
import {mapGetters} from "vuex";
import {getGatewayById} from "@/helpers/gateways";
import RefundOrder from "@/views/orders/refund-order";
import OrderSummary from "@/views/orders/order-summary";

const moment = require('moment-timezone');


export default {
	name: "OrderDetailsNew",
	title: "Order details | Funnelish™",
	mixins: [API],
	components: {
		OrderSummary,
		RefundOrder,
		DeleteOrder,
		AddTrackingInfo,
		OrderFulfillment,
		FulfillOrder,
		CancelOrder,
		QuickActionsBtn,
		BackBtn,
		PopupBackdrop,
		CustomerDetailsNew: ()=>import("@/views/customers/customer-details-new"),
		OrderNotes,
		OrderTimeline,
		CustomerEntry,
		ExpandableArea,
		SelectField,
		InputField,
		InfoIcon,
		VButton,
		Tooltip,
		Loading
	},
	props:{
		orderId:{
			type: Number,
		},
		level: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			loadingFulfillment: false,
			loadingCustomer: false,
			cancelOrderOpen: false,
			deleteOrderOpen: false,
			addTrackingInfoOpen: false,
			fulfillOrderOpen: false,
			refundOpen: false,
			customerOpen: false,
			saving: false,
			orderMenuLoading: false,
			loading: true,
			fulfillment: {},
			customer: {
				first_name: '',
				last_name: ''
			},
			order: {
				items: [],
			},
			countries: countries,
			loadingImages: false,
		}
	},
	computed:{
		...mapGetters(['getOrdersStore']),
		localDate(){
			return moment(this.order.created_at).format("DD MMMM YYYY, HH:mm:ss")
		},
		currency() {
			let c = this.$store.state.userInfo.currency_code;
			if (this.order && this.order.currency_code) c = this.order.currency_code;
			return getSymbol(c);
		},
		canRefund(){
			return this.gateway.canRefund && !this.order.is_refunded;
		},
		gateway(){
			return getGatewayById(this.order.gateway_id);
		},
	},
	methods: {
		menuClick(e){
			if (e.action==='Archive'||e.action==='Unarchive') {
				this.archiveOrder();
			} else if (e.action==='Cancel order') {
				this.cancelOrderOpen = true;
			} else if (e.action==='Delete order') {
				this.deleteOrderOpen = true;
			} else if (e.action==='Fulfill') {
				this.fulfillOrderOpen = true;
			} else if (e.action==='Refund') {
				this.refundOpen = true;
			} else if (e.action==='Update tracking info') {
				this.addTrackingInfoOpen = true;
			}
		},
		roundPrice(n) {
			return numbers.round_to_two(n)
		},
		closePopup() {
			this.$emit('close')
		},
		async archiveOrder(){
			try {
				this.orderMenuLoading = true;

				let order = await this.api.orders.archiveToggle(this.orderId);
				if (order.error) this.$error(order.error);
				else {
					this.order.is_archived = order.is_archived;
					let o = this.getOrdersStore.find(x=>x.id===this.order.id);
					if (o) {
						o.is_archived = order.is_archived;
					}
				}
			} catch (e) {
				this.$error(e)
			} finally {
				this.orderMenuLoading = false;
			}
		},
		async loadCustomerBrief() {
			try {
				this.loadingCustomer = true;
				this.customer = {};
				let c = await this.api.customers.get(this.order.customer_id, true);
				if (c.error) this.customer = {};
				else this.customer = c;
				this.$store.dispatch("setCurrentCustomer", this.customer)
			} catch (e) {
				this.$warn(e, 'Failed to load customer details! try again later.')
			} finally {
				this.loadingCustomer = false;
			}
		},
		async loadFulfillment() {
			try {
				this.loadingFulfillment = true;
				this.fulfillment = {};
				let c = await this.api.orders.getFulfillment(this.order.id);
				if (c.error) this.fulfillment = {};
				else this.fulfillment = c;
			} catch (e) {
				this.$warn(e, 'Failed to load fulfillment details! try again later.')
			} finally {
				this.loadingFulfillment = false;
			}
		},
		async loadImages() {
			try {
				this.loadingImages = true;
				let imgs = await this.api.wsteps.getProductImages(this.order.items.map(x=>x.product_id));
				if (imgs.error) throw imgs;
				else {
					imgs.forEach(img=> {
						let item = this.order.items.find(x=>x.product_id===img.id);
						if (item&&img.image_url) {
							this.$set(item, 'image_url', img.image_url);
						}
					})
				}
			} catch (e) {
				this.$warn(e, 'Failed to load product images! try again later.')
			} finally {
				this.loadingImages = false;
			}
		},

		//API
		async init() {
			try {
				this.loading = true;
				this.loadingCustomer = true;
				this.order = {};

				this.order = await this.api.orders.get(this.orderId);
				this.$store.dispatch("setCurrentOrder", this.order)
				this.loadCustomerBrief(); // async

				if (!this.order.is_apps) this.loadImages();

				if (this.order.is_fulfilled) this.loadFulfillment();
			} catch (e) {
				this.$error(e);
			} finally {
				this.loading = false;
			}
		},
	},
	created() {
		this.init();
	},
}
</script>
<style lang="scss" scoped>
.order-details-popup {
	padding: 0;
	&.archived {
		.title {
			color: $dark-grey;
		}
	}
	&.canceled {
		.title {
			text-decoration: line-through;
		}
	}
	.status-tags {
		display: flex;
		flex-direction: row;
		margin-right: 25px;
	}
	.status-tag {
		border-radius: 5px;
		background: $light-silver;
		padding: 3px 10px;
		display: flex;
		flex-direction: row;
		align-items: center;
		.value {
			margin: 0 0 0 5px;
		}
		svg {
			//color: #18d7ae;
			height: 18px;
			&.refund {
				color: $black;
			}
		}
		margin-right: 3px;
	}
	&.level-1 {
		max-width: 580px;
		box-shadow: -5px 0 20px 15px rgba(0, 0, 0, 0.10);
	}
	&.level-2 {
		max-width: 560px;
		box-shadow: -5px 0 20px 15px rgba(0, 0, 0, 0.05);
	}
	.title {
		display: flex;
		flex-direction: column;
		align-items: start;
		position: relative;
		.back-btn {
			position: absolute;
			top: -30px;
		}
	}

	.popup_high_right {
		padding: 0;
	}
	.title {
		font-size: 22px;
		line-height: 150%;
		font-weight: 600;
		letter-spacing: 0.02em;
		color: #252A32;
		margin-bottom: 5px;
		background: white;
		min-height: 40px;
	}
	.popup-container {
		display: flex;
		flex-direction: column;
		flex: 1;
		padding: 35px 30px 20px;
	}
}
.order-status-row {
	margin-top: 10px;
	margin-bottom: 10px;
	display: flex;
	flex-direction: row;
	font-size: 13px;
	align-items: center;
	color: #26252e;
	.label {
		font-weight: bold;
		margin-right: 10px;
	}
	.value {
		display: flex;
		flex-direction: row;
		margin-right: 20px;
		margin-left: 5px;
	}
}
.popup_title {
	font-size: 20px;
}
</style>
