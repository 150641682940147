<template>
	<popup class="save-email-template-popup" title="Save changes?" @close="closeThisPopup">
		<template v-slot:content>
			<p>There are some unsaved changes, how would you like to proceed?</p>
		</template>
		<template v-slot:actions>
			<v-button @click="noSaveRedirect" slim label="Ignore changes" border red />
			<v-button @click="saveFunc" slim label="Save changes"/>
		</template>
	</popup>
</template>

<script>
import API from "@/api"
import Popup from "@/components/base/popup";
import VButton from "@/components/base/v-button";
export default {
    name: 'SaveTemplatePopup', components: {VButton, Popup}, mixins: [API],
    data: function(){
        return {
        }
    },
    watch: {
    },
    methods: {
        closeThisPopup(){
            this.$emit('closePopup');
        },
        noSaveRedirect(){
            this.$emit('noSave');
            this.closeThisPopup();
        },
        saveFunc(){
            this.$emit('saveFunc')
            this.closeThisPopup();
        }
    },
}
</script>

<style lang="scss" scoped>
</style>
<style lang="scss">
.save-email-template-popup {
	.popup {
		max-width: 520px;
	}
}
</style>
