import {
    shadows, sticky,
} from './fields/'

export default {
    tag: 'orderSummary',
    labelTitle: '',
    labelColor: '',
    id: { value: null },
    content: {
        product: 'Product',
        price: 'Price',
        total: 'Total',
        tax: 'Tax',
        subtotal: 'Subtotal',
        discountCode: 'Discount code',
        apply: 'Apply',
        discount:'Discount',
        showSummary:'Show order summary',
        hideSummary:'Hide order summary'
    },
    attributes: {
        basic: {
            margin: { title: 'Margin', comp: 'marginPadding', value: { top: 15, left: 0, right: 0, bottom: 0 } },
            props: { title: 'Props', comp: 'orderSummaryProps', value: {
                    showImages: true,
                    allowDiscountCodes: true,
                    showHeader: false,
                    showTotal: true,
                    collapsable: true,
                    isOpen: true,
                }
            },
            font: { title: 'Labels font', comp: 'FontPanel', value: { fontFamily: null, fontSize: 14, color: '#535353', fontStyling: { bold: false, italic: false, 'line-through': false, underline: false, } } },
            font2: { title: 'Items font', comp: 'FontPanel', value: { fontFamily: null, fontSize: 14, color: '#252A32', fontStyling: { bold: false, italic: false, 'line-through': false, underline: false, } } },
            backgroundColor: { title: 'Background color', comp: 'colorPicker2', value: '#ffffff' },
            separatorColor: { title: 'Separator color', comp: 'colorPicker2', value: '#afafaf57' },
            headerBgColor: { title: 'Header background color', comp: 'colorPicker2', value: '#fafafa' },
            border: {
                title: 'Border',
                comp: 'border',
                value: {
                    width: 1,
                    color: '#AFAFAF56',
                    style: 'solid',
                    sides: { top: true, left: false, right: false, bottom: true },
                    radius: { analytics: 0, topRight: 0, bottomRight: 0, bottomLeft: 0 },
                }
            },
            shadows
        },
        advanced: {
            cssId: { title: 'ID', comp: 'TextInput', value: '' },
            cssClasses: { title: 'CSS Classes', comp: 'MultiSelectItem', value: [], options: [] },
            padding: { title: 'Padding', comp: 'marginPadding', value: { top: 10, left: 10, right: 10, bottom: 10 } },
            sticky

        },
    },
}
