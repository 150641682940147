export default {
    tag: 'div', // div, h1-h6, p, img, video ...
    labelTitle: '',
    labelColor: '',
    id: { value: null },
    attributes: {
        basic: {
            margin: { title: 'Margin', comp: 'marginPadding', value: { top: 0, left: 0, right: 0, bottom: 0 } },
            cols: {
                title: 'Columns',
                comp: 'selectItem',
                value: 'one',
                options: [
                    { id: "one", text: "One" }, { id: "two", text: "Two" }, { id: "three", text: "Three" },
                    { id: "four", text: "Four" }, { id: "five", text: "Two + One" }, { id: "six", text: "One + Two" }
                ]
            },
            backgroundColor: { title: 'Background color', comp: 'colorPicker', value: 'transparent' },
            backgroundImage: { title: 'Background image', comp: 'imageUploader', value: '' },
            font: { title: 'Font', comp: 'FontPanel', value: { fontFamily: 'Roboto', fontSize: 14, color: '#F6F9FE', fontStyling: { bold: false, italic: false, 'line-through': false, underline: false, } } },
            padding: { title: 'Padding', comp: 'marginPadding', value: { top: 0, left: 0, right: 0, bottom: 0 } },
            border: {
                title: 'Border',
                comp: 'border',
                value: {
                    width: 0,
                    color: 'transparent',
                    style: 'solid',
                    sides: { top: true, left: true, right: true, bottom: true },
                    radius: { leftTop: 0, topRight: 0, bottomRight: 0, bottomLeft: 0 },
                }
            },
        },
        advanced: {
            cssId: { title: 'ID', comp: 'TextInput', value: '' }, // !!!Unique!!!
            cssClasses: { title: 'CSS Classes', comp: 'MultiSelectItem', value: [], options: [{id: 'class1', text: 'class1'}, {id: 'class2', text: 'class2'}, {id: 'class3', text: 'class3'}] }, // Array of Strings
        },
    },
}