<template>
	<div class="customer-entry" :class="{'disabled':!customer.optin_email}" @click="$emit('click')">
		<v-spinner v-if="loading" />
		<div v-else class="headline" >
			<div>
				<span class="avatar" :class="{'unknwon':name==='Unknown'}">
					<span class="x" >{{letter}}</span>
				</span>
			</div>
			<div class="flex layout-column ">
				<span class="name">{{name}}</span>
				<span v-if="customer.shipping_city&&customer.shipping_country" class="subtitle">
					{{customer.shipping_city + ' ' + customer.shipping_country}}
				</span>
				<span v-else class="subtitle">{{customer.optin_email}}</span>
			</div>
			<div class="flex-none">
				<quick-actions-btn :items="['Edit']" @menuClick="$emit('click',$event.ev)"/>
			</div>
		</div>
	</div>
</template>

<script>
import VButton from "@/components/base/v-button";
import API from '@/api';
import VSpinner from "@/components/base/v-spinner";
import QuickActionsBtn from "@/components/base/quick-actions-btn";
import MenuItem from "@/components/base/menu-item";

export default {
	name: "customer-entry",
	components: {MenuItem, QuickActionsBtn, VSpinner, VButton},
	props:['customer','loading'],
	mixins:[API],
	data(){
		return {
			open: false,
		}
	},
	computed:{
		letter(){
			return this.customer ? (this.customer.first_name || this.customer.last_name || 'X').substring(0,1) : 'X'
		},
		name(){
			if (!this.customer) return "Unknown";
			if (!this.customer.first_name && !this.customer.last_name) {
				return "Unknown";
			}
			return (this.customer.first_name||'') +' '+ (this.customer.last_name||'')
		}
	},
	methods:{
		// async init() {
		// 	try {
		// 		this.loading = true;
		// 		this.customer = {};
		// 		let c = await this.api.customers.get(this.customerId, true);
		// 		if (c.error) this.customer = {};
		// 		else this.customer = c;
		// 		this.$store.dispatch("setCurrentOrder", this.customer)
		// 	} catch (e) {
		// 		this.$warn(e, 'Failed to load customer details! try again later.')
		// 	} finally {
		// 		this.loading = false;
		// 	}
		// },
	},
	created() {
		// this.init();
	}
}
</script>

<style lang="scss" scoped>
.customer-entry {
	position: relative;
	min-height: 30px;

	&.disabled {
		pointer-events: none;
	}
	.headline {
		font-weight: 500;
		font-size: 16px;
		line-height: 150%;
		letter-spacing: 0.02em;
		color: #25242d;
		display: flex;
		flex-direction: row;
		position: relative;
		cursor: pointer;
		padding: 8px 15px;
		background: transparent;
		border-radius: 8px;
		opacity: 1;
		align-items: center;
		.name {
			font-weight: 600;
			font-size: 14px;
			color: $black;
		}
		.subtitle {
			font-weight: 400;
			font-size: 14px;
			color: $dark-grey;
		}
		&:hover {
			background: #f7f7f7;
		}
		&.open {
			background: #f7f7f7;
		}
		.avatar {
			border-radius: 50%;
			background: orange;
			color: white;
			font-size: 18px;
			height: 40px;
			width: 40px;
			position: relative;
			display: flex;
			margin-right: 10px;
			&.unknwon {
				background: silver;
			}
			.x {
				margin: 0;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
	}
}
</style>
