const moment = require('moment-timezone');

export default (config, axios, token) => {

    let call = async function(path, method = 'GET', data, isV2=false) {
        data ? data = JSON.stringify(data) : '';
        const url = (isV2 ? config.AnalyticsURL : config.URL) + path;
        try {
            const response = await axios({
                url,
                method,
                data,
                headers: { 'Authorization': 'Bearer ' + token }
            })
            if (response.error) {
                throw response.error
            }
            return response.data
        } catch (error) {
            return { error: true, info: error.response }
        }
    }

    return {

        getPerfBreakdown: async(funnelId, startDate, endDate, unit = 'month', oldStartDate, oldEndDate) => {
            startDate = moment(startDate).utc(false).unix();
            endDate = moment(endDate).utc(false).unix();
            oldStartDate = moment(oldStartDate).utc(false).unix();
            oldEndDate = moment(oldEndDate).utc(false).unix();
            return call(`funnels/${funnelId}/performance-breakdown?funnel_id=${funnelId}&start_time=${startDate}&end_time=${endDate}&old_stime=${oldStartDate}&old_etime=${oldEndDate}&unit=${unit}`)
        },

        // Send locale date as is.
        get: async(id, startDate, endDate, unit = 'month', oldStartDate, oldEndDate) => {

            startDate = moment(startDate).utc(false).unix();
            endDate = moment(endDate).utc(false).unix();
            oldStartDate = moment(oldStartDate).utc(false).unix();
            oldEndDate = moment(oldEndDate).utc(false).unix();
            return call(`funnel-analytics?funnel_id=${id}&start_time=${startDate}&end_time=${endDate}&old_stime=${oldStartDate}&old_etime=${oldEndDate}&unit=${unit}`)
        },

        getBreakdownV2: async(funnelId, startDate, endDate, conditions=[], testMode='') => {
            startDate = moment(startDate).utc(false).unix();
            endDate = moment(endDate).utc(false).unix();
            return call(`performance-breakdown?funnel_id=${funnelId}&start_time=${startDate}&end_time=${endDate}&test_mode=${testMode}`,
                'POST', conditions, true)
        },

        getTrends_v2: async(chart='revenue', funnelId, startDate, endDate, unit = 'month', conditions=[], testMode='') => {

            startDate = moment(startDate).unix();
            endDate = moment(endDate).unix();

            return call(`trends?chart=${chart}&funnel_id=${funnelId}&start_time=${startDate}&end_time=${endDate}&unit=${unit}&test_mode=${testMode}`,
                'POST', conditions, true)
        },
        getUtmReport: async(searchTerm='', utm_types=['utm_source','utm_term'],
                            funnelId, startDate, endDate, conditions=[], testMode='',
                            limit=100, page=1) => {

            startDate = moment(startDate).unix();
            endDate = moment(endDate).unix();

            let utmTypes = utm_types.join(',')

            return call(`utm-table?search_term=${searchTerm}&funnel_id=${funnelId}&start_time=${startDate}&end_time=${endDate}`+
                `&utm_types=${utmTypes}&test_mode=${testMode}&limit=${limit}&page=${page}`,
                'GET', conditions, true)
        },
        getPaymentMethodPerformance: async(funnelId, startDate, endDate, conditions=[],testMode='') => {

            startDate = moment(startDate).utc(false).unix();
            endDate = moment(endDate).utc(false).unix();

            return call(`payment-method-performance?funnel_id=${funnelId}&start_time=${startDate}&end_time=${endDate}&test_mode=${testMode}`,
                'POST', conditions, true)
        },
        getPaymentMethodsReport: async(funnelId, startDate, endDate, conditions=[],testMode='') => {
            startDate = moment(startDate).utc(false).unix();
            endDate = moment(endDate).utc(false).unix();

            return call(`payment-methods-report?funnel_id=${funnelId}&start_time=${startDate}&end_time=${endDate}&test_mode=${testMode}`,
                'GET', conditions, true)
        },
        getDevicePerformance: async(funnelId, startDate, endDate, conditions=[], testMode='') => {

            startDate = moment(startDate).utc(false).unix();
            endDate = moment(endDate).utc(false).unix();

            return call(`device-performance?funnel_id=${funnelId}&start_time=${startDate}&end_time=${endDate}&test_mode=${testMode}`,
                'POST', conditions, true)
        },
        getFunnelPerformance: async(funnelId, startDate, endDate, conditions=[],testMode='',limit=7, page=1, search='') => {

            startDate = moment(startDate).utc(false).unix();
            endDate = moment(endDate).utc(false).unix();

            return call(`funnel-performance?funnel_id=${funnelId}&start_time=${startDate}&end_time=${endDate}`+
                `&limit=${limit}&test_mode=${testMode}&search=${search}&page=${page}`,
                'GET', conditions, true)
        },
        getFunnelStats: async(funnelId, startDate, endDate,testMode='',limit=7) => {

            startDate = moment(startDate).utc(false).unix();
            endDate = moment(endDate).utc(false).unix();

            return call(`funnel-stats?funnel_id=${funnelId}&start_time=${startDate}&end_time=${endDate}&limit=${limit}&test_mode=${testMode}`,
                'GET', null, true)
        },
        getSummary_v2: async(chart='revenue', funnelId, startDate, endDate,
                             oldStartDate, oldEndDate, conditions=[], testMode='') => {

            startDate = moment(startDate).utc(false).unix();
            endDate = moment(endDate).utc(false).unix();
            oldStartDate = moment(oldStartDate).utc(false).unix();
            oldEndDate = moment(oldEndDate).utc(false).unix();
            return call(`summary?chart=${chart}&funnel_id=${funnelId}&start_time=${startDate}&end_time=${endDate}&old_stime=${oldStartDate}&old_etime=${oldEndDate}&test_mode=${testMode}`,
                'POST', conditions, true)
        },
        getDownstreamSummary: async(funnelId, stepId, pageId=0, startDate, endDate,
                             oldStartDate, oldEndDate, conditions=[], testMode='') => {

            startDate = moment(startDate).utc(false).unix();
            endDate = moment(endDate).utc(false).unix();
            oldStartDate = moment(oldStartDate).utc(false).unix();
            oldEndDate = moment(oldEndDate).utc(false).unix();
            return call(`downstream-summary?step_id=${stepId||0}&page_id=${pageId||0}&funnel_id=${funnelId}&start_time=${startDate}&end_time=${endDate}&old_stime=${oldStartDate}&old_etime=${oldEndDate}&test_mode=${testMode}`,
                'POST', conditions, true)
        },

        dashboard: async(startDate, endDate, unit = 'month', funnels=[]) => {
            startDate = moment(startDate).utc(false).unix();
            endDate = moment(endDate).utc(false).unix();

            return call('dashboard-summary?start_time='+startDate+ '&end_time='+endDate + '&unit=' + unit + "&funnels="+funnels.join("|"));
        },

        getRecentActivity: async() =>
            call('recent-activity', "POST", null, true),

        getLiveView: async() =>
            call('live-view', "GET", null, true),

        getRevenueTable: async(funnelId, startDate, endDate, unit = 'month', conditions=[], testMode=false) => {
            startDate = moment(startDate).unix();
            endDate = moment(endDate).unix();
            return call(`revenue-table?funnel_id=${funnelId}&start_time=${startDate}&end_time=${endDate}&unit=${unit}&test_mode=${testMode}`,
                'GET', conditions, true)
        },
        getTrafficTable: async(funnelId, startDate, endDate, unit = 'month', conditions=[], testMode=false) => {
            startDate = moment(startDate).unix();
            endDate = moment(endDate).unix();
            return call(`traffic-table?funnel_id=${funnelId}&start_time=${startDate}&end_time=${endDate}&unit=${unit}&test_mode=${testMode}`,
                'GET', conditions, true)
        },
        getAovTable: async(funnelId, startDate, endDate, unit = 'month', conditions=[], testMode=false) => {
            startDate = moment(startDate).unix();
            endDate = moment(endDate).unix();
            return call(`aov-table?funnel_id=${funnelId}&start_time=${startDate}&end_time=${endDate}&unit=${unit}&test_mode=${testMode}`,
                'GET', conditions, true)
        },
        getOrdersTable: async(funnelId, startDate, endDate, unit = 'month', conditions=[], testMode=false) => {
            startDate = moment(startDate).unix();
            endDate = moment(endDate).unix();
            return call(`orders-table?funnel_id=${funnelId}&start_time=${startDate}&end_time=${endDate}&unit=${unit}&test_mode=${testMode}`,
                'GET', conditions, true)
        },
        getConvRateTable: async(funnelId, startDate, endDate, unit = 'month', conditions=[], testMode=false) => {
            startDate = moment(startDate).unix();
            endDate = moment(endDate).unix();
            return call(`conv-rate-table?funnel_id=${funnelId}&start_time=${startDate}&end_time=${endDate}&unit=${unit}&test_mode=${testMode}`,
                'GET', conditions, true)
        },
        getOptinsTable: async(funnelId, startDate, endDate, unit = 'month', conditions=[], testMode=false) => {
            startDate = moment(startDate).unix();
            endDate = moment(endDate).unix();
            return call(`optins-table?funnel_id=${funnelId}&start_time=${startDate}&end_time=${endDate}&unit=${unit}&test_mode=${testMode}`,
                'GET', conditions, true)
        },
        getDeviceTrends: async(chart='revenue', funnelId, startDate, endDate, unit = 'month', conditions=[], testMode='') => {

            startDate = moment(startDate).utc(false).unix();
            endDate = moment(endDate).utc(false).unix();
            return call(`device-trends?chart=${chart}&funnel_id=${funnelId}&start_time=${startDate}&end_time=${endDate}&unit=${unit}&test_mode=${testMode}`,
                'GET', conditions, true)
        },

    }
}
