export default {
    state: {
        statistic: [
            {
                id: 1,
                imgPath: require('@/assets/img/optin.svg'),
                title: "Optin",
                value: "12.9k",
                delta: "0.54%",
                deltaClass: "delta-up"
            },
            {
                id: 2,
                imgPath: require('@/assets/img/purchases.svg'),
                title: "Purchases",
                value: "394",
                delta: "0.54%",
                deltaClass: "delta-down"
            },
            {
                id: 3,
                imgPath: require('@/assets/img/visitors.svg'),
                title: "Visitors",
                value: "198 K",
                delta: "0.54%",
                deltaClass: "delta-up"
            },
            {
                id: 4,
                imgPath: require('@/assets/img/conversion.svg'),
                title: "Conversion Rate",
                value: "5.72%",
                delta: "0.54%",
                deltaClass: "delta-up"
            },
            {
                id: 5,
                imgPath: require('@/assets/img/rate.svg'),
                title: "Optin rate",
                value: "5.72%",
                delta: "0.54%",
                deltaClass: "delta-up"
            }
        ]
    },
    getters: {
        getStatData(state) {
            return state.statistic
        }
    }
}