export default {
    tag: 'link',
    labelTitle: '',
    labelColor: '',
    id: { value: null },
    attributes: {
        basic: {
            margin: { title: 'Margin', comp: 'marginPadding', value: { top: 0, left: 0, right: 0, bottom: 0 } },
            content: { title: 'Link text', comp: 'TextInput', value: '' },
            linkUrl: { title: 'Click action', comp: 'ClickAction', value: { url: '', mainToggle: 'No action', mainSelectOptions: [
                { id: 'No action', text: 'No action' },
                { id: 'Submit step', text: 'Submit step' },
                { id: 'Go to next step', text: 'Go to next step' },
                { id: 'Go to URL', text: 'Go to URL' },
                { id: 'Go to section/row', text: 'Go to section/row' },
            ] } },
            font: { title: 'Font', comp: 'FontPanel', value: { fontFamily: 'Roboto', fontSize: 14, color: '#4368e0', fontStyling: { bold: false, italic: false, 'line-through': false, underline: false, }, textAlign: 'center'} },
        },
        advanced: {
            cssId: { title: 'ID', comp: 'TextInput', value: '' },
            cssClasses: { title: 'CSS Classes', comp: 'MultiSelectItem', value: [], options: [{id: 'class1', text: 'class1'}, {id: 'class2', text: 'class2'}, {id: 'class3', text: 'class3'}] },
            backgroundColor: { title: 'Background color', comp: 'colorPicker', value: 'transparent' },
            padding: { title: 'Padding', comp: 'marginPadding', value: { top: 0, left: 0, right: 0, bottom: 0 } },
            border: {
                title: 'Border',
                comp: 'border',
                value: {
                    width: 0,
                    color: 'transparent',
                    style: 'solid',
                    sides: { top: true, left: true, right: true, bottom: true },
                    radius: { leftTop: 0, topRight: 0, bottomRight: 0, bottomLeft: 0 },
                }
            },
            // displayType: { title: 'Display type', comp: 'selectItem', value: 'inline-block', options: [{ id: "inline-block", text: "inline-block" }, { id: "block", text: "block" }, { id: "flex", text: "flex" }] },
        },
    },
}