<template>
    <div class="wrapper">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: "MainLayout",
    components: {},
}
</script>
<style lang="scss" scoped>

</style>
