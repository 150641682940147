<template>
	<expandable-area title="Order Summary" name="order-summary">
		<div class="layout-column order-summary">
			<div class="layout-column" v-if="order.items">
				<div class="order-item" v-for="item in order.items">
<!--					product_id:1 is reserved for tax product entry -->
					<div class="item-img" v-if="item.product_id>2">
						<img v-if="item.image_url" class="" :src="imgUrl(item)" />
						<img v-else class="placeholder" :src="require('@/assets/img/placeholder-img.svg')" />
					</div>
					<div class="layout-column layout-stretch flex item-name-wrapper" >
						<a v-if="item.product_id>2" class="item-name">
							<external-link :label="item.name"
							               :href="`/${order.is_apps?'funnel':'wfunnel'}/${order.funnel_id}/steps/${order.step_id}/product/${item.product_id}`" />
						</a>
						<a v-else class="item-name">{{ item.name }}</a>
						<span class="item-variant"
						      v-if="item.variant_title && item.variant_title !== 'Main Product'">
              {{ item.variant_title }}
            </span>
					</div>
					<div style="width: initial; min-width: 25px;">
						<div class="price">
							{{formatPrice(item.amount)}}

						{{ (item.qty > 1 ? ' x ' + item.qty : '') }}</div>
					</div>
				</div>
			</div>
			<div class="order-item total" v-if="order.items">
				<span class="item-name flex">Total</span>
				<span class="price">{{formatPrice(order.amount)}}</span>
			</div>
		</div>
	</expandable-area>
</template>

<script>
import ExpandableArea from "@/components/base/expandable-area";
import {formatPrice, getSymbol} from "@/helpers/currencies";
import numbers from "@/helpers/numbers";
import ExternalLink from "@/components/base/external-link";
export default {
	name: "order-summary", components: {ExternalLink, ExpandableArea},
	props:['order'],
	computed:{

		currency() {
			let c = this.$store.state.userInfo.currency_code;
			if (this.order && this.order.currency) c = this.order.currency;
			return c;
		},
	},
	methods:{
    imgUrl(item) {
      if (item.image_url?.includes("images.funnelish.com"))
          return item.image_url.replace("images.funnelish.com", "img.funnelish.com");
      else
        return item.image_url || '';
    },
		formatPrice(price) {
			return formatPrice(this.currency, this.roundPrice(price))
		},
		roundPrice(n) {
			return numbers.round_to_two(n)
		},
	}
}
</script>

<style lang="scss" scoped>
.order-summary {
	.order-item {
		display: flex;
		flex-direction: row;
		padding: 4px;
		&:first-child {
			margin-top: 10px;
		}
		&.total {
			margin-top: 10px;
		}
		.item-img {
			margin-right: 10px;
			border: 1px solid $light-grey;
			background: white;
			width: 48px;
			height: 48px;
			border-radius: 8px;
			overflow: hidden;
			position: relative;
			align-items: center;
			display: flex;
			img {
				display: flex;
				align-self: center;
				flex: 1;
				width: 48px;
				height: 48px;
				object-fit: cover;
				&.placeholder {
					opacity: .6;
					width: 30px;
					height: 30px;
					object-fit: scale-down;
				}
			}
		}
		.price {
			font-weight: 600;
			font-size: 14px;
			color: $black;
		}
		.item-name-wrapper {
			margin-top: auto;
			margin-bottom: auto;
		}
		.item-name {
			font-weight: 600;
			font-size: 14px;
			color: $black;
		}
		.item-variant {
			font-weight: normal;
			font-size: 13px;
			color: $grey;
			margin-top: 5px;
		}
	}
}
</style>
<style lang="scss">
.order-summary {
	.item-name {
		.external-link {
			font-weight: 600;
			font-size: 14px;
			color: $black;
			margin: 0;
			svg {
				display: none;
			}
			&:hover {
				color: $blue;
				svg {
					display: flex;
				}
			}
		}
	}
}
</style>
