export default {
    tag: 'custom',
    labelTitle: '',
    labelColor: '',
    id: {value: null},
    content: '',
    blockStyles: '',
    attributes: {
        basic: {
            margin: { title: 'Margin', comp: 'marginPadding', value: { top: 10, left: 0, right: 0, bottom: 0 } },
            openPopup: { title: 'Edit Custom HTML', comp: 'PopupOpenBtn'},
        },
        advanced: {
            cssId: { title: 'ID', comp: 'TextInput', value: '' },
            cssClasses: { title: 'CSS Classes', comp: 'MultiSelectItem', value: [], options: [] },
        },
    },
}
