<template>
	<div class="topbar-icon ">
		<span v-tippy="{theme:'small-black'}" :content="label">
			<slot></slot>
			<!--		<span v-if="label">-->
			<!--			{{label}}-->
			<!--		</span>-->
		</span>
	</div>
</template>

<script>
export default {
	name: "topbar-icon",
	props:{
		label:{},
		img:{},
	}
}
</script>

<style lang="scss" scoped>
.topbar-icon {
	display: flex;
	align-items: center;
	height: fit-content;
	position: relative;
	justify-content: center;
	transition: none;
	color: #343338D2;
	position: relative;
	font-weight: 500;
	font-size: 14px;
	border-radius: 5px;
	min-height: 30px;
	gap: 5px;
	cursor: pointer;

	> span {
		white-space: nowrap;
		padding: 6px;
	}

	&:hover {
		color: $black;
		//background: $hover-light-blue;
		//box-shadow: inset -1px 0 0px 0px rgb(214 230 255 / 54%);
	}
	svg {
		display: block;
		max-width: 20px;
		max-height: 20px;
		width: 20px;
		height: 20px;
		margin: 0;
	}
	&.logo {
		svg {
			max-width: 28px;
			max-height: 28px;
		}
	}
	&.__account.tippy-active {
		color: $blue;
		background: $hover-light-blue;
	}
		&.router-link-active:not(.dashboard):not(.__account) {
			background: $hover-light-blue;
			//border-top: 1px solid $light-grey;
			//border-bottom: 1px solid $light-grey;
			color: $blue;
			//&:not(.logo)::after {
			//	content:'';
			//	position: absolute;
			//	width: 100%;
			//	height: 4px;
			//	z-index: 1;
			//	left: 0;
			//	top: 0;
			//	background: $blue;
			//	border-radius: 0 3px 3px 0;
			//}
		}

	&.router-link-exact-active.dashboard {
		background: $hover-light-blue;
		//border-top: 1px solid $light-grey;
		//border-bottom: 1px solid $light-grey;
		color: $blue;
	}
}
</style>
