<template>

	<a v-if="account" class="topbar-item __account">
		<inline-svg v-if="img" :src="img" />
		<span>
			{{label}}
		</span>
	</a>
	<router-link v-else class="topbar-item" :to="to">
		<inline-svg v-if="img" :src="img" />
		<span>
			{{label}}
		</span>
		<span class="__tag" v-if="tag">
			{{tag}}
		</span>
	</router-link>
</template>

<script>
export default {
	name: "topbar-item",
	props:{
		to:{},
		label:{},
		img:{},
		account:{type:Boolean},
		tag:{}
	}
}
</script>

<style lang="scss" scoped>
.topbar-item {
	display: flex;
	align-items: center;
	height: fit-content;
	position: relative;
	justify-content: center;
	transition: none;
	color: $darker-grey;
	position: relative;
	font-weight: 500;
	font-size: 14px;
	padding: 4px 15px;
	border-radius: 8px;
	min-height: 30px;
	gap: 5px;

	.__tag {
		background: $blue;
		padding: 3px 4px;
		font-size: 9px;
		color: white;
		font-weight: 500;
		border-radius: 4px;
		margin-bottom: 4px;
		line-height: 1;
		align-self: baseline;
	}
	> span {
		white-space: nowrap;
		line-height: 1;
	}

	&:hover:not(.router-link-exact-active) {
		color: $darker-grey;
		background: $light-silver;
		//box-shadow: inset -1px 0 0px 0px rgb(214 230 255 / 54%);
	}
	svg {
		display: block;
		max-width: 20px;
		max-height: 20px;
		margin: 0;
	}
	&.logo {
		svg {
			max-width: 28px;
			max-height: 28px;
		}
	}
	&.__account.tippy-active {
		color: $blue;
		background: $hover-light-blue;
	}
		&.router-link-active:not(.dashboard):not(.__account) {
			background: $dark-hover-light-blue;
			//border-top: 1px solid $light-grey;
			//border-bottom: 1px solid $light-grey;
			color: $blue;
			//&:not(.logo)::after {
			//	content:'';
			//	position: absolute;
			//	width: 100%;
			//	height: 4px;
			//	z-index: 1;
			//	left: 0;
			//	top: 0;
			//	background: $blue;
			//	border-radius: 0 3px 3px 0;
			//}
		}

	&.router-link-exact-active.dashboard {
		background: $hover-light-blue;
		//border-top: 1px solid $light-grey;
		//border-bottom: 1px solid $light-grey;
		color: $blue;
	}
}
</style>
