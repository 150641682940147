export default (config, axios, token) => {

    var call = async function(path, method = 'GET', data={}) {
        // data ? data = JSON.stringify(data) : '';
        const url = config.URL + path;
        try {
            const response = await axios({
                url,
                method,
                data,
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json',
                }
            })
            return response.data
        } catch (error) {
            return { error: true, info: error.response }
        }
    }
    var downloadCsv = async function(path, method = 'GET', data) {
        const url = config.URL + path;
        try {
            const response = await axios({
                url,
                method,
                data,
                headers: { 'Authorization': 'Bearer ' + token }
            })
            return response.data
        } catch (error) {
            return { error: true, info: error.response }
        }
    }

    return {

        get: async(id, brief=false) =>
            call(`customers/${id}?brief=${brief}`),

        getAll: async(page = 0, per_page = 25, query = '') =>
            call('customer-briefs?page=' + page + '&per_page=' + per_page + '&query=' + query),

        getStats: async() =>
            call('customers-stats'),

        patch: async(id, data) =>
            call('customers/' + id, 'PATCH', data),

        delete: async(id) =>
            call('customers/' + id, 'DELETE'),

        getOrders: async(id) =>
            call(`customers/${id}/orders`),

        export: async(data) =>
            call('customers-export', 'POST', data),

        download: async(id, funnelId) =>
            downloadCsv('download-export', 'POST', { id:id, funnel_id:funnelId }),

    }
}
