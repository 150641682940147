<template>
    <div class="code-editor" :ref="`editor${id}`"></div>
</template>

<script>
const monaco = require('monaco-editor')

let id = 0;
export default {
  name: "code-editor",
  props:{
    language:{
      default:'html',
      type:String,
    },
    value:{
      default:'',
      required:true,
    },
    small:{
      default:false,
      type:Boolean,
    },
    wrap:{
      default:false,
      type:Boolean,
    },
  },
  data() {
    return {
      id: ++id,
      editor:null,
      resizeObserver:null,
      width:1,
      height:1,
    }
  },
  watch:{
    language(newVal) {
      if (this.editor) {
        const editor = this.editor;
        monaco.editor.setModelLanguage(editor.getModel(), newVal)
      }
    },
    value(newValue) {
      if (this.editor) {
        const editor = this.editor;
        if (newValue !== editor.getValue()) {
          editor.setValue(newValue)
        }
      }
    },
  },
  methods:{
    updateLayout() {
      this.editor?.layout({width: this.width, height: this.height})
    }
  },
  mounted() {
    let editorDiv = this.$refs[`editor${this.id}`];
    const editorOptions = {
      language: this.language,
      value:this.value,
      minimap: { enabled: false },
    };

    if (this.small) {
      Object.assign(editorOptions, {
        lineDecorationsWidth: 0,
        fontSize: 10,
        lineNumbers: 'on',
        lineNumbersMinChars: 1,
        wordWrap: 'on',
      })
    }

    if (this.wrap) {
      editorOptions.wordWrap = "on"
    }

    // loader.init().then((monaco) => {
      this.editor = monaco.editor.create(editorDiv, editorOptions);

      this.editor.onDidChangeModelContent(event => {
        const value = this.editor.getValue()
        if (this.value !== value) {
          this.$emit('input', value, event)
          this.$emit('change', value, event)
        }
      })
    // });
    this.resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        this.width = entry.contentRect.width
        this.height = entry.contentRect.height

        this.updateLayout();
      }
    });
    this.resizeObserver.observe(editorDiv);
  },
  beforeDestroy() {
    // this.editor && this.editor.dispose()
  },
  unmounted() {
    this.editor && this.editor.dispose()
    this.resizeObserver.unobserve(editorDiv);
  }
}
</script>

<style scoped lang="scss">
.code-editor {
  width: 100%;
  height: 100%;
  flex: 1;
  position: absolute;
}
</style>