import Vue from 'vue'
import Vuex from 'vuex'
import VuexUndoRedo from "@/modules/email-editor/src/store/undoRedoPlugin";


import editor from '@/modules/email-editor/src/store/editor'

Vue.use(Vuex)

Vue.use(VuexUndoRedo, { ignoreMutations: ['EMAIL_SET_VIEW', 'EMAIL_SET_CODEPOPUPOPEN', 'EMAIL_SET_CURRENT_ID', 'EMAIL_SET_TEMPLATES', 'EMAIL_SET_INITIAL', 'EMAIL_SET_TAB', 'EMAIL_SET_ID', 'EMAIL_SET_TABID'] });

export default {
    state: {
        view: 'desktop',
        tab: null,
        id: null,
        templates: [],
        userTemplate: {

        },
        currentTemplate: {
            id: false,
            name: false
        },
        customCode: {
            css: '/* CSS */\n',
            // html: '<!-- HTML -->\n    '
        },
        page: {
            basic: {
                bgColor: '#fff',
                bgImage: '',
                padding: { top: 68, left: 30, right: 30, bottom: 200 },
                font: {
                    fontStyling: { bold: false, italic: false, 'line-through': false, underline: false },
                    color: '#252A32',
                    fontFamily: 'Roboto',
                    fontSize: 14,
                },
                width: {
                    fullWidth: false,
                    width: 650,
                },
                border: {
                    title: 'Border',
                    value: {
                        width: 0,
                        color: 'transparent',
                        style: 'solid',
                        sides: { top: true, left: true, right: true, bottom: true },
                        radius: { leftTop: 0, topRight: 0, bottomRight: 0, bottomLeft: 0 },
                    }
                },
            },
            advanced: {
                id: '',
                cssClasses: []
            }
        },
        editor: editor.state,
        codePopupOpen: false,
        //token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InlvdXNmaS55YUBnbWFpbC5jb20iLCJleHBpcnkiOiIyMDIwLTA2LTIxVDEyOjE1OjM5LjQ5OTA4OTA1M1oiLCJpZCI6MywicGFzc3dvcmQiOiJORE5oWVRnNVpUTmhZVFF3TVRaaFpERmlPR1ZqTW1ZNU9UazJPRFEzWTJVd05EbGpaamcxWW1OaE1HRXdOMkV4TlRaaE1UUTFOek00TlRnd09UazJaZz09In0.n7d-rhHRyg7zIYjqrF_njCnCZYRCUdGoCbqoNzi7OHE', // test only
        initial: {
            funnel: null,
            automation: null,
            token: null,
            user: null,
            key: null
        }
    },
    mutations: {
        EMAIL_SET_TABID(state, { tab, id }) {
            state.tab = tab
            state.id = id
        },
        EMAIL_SET_TAB(state, value) {
            state.tab = value
        },
        EMAIL_SET_ID(state, value) {
            state.id = value
        },
        EMAIL_SET_PAGE_PROPS(state, value) {
            state.page[value.type][value.name] = value.item
        },
        EMAIL_SET_VIEW(state, value) {
            state.view = value
        },
        EMAIL_SET_CODEPOPUPOPEN(state, value) {
            state.codePopupOpen = value
        },
        EMAIL_SET_CUSTOM_CODE(state, { css }) {
            state.customCode.css = css;
        },
        EMAIL_SET_CURRENT_ID(state, value) {
            state.currentTemplate.id = value
        },
        EMAIL_SET_TEMPLATES(state, value) {
            state.templates = value
        },
        EMAIL_APPLY_TEMPLATE(state, value) {
            state.userTemplate = value;
            state.currentTemplate.id = value.id || false;
            state.currentTemplate.name = value.name || 'Email Template';
            if (!value.clear && value.config.page && value.config.editor && Object.keys(value.config.page).length && Object.keys(value.config.editor).length) {
                state.editor = {...value.config.editor };
                state.page = {...value.config.page };
            } else {
                state.editor.content = [];
                state.editor.rows = [];
                state.editor.children = [];
                state.page = {
                    basic: {
                        bgColor: '#fff',
                        bgImage: '',
                        padding: { top: 68, left: 30, right: 30, bottom: 200 },
                        font: {
                            fontStyling: { bold: false, italic: false, 'line-through': false, underline: false },
                            color: '#252A32',
                            fontFamily: 'Roboto',
                            fontSize: 14,
                        },
                        border: {
                            radius: { bottomLeft: 0, bottomRight: 0, leftTop: 0, topRight: 0 },
                            sides: { bottom: true, left: true, right: true, top: true },
                            color: 'transparent',
                            style: 'solid',
                            width: 0
                        }
                    },
                    advanced: { id: '', cssClasses: [] }
                }
            }
            state.customCode.css = value.css;
            //state.editor = value.config || { content: [], rows: [], children: [] };
        },
        EMAIL_SET_INITIAL(state, value) {
            state.initial = value
        },
        ...editor.mutations
    },
    actions: {
        EMAIL_SET_PAGE_PROPS(context, value) {
            context.commit('EMAIL_SET_PAGE_PROPS', value);
        },
        EMAIL_SET_VIEW(context, value) {
            context.commit('EMAIL_SET_VIEW', value)
        },
        EMAIL_SET_CODEPOPUPOPEN(context, value) {
            context.commit('EMAIL_SET_CODEPOPUPOPEN', value)
        },
        EMAIL_SET_CUSTOM_CODE(context, value) {
            context.commit('EMAIL_SET_CUSTOM_CODE', value);
        },
        EMAIL_SET_CURRENT_ID(context, value) {
            context.commit('EMAIL_SET_CURRENT_ID', value);
        },
        EMAIL_SET_TEMPLATES(context, value) {
            context.commit('EMAIL_SET_TEMPLATES', value);
        },
        EMAIL_APPLY_TEMPLATE(context, value) {
            //console.log(typeof value.config, value.config);
            if (!value.clear && value.config && typeof value.config !== 'object') {
                value.config = JSON.parse(value.config);
            }

            context.commit('EMAIL_APPLY_TEMPLATE', value);
        },
        EMAIL_SET_INITIAL(context, value) {
            context.commit('EMAIL_SET_INITIAL', value);
        },
        ...editor.actions
    },
    getters: {
        email_id(state) {
            return state.id
        },
        email_tab(state) {
            return state.tab
        },
        ...editor.getters
    }
}
