<template>
<div class="menu-item" :class="{'separator':label==='','has-icon':!!src, disabled, active}" @click="$emit('click', $event)">
	<template v-if="label!==''">
		<div class="icon" v-if="src" >
			<inline-svg v-if="src" :src="src" />
		</div>
		<span class="mi-label">{{label}}</span>
	</template>
</div>
</template>

<script>
export default {
	name: "menu-item",
	props:['src','label','disabled','active']
}
</script>

<style lang="scss" scoped>
.menu-item {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 5px 30px 5px 15px;
	border-radius: 0;
	min-width: 80px;
	cursor: pointer;
	position: relative;
	&:hover {
		background: $light-silver;
	}
	&.active {
		background: #dce9ff;
		color: #116dff;
	}
	svg {
		color: $black;
		height: 16px;
	}
	&.separator {
		pointer-events: none;
		margin: 10px;
		border-top: 1px solid $light-grey;
		padding: 0;
	}
	&.disabled {
		pointer-events: none;
		.mi-label {
			color: $dark-grey;
		}
		svg {
			color: $dark-grey;
		}
	}
	.icon {
		width: 20px;
	}
	&.has-icon {
	}
	.mi-label {
		left: 40px;
		//position: absolute;
		margin-left: 5px;
		color: $black;
	}
}
</style>
