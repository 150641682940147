<template>
	<div class="raw-test" :class="rawClass">
		<v-style :id="styleId">{{ styleInTag }}</v-style>
		<table :id="tableId" ref="table"
		       style="margin:0; padding:0;" width="100%" height="100%" bgcolor="#fff" >

			<tr style="text-decoration: inherit; text-align: center;">
				<td class="main-page" :style="pageStyle">
					<table width="100%" border="0" cellpadding="0" cellspacing="0"
						style="display: block; margin: 0 auto; padding: 0; font-family: inherit; max-width: 1200px; text-decoration: inherit;"
						bgcolor="transparent" class="main-page-main-table">
						<!-- SECTIONS -->
						<tr class="section" v-for='section in editor.content' :key='section.id'
						    style="text-decoration: inherit;display: block;text-align: center;flex-direction: column;align-items: center;">
							<td class="section-inner" align="center" :style="sectionStyle(section.id)" :width="sectionWidth(section.id)">
								<!-- ROWS -->
								<table class="section-rows" width="100%" border="0" cellpadding="0" cellspacing="0" :style="sectionMargin(section.id)">
									<tr class="row" v-for="row in section.children" style="text-decoration: inherit;">
										<td class="row-inner" :style="sectionStyle(row)">
											<!-- COLS -->
											<table class="row-column" v-for="col in email_findOne(row).cols"
											       :style="'width: '+col.width + '%; padding: 0px; display:inline-block;vertical-align:top;text-decoration: inherit;box-sizing:border-box;'">
												<!-- CHILDREN -->
												<tr class="container" v-for="item in col.children" style="text-decoration: inherit;">
													<component class="element" :class="getResponsiveClasses(email_findChild(item))"
													           :is="'email-'+email_findChild(item).type" :details="email_findChild(item)"></component>
												</tr>
												<!-- CHILDREN END -->
											</table>
											<!-- COLS END -->
										</td>
									</tr>
								</table>
								<!-- ROWS END-->
							</td>
						</tr>
						<!-- SECTIONS END -->

					</table>
				</td>
			</tr>
		</table>
	</div>
</template>

<script>
import {mapGetters} from 'vuex';
import spacer from "./generator/Spacer.vue";
import button from "./generator/Button.vue";
import headline from "./generator/Headline.vue";
import paragraph from "./generator/Paragraph.vue";
import image from "./generator/Image.vue";
import link from "./generator/Link.vue";
import social from "./generator/Social.vue";
import menu from "./generator/Menu.vue";
import timer from "./generator/Timer.vue";
import custom from "./generator/Custom.vue";

import coreCss from "../models/coreCss.js";

export default {
    name: 'RenderTamplate',
    components: {
		emailSpacer: spacer,
		emailButton: button,
		emailHeadline: headline,
		emailParagraph: paragraph,
		emailImage: image,
		emailLink: link,
		emailSocial: social,
		emailMenu: menu,
		emailTimer: timer,
		emailCustom: custom,
	},
  	props: ['tableId', 'styleId', 'rawClass'],
  	computed: {
    	...mapGetters(['email_findOne', 'email_findChild']),
    	editor() {
      		return this.$store.state.email.editor
		},
    styleInTag() {
			return coreCss.css
		},
		page() {
      		return this.$store.state.email.page
		},
		textDecorationPage(){
			var fontStyling = this.page.basic.font ? this.page.basic.font.fontStyling : false;
			if(fontStyling['line-through'] && fontStyling.underline) {
				return 'underline line-through';
			} else if(fontStyling['line-through']) {
				return 'line-through';
			} else if(fontStyling.underline){
				return 'underline';
			} else {
				return 'inherit';
			}
    	},
		pageStyle() {
			var basic = this.page.basic;

 			var border = basic.border;
            var borderTop,
                borderLeft,
                borderRight,
                borderBottom;
            var borderRadius;

			if (border && border.sides && border.radius) {
                borderTop = !border.sides.top ? 'none' : border.width + 'px ' + border.style + ' ' + border.color;
                borderLeft = !border.sides.left ? 'none' : border.width + 'px ' + border.style + ' ' + border.color;
                borderRight = !border.sides.right ? 'none' : border.width + 'px ' + border.style + ' ' + border.color;
                borderBottom = !border.sides.bottom ? 'none' : border.width + 'px ' + border.style + ' ' + border.color;

                borderRadius = border ?
                border.radius.leftTop+'px '+
                border.radius.topRight+'px '+
                border.radius.bottomRight+'px '+
                border.radius.bottomLeft+'px' : false;
            }

			var padding = basic.padding ?
				basic.padding.top+'px '+
				basic.padding.right+'px '+
				basic.padding.bottom+'px '+
				basic.padding.left+'px' : false;

			var fontFamily = basic.font ? basic.font.fontFamily : 'Roboto';
			var fontSize = basic.font ? basic.font.fontSize : '14';
			// var textAlign = this.basic.font ? this.basic.font.textAlign : 'center';
			var fontStyling = basic.font ? basic.font.fontStyling : false;
			var color = basic.font ? basic.font.color : 'initial';

			var imageUrl = basic.bgImage;
			imageUrl = imageUrl.replace('http://', '');
			imageUrl = imageUrl.replace('https://', '');
			imageUrl = imageUrl.replace(/ /g, '%20');

			let width = basic.width ? basic.width.width+'px' : '650px';
			if (basic.width && basic.width.fullWidth) width = '100%'

			var style = {
					width: width,
					maxWidth: '100%',
				backgroundColor: basic.bgColor,
				backgroundImage: imageUrl.length ? 'url(//'+imageUrl+')' : 'none',
				backgroundPosition: 'center',
				backgroundSize: 'cover',
				backgroundRepeat: 'no-repeat',
				padding,
                borderRadius,
                borderTop,
                borderLeft,
                borderRight,
                borderBottom,
				color,
				fontFamily,
				fontSize: fontSize + 'px',
				fontStyling,
				fontWeight: fontStyling.bold ? 'bold' : 'normal',
				fontStyle: fontStyling.italic ? 'italic' : 'normal',
				textDecoration: this.textDecoration,
				display: 'inline-block',
			}

			return style
		},
	},
  	methods: {
	    getResponsiveClasses(element) {
		    let c = '';
		    if (!element.responsive) return '';//element.attributes.advanced.cssClasses;
		    if (!element.responsive.desktop) c = 'no-desktop';
		    if (!element.responsive.mobile) c += ' no-mobile';
		    if (!element.responsive.tablets) c += ' no-tablet';
		    return c;//element.attributes.advanced.cssClasses;
	    },
        sectionWidth(sectionId) {
			let findSection = this.email_findOne(sectionId);

			let basic = findSection.attributes.basic;

			let widthData = basic.width;

            if(widthData == 'full') {
                return '100%';
            } else if(widthData == 'wide') {
                return '75%';
            } else if(widthData == 'half') {
                return '50%';
            } else {
                return '100%'
            }
		},
		sectionMargin(sectionId){
			let findSection = this.email_findOne(sectionId);

			let basic = findSection.attributes.basic;
			var margin = basic.margin ?
				basic.margin.top+'px '+
				basic.margin.right+'px '+
				basic.margin.bottom+'px '+
				basic.margin.left+'px' : false;
			var obj = {
				margin,
				textDecoration: 'inherit'
			};
			return obj
		},
		textDecoration(sectionId){
			let findSection = this.email_findOne(sectionId);
			let basic = findSection.attributes.basic;
            var fontStyling = basic.font ? basic.font.fontStyling : false;
            if(fontStyling['line-through'] && fontStyling.underline) {
                return 'underline line-through';
            } else if(fontStyling['line-through']) {
                return 'line-through';
            } else if(fontStyling.underline){
                return 'underline';
            } else {
                return 'inherit';
            }
        },
		sectionStyle(sectionId) {
			let findSection = this.email_findOne(sectionId);

			let basic = findSection.attributes.basic;
			let advanced = findSection.attributes.advanced;

 			var border = basic.border;
            var borderTop,
                borderLeft,
                borderRight,
                borderBottom;
            var borderRadius;

			if (border && border.sides && border.radius) {
                borderTop = !border.sides.top ? 'none' : border.width + 'px ' + border.style + ' ' + border.color;
                borderLeft = !border.sides.left ? 'none' : border.width + 'px ' + border.style + ' ' + border.color;
                borderRight = !border.sides.right ? 'none' : border.width + 'px ' + border.style + ' ' + border.color;
                borderBottom = !border.sides.bottom ? 'none' : border.width + 'px ' + border.style + ' ' + border.color;

                borderRadius = border ?
                border.radius.leftTop+'px '+
                border.radius.topRight+'px '+
                border.radius.bottomRight+'px '+
                border.radius.bottomLeft+'px' : false;
            }

			var margin = basic.margin ?
				basic.margin.top+'px '+
				basic.margin.right+'px '+
				basic.margin.bottom+'px '+
				basic.margin.left+'px' : '0 auto';

			var padding = basic.padding ?
				basic.padding.top+'px '+
				basic.padding.right+'px '+
				basic.padding.bottom+'px '+
				basic.padding.left+'px' : false;

            var fontFamily = basic.font ? basic.font.fontFamily : 'inherit';
            var fontSize = basic.font ? basic.font.fontSize : 'inherit';
            // var textAlign = basic.font ? basic.font.textAlign : 'center';
            var fontStyling = basic.font ? basic.font.fontStyling : false;
            var color = basic.font ? basic.font.color : 'inherit';

            var boxShadow = advanced.shadows ?
                advanced.shadows.color+ ' ' +
                advanced.shadows.angle+'px '+
                advanced.shadows.distance+'px '+
                advanced.shadows.blur+'px '+
                advanced.shadows.spread+'px '+
                advanced.shadows.type : false;

			var maxWidth = this.sectionWidth(sectionId);

			var obj = {
				// borderColor: this.label.bg,
				backgroundColor: basic.backgroundColor || 'transparent',
				backgroundImage: basic.backgroundImage ? `url(//${basic.backgroundImage})` : 'none',
				backgroundPosition: 'center',
				backgroundSize: 'cover',
				backgroundRepeat: 'no-repeat',
				margin,
				padding,
                borderRadius,
                borderTop,
                borderLeft,
                borderRight,
                borderBottom,
				maxWidth,
				width: '100%',
                color,
                fontFamily,
                fontSize: fontSize + 'px',
                // textAlign,
                fontStyling,
                fontWeight: fontStyling.bold ? 'bold' : 'inherit',
                fontStyle: fontStyling.italic ? 'italic' : 'inherit',
                textDecoration: this.textDecoration(sectionId),
                boxShadow,
			};
			return obj
		},
    },
}
</script>
