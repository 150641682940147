<template>
    <div class="spinner">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
             style="margin: auto; background: none; display: block; shape-rendering: auto;"
             width="41px" height="41px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
            <circle cx="50" cy="50" fill="none" stroke="#4368e0" stroke-width="2" r="15"
                    stroke-dasharray="70.68583470577033 25.561944901923447" transform="rotate(73.7169 50 50)">
                <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="0.6134969325153374s"
                                  values="0 50 50;360 50 50" keyTimes="0;1"/>
            </circle>
        </svg>
    </div>
</template>

<script>
export default {
    name: 'Spinner'
}
</script>
