<template>
    <div class="__main-search" :class="[ {'searched': searchInput.length > 0 },]">

	    <div class="__input"  :class="{focused}">
		    <span class="__search-icon">
          <span class="__spinning" v-if="loading">
	          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="41px" height="41px" viewBox="0 0 100 100"
	               preserveAspectRatio="xMidYMid" style="margin: auto; background: none; display: block; shape-rendering: auto;">
		          <circle cx="50" cy="50" fill="none" stroke="#4368e0" stroke-width="2" r="15" stroke-dasharray="70.68583470577033 25.561944901923447"
		                  transform="rotate(73.7169 50 50)">
			          <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite"
			                            dur="0.6134969325153374s" values="0 50 50;360 50 50" keyTimes="0;1">
			          </animateTransform>
		          </circle>
	          </svg>
          </span>
			    <inline-svg v-else :src="require('./search_icon_gray.svg')" />
		    </span>
		    <input type="text" :name="`input${id}`" placeholder="Type to search..." class="main-search-input__"
		           @focus="onFocus" @blur="blurred" @input="onInput" @click="onFocus"
		           v-model="searchInput">
	    </div>

	    <tippy :to="`input${id}`" :ref="`tippy${id}`" theme="search-results" maxWidth="500px" placement="bottom"
	           :hideOnClick="true" :toggle="false" :interactive="true" trigger="manual" >
		    <div class="main-search-results-popup">

			    <div v-if="loading" class="__loading" >
				    <Loading   />
			    </div>

			    <div v-else class="search_result_panels_wrpr scroll">

				    <div class="items_found_number" v-if="searchSumm > 0">{{ searchSumm }} results found</div>

				    <div class="search_result_panel" v-if="customers.length > 0">
					    <div class="result_panel_header">
						    <h3>Customers</h3>
						    <router-link :to="'/customers'">View all</router-link>
					    </div>
					    <div class="result_panel_body">
						    <ul>
							    <li v-for="(item, index) in customers" :key="index">
								    <a @click="goToLink(`/customers?id=${item.id}`)"
								       v-html="textBolded(item['first_name'] ? item['first_name'] + ' ' + (item['last_name']||'') : item.optin_email||'unknown customer')">

								    </a>
							    </li>
						    </ul>
					    </div>
				    </div>

				    <div class="search_result_panel" v-if="funnels.length > 0">
					    <div class="result_panel_header">
						    <h3>Funnels</h3>
						    <router-link :to="'/funnels'">View all</router-link>
					    </div>
					    <div class="result_panel_body">
						    <ul>
							    <li v-for="(item, index) in funnels" :key="index">
								    <a v-if="item['funnel_type'] === 0" @click="goToLink('/funnel/'+item.id+'/')" v-html="textBolded(item.name)"></a>
								    <a v-if="item['funnel_type'] === 1" @click="goToLink('/wfunnel/'+item.id+'/')" v-html="textBolded(item.name)"></a>
							    </li>
						    </ul>
					    </div>
				    </div>

				    <div class="search_result_panel" v-if="apps.length > 0">
					    <div class="result_panel_header">
						    <h3>Apps</h3>
						    <router-link :to="'/apps'">View all</router-link>
					    </div>
					    <div class="result_panel_body">
						    <ul>
							    <li v-for="(item, index) in apps" :key="index">
								    <a @click="goToLink('/apps/'+item.id)" v-html="textBolded(item.name)"></a>
							    </li>
						    </ul>
					    </div>
				    </div>

				    <div class="search_result_panel" v-if="integrations.length > 0">
					    <div class="result_panel_header">
						    <h3>Integrations</h3>
						    <router-link :to="'/integrations'">View all</router-link>
					    </div>
					    <div class="result_panel_body">
						    <ul>
							    <li v-for="(item, index) in integrations" :key="index">
								    <a @click="goToLink('/integrations/'+item.id)" v-html="textBolded(item.name)"></a>
							    </li>
						    </ul>
					    </div>
				    </div>

				    <div class="search_no_result" v-if="searchSumm < 1">
					    <h3>No results for “{{searchInput}}”</h3>
					    <p><b>Search tips:</b> Some search terms require an exact match. <br>
						    Try typing the entire term, or use a different word or phrase.</p>
				    </div>
			    </div>

			    <div class="search_result_box_footer">
				    <div class="left">
					    <span>Explore other resources:</span>
				    </div>
				    <div class="right">
					    <a href="https://community.funnelish.com" target="_blank">
						    <inline-svg :src="require('@/assets/img/community_icon.svg')"/> Community
					    </a>
					    <a href="https://docs.funnelish.com" target="_blank">
						    <inline-svg :src="require('@/assets/img/check-tutorials.svg')"/> Docs & tutorials
					    </a>
				    </div>
			    </div>
		    </div>
	    </tippy>
    </div>
</template>

<script>
import API from "@/api"
import Loading from "@/components/Loading.vue"
import Spinner from "@/components/Spinner";
import debounce from "debounce";
let id = 0;
export default {
    name: "MainSearch",
    components: {
	    Spinner,
        Loading
    },
    mixins: [API],
    // props: ['value'],
    data: function() {
        return {
					id:++id,
	        tippy:null,
	        focused:false,
          loading: false,
          searchInput: '',
          funnels: [],
          apps: [],
          customers: [],
          integrations: [],
          products: [],
        };
    },
    watch:{
        async searchInput(val){
					try {
						this.funnels = [];
						this.apps = [];
						this.customers = [];
						this.integrations = [];
						this.products = [];

						if (!val) return;

						this.loading = true;
						var result = await this.api.search.get(val);
						// console.log(result);
						this.funnels = result.funnels;
						this.apps = result.apps;
						this.customers = result.customers;
						this.integrations = result.integrations;
						this.products = result.products;
					} catch (e) {

					} finally {
						this.$nextTick(() => {
							this.loading = false;
						});
					}
        }
    },
    computed: {
        notificationsOpen() {
            return this.$store.state.notificationsOpen;
        },
        searchSumm(){
            return (this.funnels.length + this.apps.length + this.customers.length + this.integrations.length + this.products.length);
        },
    },
    methods: {
			onFocus() {
				this.focused = true;
				if (this.searchInput && this.tippy) {
					this.tippy.show();
				}
			},
	    blurred() {
		    this.focused = false;
		    if (this.searchInput && this.tippy) {
			    this.tippy.hide();
		    }
	    },
	    onInput(){
				if (!this.tippy) return;

		    if (this.searchInput) {
					this.tippy.show();
		    } else {
			    this.tippy.hide();
		    }
	    },
        goToLink(val){
            document.location.href = window.location.origin + val;
        },
        textBolded(val) {
            var strRegExp = new RegExp(this.searchInput, 'g');
            return val.replace(strRegExp, '<b>'+this.searchInput+'</b>');
        },
	    onEscape() {
				// TODO
	    }
    },
  created() {
  },
	mounted() {
		this.tippy = this.$refs[`tippy${this.id}`].tippy()
	},
}
</script>

<style lang="scss" scoped>
.__main-search {
	max-width: 500px;
	min-width: 500px;
	background: transparent;
	display: flex;
	position: relative;
	flex-direction: column;
	.__input {
		flex: 1;
		width: 100%;
		position: relative;
		background:transparent;
		border: 1px solid #E4E6F1;
		border-radius: 38px;
		max-height: 28px;
		min-height: 28px;
		display: flex;
		flex-direction: row;
		gap: 4px;
		overflow: hidden;

		input {
			position: absolute;
			left: 0;
			width: 100%;
			height: 100%;
			top: 0;
			padding-left: 40px;
			background: rgba(255, 255, 255, 0.7);
		}

		&:hover {
			border: 1px solid $dark-grey;
		}

		&.focused {
			border: 1px solid $blue;
		}

		.__search-icon {
			display: flex;
			position: relative;
			width: 40px;
			flex-direction: column;
			align-items: center;
			align-self: center;
			pointer-events: none;
			z-index: 1;

			svg {

			}
		}
	}

}
</style>
<style lang="scss">
.main-search-results-popup {
	width: 100%;
	left: 0px;
	//box-shadow: 0px 5px 20px rgba(37, 42, 50, 0.2);
	border-radius: 8px;
	border: 0;
	padding: 0;
	overflow: hidden;

	.__loading {
		min-height: 100px;
		display: flex;
		flex-direction: column;
		align-items: center;
		background: white;
		z-index: 1;
	}
	.search_result_box_footer {
		padding: 20px 25px;
		background: #F6F9FE;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.left span {
			font-weight: normal;
			font-size: 12px;
			line-height: 150%;
			letter-spacing: 0.02em;
			//text-transform: uppercase;
			color: #636A7B;
		}
		.right {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			a {
				font-weight: normal;
				font-size: 12px;
				line-height: 150%;
				letter-spacing: 0.02em;
				color: #636A7B;
				display: flex;
				align-items: center;
				white-space: nowrap;
			}
			a:hover {
				color: #4368E0;
			}
			a+a {
				margin-left: 34px;
			}
			a svg {
				margin-right: 10px;
				height: 20px;
				width: 20px;
			}
		}
	}

	.items_found_number {
		padding: 15px 25px 0;
		font-weight: normal;
		font-size: 13px;
		line-height: 150%;
		letter-spacing: 0.02em;
		color: #C5C7D2;
		text-align: left;
	}

	.search_result_panel {
		padding: 10px;
		.result_panel_header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0 10px 5px;
			border-bottom: 1px solid #EBEFF6;
			h3 {
				font-weight: bold;
				font-size: 14px;
				line-height: 150%;
				letter-spacing: 0.02em;
				color: #C5C7D2;
			}
			a {
				font-weight: 500;
				font-size: 12px;
				line-height: 150%;
				letter-spacing: 0.02em;
				color: #4368E0;
			}
			a:hover {
				text-decoration: underline;
			}
		}
		.result_panel_body {
			padding: 15px 10px 0;
			ul {
				display: flex;
				flex-direction: column;
				gap: 4px;
			}
			ul li {
				width: 100%;
				display: flex;
				a {
					width: 100%;
					cursor: pointer;
					font-size: 14px;
					line-height: 150%;
					letter-spacing: 0.02em;
					color: $dark-grey;
					border-radius: 4px;
					padding: 5px 20px 5px 10px;
					&:hover {
						background: $hover-light-blue;
					}
				}
			}
			ul li a b {
				font-weight: bold;
				color: #252A32;
				transition: 0.15s all ease-in-out;
			}
			ul li a:hover,ul li a:hover b {
				color: $blue;
			}

		}
	}

	.search_result_panels_wrpr {
		max-height: 376px;
		overflow: auto;
		width: 100%;
	}



	.search_result_box {
		max-width: 789px;
		left: 30px;
	}

	.search_no_result {
		text-align: center;
		padding-top: 15px;
	}

	.search_no_result h3 {
		font-weight: 500;
		font-size: 18px;
		line-height: 150%;
		letter-spacing: 0.02em;
		color: #636A7B;
		margin-bottom: 15px;
	}

	.search_no_result p {
		font-size: 14px;
		line-height: 150%;
		letter-spacing: 0.02em;
		color: #97AACD;
	}

}
</style>
