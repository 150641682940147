import {
    border, margin, cssId, cssClasses, id, backgroundColor, props, inputType, label, label2, labelFont, font3, sticky,
} from './fields/'

export default {
    tag: 'formElement',
    labelTitle: '',
    labelColor: '',
    id,
    attributes: {
        basic: {
            inputType,
            margin,
            props,
            label,
            label2,
            labelFont,
            font: font3,
            backgroundColor,
            border,
        },
        advanced: {
            cssId,
            cssClasses,
            sticky

        },
    },
}
