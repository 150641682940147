<template>
	<div class="funnel-entry-wrapper" draggable="true" :ref="'root'+id">
	<div class="entry panel-item funnel-entry">

		<div class="show-sub-funnels" >
			<a v-if="funnel.funnel_type===1" @click="subFunnelsOpen=!subFunnelsOpen"
			   v-tippy="{theme:'small-black'}" content="Geo-funnels"
			   :class="{'panel_item_btn pinn_btn':true, 'active':subFunnelsOpen}">
				<inline-svg v-if="subFunnelsOpen" :src="require('@/assets/img/chevron-top.svg')"/>
				<inline-svg v-else :src="require('@/assets/img/arrow-bottom.svg')"/>
			</a>
		</div>

		<a @click.prevent="redirectToFunnels"
       :href="funnel.funnel_type ? '/wfunnel/'+funnel.id : '/funnel/'+funnel.id" class="list-item-link">

			<div class="products_panel_first_cell">
				<div class="products_panel_img_wrpr" :data-funnel-type="funnel.funnel_type">
					<img v-if="funnel.funnel_type" draggable="false"
					            :src="require('@/assets/img/funnel_panel_img_2.svg')" alt="Funnelish"
					     content="Funnelish" v-tippy="{theme:'small-black'}" />
					<img v-else :src="require('@/assets/img/funnel_panel_img_1.svg')" alt="ClickFunnels"
					            content="ClickFunnels" v-tippy="{theme:'small-black'}" />
				</div>
			</div>
			<div class="panel-item-cell panel-item-title">
				<div class="list-item-title">{{ funnel.name }}</div>
				<span>{{ date(funnel.updated_at).diff }}</span>
			</div>
			<template v-if="loadingStats">
<!--				<div class="panel-item-cell stats-entry">-->
<!--					<div class="cell-value">-->
<!--						<v-spinner></v-spinner>-->
<!--					</div>-->
<!--				</div>-->
<!--				<div class="panel-item-cell stats-entry">-->
<!--					<div class="cell-value">-->
<!--						<v-spinner></v-spinner>-->
<!--					</div>-->
<!--				</div>-->
				<div class="panel-item-cell stats-entry">
					<div class="cell-value">
						<v-spinner></v-spinner>
					</div>
				</div>
				<div class="panel-item-cell stats-entry">
					<div class="cell-value">
						<v-spinner></v-spinner>
					</div>
				</div>
				<div class="panel-item-cell stats-entry">
					<div class="cell-value">
						<v-spinner></v-spinner>
					</div>
				</div>
			</template>
			<template v-else>
				<div class="panel-item-cell stats-entry">
					<div class="cell-title">Revenue</div>
					<div class="cell-value">{{ formattedRevenue }}
					</div>
				</div>
				<div class="panel-item-cell stats-entry">
					<div class="cell-title">Uniq. Orders</div>
					<div class="cell-value">{{ stats.orders || 0 }}</div>
				</div>
				<div class="panel-item-cell stats-entry">
					<div class="cell-title">Uniq. Visits</div>
					<div class="cell-value cell-info">
						<div class="small-text">{{ stats.sessions || 0 }}</div>
<!--						<div class="delta delta-up">{{ // stats.visits || 0 }}</div>-->
					</div>
				</div>
			</template>
		</a>

		<div v-if="isDeleted" class="panel_item_btn_wrpr">
			<v-button label="Restore" border slim />
		</div>
		<div v-else-if="!noactions" class="panel_item_btn_wrpr">
			<a @click="pinnFunnel(funnel)"
			   v-tippy="{theme:'small-black'}" :content="funnel.pinned?'Unpin':'Pin'"
			   :class="{'panel_item_btn pinn_btn':true, 'active':funnel.pinned}">
				<v-spinner v-if="pinning" />
				<inline-svg v-else :src="require('@/assets/img/pinn_icon.svg')"/>
			</a>
			<a @click="cloneFunnelOpen=true"
			   v-tippy="{theme:'small-black'}" content="Clone funnel"
			   class="panel_item_btn copy_btn" :class="{'disabled':!is('funnels')}">
				<inline-svg :src="require('@/assets/img/copy_icon.svg')"/>
			</a>
			<portal to="popup">
				<clone-funnel-popup v-if="cloneFunnelOpen" @close="cloneFunnelOpen=false"
				                     :funnel="funnel" />
			</portal>
			<a @click="showFunnelDeletePopup(funnel.id, funnel)"
			   v-tippy="{theme:'small-black'}" content="Delete funnel"
			   class="panel_item_btn delete_btn"
			   :class="{'disabled':!is('funnels')}">
				<inline-svg :src="require('@/assets/img/delete_icon.svg')"/>
			</a>
		</div>

	</div>

		<template  v-if="funnel.funnel_type==1">
			<v-spinner v-if="loadingSubfunnels" />
			<div class="entries-table sub-funnels" v-else-if="subFunnelsOpen">
				<ABFunnelEntry :funnel="f" v-for="f in abFunnels" :key="f.id"
				               :loading="splitting"
				               @startSplitting="startSplitting(f)"
				               @showFunnelDeletePopup="$emit('showFunnelDeletePopup', f.id, f)" />

				<div class="ab-funnel new" >
					<div class="ab-linkage">
					</div>
					<v-button :class="{'splitting':splitting}" v-tippy="{theme:'small-black'}" content="Create a new Geo-funnel"
					          :locked="!is('funnels')"
					          flat @click="startSplitting(funnel)">
						<div v-if="splitting" class="splitting-process">
							<label>Cloning...</label>
							<div v-if="progressNumber < 100" class="saving-process">
								<div class="prog-line">
									<div class="prog-line-inner" v-bind:style="{width: progressNumber +'%'}"></div>
								</div>
							</div>
							<v-spinner v-else />
						</div>
						<template v-else>
							<inline-svg :src="require('@/assets/img/pin-round.svg')"/>
							New Geo-funnel
						</template>
					</v-button>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
import API from "@/api";
import {formatPrice, getSymbol} from "@/helpers/currencies";

import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import VSpinner from "@/components/base/v-spinner";
import VButton from "@/components/base/v-button";
import Loading from "@/components/Loading";
import ABFunnelEntry from "@/components/base/ab-funnel-entry";
import CloneFunnelPopup from "@/components/popups/clone-funnel-popup";
import {is} from "@/helpers/roles";
import { Draggable } from 'vue-smooth-dnd'
import shortValue from "@/helpers/shortValue";

TimeAgo.addLocale(en);
const timeAgo = new TimeAgo("en-US");
let id = 1;
export default {
	name: "FunnelEntry",
	components: {Draggable, CloneFunnelPopup, ABFunnelEntry, Loading, VButton, VSpinner},
	props: ["funnel", "noactions", "stats", "loadingStats", 'loadingSubfunnels', 'subFunnels'],
	mixins: [API],
	data() {
		return {
			id:id++,
			cloneFunnelOpen: false,
			progressNumber: 60,
			closed: false,
			splitting: false,
			nameEditing: false,
			saved: false,
			subFunnelsOpen: false,
			pinning:false,
		}
	},
	computed: {
		abFunnels() {
			if (!this.subFunnels || !this.funnel.funnel_group_id) return [];
			return this.subFunnels.filter(f => f.funnel_group_id===this.funnel.funnel_group_id)
		},
		formattedRevenue() {
      let v = shortValue(this.stats.revenue || 0, 2, false)
			return formatPrice(this.funnel.currency_code, v)
		},
		isDeleted() {
			return !!this.funnel.deleted_at
		}
	},
	methods: {
		is(role){
			return is(role)
		},
		updateName(ev){
			this.saved=false
		},
		async saveName(ev) {
			this.page.name = this.$refs['page_entry_name_'+this.id].innerText;
			await this.saveProps();
		},
		async saveProps() {
			try {
				this.saving = true
				let result = await this.api.wsteps.savePageProps(this.page.funnel_id, this.step.id, this.page.id, {
					name: this.page.name,
					ab_percent: this.page.ab_percent,
					ab_type: this.step.ab_type,
					ab_geo: this.page.ab_geo,
				});
				if (result.error) throw result;
				this.saved = true;
			} catch (e) {
				this.$warn(e);
			} finally {
				this.saving = false
			}
		},
		nameBlur(){
			this.nameEditing = false;
		},
		startSplitting(funnel) {
			this.splitting = true;
			this.progressNumber = 0;
			this.splitFunnel(funnel);
			this.progressIncrement();
		},
		progressIncrement() {
			let progressIncrementInterval = setInterval(() => {
				if (this.progressNumber < 100) {
					this.progressNumber += 1;
				} else {
					clearInterval(progressIncrementInterval);
				}
			}, 50);
		},
		async redirectToFunnels() {
			//fetch steps
			let pathString = '/funnel/' + this.funnel.id;
			if (this.funnel.funnel_type) { //Funnel+
				pathString = '/wfunnel/' + this.funnel.id
			}
			this.$router.push(pathString);
		},
		currencySymbol(code) {
			return code.length ? getSymbol(code) : '$';
		},
		date(string) {
			var date = new Date(string);
			var monthArr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
			var month = monthArr[date.getMonth()];
			var day = date.getDate();
			var hours = date.getHours();
			var minutes = date.getMinutes();

			var format = `${month} ${day} ${hours}:${minutes}`;
			var diff = timeAgo.format(date);
			//Feb 26 16:58
			return {format, diff};
		},
		async splitFunnel(funnel) {
			try {
				let newFunnel = await this.api.funnels.abSplit(funnel.id);
				if (newFunnel.error) throw newFunnel;

				this.funnel.funnel_group_id = newFunnel.funnel_group_id; // fix first funnel without group id.
				this.subFunnels.push(newFunnel);
			} catch (e) {
				this.$error(e);
			} finally {
				this.splitting = false;
			}
		},
		async pinnFunnel(funnel) {
			try {
				this.pinning = true;
				let res = await this.api.funnels.pin(funnel.id, !funnel.pinned);
				if (!res.error)
					funnel.pinned = !funnel.pinned;
			} catch (e) {
				this.$error(e)
			} finally {
				this.pinning = false;
			}
		},
		showFunnelDeletePopup(funnelId, funnel) {
			this.$emit('showFunnelDeletePopup', funnelId, funnel);
		},
		onDragStart(e) {
			let r = this.$refs['root'+this.id];
			r.classList.add('__dragging')

			let crt = document.createElement('div')
			crt.className = 'funnel-entry-wrapper __ghost'
			// <span class="__tip">Drag me into a funnels' folder</span>
			crt.innerHTML += `<span>${this.funnel.name}</span>`
			crt.style.height = "30px";
			crt.style.width = "200px";
			crt.style.position = "absolute";
			crt.style.top = "0";
			crt.style.left = "0";
			crt.style.zIndex = "-999";
			document.body.appendChild(crt);
			e.dataTransfer.setDragImage(crt, 100, 15);

			e.dataTransfer.setData("funnel", JSON.stringify(this.funnel));

			window.setTimeout(function () {
				document.body.removeChild(crt);
			}, 100);
		},
		onDragEnd(e) {
			let r = this.$refs['root'+this.id];
			r.classList.remove('__dragging')
		},
	},
	mounted() {
		let r = this.$refs['root'+this.id];
		r.addEventListener("dragstart", this.onDragStart, false);
		r.addEventListener("dragend", this.onDragEnd, false);
	},
	beforeDestroy() {
		let r = this.$refs['root'+this.id];
		r.removeEventListener("dragstart", this.onDragStart);
		r.removeEventListener("dragend", this.onDragEnd);
	}
};
</script>
<style lang="scss">
.funnel-entry-wrapper {
	&.__dragging {
		opacity: 0.5;
		.sub-funnels {
			display: none;
		}
	}
	&.__ghost {
		border-radius: 8px;
		background: $light-blue;
		display: flex;
		flex-direction: column;
		border: 1px solid $grey;
		margin: 0;
		cursor: grabbing;
		align-items: start;
		justify-content: center;
		color: $blue;
		opacity: 0.7;
		gap: 5px;
		padding: 2px 8px;
		font-size: 12px;
		font-weight: 600;
		overflow: hidden;
		box-shadow: none;
		white-space: nowrap;
		> span {
			text-overflow: ellipsis;
			overflow: hidden;
			width: 100%;
		}
		.__tip {
			color: $dark-grey;
			font-size: 10px;
			font-weight: 500;
		}
	}
}
</style>
<style lang="scss" scoped>
.funnel-entry {

	.stats-entry {

	}
}

.funnel-entry-wrapper {
	.show-sub-funnels {
		margin-left: -15px;
		min-width: 50px;
	}
	.sub-funnels {
		overflow: hidden;
		padding-top: 10px;
	}
	.splitting-process {
		width: 200px;
		padding: 2px;
		border-radius: 5px;
		display: flex;
		flex-direction: row;
		align-items: center;
		label {
			font-size: 12px;
		}
		.saving-process {
			width: 100%;
			.prog-line {
				height: 6px;
				margin: 0 0 0 10px;
			}
			.prog-line-inner {
				height: 6px;
				background: #344989;
			}
		}
	}
}
.ab-funnel {
	display: flex;
	flex-direction: row;
	align-items: center;
	border: 1px solid $light-grey;
	margin-bottom: 10px;
	margin-left: 120px;
	border-radius: 4px;
	position: relative;

	&.new {
		left: 1px;
		border: none;
		margin-top: 10px;
		.button {
			display: flex;
			flex-direction: row;
			border: 1px solid $light-grey;
			color: #40454d;
			padding: 4px 25px 4px 15px;
			border-radius: 8px;
			&.splitting {
				pointer-events: none;
			}
			svg {
				margin-right: 10px;
				height: 16px;
				width: 16px;
				path {
					fill: #40454d;
				}
			}
			&:hover {
				color: $blue;
				svg path {
					fill: $blue;
				}
			}
		}
	}

	.ab-linkage {
		position: absolute;
		left: -100px;
		border-left: 1px solid $light-grey;
		border-bottom: 1px solid $light-grey;
		top: 0;
		height: 50%;
		width: 100px;
		border-radius: 0 0 0 10px;

		&:before {
			height: 60px;
			width: 1px;
			background: $light-grey;
			top: -50px;
			content: "";
			position: absolute;
			left: -1px;
			z-index: -1;
		}

	}
	.img {
		border: 1px solid #E4E6F1;
		border-radius: 5px;
		overflow: hidden;
		width: 40px;
		display: flex;
		align-items: flex-start;
		height: 40px;
		margin: 4px;
		min-width: 40px;
		img {
			object-fit: cover;
			width: 100%;
		}
	}
	.name {
		flex: 1;
		margin-left: 5px;
		//h3 {
		//	margin: 2px 0;
		font-weight: 500;
		font-size: 14px;
		line-height: 150%;
		letter-spacing: 0.02em;
		color: #252A32;
		//}
	}
	.btns {
		flex: none;
		display: flex;
		flex-direction: row;
		align-items: center;
		.button {
			margin-right: 15px;
			padding: 10px;
			color: #C5C7D2;
			svg {
				margin: auto;
			}
			svg, path {
				color: #C5C7D2;
			}
		}
	}
}

@media (max-width: 1100px) {
	.funnel-entry {
		.stats-entry {
			display: none;
		}
	}
}
</style>

