<template>
    <div class="popup_wrpr_inner">
        <div class="popup popup_2">
            <div class="popup_header">
                <h3>Add new step</h3>
                <a @click="closeAddNewStepPopup" class="close_popup_btn">
                    <inline-svg :src="require('@/assets/img/popup_close.svg')"/>
                </a>
            </div>

            <div class="content" v-if="popupStep == 1">
                <div class="wrap_select_step_type">
                    <label class="form_steps_label step_1">
                        <span class="icon"></span> Select a step type
                    </label>

                    <div class="row step-types-list">
                          <SelectButton label="Checkout" :src="require('@/assets/img/Order-Form.svg')"
                                        :value="1" @click="selectType(1)"></SelectButton>
                          <SelectButton label="Upsell" :src="require('@/assets/img/upsell-2.svg')"
                                        :value="2" @click="selectType(2)"></SelectButton>
                          <SelectButton label="Thank you page" :src="require('@/assets/img/Thank You Page.svg')"
                                        :value="3" @click="selectType(3)"></SelectButton>
		                    <SelectButton label="Downsell" :src="require('@/assets/img/Downsell.svg')"
		                                  :value="4" @click="selectType(4)"></SelectButton>
                          <SelectButton label="Landing page" :src="require('@/assets/img/Optin Page.svg')"
                                        :value="5" @click="selectType(5)"></SelectButton>
                          <SelectButton label="Other" :src="require('@/assets/img/Other.svg')"
                                        :value="6" @click="selectType(6)"></SelectButton>
                    </div>
                </div>
            </div>

            <div class="content" v-if="popupStep == 2">
                <div class="wrap_input">
                    <label class="form_steps_label step_2">
                        <span class="icon"></span> Select a step type
                        <span class="check">
                            <span class="icon_check">
                                <inline-svg :src="stepTypes[this.newStep.step_type-1].img"/>
                            </span>
                            <span class="title">{{stepTypes[this.newStep.step_type-1].title}}</span>
                        </span>
                    </label>

                    <label class="form_steps_label step_1">
                        <span class="icon"></span> Your step details
                    </label>

                  <input-field label="Name" v-model="newStep.name" required></input-field>

                  <input-field label="Step URL" v-model="newStep.url" required
                               @input="stripUrl($event, 'url')" />

                  <input-field label="Step variant URL (optional)" v-model="newStep.url2"
                               @input="stripUrl($event, 'url2')" />
                </div>
            </div>
            <div class="popup_btn_wrpr step_2 active" v-if="popupStep == 2">
                <a @click="popupStep = 1" class="btn brdr_btn popup_back_btn">Back</a>

              <v-button label="Continue" @click="addNewStep" :loading="isSaving" :disabled="validateStep.confirm"></v-button>
            </div>
        </div>
    </div>
</template>

<script>
import API from "@/api"
import stepTypes from "@/helpers/stepTypes"
import InputField from "@/components/base/input-field";
import SelectButton from "@/components/base/select_button";
import VButton from "@/components/base/v-button";
export default {
    name: "NewStepPopup",
    components: {
      VButton,
      SelectButton,
        InputField
    },
    mixins: [API],
    props: ['funnel', 'steps', 'popupStepTwoNumber'],
    data: function() {
        return {
            popupStep: 1,
          isSaving: false,
            newStep: {
	            url: "",
	            url2:"",
	            "step_type": 1,
	            "funnel_id": parseInt(this.funnel.id),
	            "user_id": null,
	            ob: null,
	            "order_index": parseInt(this.steps.length + 1),
	            "created_at": null,
	            "updated_at": null,
	            "deleted_at": null,
	            "thumbnail_hash": null,
	            name: ""
            },
            stepTypes: stepTypes,
            visited: {
                name: false,
                url: false
            }
        };
    },
    computed: {
        validateStep() {
            var name = this.newStep.name.trim();
            var url = this.newStep.url.trim();
             var result = {
                confirm: (!name.length || !url.length),
                name: !name.length,
                url: !url.length
            }
            return result;
        },
        isPlus() {
            return this.funnel.funnel_type
        }
    },
    methods: {
        stripUrl(ev, field) {
	          let url = ev.replace(/(^\w+:|^)\/\//, ''); // remove all protocols, keep www.
            this.newStep[field] = url.trim();
        },
        selectType(type) {
            this.newStep.step_type = type;
            this.popupStep = 2
        },
        closeAddNewStepPopup() {
            this.$store.commit("closeAddNewStepPopup");
        },
        async addNewStep() {
          try {
            this.isSaving = true;
            var {id} = this.$route.params;
            var result = await this.api.steps.create(this.newStep);

            if (!result.error) {
              this.$store.dispatch('ADD_STEP', result);
              this.$router.push({path: `/funnel/${id}/steps/${result.id}`})
            } else {
            	this.$error(result, result.info.data);
            }
            this.closeAddNewStepPopup();
          } catch (e) {
          	this.$error(e);
          } finally {
            this.isSaving = false;
          }
        }
    },
    mounted() {
        if(this.popupStepTwoNumber) {
            this.selectType(this.popupStepTwoNumber);
        }
    },
    created(){
    }
}
</script>
