import {
    shadows, buttonShadows, // textShadows,
    border, margin, linkUrl, title, cssId, cssClasses, id, backgroundColorButton, sticky
} from './fields/'

let hoverFont = {
    comp: 'FontPanel',
    value: {
        fontFamily: null,
        fontSize: null,
        color: null, // When null it falls-back to the CORE.CSS styles.
        fontStyling: {
            bold: null,
            italic: null,
            'line-through': null,
            underline: null,
        },
        textAlign: null
    }
}


export default {
    tag: 'buttonV2',
    labelTitle: '',
    labelColor: '',
    id,
    editLock: false,
    content:`<p>Call To Action&nbsp;<span class="ri-arrow-right-line " data-src="ri-arrow-right-line" data-type="icon"></span></p>`,
    attributes: {
        basic: {
            align: { title: 'Alignment', comp: 'alignment', value: {mobile:null,desktop:'center',tablet:null} },
            margin,
            // content: { title: 'Content', comp: 'RichTextInput', value: 'Call To Action' },
            linkUrl,
            font:{
                title: 'Default font',
                comp: 'FontPanel',
                value: {
                    fontFamily: '',
                    fontSize: 20,
                    color: null, // When null it falls-back to the CORE.CSS styles.
                    fontStyling: {}, // not supported by Buttons (uses p formatting).
                    textAlign: 'center'
                }
            },
            backgroundColor: backgroundColorButton,
            lineHeight: {
                title:'Line spacing',
                comp: 'SmallNumber',
                value: {
                    append: 'x',
                    placeholder: 'normal',
                    number: 1,
                    min: 0
                }
            },
            letterSpacing: {
                title:'Letter spacing',
                comp: 'SmallNumber',
                value: {
                    append: 'px',
                    placeholder: 'normal',
                    number: null,
                }
            },
            maxWidth: {
                title:'Max width',
                comp: 'inputNumber',
                value: {
                    mobile:null,
                    desktop:{
                        append: 'px',
                        placeholder: 'none',
                        number: null,
                    },
                    tablet:null,
                }
            },
            border,
            shadows,
            textShadows: buttonShadows
        },
        hover: {
            backgroundColor: {title: 'Background color', comp: 'colorPicker2', value: null},
            border: {
                title: 'Border',
                comp: 'border',
                value: null,
                //     {
                //     width: null,
                //     color: null,
                //     style: 'solid',
                //     sides: { top: true, left: true, right: true, bottom: true },
                //     radius: { topLeft: null, topRight: null, bottomRight: null, bottomLeft: null },
                // }
            },
            font: {title: 'Default font', ...hoverFont},
            shadows,
            textShadows: buttonShadows
        },
        advanced: {
            title,
            cssId,
            cssClasses,
            padding:{
                title: 'Padding',
                comp: 'marginPadding',
                value: { top: 15, left: 30, right: 30, bottom: 15 }
            },
            sticky

        },
    },
}

