<template>
	<popup class="export_orders_popup" title="Export orders" @close="closePopup">
		<template v-slot:content>
			<div class="automation-progress-panel popup_progress_panel">
				<div class="item-prog" :class="{deactivated: step.status === 'deactivated'}" v-for="step in steps" :key="step.id">
					<div class="icon-status" :class="{'active': step.status === 'active' }">
						<inline-svg v-if="step.status === 'active' || step.status === 'deactivated'" :src="require('@/assets/img/circle-arrow-right.svg')"/>
						<inline-svg v-if="step.status === 'completed'" :src="require('@/assets/img/circle-checked.svg')"/>

						<inline-svg v-if="step.status === 'error'" :src="require('@/assets/img/circle-error.svg')"/>
					</div>
					<div class="item-prog-text">
						<span>{{step.status === 'error' ? 'Error' : step.name}}</span>
					</div>
				</div>
			</div>

			<div class="content" style="overflow: initial;" :class="{'min_h' : pickerOpen}" v-if="stepState === 1">
				<label class="switch_checkbox_item radio_box_item">
					<input type="radio" name="orders_by" value="by_date" v-model="ordersBy">
					<div class="switch_checkbox">
						<span></span>
					</div>
					Orders by date
				</label>
				<div class="datapicker_wrpr" v-if="ordersBy === 'by_date'">
					<date-range-picker ref="date_range_picker" :append-to-body="true" :autoApply="true"
					                   :always-show-calendars="true" single-date-picker="range"
					                   v-model="dateRange" :calculate-position="updateDateRangePickerPosition"
							@toggle="pickerOpen = !pickerOpen">
					</date-range-picker>
				</div>
				<label class="switch_checkbox_item radio_box_item">
					<input type="radio" name="orders_by" value="all" v-model="ordersBy">
					<div class="switch_checkbox">
						<span></span>
					</div>
					All orders
				</label>
			</div>
			<div class="content" v-if="stepState === 2">
				<label class="form_steps_label step_1">
					<span class="icon"></span> Exporting your orders...
				</label>
				<div class="saving-process">
					<div class="prog-line">
						<div class="prog-line-inner" v-bind:style="{width: progressNumber +'%'}"></div>
					</div>
					<span class="btm-info">Please wait while the process finishes...</span>
					<!-- <p>P.S. You can close this dialog and let the process to complete at the background</p> -->
				</div>
			</div>
			<div class="content" v-if="stepState === 3" style="align-items: center;">
				<div class="form-group">
					<p style="font-size: 16px; font-weight: bold; margin: 0 0 10px;">Your orders CSV is ready to download</p>
				</div>
				<a @click="downloadEvent()" class="btn">Download .CSV of orders</a>
			</div>
		</template>

		<template v-slot:actions>
			<v-button label="Export" @click="progressIncrement()" v-if="stepState === 1"></v-button>
			<v-button label="Cancel" @click="closePopup" border></v-button>
		</template>
	</popup>

</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import Popup from "@/components/base/popup";
import VButton from "@/components/base/v-button";
import { saveAs } from 'file-saver';
import API from '@/api';
import moment from "moment";

export default {
    name: "ExportOrders",
		mixins: [API],
    components: {
	    VButton,
	    Popup,
      DateRangePicker,
    },
		props:['funnel'],
    data() {
        return {
					exporting:false,
        	exportData: {
						id: 0,
		        funnel_id: 0,
	        },
          steps: [
              {
                  id: 1,
                  name: "Select orders to export",
                  step: "Step1",
                  status: "active"
              },
              {
                  id: 2,
                  name: "Exporting your orders...",
                  step: "Step2",
                  status: "deactivated"
              },
              {
                  id: 3,
                  name: "Exporting completed",
                  step: "Step3",
                  status: "deactivated"
              }
          ],
          stepState: 1,
          dateRange: {
              startDate: moment().startOf('day'),
              endDate: moment().endOf("day"),
          },
          ordersBy: 'all',
          pickerOpen: false,
          progressNumber: 0,
      }
    },
    // watch: {
    //     ordersBy(val){
    //         this.$emit('ordersByTypeChange', this.ordersBy);
    //     }
    // },
    methods: {
    	updateDateRangePickerPosition() {
		    let r = this.$refs.date_range_picker.$el.getBoundingClientRect();
		    let popup = window.document.querySelector('.daterangepicker');
		    if (!popup) return;
		    popup.style.top = (r.top+r.height)+'px';
    		return r;
	    },
        nextStep(numberStep) {
            this.stepState = numberStep;
            if (numberStep !== 1) {
                this.steps[numberStep-2].status = "completed";
            } else  {
                this.steps[numberStep-1].status = "deactivated";
            }
            this.steps[numberStep-1].status = 'active';

            // if(numberStep === 3) {
            //     this.steps[numberStep-1].status = "completed";
            //     this.exportEvent();
            // }
        },
        async progressIncrement() {
		        this.exportEvent();
            this.nextStep(2)

            let progressIncrementInterval = setInterval(() => {
                if (this.progressNumber !== 100) {
									if (this.progressNumber>95 && this.exporting) {
										// halt until exporting is done.
									} else
                    this.progressNumber += 1;
                } else {
                    clearInterval(progressIncrementInterval);

                    this.nextStep(3);
                }
            }, 10);
        },
        downloadEvent(){
	        this.exportCSV(true)
            this.closePopup();
        },
        backStep(numberStep) {
            this.stepState = numberStep;
            this.steps[numberStep-1].status = 'active';
            this.steps[numberStep].status = 'deactivated';
        },
        exportEvent(){
	    		this.exportCSV(false)
        },
        closePopup(){
            this.$emit('closeExportPopup');
        },

	    async exportCSV(downloadType = false) {
				try {
					this.exporting = true;
					let funnelId = 0;
					if (this.funnel && parseInt(this.funnel.id) > 0) funnelId = parseInt(this.funnel.id);

					if (downloadType === false) {
						this.exportData = {};

						let startDate = moment(this.dateRange.startDate).startOf('day').unix();
						let endDate = moment(this.dateRange.endDate).endOf('day').unix();

						if (this.ordersBy === "all") {
							startDate = 0;
							endDate = 0;
						}

						let data = {
							funnel_id: funnelId,
							time: null,
							data_size: null,
							file_name: null,
							done: false,
							failed: false,
							time_done: null,
							expiry_date: null,
							expired: false,
							downloads: null,
							params: JSON.stringify({
								exportType: this.ordersBy, rangeStart: startDate || 0, rangeEnd: endDate || 0
							})
						}
						this.exportData = await this.api.orders.export(data);
					} else {
						var downloadRes = await this.api.orders.download(this.exportData.id, funnelId)
						var blob = new Blob([downloadRes], {type: "text/csv;charset=utf-8"});

						saveAs(blob, "orders-export.csv");

						this.ordersByType = 'all';
						// this.startDate = "2020-12-02";
						// this.endDate = "2020-12-02";
					}
				} catch (e) {
					this.$error(e)
				} finally {
					this.exporting = false
		    }
	    },
    },
	mounted() {
		window.addEventListener('resize', this.updateDateRangePickerPosition);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.updateDateRangePickerPosition);
	},
	created() {
	},
}
</script>

<style lang="scss">
.vue-daterange-picker {
    width: 100%;
}
.export_orders_popup .switch_checkbox_item {
    margin-bottom: 10px;
}
.form-control.reportrange-text {
    background-color: #F6F9FE;
    border-radius: 8px;
    line-height: 20px;
    font-size: 13px;
}
.export_orders_popup .vue-daterange-picker {
    margin-bottom: 15px;
}
.export_orders_popup .automation-progress-panel.popup_progress_panel .item-prog {
    margin: 5px;
}
.export_orders_popup .automation-progress-panel.popup_progress_panel {
    flex-wrap: wrap;
    padding: 2px;
    margin-bottom: 20px;
}
</style>
