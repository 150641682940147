export default (config, axios, token) => {

    var call = async function(path, method = 'GET', data) {
        data ? data = JSON.stringify(data) : '';
        const url = config.URL + path;
        try {
            const response = await axios({
                url,
                method,
                data,
                headers: {
                    'Authorization': 'Bearer ' + token,
                'Content-Type':'application/json'}
            })
            return response.data
        } catch (error) {
            return { error: true, info: error.response }
        }
    }

    return {


        getAll: async() => call('automations'),

        getAllDeleted: async() => call('automations?deleted=true'),

        get: async(id=false) =>
            call('automations/' + id),

        create: async(sourceId, key='') =>
            call('automations?source='+sourceId+'&key='+key, 'POST', {}),

        update: async(id, data) =>
            call('automations/' + id, 'PUT', data),

        delete: async(id) =>
            call('automations/' + id, 'DELETE'),

        restore: async(id) =>
            call('automations/' + id + '/restore', 'PUT'),

        //Conditions
        conditions: async() =>
            call(`automations/conditions`),

        //Entries:
        entriesGet: async(id, entry = false) =>
            call(
                entry ?
                    'automations/' + id + '/entries/' + entry :
                    'automations/' + id + '/entries'
            ),

        entriesCreate: async(id, data) =>
            call('automations/' + id + '/entries', 'POST', data),

        entriesPatch: async(id, entry) =>
            call(`automations/${id}/entries/${entry.id}`, 'PUT', entry),

        entriesUpdateAll: async(id, entries) =>
            call(`automations/${id}/entries/update-all`, 'PUT', entries),

        entriesDelete: async(id, entryId) =>
            call(`automations/${id}/entries/${entryId}`, 'DELETE'),

        //email templates:
        // send: async(id, data) =>
        //     call('funnels/' + id + '/send-test-email', 'POST', data),
        //user:
        // userGetAll: async({ userId }) =>
        //     call('users/' + userId + '/email-templates'),
        //
        // userGet: async({ userId, id }) =>
        //     call('users/' + userId + '/email-templates/' + id),
        //
        // userCreate: async({ userId, data }) =>
        //     call('users/' + userId + '/email-templates', 'POST', data),
        //
        // userPatch: async({ userId, id, data }) =>
        //     call('users/' + userId + '/email-templates/' + id, 'PATCH', data),
        //
        // userDelete: async({ userId, id }) =>
        //     call('users/' + userId + '/email-templates/' + id, 'DELETE'),

        //shared:
        // sharedGet: async({ id }) =>
        //     call(id ? 'email-templates/' + id : 'email-templates'),
        //
        // sharedCreate: async({ id, data }) =>
        //     call('email-templates/' + id, 'POST', data),
        //
        // sharedPatch: async({ id, data }) =>
        //     call('email-templates/' + id, 'PATCH', data),
        //
        // sharedDelete: async({ id }) =>
        //     call('email-templates/' + id, 'DELETE'),

        //AUTOMATION templates:
        templates: async() =>
            call('automations/templates')

    }
}
