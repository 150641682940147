<template>
	<transition name="fade">
		<div class="popup_wrpr popup-backdrop active">
			<div class="popup new-trial-popup">

				<div class="popup_header">
					<h3><span style="margin-right: 5px;">👋</span> Welcome to Funnelish</h3>
					<v-button v-if="verified" @click="close" flat :icon="require('@/assets/img/popup_close.svg')" class="close_popup_btn" />
				</div>

				<div class="content scroll">
					<p>Thank you for choosing Funnelish!</p>

					<p v-if="!verified">
            <b>Please <u>check your email inbox</u> for a verification email.</b>
					</p>

					<p style="display: flex;align-items: center;margin: 0;">
						<span style="margin-right: 5px;">Make sure to </span>
						<external-link href="https://www.facebook.com/groups/funnelish"
						               label="Join our private Facebook Group" />
					</p>

					<p>Get started by creating your first funnel or choosing a plan!</p>

					<p style="line-height: 160%;font-size: 12px;">
						<span>If you have any questions or face any difficulties, reach out to our chat support or check the help section.</span>
					</p>

				</div>

        <div class="layout-row buttons" v-if="!verified">
          <v-button :loading="resending" @click="resendVerification">
            Resend verification email
            {{waitPeriod>0?` (${waitPeriod}s)`:''}}
          </v-button>

          <a v-if="isShopifyUser" @click="close()">
            <span>Or, Do this later</span>
          </a>

        </div>
        <div class="layout-row buttons" v-else>
          <v-button :loading="loading" @click="addFunnel" label="Create your first funnel" ></v-button>
          <a  @click="choosePlan">
            <span>Or, Choose a plan </span>
          </a>
        </div>

			</div>
		</div>
	</transition>
</template>

<script>
import API from '@/api';
import VButton from "@/components/base/v-button";
import ExternalLink from "@/components/base/external-link";

export default {
	name: "new-trial-popup",
	components: {ExternalLink, VButton},
	mixins: [API],

	data(){
		return {
			loading: false,
      resending:false,
      waitPeriod:0,
      interval:null,
		}
	},
	computed:{
    isShopifyUser() {
      return this.$store.state.userInfo.is_shopify_user;
    },
    verified() {
      return this.$store.state.userInfo.verified;
    },
	},

	methods: {
    async resendVerification() {
      if (this.interval || this.waitPeriod>0) {
        this.$warn("", "Cannot re-send a verification email at the moment, retry again later.")
        return
      }
      try {
        this.resending = true;
        let result = await this.api.users.sendVerificationEmail()
        console.log("resendVerification ", result)
        if (result && result.error) {
          throw result
        }
        this.$info("", "Verification email sent successfully.")
      } catch (e) {
        this.$error(e)
      } finally {
        this.resending = false;
        this.waitPeriod = 30;
        this.interval = setInterval(()=>{
          this.waitPeriod -= 1;
          if (this.waitPeriod <= 0 && this.interval) {
            clearInterval(this.interval)
            this.interval = null;
          }
        }, 1000)
      }
    },
		async onboardingDone() {
			try {
				this.loading = true;

				let id = this.$store.state.userInfo.id;
				this.$store.state.userInfo.onboarding_finished = true;

				await this.api.users.patch(id, this.$store.state.userInfo);

			} catch (e) {
				console.error(e);
			} finally {
				this.loading = false;
			}

		},
		async close() {
			await this.onboardingDone();
			this.$emit('close');
		},
		async choosePlan() {

			await this.onboardingDone();
			this.$emit('close');
			this.$router.push('/settings/billing');
		},
		async addFunnel() {

			await this.onboardingDone();
			this.$emit('close');

			// this.$store.commit("showAddNewFunnelPopup")

			// this.$router.push('/funnels?newfunnel=true');
		}
	},
	created() {

	},
  unmounted() {
    if (this.interval) {
      clearInterval(this.interval)
      this.interval = null;
    }
  }
}
</script>

<style lang="scss" scoped>
.new-trial-popup {
	background-color: $light-blue;
	box-shadow: 0 5px 20px rgba(37, 42, 50, 0.2);
	border-radius: 16px;
	max-width: 550px;
	padding: 10px 10px;
	min-height: 250px;

	background-image: url("~@/assets/img/popup-bg.svg");
	background-position: right bottom;
	background-size: inherit;
	background-repeat: no-repeat;
  font-stretch: normal;


	.content {
		font-size: 16px;
	}
	.buttons {
		padding: 10px 20px;
		align-items: center;
		.button {
			margin-right: 20px;
		}
		a:not(.button) {
			//color: $sharp-blue;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	.popup_header h3 {
		font-size: 26px;
		font-weight: 500;
	}
}
</style>
