import axios from "axios";
import config from "@/config/config";

export default (config, axios, token) => {

    var call = async function(path, method = 'GET', data, isJson=false) {
        data ? data = JSON.stringify(isJson ? data : { data }) : '';
        const url = config.URL + path;
        try {
            const response = await axios({
                url,
                method,
                data,
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json',
                }
            })
            return response.data
        } catch (error) {
            return { error: true, info: error.response }
        }
    }

    return {

        getAllSteps: async() =>
            call('all-funnel-steps'),

        get: async(id) =>
            call(id ? 'funnels/' + id : 'funnels'),
        getNames: async() =>
            call('funnels/names'),
        getArchived: async() =>
            call('funnels/archive'),

        getProducts: async(id, base='funnels') =>
            call((`${base}/`) + id + '/products'),

        create: async(data) =>
            call('funnels', 'POST', data, true),

        patch: async(id, data) =>
            call('funnels/' + id, 'PATCH', data, true),

        pin: async(id, pin) =>
            call('funnels/' + id + '/pin?pinned=' + pin, 'PATCH'),

        restore: async(id) =>
            call('funnels/' + id + '/restore', 'PUT'),

        delete: async(id) =>
            call('funnels/' + id, 'DELETE'),

        clone: async(data) =>
            call('clone-funnels', 'POST', data, true),

        saveProps: async(id, data) =>
            call(`funnels/${id}/save-props`, 'PUT', data, true),

        // To clone someone else's funnel (works on WFunnels only!)
        share: async(id,code,newName,cloneGeoFunnels,folder_id=0) =>
            call(`wfunnels/${id}/share?code=${code}&name=${newName}&clone-geo-funnels=${cloneGeoFunnels}&folder_id=${folder_id}`, 'POST'),

        abSplit: async(funnelId) =>
            call(`wfunnels/${funnelId}/ab-split`, 'POST'),

        getSubfunnels: async(id) =>
            call(id ? 'sub-funnels/' + id : 'sub-funnels', 'GET'),

        getStats: async() =>
            call('funnel-stats'),


        // funnel folders
        getFolders: async() =>
            call('funnels/folders', 'GET',true),
        createFolder: async(data) =>
            call('funnels/folders', 'POST', data, true),
        saveFolder: async(data) =>
            call(`funnels/folders/${data.id}`, 'PUT', data, true),
        deleteFolder: async(id) =>
            call('funnels/folders/'+id, 'DELETE'),
        addToFolder: async(folderId, funnelId) =>
            call(`funnels/folders/${folderId||0}/add?funnel_id=${funnelId}`, 'PUT'),

    }
}
