<template>
    <div :class="['main-right scroll', {'closed': !notificationsOpen}, addedClass]">
        <div class="main-right-inner">
            <h2>Activity</h2>

            <div class="info-graph">
                <div class="info-graph-title">
	                Active visitors right now
                  <span class="pulse"></span>
                </div>
                <div class="info-graph-value">{{recentVisitorsCount}}</div>
                <div class="info-graph-title">Page views per minute</div>
                <div class="info-graph-chart">
                    <span class="chart-bar"
                        v-for="(item, index) in visitors"
                        :key="index"
                        :style="{height: ((70 / getMaxCount) * item)+'px', minHeight:'1px'}"
                          :content="item + ' page views'"
                          v-tippy="{theme:'small-black'}"
                    ></span>
                </div>
            </div>
            <div class="panel activity-panel">
                <div class="panel-head">
                    <h2>Recent activity</h2>

<!--                  TODO: implement all activity route.-->
<!--                    <a class="view-all">View more</a>-->
                </div>
                <div class="panel-body entries-table">
                    <div class="panel-item entry __activity_entry" v-for="(item, index) in activityPanels" :key="index"
                         @click="gotoCustomer(item)">
                        <div class="panel-item-icon">
                            <img :src="activityEventTypeImgCheck(item.event_id)" alt="">
                        </div>
                        <div class="panel-item-info">
	                        <p class="__entry_title">
		                        <b  v-if="item.first_name && item.last_name">{{item.first_name}} {{item.last_name}}</b>
		                        <b v-else>{{item.optin_email}}</b>

                              <template v-if="item.event_id==2">
                                  Made a
                                <b>{{currency(item)}}{{item.amount}}</b> purchase
                              </template>
                              <template v-else>
                                  {{activityEventTypeTextCheck(item.event_id)}}
                              </template>
	                        </p>

                          <span class="__entry_time">{{date(item.time)}}</span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>


<script>
import API from "@/api"
import moment from "moment";
import {getSymbol} from "@/helpers/currencies";

export default {
    name: "MainRight",
    components: {
        // Templates
    },
    props: ['addedClass', 'show'],
    mixins: [API],
    data: function() {
        return {
            interval: false,
            activityPanels: [
            ],
            activityEventType: {
                1: require('../assets/img/target.svg'),
                2: require('../assets/img/on-purchase.svg'),
                3: require('../assets/img/target.svg'),
                4: require('../assets/img/new-subscription.svg'),
                5: require('../assets/img/subscription-cancelled.svg'),
                6: require('../assets/img/purchase-exit.svg'),
                7: require('../assets/img/purchased_atempts_icon.svg'),
                8: require('../assets/img/refund.svg'),
                9: require('../assets/img/refund.svg'),
                10: require('../assets/img/purchased_atempts_icon.svg'),
		            11: require('../assets/img/new-subscription.svg'),
            },
            activityEventTypeText: {
                1: 'Clicked',
                2: 'Made a purchase',
                3: 'Opted into a funnel',
                4: 'Started a new subscription',
                5: 'Subscription cancelled',
                6: 'Subscription completed',
                7: 'Subscription failed',
                8: 'Started a new dispute',
                9: 'Was refunded',
		            10: 'Attempted to purchase',
		            11: 'Recurring payment',
            },
            recentVisitorsCount: 0,
            recentViewsData: [],
            visitors: [],
        };
    },
    computed: {
        notificationsOpen() {
            return this.show ? this.show : this.$store.state.notificationsOpen;
        },
        getMaxCount() {
            var maxCount = 0;
            if(this.visitors.length > 0) {
                var arr = this.visitors;
                maxCount = Math.max(...arr);
            }
            return maxCount
        }
    },
    methods: {
    	currency(item){
    		return getSymbol(item.currency) || "$";
	    },
    	gotoCustomer(item) {
    		this.$router.push(`/customers?id=${item.customer_id}`)
	    },
        notifToggle: function() {
            this.$store.dispatch('notificationsOpenToggle');
        },
        activityEventTypeImgCheck(eventId){
            return this.activityEventType[eventId];
        },
        activityEventTypeTextCheck(eventId){
            return this.activityEventTypeText[eventId];
        },
      async init() {
				try {
					let recentViews = await this.api.analytics.getRecentActivity()
					this.activityPanels = recentViews.recent_activity;

					this.recentViewsData = recentViews.page_views;
					this.recentVisitorsCount = recentViews.visitors_count;

					if (this.recentViewsData.length) {
						var arr = []
						this.recentViewsData.forEach(view => {
							arr.push(view.value)
						});
						arr.length > 24 ? this.visitors = arr.splice(0, arr.length - 24) : this.visitors = arr;

					}
				} catch (e) {
					console.warn(e)
				}
      },
      date(time) {
        return moment.unix(time).fromNow()
			},
    },
    created() {
        this.init();

        if(this.$route.name == 'dashboard' || this.$route.name == 'Help'){
            this.interval = setInterval(()=>{
							// only trigger reload if the page is in view.
							if (!document.hidden) {
								this.init()
							}
            }, 3 * 1000);
        }
    },
    beforeDestroy() {
        console.log('beforeDestroy');
        clearInterval(this.interval);
    }
}
</script>

<style scoped lang="scss">
.__activity_entry {
	.panel-item-icon {
		width: fit-content;
	}
	.panel-item-info {
		display: flex;
		flex-direction: column;
		gap: 2px;
		width: 85%;
	}
	.__entry_title {
		b {
			font-weight: bold;
		}
		margin: 0;
		line-height: 1.5;
		color: $text-dark;
		font-size: 12px;
		text-overflow: ellipsis;
		overflow: hidden;
	}
	.__entry_time {
		font-size: 11px;
		font-weight: 400;
		color: #7e8188;
	}

}
.activity-panel .panel-item {
	cursor: pointer;
}
    .info-graph-chart .chart-bar:not(:last-child) {
        margin-left: 10px;
    }
</style>
