export default {
    tag: 'spacer',
    labelTitle: '',
    labelColor: '',
    id: { value: null },
    attributes: {
        basic: {
            margin: { title: 'Margin', comp: 'marginPadding', value: { top: 10, left: 0, right: 0, bottom: 0 } },
            widthNumber: { title: 'Width', comp: 'InputNumber', value: { number: 2 } },
            color: { title: 'Color', comp: 'colorPicker', value: '#D5E5FF' },
            style: { title: 'Border style', comp: 'selectItem', value: 'solid', options: [{ id: "dotted", text: "Dotted" }, { id: "dashed", text: "Dashed" }, { id: "solid", text: "Solid" }, { id: "double", text: "Double" }, { id: "groove", text: "Groove" }, { id: "ridge", text: "Ridge" }, ] },
            padding: { title: 'Padding', comp: 'marginPadding', value: { top: 5, left: 5, right: 5, bottom: 5 } },
        },
        advanced: {
            cssId: { title: 'ID', comp: 'TextInput', value: '' },
            cssClasses: { title: 'CSS Classes', comp: 'MultiSelectItem', value: [], options: [] },
        },
    },
}
