<template>
    <div class="main">
<!--        <Loading v-show="loading"/>-->

        <div class="main-center scroll">
            <div class=" dashboard-content">
                <h1>Dashboard</h1>
                <div class="filter-line margin-t-25">
	                <div class="flex">
		                <filters_panel @change="loadAnalytics()" />
	                </div>

	                <div class="layout-row range-wrapper">
		                <range-picker :end-date="endDate" :start-date="startDate" :min-date="minDate" :max-date="maxDate"
		                              @rangeChanged="rangeChanged" :loading="loadingAll" @reload="loadAnalytics()"/>
	                </div>
                </div>
                <div class="chart-box">
                  <chart title="Revenue" :data="values['revenue']" :old-data="oldValues['revenue']" :prefix="currencyCode"
                         :total="summary['revenue'].value" :old-total="summary['revenue'].old_value" is-money large
                         :startDate="chartStart" :endDate="chartEnd" :loading="loading['revenue']"/>
                </div>

                <div class="layout-column dashboard" style="margin-top: 40px;">
	                <div class="layout-row" >
		                <chart title="Uniq. Orders" :data="values['unique_orders']" :old-data="oldValues['unique_orders']" :round-value="true"
		                       :total="summary['unique_orders'].value" :old-total="summary['unique_orders'].old_value" small
		                       :startDate="chartStart" :endDate="chartEnd" :loading="loading['unique_orders']"/>

		                <chart title="Conv. rate" :data="values['conv_rate']" :old-data="oldValues['conv_rate']" suffix="%"
		                       :total="summary['conv_rate'].value" :old-total="summary['conv_rate'].old_value" small
		                       :startDate="chartStart" :endDate="chartEnd" :loading="loading['conv_rate']">
			                <template v-slot:info>
				                This is the <b>Conversion Rate</b> of pages on all your funnels combined.
			                </template>
		                </chart>

		                <chart title="AOV" :data="values['aov']" :old-data="oldValues['aov']" :round-value="true"
		                       :total="summary['aov'].value" :old-total="summary['aov'].old_value" small is-money :prefix="currencyCode"
		                       :startDate="chartStart" :endDate="chartEnd" :loading="loading['aov']">
			                <template v-slot:info>
				                This is the <b>Average Order Value</b> on all your funnels combined.
			                </template>
		                </chart>
	                </div>

	                <div class="layout-row" >
		                <chart title="Page views" :data="values['pageviews']" :old-data="oldValues['pageviews']"
		                       :total="summary['pageviews'].value" :old-total="summary['pageviews'].old_value" small
		                       :startDate="chartStart" :endDate="chartEnd" :loading="loading['pageviews']"/>

                    <chart title="Optins" :data="values['optins']" :old-data="oldValues['optins']" :round-value="true"
                           :total="summary['optins'].value" :old-total="summary['optins'].old_value" small
                           :startDate="chartStart" :endDate="chartEnd" :loading="loading['optins']"/>

                    <chart title="Optin rate" :data="values['optin_rate']" :old-data="oldValues['optin_rate']" suffix="%"
                           :total="summary['optin_rate'].value" :old-total="summary['optin_rate'].old_value" small
                           :startDate="chartStart" :endDate="chartEnd" :loading="loading['optin_rate']"/>
	                </div>
                </div>

                <div class="panel funnels-panel margin-t-25" v-if="top_funnels && top_funnels.length>0">
                    <div class="panel-head">
                        <h2>Top funnels <i>🚀</i></h2>
                        <router-link to="/funnels" class="view-all">View all</router-link>
                    </div>
                    <div class="panel-body entries-table">
                        <div class="panel-item entry" v-for="(item, index) in top_funnels" :key="index">
                            <router-link :to="'/wfunnel/'+item.id" class="list-item-link">
                                <div class="panel-item-cell panel-item-title" style="flex: 1">
                                    <div class="list-item-title">{{ item.name }}</div>
                                    <span>{{ item.updated_at }}</span>
                                </div>
                                <div class="panel-item-cell" style="width: 80px;">
                                    <div class="cell-title">Revenue</div>
                                    <div class="cell-value">{{currencySymbol(item.currency||currencyCode) + getShort(item.revenue,1,false)}}</div>
                                </div>
                                <div class="panel-item-cell" style="width: 60px;">
                                    <div class="cell-title">Orders</div>
                                    <div class="cell-value">{{item.orders}}</div>
                                </div>
                                <div class="panel-item-cell">
                                    <div class="cell-title">Conversion rate</div>
                                    <div class="cell-value cell-info">
                                        <div class="small-text">{{ shortValue(item.conv_rate) }} %</div>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <MainRight show="true"/>
    </div>
</template>

<script>

import MainRight from "@/components/MainRight.vue";
import MainSearch from "@/components/MainSearch.vue"
import DashboardBanner from "../components/DashboardBanner.vue";
import API from "@/api"
import Loading from "@/components/Loading.vue"
import {getSymbol} from "@/helpers/currencies"
import shortValue from "@/helpers/shortValue"
import FunnelEntry from "@/components/base/funnel-entry";
import analytics, {percentage} from "@/helpers/analytics";
// import moment from "moment";
const moment = require('moment-timezone');

import Chart from "@/components/base/chart";
import RangePicker from "@/components/base/range-picker";
import Filters_panel from "@/views/funnels/funnel-plus/funnel/analytics/filters_panel";

export default {
  name: "MainLayout",
	title:"Home | Funnelish™",
  components: {
	  Filters_panel,
    RangePicker,
    Chart,
    FunnelEntry,
    MainRight,
    MainSearch,
    DashboardBanner,
    Loading
  },
  mixins: [API],
  data: function() {
      return {
	      top_funnels: [],

        unit: "day",
        maxDate: new Date(),
        startDate: new Date(),
        endDate: new Date(),
	      currentRange: "Last 30 days",

        loadingAll:false,
	      loading: {
					'aov':true,
		      'revenue':true,
		      'optins':true,
		      'orders':true,
		      'pageviews':true,
		      'unique_visits': true,
		      'unique_orders': true,
		      'optin_rate': true,
		      'conv_rate': true,
	      },
	      values:{
					'aov':[],
		      'revenue':[],
		      'orders':[],
		      'optins':[],
		      'pageviews':[],
		      'unique_visits': [],
		      'unique_orders':[],
		      'optin_rate':[],
		      'conv_rate':[],
	      },
	      summary:{
					'aov':{},
		      'revenue': {},
		      'orders': {},
		      'optins': {},
		      'pageviews': {},
		      'unique_visits': {},
		      'unique_orders':{},
		      'optin_rate':{},
		      'conv_rate':{},
	      },
	      oldValues:{
		      'aov':[
			      {date:moment(),value:0}
		      ],
		      'revenue':[
			      {date:moment(),value:0}
		      ],
		      'orders':[
			      {date:moment(),value:0}
		      ],
		      'pageviews':[
			      {date:moment(),value:0}
		      ],
		      'unique_orders':[
			      {date:moment(),value:0}
		      ],
		      'unique_visits':[
			      {date:moment(),value:0}
		      ],
		      'conv_rate':[
			      {date:moment(),value:0}
		      ],
		      'optin_rate':[
			      {date:moment(),value:0}
		      ],
	      },
	      on_boarding: {},
        // banerOpened: true,
        topFunnels: []
      };
  },
  computed: {
    currencyCode() {
	    if (!this.$store.state.userInfo) return '$';
	    let c = this.$store.state.userInfo.default_currency_code;
	    return getSymbol(c) || '$';
    },
    chartStart() {
	    return analytics.formatChartDate(this.unit,this.startDate);
    },
	  oldStartDate() {
		  if (this.unit==="hour") {
			  let days = moment(this.endDate).diff(this.startDate, "hours")+1;
			  let start = moment(this.startDate).subtract(days, "hours").startOf('day');

			  return start;
		  } else {
			  let amount = moment(this.endDate).diff(this.startDate, this.unit)+1;
			  let start = moment(this.startDate).subtract(amount, this.unit).startOf(this.unit);

			  return start;
		  }
	  },
    chartEnd() {
	    return analytics.formatChartDate(this.unit, this.endDate);
    },
    oldEndDate() {
    	if (this.unit==="hour") {
		    let end = moment(this.startDate).subtract(1, "hours").endOf('day');
		    return end;
	    } else {
		    let end = moment(this.startDate).subtract(1, this.unit).endOf(this.unit);
		    return end;
	    }
    },
	  minDate() {
		  return this.$store.state.userInfo ? moment(this.$store.state.userInfo.created) :
				  moment();
	  }
  },
  methods: {
    shortValue(v) {
      return shortValue(v * 100, 2)
    },
	  async loadChart(chart='revenue') {
		  try {
			  this.loading[chart] = true;

        let {values, oldValues, summary} = await analytics.loadChart(this.api, chart, this.startDate, this.endDate, this.oldStartDate, this.oldEndDate,
            this.unit, 0, '')

				  this.values[chart] = values
				  this.oldValues[chart] = oldValues
          this.summary[chart] = summary
		  } catch (e) {
			  console.error(e)
		  } finally {
			  this.loading[chart] = false;
		  }
	  },
    percentage(sub,total) {
	    return shortValue(percentage(sub,total), 2)
    },
    rangeChanged(currentRange, startDate, endDate) {
	    this.startDate = startDate;
	    this.endDate = endDate;
	    this.currentRange = currentRange;

	    this.storeRange();

	    this.loadAnalytics();
    },
    storeRange() {
    	analytics.storeRange(this.currentRange, this.startDate, this.endDate);
    },
    loadRange() {
	    let {currentRange, startDate, endDate} = analytics.loadRange();

      console.log("loadRange:", currentRange, startDate, endDate)

	    this.currentRange = currentRange;
	    this.endDate = endDate;
	    this.startDate = startDate;
    },

    //UI
    getShort(value, digits, money=false) {
        return shortValue(value, digits, money);
    },
    // Calculates conversion, optin rate
    currencySymbol(code) {
        return code.length ? getSymbol(code) : '';
    },
    async loadAnalytics() {
	    try {
        this.loadingAll = true;

		    let {startDate, endDate} = analytics.rangeToTime(this.currentRange, this.startDate, this.endDate, this.minDate);
		    this.startDate = startDate;
		    this.endDate = endDate;

		    let unit = "day";
		    let days = Math.abs(moment(this.endDate).diff(this.startDate, "days"))+1;
		    if (days > 6*30) {
			    unit = "month";
		    } else if (days > 61) {
			    unit = "week";
		    } else if (days <= 2) {
		    	unit = "hour"
		    }
		    this.unit = unit;


       await Promise.all([
          this.loadChart('revenue'),
          //// this.loadChart('orders')
          this.loadChart('aov'),
          this.loadChart('pageviews'),
          this.loadChart('optins'),
          this.loadChart('unique_orders'),
          this.loadChart('conv_rate'),
          this.loadChart('optin_rate'),
          this.loadFunnelPerformance(),
          this.loadDashboard()
        ])

	    } catch (e) {
		    this.$error(e);
	    } finally {
		    this.loadingAll = false;
	    }
    },
    async loadFunnelPerformance() {
      try {
        let topFunnels = await this.api.analytics.getFunnelPerformance(0, this.startDate, this.endDate,
            analytics.getConditions(), '', 5)

        this.top_funnels = topFunnels?.rows || [];
      } catch (e) {
        this.top_funnels = []
      }
    },
    async loadDashboard() {
      try {
        let data = await this.api.analytics.dashboard(this.startDate, this.endDate, unit);
        this.on_boarding = data.on_boarding;
      } catch (e) {
        this.on_boarding = {}
      }
    },
  },
	mounted() {
    console.log("mounted")
    this.loadAnalytics();
  },
  created() {
    console.log("created")
    this.loadRange();
  }
}
</script>
<style lang="scss" scoped>
.chart-box {
	display: flex;
}
.chart {
	margin: 10px;
}
</style>
