<template>

  <popup title="Funnelish™ subdomain" medium @close="close" class="subdomain-popup" :class="{'no-close':!canClose}">
    <template v-slot:content>
      <div class="layout-column margin-10 gap-20">
        <span class="margin-t-10">
          Pick your default free subdomain, must be 5 letters or more.
        </span>
<!--          <div class="inner_content">-->
        <div>
          <input-field class="flex" label="" placeholder="Your subdomain name." @input="onInput"
                       required append=".funnelish.com" v-model="subdomain" :max-length="30" />
        </div>

	          <span v-if="!canClose">
		          You can always change this under <b>Account &gt; Settings</b>,
		          or connect your custom domain under <b>Funnel &gt; Settings</b>.
	          </span>

<!--	          Only show the warning when changing a subdomain, not first time. -->
	          <div v-else class="alert_box orange">
		          <span class="icon">
                  <inline-svg :src="require('@/assets/img/alert_icon_yellow.svg')"/>
              </span>
		          Changing this subdomain will change the domain names of all funnels connected to the current subdomain.
	          </div>
<!--          </div>-->
      </div>
    </template>
    <template v-slot:actions>
      <v-button class="cancel-btn" new-style label="Cancel" @click="close()" border />
      <v-button label="Save changes" new-style :loading="loading" :disabled="!isValid" @click="save()" />
    </template>
  </popup>

</template>

<script>
import API from "@/api"
import InputField from "@/components/base/input-field";
import Popup from "@/components/base/popup";
import VButton from "@/components/base/v-button";
import InfoMessage from "@/modules/page-editor/src/components/sidebar/sidebar-items/InfoMessage";
export default {
  name: 'SubdomainPopup',
  components: {InfoMessage, VButton, Popup, InputField},
  mixins: [API],
	props: {
  	canClose: {
  		default: true,
		  type: Boolean
	  }
	},
  computed: {
  	isValid() {
  		return !!this.subdomain && this.subdomain.length >= 5
	  },
	  user() {
  		return this.$store.state.userInfo;
	  }
  },
  data() {
    return {
      loading: false,
      subdomain: "",
      oldName:"",
    }
  },
  methods: {
    onInput(ev) {
      console.log("ev", ev)
      this.subdomain = ev.replace(/[^a-z0-9]/gi, '');
    },
    async save(){
    	try {
    		this.loading = true;
		    let result = await this.api.wsteps.changeSubdomain({subdomain: this.subdomain});
		    if (!result.error) {
			    this.$notify({
				    group: 'main',
				    title: "Your default Funnelish subdomain was changed",
				    type: 'info',
			    });
			    this.close();

			    // only reload if can close
			    if (this.canClose) window.location.reload(); // forces to reload funnels, domains and userInfo
			    else this.user.subdomain = this.subdomain;
		    } else {
			    console.error(result);
			    this.$notify({
				    group: 'main',
				    title: result.info.data,
				    type: 'error',
			    });
		    }
	    } catch (e) {
		    console.error(e);
		    this.$notify({
			    group: 'main',
			    title: "Something went wrong! try again in a bit, do let us know if this problem persists.",
			    type: 'error',
		    });
	    } finally {
		    this.loading = false;
	    }
    },
    close() {
      this.$emit('close')
    },
  },
  created() {
  	this.subdomain = this.user.subdomain || '';
    this.oldName = this.subdomain || '';
  }
}
</script>

<style lang="scss">
.subdomain-popup {
  .popup {

  }
	&.no-close {
		.close_popup_btn, .cancel-btn {
			opacity: 0.4;
			pointer-events: none;
		}
	}
}
</style>

<style lang="scss" scoped>

.inner_content {
  .input-field {
	  margin: 15px 80px;
  }
}
</style>
