export default {
    state: {
        triggerItems: [{
                id: 1,
                icon: require('@/assets/img/tg-icon-1.svg'),
                text: "On Purchase",
                active: false
            },
            {
                id: 2,
                icon: require('@/assets/img/tg-icon-2.svg'),
                text: "On Refund",
                active: false
            },
            {
                id: 3,
                icon: require('@/assets/img/tg-icon-3.svg'),
                text: "New Subscription",
                active: false
            },
            {
                id: 4,
                icon: require('@/assets/img/tg-icon-4.svg'),
                text: "Subscription Canceled",
                active: false
            }
        ],
        integrationItems: [{
                id: 1,
                img: require('@/assets/img/s-img-1.png'),
                title: "Shopify",
                active: true,
                selected: false,
                description: "Accept Shopify on your funnels"
            },
            {
                id: 2,
                img: require('@/assets/img/s-img-2.png'),
                title: "ActiveCampaign",
                active: true,
                selected: false,
                description: "Accept ActiveCampaign on your funnels"
            },
            {
                id: 3,
                img: require('@/assets/img/s-img-3.png'),
                title: "Orderlytics",
                active: true,
                selected: false,
                description: "Accept Orderlytics on your funnels"
            },
            {
                id: 4,
                img: require('@/assets/img/s-img-4.png'),
                title: "Google Sheets",
                active: true,
                selected: false,
                description: "Accept Google Sheets on your funnels"
            },
            {
                id: 5,
                img: require('@/assets/img/s-img-5.png'),
                title: "PayPal",
                active: false,
                selected: false,
                description: "Accept PayPal Payments on your funnels"
            }
        ],
        continueBtnStatus: false,
        activateItem: {},
        automationData: {},
        automationDataItem: {}
    },
    mutations: {
        selectTrigger(state, id) {
            return state.triggerItems.map((item) => {
                if (item.id === id) {
                    item.active = true
                    state.continueBtnStatus = true
                    state.automationDataItem.triggerId = item.id
                } else {
                    item.active = false
                }
                return item
            })
        },
        selectIntegration(state, id) {
            return state.integrationItems.map((item) => {
                if (item.id === id) {
                    item.selected = true
                    state.continueBtnStatus = true
                    state.automationDataItem.integrationId = item.id
                } else {
                    item.selected = false
                }
                return item
            })
        },
        continueBtnStatusChange(state, status) {
            state.continueBtnStatus = status
        },
        continueBtnStatusCheck(state) {
            if (!(state.integrationItems.filter(item => item.selected === true)).length) {
                state.continueBtnStatus = false
            } else {
                state.continueBtnStatus = true
            }
        },
        detectCurrentIntegration(state, item) {
            state.activateItem = item
        },
        integrationActiveChange(state, value) {
            return state.integrationItems.map((item) => {
                if (state.activateItem.id === item.id) {
                    item.active = value
                }
                return item
            })
        },
        setActionTrigger(state, data) {
            state.automationDataItem.actionCaption = data.actionCaption
            state.automationDataItem.action = data.action
            state.automationDataItem.qty = data.qty
            state.automationDataItem.customerEmail = data.customerEmail
            state.automationDataItem.targetProductId = data.targetProductId
        },
        createNewAutomation(state) {
            // const item = {
            //     id: new Date().getTime(),
            //     attributes: {

            //     },
            // }
            // const item = {
            //     id: new Date().getTime(),
            //     "attributes": {
            //         "trigger-id": 2,
            //         "product-id": 4390,
            //         "integration-id": 5,
            //         "type-id": 1,
            //         "list": null,
            //         "tags": null,
            //         "send-to-optin-email": true,
            //         "config": null,
            //         "action-caption": "2 x 360 Flexible Selfie Holder",
            //         "content": "{\"product_id\":579998253117,\"qty\":2,\"links\":[]}"
            //     },
            //     "type": "product-automations"
            // }
            state.automationData = state.automationDataItem
        },
        deleteAutomationItem(state, id) {
            state.automationData = state.automationData.filter((item) => item.id !== id)
        },
        editAutomationItem(state, id) {

        },
        setAutomationData(state, items) {
            state.automationData = items
        },
        updAutomation(state) {
            state.automationData = Date.now();
        }

    },
    actions: {
        updAutomation(context) {
            context.commit('updAutomation');
        },
        setAutomationData(context, items) {
            context.commit('setAutomationData', items)
        },
    },
    getters: {
        getTriggerItems(state) {
            return state.triggerItems
        },
        getIntegrationItems(state) {
            return state.integrationItems
        },
        getContinueBtnStatus(state) {
            return state.continueBtnStatus
        },
        getCurrentIntegration(state) {
            return state.activateItem
        },
        getProductLinkVariantsLeft(state) {
            return state.productLinkVariantsLeft
        },
        getProductLinkVariantsRight(state) {
            return state.productLinkVariantsRight
        },
        getAutomationData(state) {
            return state.automationData
        },
        // getActionSelect(state) {
        //     return state.actionSelect
        // },
        // getProductSelect(state) {
        //     return state.productSelect
        // },
        // getTagsSelect(state) {
        //     return state.tagsSelect
        // },
        // getTargetProduct(state) {
        //     return state.targetProduct
        // }
    }
}
