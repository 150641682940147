<template>
    <section class="s-auth">
        <router-view></router-view>
    </section>
</template>

<script>
	export default {
		name: "EmptyLayout",
	}
</script>