import {
    shadows,
    border,
    margin,
    cssId,
    cssClasses,
    id,
    size,
    image,
    sticky,
} from './fields/'

export default {
    tag: 'beforeAfterSlider',
    labelTitle: '',
    labelColor: '',
    id,
    content:{
        before:"Before",
        after:"After"
    },
    attributes: {
        basic: {
            align: { title: 'Alignment', comp: 'alignment', value: {mobile:null,desktop:'center',tablet:null} },
            margin,
            beforeImage:{title: 'Before image', comp: 'imageUploader', value: 'https://img.funnelish.com/default/placeholder-before.jpg'},
            afterImage:{title: 'After image', comp: 'imageUploader', value: 'https://img.funnelish.com/default/placeholder-after.jpg'},
            showLabels: { title: 'Show labels', comp: 'Toggle', value: true },
            labelsFont:{
                title: 'Labels font',
                comp: 'FontPanel',
                value: {
                    fontFamily: '',
                    fontSize: 13,
                    color: null, // When null it falls-back to the CORE.CSS styles.
                    fontStyling: {
                        bold: false,
                        italic: false,
                        'line-through': false,
                        underline: false,
                    },
                    textAlign: null
                }
            },
            separatorColor:{
                title: 'Separator color',
                comp: 'colorPicker2',
                value: '#FFFFFF'
            },
            labelsBgColor:{
                title: 'Labels background color',
                comp: 'colorPicker2',
                value: '#FFFFFFCC'
            },
            size:{
                title: 'Size',
                comp: 'SizeValue',
                placeholder: 'auto',
                value: {
                    width: 320,
                    height: null /* null will be treated as 'auto'*/
                }
            },
            lazyload: { title: 'Lazyload', comp: 'LazyloadBool', options:{recommended:true},
                value: {booleanValue:true} },
            border:{
                title: 'Border',
                comp: 'border',
                value: {
                    width: 2,
                    color: '#ffffff',
                    style: 'solid',
                    sides: { top: true, left: true, right: true, bottom: true },
                    radius: { topLeft: 3, topRight: 3, bottomRight: 3, bottomLeft: 3 },
                }
            },
            shadows
        },
        advanced: {
            cssId,
            cssClasses,
            sticky
        },
    },
}
