<template>
	<popup class="send_email_popup"
	       :title="step===1 ? 'Send email' : 'Send a test email'" @close="closeThisPopup">
		<template v-slot:content>
			<div class="popup_body" v-if="step == 1">
				<div class="inputs_wrpr">

					<input-field style="width: 100%;margin-right: 20px" type="email"
					             placeholder="Type a valid email address" v-model="emailInput"/>
				</div>
			</div>
			<div class="popup_body" v-else-if="step == 2">
				<div class="layout-column" style="align-items: center">
					<inline-svg :src="require('@/assets/email/img/success_icon.svg')"/>
					<p>Test email has been sent successfully!</p>
				</div>
			</div>
		</template>
		<template v-slot:actions>
			<v-button label="Cancel" @click="closeThisPopup" border />
			<v-button @click="stepTwo"
			          :loading="loading" label="Send test" :disabled="!isValid"/>
		</template>
	</popup>
</template>

<script>
import API from "@/api"
import VButton from '@/components/base/v-button'
import InputField from '@/components/base/input-field'
import popup from '@/components/base/popup';

export default {
	name: 'SendTestEmailPopup',
	props: ['template'],
	mixins: [API],
	components: {VButton, InputField, popup},
	data: function () {
		return {
			step: 1,
			loading: false,
			emailInput: '',
			emailError: false
		}
	},
	watch: {
		step(val) {
			if (val == 2) {
				setTimeout(() => {
					this.closeThisPopup();
				}, 1500);
			}
		}
	},
	computed: {
		isValid() {
			let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(this.emailInput);
		}
	},
	methods: {
		closeThisPopup() {
			this.$emit('closePopup');
		},
		async stepTwo() {
			try {
				this.loading = true;

				let result = await this.api.automations.send(1016, {
					recipient: this.emailInput,
					email_template_id: this.template
				});
				console.log('result: ', result);
				if (result.success) this.step = 2;
				else this.emailError = true;
			} catch (e) {
				this.$error(e);
			} finally {
				this.loading = false;
			}
		},
	},
}
</script>
<style lang="scss" scoped>
.send_email_popup {
}
</style>
<style lang="scss">
.send_email_popup {
	.popup {
		max-width: 550px !important;
	}
}
</style>
