import store from '@/store';
import router from '@/router';

function isAdmin(){
    try {
        if (!store.state.userInfo.member_account) return false;
        let ma = store.state.userInfo.member_account;
        if (!ma.permissions) return false;
        let p = JSON.parse(ma.permissions);
        return !!p.admin
    } catch {
        return false;
    }
}

function is(role="admin"){
    try {
        if (!store.state.userInfo.member_account) return false;
        let ma = store.state.userInfo.member_account;
        if (!ma.permissions) return false;
        let p = JSON.parse(ma.permissions);
        return !!p[role]
    } catch (e) {
        console.error(e)
        return false;
    }
}

function validateUser(user, route){
    try {
        if (!user || user.error) {
            if (['LogIn','SignUp','ShareFunnel','ForgotPassword','PasswordRecovery','NewPassword',
                 'SelectAccount'].includes(route.name)) {
                return true; // don't redirect
            }
            router.replace({path:'/log-in'}).catch(()=>{})
            return false;
        }
        // let data = user.info.data;
        // if (!data || data.trim()==="Exception: invalid token") {
        //     router.replace('/log-in').catch(()=>{})
        //     return false;
        // }
        return true
    } catch (e) {
        console.error(e)
        return false;
    }
}

export {
    isAdmin,
    is,
    validateUser
}
