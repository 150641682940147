export default (config, axios, token) => {

    // noData used to prevent embedding in {data} struct
    var call = async function(path, method = 'GET', data) {
        data ? data = JSON.stringify(data) : '';
        const url = config.URL + path;
        try {
            const response = await axios({
                url,
                method,
                data,
                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type':'application/json' }
            })
            return response.data
        } catch (error) {
            return { error: true, info: error.response }
        }
    }

    return {

        getStaff: async(id=null) =>
            call(`settings/staff${id?'/'+id:''}`),

        updateStaff: async(data) =>
            call('settings/staff', 'PUT', data),

        updateProfile: async(data) =>
            call('settings/staff/me', 'PUT', data),

        createStaff: async(data) =>
            call('settings/staff', 'POST', data),

        deleteStaff: async(id) =>
            call(`settings/staff/${id}`, 'DELETE'),

    }
}
