export default (config, axios, token) => {

    // noData used to prevent embedding in {data} struct
    var call = async function(path, method = 'GET', data) {
        data ? data = JSON.stringify(data) : '';
        const url = config.URL + path;
        try {
            const response = await axios({
                url,
                method,
                data,
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
            })
            return response.data
        } catch (error) {
            throw error
        }
    }

    return {

        placeShopifyOrder: async(data) =>
            call('billing/place-shopify-order', 'POST', data),

        placeOrder: async(data) =>
            call('billing/place-order', 'POST', data),

        updateDetails: async(data) =>
            call('billing/update-details', 'POST', data),

        setupIntent: async(data) =>
            call('billing/setup-intent', 'POST', data),

        updateBillingInfo: async(data) =>
            call('billing/billing-info', 'PUT', data),

        cancelSubscription: async() =>
            call('billing/cancel-subscription', 'POST'),

        getBillingDetails: async() =>
            call('billing/billing-details', 'GET'),

        getCharges: async() =>
            call('billing/charges', 'GET'),

    }
}
