export default (config, axios, token) => {

    var call = async function(path, method = 'GET', data) {
        const url = config.URL + path;
        try {
            const response = await axios({
                url,
                method,
                data,
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json',
                }
            })
            return response.data
        } catch (error) {
            return { error: true, info: error.response }
        }
    }

    return {

        get: async(funnelId, id) =>
            call(`wfunnels/${funnelId}/tracking-codes/${id}`),

        getAll: async(funnelId) =>
            call(`wfunnels/${funnelId}/tracking-codes`),

        delete: async(funnelId, id) =>
            call(`wfunnels/${funnelId}/tracking-codes/${id}`,'DELETE'),

        update: async(code) =>
            call(`wfunnels/${code.funnel_id}/tracking-codes`,'PUT', code),

        updateIndex: async(code) =>
            call(`wfunnels/${code.funnel_id}/tracking-codes/${code.id}/update-index`,'PUT', code),

        create: async(code) =>
            call(`wfunnels/${code.funnel_id}/tracking-codes`,'POST', code),
    }
}
