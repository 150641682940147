<template>
	<popup class="popup_2 " noscroll :class="{'select-template':popupStep===1}" title="Add new step" @close="closeAddNewStepPopup">
		<template v-slot:content>
			<div class="content scroll" v-if="popupStep === 2">
				<div class="wrap_select_step_type">
					<label class="form_steps_label step_1">
						<span class="icon"></span> Select a step type
					</label>

					<div class="row step-types-list">
						<SelectButton label="Checkout" :src="require('@/assets/img/Order-Form.svg')"
						              :value="1" @click="selectType(1)"
						              info="Ideal for taking the initial payment from customers through a <b>payment form</b> or a <b>two step form</b>." />
						<SelectButton label="Upsell" :src="require('@/assets/img/upsell-2.svg')"
						              :value="2" @click="selectType(2)"
						              info="Allows your customers to buy more of your products without having to provide their payment details again." />
						<SelectButton label="Thank you page" :src="require('@/assets/img/Thank You Page.svg')"
						              :value="3" @click="selectType(3)"
						              info="This is usually the last page customers will see in your funnel, also a chance to show more of your other products." />
						<SelectButton label="Downsell" :src="require('@/assets/img/Downsell.svg')"
						              :value="4" @click="selectType(4)"
						              info="This step is only shown to your customers when they say <b>NO</b> to a previous Upsell offer." />
						<SelectButton label="Landing page" :src="require('@/assets/img/Optin Page.svg')"
						              :value="5" @click="selectType(5)"
						              info="Ideal for landing pages, regardless of whether you collect optins or not." />
						<SelectButton label="Other" :src="require('@/assets/img/Other.svg')"
						              :value="6" @click="selectType(6)" info="Any other step type not in the list, can be used for pages such as (<b>Terms of Service, Privacy Policy</b>,...etc)" />
					</div>
				</div>
			</div>

			<div class="content scroll" v-if="popupStep === 3">
				<div class="wrap_input">
					<label class="form_steps_label step_2">
						<span class="icon"></span> Select a step type
						<span class="check">
              <span class="icon_check">
                <inline-svg :src="stepTypes[newStep['type']-1].img"/>
              </span>
              <span class="title">{{stepTypes[newStep['type']-1].title}}</span>
            </span>
					</label>

					<label class="form_steps_label step_1">
						<span class="icon"></span> Your step details
					</label>

					<InputField select-all placeholder="Your step name" info="This is only visible to you."
					            label="Name" v-model="newStep.name" required />

					<InputField label="Step path" placeholder="Your step URL path"
					            info="This is the URL path your customers will access this step."
					            v-model="newStep.path" :prepend="stepPathPrepend" />
					<div v-if="isGeoFunnel" class="alert_box blue" style="margin-top: -10px">
						<div class="icon">
							<inline-svg :src="require('@/assets/img/adress_icon.svg')" />
						</div>
						<span>
								This is a <b>Geo-funnel step</b>, you can use the same path/url for similar pages across all Geo-funnels within the same group.
								<b>Funnelish</b> will deliver the right step/page based on your visitors location.
							</span>
					</div>
				</div>
			</div>

			<div class="templates-container layout-column flex noscroll" v-if="popupStep === 1">
				<step-templates @selected="selectTemplate($event)" />
			</div>
		</template>

		<template v-slot:actions>
			<span v-if="popupStep===1" class="sub-headline">
				We are adding and improving our templates on a daily basis.<br>
				Recommend new page ideas or improvements on our
				<a target="_blank" href="https://community.funnelish.com">community</a>.
			</span>

      <v-button new-style @click="closeAddNewStepPopup" border label="Cancel" />

			<v-button new-style @click="popupStep -= 1" v-if="popupStep>1" border label="Back" />
			<v-button new-style v-if="popupStep===2" disabled label="Continue" />

			<v-button new-style @click="createStep" v-if="popupStep===3" :loading="creating"
			          :disabled="validateStep.confirm" label="Create step" />

<!--			first step set template only -->
			<v-button new-style @click="popupStep=2" v-if="popupStep===1" :loading="creating" label="Continue" />
		</template>
	</popup>
</template>

<script>
import API from "@/api"
import stepTypes from "@/helpers/stepTypes"
import InputField from "@/components/base/input-field";
import SelectButton from "@/components/base/select_button";
import Popup from "@/components/base/popup";
import StepTemplates from "@/views/funnels/funnel-plus/steps/templates";
import VButton from "@/components/base/v-button";
export default {
    name: "NewPlusStepPopup",
    components: {
	    VButton,
	    StepTemplates,
	    Popup,
      SelectButton,
        InputField
    },
    mixins: [API],
    props: ['funnel', 'steps', 'popupStepTwoNumber'],
    data: function() {
        return {
        	pathTaken: false,
        	creating: false,
	        isUserTemplate: false,
	        loadingTemplates: false,
            popupStep: 1,
            newStep: {
              name: "",
              path: '',
              type: null,
              url: "",
              user_id: parseInt(this.$store.state.userInfo.id),
              funnel_id: parseInt(this.funnel.id),
              template_id: 0,
	            order_index: 0,
	            pages:[
		            {id:0, template_id:0}
	            ],
            },
            stepTypes: stepTypes,
            visited: {
                name: false,
                path: false,
                url: false
            },
            fieldsets: {
                one: false,
                two: false,
                three: false
            },
        };
    },
    computed: {
	    isGeoFunnel() {
		    return this.funnel && this.funnel.is_sub_funnel;
	    },
        validateStep() {
            var name = this.newStep.name.trim();
            var path = this.newStep.path.trim();
            // var url = this.newStep.url.trim();
             var result = {
                confirm: (!name.length), // path can be empty (eg. home page)
                name: !name.length,
                path: !path.length,
                // url: !url.length
            }
            return result;
        },
        isPlus() {
            return this.funnel['funnel-type']
        },
        stepPathPrepend(){
          let path = '';
          if (this.funnel.domain_name && this.funnel.domain_name.length) {
            path = 'https://'+this.funnel.domain_name + '/';
          } else {
            path = '/'
          }
          if (this.funnel.path && this.funnel.path.length) {
            path += this.funnel.path + '/';
          }
          return path;
        }
    },
    methods: {
    	selectTemplate(id) {
    		if ((''+id).startsWith('U.')) {
			    this.newStep.pages[0].template_id = parseInt(id.replace('U.',''));
    			this.isUserTemplate = true;
		    } else {
			    this.isUserTemplate = false;
			    this.newStep.pages[0].template_id = id;
		    }
	    },
        selectType(type) {
	        this.newStep.type = parseInt(type);
	        this.newStep.name = "New "+this.stepTypes[type-1].title;
	        this.newStep.path = this.newStep.name.toLowerCase().replaceAll(" ", '-');
	        this.popupStep = 3
        },
        closeAddNewStepPopup() {
	        this.$emit("close");
        },
	    async createStep() {
        	try {
        		this.creating = true;
		        let {id} = this.$route.params;
		        this.pathTaken = false;

		        this.newStep.order_index = parseInt(this.steps.length + 1);

		        let result = await this.api.wsteps.create(id, this.newStep, this.isUserTemplate)
		        if (!result.error) {
			        this.$store.dispatch('ADD_STEP', result);
			        this.newStep = result;
			        this.closeAddNewStepPopup();
			        this.$router.push({path: `/wfunnel/${id}/steps/${this.newStep.id}`})
		        } else {
		        	this.$error(result, result.info.data);
			        this.pathTaken = result.info.status===409;
		        }
	        } catch (e) {
        		this.$error(e);
	        } finally {
		        this.creating = false;
	        }
	    },
        // async applyTemplate() {
        // 	try {
		    //     this.creating = true;
		    //     let {id} = this.$route.params;
				// 		let page = this.newStep.pages[0];
				// 		if (this.newStep.template_id <= 1) {
				// 			this.closeAddNewStepPopup(); // Do nothing for empty template!
				// 			this.$router.push({path: `/wfunnel/${id}/steps/${this.newStep.id}`});
				// 			return;
				// 		}
		    //     let result = await this.api.wsteps.applyTemplate(id, this.newStep.id, page.id, this.newStep.template_id)
		    //     if (!result.error) {
			  //       this.closeAddNewStepPopup();
			  //       this.$router.push({path: `/wfunnel/${id}/steps/${this.newStep.id}`})
		    //     } else throw result.info.data;
	      //   } catch (e) {
		    //     this.$notify({
			  //       group: 'main',
			  //       title: "Something went wrong! try again.",
			  //       type: 'error',
		    //     });
		    //     console.error(e)
	      //   } finally {
		    //     this.creating = false;
	      //   }
        // },
    },
    mounted() {
        if(this.popupStepTwoNumber) {
            this.selectType(this.popupStepTwoNumber);
        }
    },
}
</script>

<style>
.popup_2 .wrap_input fieldset {
    transition: 0.15s all ease-in-out;
}
.popup_2 .wrap_input fieldset.active {
    border-color: #4368E0;
}
.popup_2 .wrap_input fieldset.active legend {
    color: #4368E0;
}
.select-template .popup {
	max-width: 1000px !important;
}
</style>

<style lang="scss" scoped>
.sub-headline {
	font-weight: 400;
	font-size: 12px;
	display: block;
	flex: 1;
	line-height: 140%;
}

.templates-container {
	min-width: 500px;
}
    .popup {
        .popup_btn_wrpr {
            border-top: 0;
            padding: 0px 30px 30px;
        }
        .popup_header {
            padding: 30px 30px 0;
        }
        .wrap_input {
            margin-left: 10px;
            margin-right: 10px;
        }
    }
    .popup.email_template_popup {
        max-width: 1021px;
        .main-content {
            padding: 0;
        }
    }
</style>
