import config from '@/config/config'
import axios from 'axios'

import funnels from "@/api/funnels.api"
import customers from "@/api/customers.api"
import orders from "@/api/orders.api"
import gateways from "@/api/gateways.api"
import apps from "@/api/apps.api"
import users from "@/api/users.api"
import steps from "@/api/steps.api"
import wsteps from "@/api/wsteps.api"
import analytics from "@/api/analytics.api"
import automations from "@/api/automations.api"
import integrations from "@/api/integrations.api"
import search from "@/api/search.api"
import images from "@/api/images.api"
import templates from "@/api/templates.api"
import billing from "@/api/billing.api"
import popups from "@/api/popups.api"
import settings from "@/api/settings.api"
import discounts from "@/api/discounts.api";
import codes from "@/api/codes.api";
import smsApi from "@/api/sms.api";
import developers from "@/api/developers.api";
import assets from "@/api/assets.api";
import compositions from "@/api/compositions.api";
import globalAutomations from "@/api/global_automations.api";
import videos from "@/api/videos.api";

export default (token) => {
    return {
        videos: videos(config, axios, token),
        compositions: compositions(config, axios, token),
        assets: assets(config, axios, token),
        funnels: funnels(config, axios, token),
        customers: customers(config, axios, token),
        orders: orders(config, axios, token),
        gateways: gateways(config, axios, token),
        apps: apps(config, axios, token),
        users: users(config, axios, token),
        steps: steps(config, axios, token),
        wsteps: wsteps(config, axios, token),
        analytics: analytics(config, axios, token),
        automations: automations(config, axios, token),
        integrations: integrations(config, axios, token),
        search: search(config, axios, token),
        images: images(config, axios, token),
        templates: templates(config, axios, token),
        billing: billing(config, axios, token),
        popups: popups(config, axios, token),
        settings: settings(config, axios, token),
        discounts: discounts(config, axios, token),
        codes: codes(config, axios, token),
        sms: smsApi(config, axios, token),
        developers: developers(config, axios, token),
        globalAutomations: globalAutomations(config, axios, token),

    }
}
