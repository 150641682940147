import {percentage} from "@/helpers/analytics";

export default (config, axios, token) => {

    var call = async function(path, method = 'GET', data) {
        data ? data = JSON.stringify(data) : '';
        const url = config.VideosURL + path;
        try {
            const response = await axios({
                url,
                method,
                data,
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type':'application/json',
                }
            })
            return response.data
        } catch (error) {
            return { error: true, info: error.response }
        }
    }

    var upload = async function(path, method = 'POST', data) {
        const url = config.VideosURL + path;
        try {
            const response = await axios({
                url,
                method,
                headers: {
                    'Authorization': 'Bearer ' + token,
                },
                data,
                redirect: 'follow'
            })
            return response.data
        } catch (error) {
            return { error: true, info: error.response }
        }
    }

    return {
        // Images
        get: async() =>
            call(''),

        create: async(data, folder = 0, callback) => {
            const url = config.VideosURL + `?folder_id=${folder||0}`;
            try {
                const response = await axios({
                    url,
                    method:"POST",
                    headers: {
                        'Authorization': 'Bearer ' + token,
                    },
                    data,
                    redirect: 'follow',
                    onUploadProgress: function(progressEvent) {
                        let percent = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
                        percent = Math.max(0, percent - 10)
                        callback && callback(percent, progressEvent.loaded, progressEvent.total);

                        console.log("progressEvent", percent, progressEvent.loaded, progressEvent.total)
                    }
                })
                return response.data
            } catch (error) {
                return { error: true, info: error.response }
            }
        },
            // upload(`?folder_id=${folder||0}` , 'POST', data),
        editorAuth: async(page, funnel, data) =>
            call(`editor-auth/${funnel}/${page}` , 'POST', data),

        delete: async(videoId) =>
            call(videoId, 'DELETE'),

        // Image Folders
        foldersGet: async() =>
            call('video-folders'),

        foldersCreate: async(data) =>
            call('video-folders', 'POST', data),

        foldersPatch: async(folder, data) =>
            call('video-folders/' + folder, 'PATCH', data),

        foldersDelete: async(folder) =>
            call('video-folders/' + folder, 'DELETE'),
    }
}
