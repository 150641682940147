<template>
	<div v-if="isOpen" class="hard-refresh-popup_wrapper">
		<div class="hard-refresh-popup">
			<span>There was an update, hard-refresh for better experience</span>
			<div v-if="isMac()" class="__btns">
				<inline-svg :src="require('@/assets/img/apple-keyboard-command.svg')" />  +
				<inline-svg :src="require('@/assets/img/apple-keyboard-shift.svg')" /> +
				<inline-svg :src="require('@/assets/img/alpha-r.svg')" />
			</div>
			<div v-else class="__btns">
				<span class="__ctrl">Ctrl</span>  +
				<inline-svg :src="require('@/assets/img/keyboard-f5.svg')" />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "hard-refresh-popup",
	data() {
		return {
			isOpen: false,
		}
	},
	methods:{
		isMac() {
			return navigator.userAgent.indexOf('Mac OS X') >= 0
		},
		isOutdated(v, oldV) {
			let vParts = v.split('.')
			let oldVParts = oldV.split('.')
			if (vParts.length !== oldVParts.length) return true;
			if (vParts.length < 4) return false;

			for (let i=0; i<4; i++) {
				// format: 1.0.1.timestamp
				if (parseInt(vParts[i]) > parseInt(oldVParts[i])) {
					return true
				}
			}
			return false
		}
	},
	created() {
		let v = this.$cookies.get("dashboard_version");
		console.log("Dashboard version:", v)

		setInterval(()=>{
			try {

				v = this.$cookies.get("dashboard_version");

				let oldV = process.env.VUE_APP_DASHBOARD_VERSION;
				if (!v || !oldV) return;

				if (this.isOutdated(v, oldV)) {
					if (!this.isOpen) {
						console.log("isOutdated new version:", v, ", current version:", oldV)
						this.isOpen = true
					}
				} else {
					this.isOpen = false
				}
			} catch {}
		}, 10000)
	}
}

</script>

<style lang="scss" scoped>
.hard-refresh-popup_wrapper {
	left: 0;
	right: 0;
	flex-direction: column;
	align-items: center;
	pointer-events: none;
	display: flex;
	position: fixed;
	bottom: 8px;
	z-index: 200;

	.hard-refresh-popup {
		pointer-events: all;
		transition: all .4s;
		max-width: 500px;
		background: #414552;
		color: #fff;
		border-radius: 5px;
		box-shadow: 0 7px 14px 0 rgba(0, 0, 0, 0.1),0 3px 6px 0 rgba(0, 0, 0, 0.16);
		padding: 10px 15px;
		display: flex;
		flex-direction: row;
		align-items: center;
		svg {
			width: 24px;
			height: 24px;
			border-radius: 4px;
			border: 1px solid white;
			padding: 2px;

		}
		.__ctrl {
			height: 24px;
			border-radius: 4px;
			border: 1px solid white;
			padding: 2px;

		}
		.__btns {
			display: flex;
			flex-direction: row;
			gap: 5px;
			align-items: center;
			margin-left: 5px;
		}
	}
}
</style>
