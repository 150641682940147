<template>
    <div class="item_sidebar">
        <div class="title title_m_b">{{title}}</div>
        <div class="info_message_box">
            <p>{{value.text}}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'InfoMessage',
    props: ["value", "title"],
    data: function(){
        return {

        }
    },
    methods: {
        dataChanged(val){
            this.$emit('updated', val);
        },
    }
}
</script>
