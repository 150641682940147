<template>
    <div :class="['left-bar', {'collapsed' : barCollapsed}]">
        <a class="logo" @click="$emit('goBack')">
            <img :src="require('@/assets/email/img/logo.svg')" alt="Funnelish">
            <span>Funnelish</span>
        </a>
        <ul class="main-nav list-nav">
            <li>
                <a @click="linkTo(null)" :class="{'router-link-exact-active' : email_tab === null}" content="Add new element" v-tippy='{ placement : "right" }'>
                    <!-- '@/assets/email/img/nav-add-new-element.svg' -->
                    <svg fill="none" viewBox="0 0 19 20" height="20" width="19" xmlns="http://www.w3.org/2000/svg">
                        <path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" d="M9.50016 19.1667C4.43755 19.1667 0.333496 15.0626 0.333496 10C0.333496 4.9374 4.43755 0.833344 9.50016 0.833344C14.5628 0.833344 18.6668 4.9374 18.6668 10C18.6668 15.0626 14.5628 19.1667 9.50016 19.1667ZM9.50016 17.5C13.6423 17.5 17.0002 14.1421 17.0002 10C17.0002 5.85787 13.6423 2.50001 9.50016 2.50001C5.35803 2.50001 2.00016 5.85787 2.00016 10C2.00016 14.1421 5.35803 17.5 9.50016 17.5ZM10.3335 9.16668H13.6668V10.8333H10.3335V14.1667H8.66683V10.8333H5.3335V9.16668H8.66683V5.83334H10.3335V9.16668Z" fill="#4368E0"></path>
                    </svg>
                    <span>Add new element</span>
                </a>
            </li>
            <li>
                <a @click="linkTo('layout')" :class="{'router-link-exact-active' : email_tab === 'layout'}" content="Layout" v-tippy='{ placement : "right" }'>
                    <!-- '@/assets/email/img/nav-layout.svg' -->
                    <svg fill="none" viewBox="0 0 18 18" height="18" width="18" xmlns="http://www.w3.org/2000/svg">
                        <path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" d="M11.5 9.83332H15.6667C16.5872 9.83332 17.3334 10.5795 17.3334 11.5V15.6667C17.3334 16.5871 16.5872 17.3333 15.6667 17.3333H11.5C10.5795 17.3333 9.83335 16.5871 9.83335 15.6667V11.5C9.83335 10.5795 10.5795 9.83332 11.5 9.83332ZM11.5 11.5V15.6667H15.6667V11.5H11.5ZM8.16669 2.33332V15.6667C8.16669 16.5871 7.4205 17.3333 6.50002 17.3333H2.33335C1.41288 17.3333 0.666687 16.5871 0.666687 15.6667V2.33332C0.666687 1.41285 1.41288 0.666656 2.33335 0.666656H6.50002C7.4205 0.666656 8.16669 1.41285 8.16669 2.33332ZM6.50002 2.33332H2.33335V15.6667H6.50002V2.33332ZM11.5 0.666656H15.6667C16.5872 0.666656 17.3334 1.41285 17.3334 2.33332V6.49999C17.3334 7.42046 16.5872 8.16666 15.6667 8.16666H11.5C10.5795 8.16666 9.83335 7.42046 9.83335 6.49999V2.33332C9.83335 1.41285 10.5795 0.666656 11.5 0.666656ZM11.5 2.33332V6.49999H15.6667V2.33332H11.5Z" fill="#4368E0"></path>
                    </svg>
                    <span>Layout</span>
                </a>
            </li>
            <li>
                <a @click="linkTo('pageProperties')" :class="{'router-link-exact-active' : email_tab === 'pageProperties'}" content="Properties" v-tippy='{ placement : "right" }'>
                    <!-- '@/assets/email/img/nav-properties.svg' -->
                    <svg fill="none" viewBox="0 0 15 20" height="20" width="15" xmlns="http://www.w3.org/2000/svg">
                        <path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" d="M9.16683 2.49999V4.99999H7.50016V2.49999H5.8335V5.83333H4.16683V2.49999H2.50016V7.49999H12.5002V2.49999H9.16683ZM0.833496 7.49999V0.833328H14.1668V7.49999L14.1668 8.33334C14.1668 9.58333 14.1668 9.58333 14.1668 10.8285C14.1741 12.0971 13.4663 12.4986 11.493 13.0899C10.3363 13.4365 10.0002 13.6269 10.0002 13.75C10.0002 14.3897 10.0587 14.8192 10.1836 15.3187C10.1595 15.2221 10.3018 15.7725 10.3332 15.9191C10.3885 16.1771 10.4168 16.4118 10.4168 16.6667C10.4168 18.0507 9.11489 19.1667 7.50016 19.1667C5.88543 19.1667 4.5835 18.0507 4.5835 16.6667C4.5835 16.4118 4.61183 16.1771 4.6671 15.9191C4.69852 15.7725 4.84086 15.2221 4.81671 15.3187C4.94159 14.8192 5.00016 14.3897 5.00016 13.75C5.00016 13.6261 4.66492 13.4361 3.51071 13.0898C1.53992 12.4986 0.833496 12.0983 0.833496 10.8333V7.49999ZM2.50016 9.16666V10.8333C2.50016 10.9572 2.8354 11.1472 3.98962 11.4935C5.9604 12.0847 6.66683 12.485 6.66683 13.75C6.66683 14.5339 6.58998 15.0975 6.43361 15.7229C6.44853 15.6633 6.3196 16.1618 6.29677 16.2684C6.26466 16.4182 6.25016 16.5383 6.25016 16.6667C6.25016 17.0683 6.75378 17.5 7.50016 17.5C8.24654 17.5 8.75016 17.0683 8.75016 16.6667C8.75016 16.5383 8.73566 16.4182 8.70356 16.2684C8.68073 16.1618 8.5518 15.6633 8.56671 15.7229C8.41034 15.0975 8.3335 14.5339 8.3335 13.75C8.3335 12.4856 9.04124 12.0847 11.011 11.4945L11.0146 11.4934C12.1672 11.148 12.5009 10.9587 12.5002 10.8333C12.5002 9.94596 12.5002 9.68853 12.5002 9.16666H2.50016Z" fill="#4368E0"></path>
                    </svg>
                    <span>Properties</span>
                </a>
            </li>

<!--	        TODO: not in use for now, later maybe load email details, and SMTP settings. -->
<!--            <li>-->
<!--                <a @click="linkTo('/email-editor/settings')" :class="{'router-link-exact-active' : $route.name === 'Settings'}" content="Settings" v-tippy='{ placement : "right" }'>-->
<!--                    &lt;!&ndash; '@/assets/email/img/nav-settings.svg' &ndash;&gt;-->
<!--                    <svg fill="none" viewBox="0 0 19 20" height="20" width="19" xmlns="http://www.w3.org/2000/svg">-->
<!--                        <path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" d="M16.8946 15.5665L15.047 17.4142L12.6821 16.7554L12.0058 17.0343L10.7917 19.1658H8.17955L6.97219 17.027L6.29656 16.7438L3.9319 17.3944L2.08514 15.5476L2.74393 13.1828L2.46494 12.5063L0.333496 11.2913V8.67928L2.47314 7.47195L2.75639 6.79639L2.10575 4.43173L3.95181 2.58567L6.3167 3.24538L6.99243 2.9667L8.20469 0.833328H10.8175L12.0248 2.97297L12.7004 3.25623L15.0647 2.60569L16.9136 4.45196L16.2547 6.81722L16.5335 7.4931L18.6668 8.70537V11.3182L16.5274 12.5254L16.2439 13.2025L16.8946 15.5665ZM14.4842 13.0898L15.2107 11.3547L17.0002 10.3449V9.67526L15.2114 8.65879L14.4953 6.92246L15.0467 4.94304L14.5726 4.46968L12.5872 5.01599L10.8539 4.28924L9.84426 2.49999H9.17458L8.15812 4.28879L6.42163 5.00495L4.44184 4.45267L3.96996 4.92455L4.51616 6.90962L3.78941 8.64291L2.00016 9.65253V10.323L3.78751 11.3418L4.50337 13.0775L3.95185 15.0573L4.42472 15.5302L6.40979 14.984L8.143 15.7107L9.15259 17.4992H9.82294L10.841 15.7119L12.5769 14.9959L14.5567 15.5475L15.0303 15.0738L14.4842 13.0898ZM9.50016 13.3333C7.65921 13.3333 6.16683 11.8409 6.16683 9.99999C6.16683 8.15905 7.65921 6.66666 9.50016 6.66666C11.3411 6.66666 12.8335 8.15905 12.8335 9.99999C12.8335 11.8409 11.3411 13.3333 9.50016 13.3333ZM9.50016 11.6667C10.4206 11.6667 11.1668 10.9205 11.1668 9.99999C11.1668 9.07952 10.4206 8.33333 9.50016 8.33333C8.57969 8.33333 7.8335 9.07952 7.8335 9.99999C7.8335 10.9205 8.57969 11.6667 9.50016 11.6667Z" fill="#4368E0"></path>-->
<!--                    </svg>-->
<!--                    <span>Settings</span>-->
<!--                </a>-->
<!--            </li>-->

        </ul>
        <ul class="bottom-nav list-nav">
            <li>
	            <!-- FIXME: CSS is a mess not stored, and stored in memory... -->
               <a @click="codePopupOpen=true" content="Custom CSS" v-tippy='{ placement : "right" }'>
                   <!-- '@/assets/email/img/nav-code-editor.svg' -->
                   <svg fill="none" viewBox="0 0 19 20" height="20" width="19" xmlns="http://www.w3.org/2000/svg">
                       <path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" d="M9.50016 19.1667C4.43755 19.1667 0.333496 15.0626 0.333496 10C0.333496 4.9374 4.43755 0.833344 9.50016 0.833344C14.5628 0.833344 18.6668 4.9374 18.6668 10C18.6668 15.0626 14.5628 19.1667 9.50016 19.1667ZM9.50016 17.5C13.6423 17.5 17.0002 14.1421 17.0002 10C17.0002 5.85787 13.6423 2.50001 9.50016 2.50001C5.35803 2.50001 2.00016 5.85787 2.00016 10C2.00016 14.1421 5.35803 17.5 9.50016 17.5ZM7.58942 12.7441L6.41091 13.9226L2.48832 10L6.41091 6.07742L7.58942 7.25593L4.84534 10L7.58942 12.7441ZM11.4109 7.25593L12.5894 6.07742L16.512 10L12.5894 13.9226L11.4109 12.7441L14.155 10L11.4109 7.25593ZM9.48882 15.137L7.84483 14.863L9.5115 4.86301L11.1555 5.13701L9.48882 15.137Z" fill="#C5C7D2"></path>
                   </svg>
                   <span>Custom CSS</span>
               </a>

	            <portal to="popup">
		            <CodePopup v-if="codePopupOpen" :insertCode="false" @close="codePopupOpen=false"
		                       @done="popupContentUpdated" :key="'main_code_popup'" />
	            </portal>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import CodePopup from "@/modules/email-editor/src/components/popups/CodePopup";
export default {
    name: 'LeftBar',
    components: {
	    CodePopup

    },
    data: function() {
        return {
	        codePopupOpen: false,
            barCollapsed: true
        };
    },
    computed: {
        ...mapGetters(['email_findAll', 'email_tab']),
        currentTemplate() {
            return this.$store.state.email.currentTemplate
        },
    },
    methods: {
	    popupContentUpdated({css}) {
	    	this.codePopupOpen = false;
		    this.$store.dispatch('EMAIL_SET_CUSTOM_CODE', {css});
	    },
        linkTo(link){
            // this.$router.replace({path: link})
            this.$store.commit('EMAIL_SET_TABID', {tab: link, id: null});
        },
    }
}
</script>


<style lang="scss">
.app-email-editor {
    .left-toggle {
        cursor: pointer;
    }
    .left-bar > .main-nav {
        padding: 10px;
        margin-top: 0;
        justify-content: flex-start;
    }
}
</style>
