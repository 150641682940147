import {
    shadows, sticky,
} from './fields/'

export default {
    tag: 'section', // div, h1-h6, p, img, video ...
    labelTitle: 'My section', // String
    labelColor: '', // String => #hex || rgba()
    id: { value: null },
    attributes: {
        basic: {
            margin: { title: 'Margin', comp: 'sectionMargin', value: { top: 0, left: 0, right: 0, bottom: 0 } },
            padding: { title: 'Padding', comp: 'marginPadding', value: { top: 0, left: 0, right: 0, bottom: 0 } },
            width: {
                title: 'Width',
                comp: 'selectItem',
                value: 'full',
                options: [{ id: "full", text: "Full width" }, { id: "wide", text: "Wide" }, { id: "half", text: "Half width" }]
            },
            backgroundColor: { title: 'Background color', comp: 'colorPicker2', value: 'transparent' },
            backgroundImage: { title: 'Background image', comp: 'imageUploader', value: '' },
            backgroundStyle: { title: 'Background style', comp: 'selectItem',
                value: 'cover', options:[{id:'auto',text:'Auto (default)'},{id:'cover',text:'Cover'}] },
            backgroundPosition: { title: 'Background position', comp: 'selectItem',
                value: 'top', options:[{id:'top',text:'Top (default)'},{id:'center',text:'Center'},{id:'bottom',text:'Bottom'}] },
            // font: { title: 'Font', comp: 'Font', value: { fontFamily: 'Roboto', fontSize: 14 } },
            // color: { title: 'Color', comp: 'colorPicker', value: '#F6F9FE' },
            // fontStyling: { title: 'Font styling', comp: 'FontStyling', value: '' },
            // align: { title: 'Text align', comp: 'TextAlign', value: 'initial' },
            font: { title: 'Font', comp: 'FontPanel', value: { fontFamily: '', fontSize: 14, color: '#2f2f2f', fontStyling: { bold: false, italic: false, 'line-through': false, underline: false, }, textAlign: 'initial' } },
            border: {
                title: 'Border',
                comp: 'border',
                value: {
                    width: 0, // Integer
                    color: 'transparent', // String => #hex || rgba()
                    style: 'solid', // String => solid || dashed || dotted,
                    sides: { top: true, left: true, right: true, bottom: true }, // Object => Boolean
                    radius: { analytics: 0, topRight: 0, bottomRight: 0, bottomLeft: 0 }, // Object => Integers
                }
            },
            shadows
        },
        advanced: {
            cssId: { title: 'ID', comp: 'TextInput', value: '' }, // !!!Unique!!!
            title: { title: 'Title', comp: 'TextInput', value: 'My section' }, //
            cssClasses: { title: 'CSS Classes', comp: 'MultiSelectItem', value: [], options: [] }, // Array of Strings
            sticky

            // animations: {
            //     title: 'Animations',
            //     comp: 'AnimationsInput', //no options yet..
            //     value: {
            //         property: '', // String => all || transform || width || height ...
            //         duration: 0, // Float => 0.2 ...
            //         easing: '', // String => ease-in-out || ease ...
            //     }
            // },
            // transformation: {
            //     title: 'Transformation',
            //     comp: 'TransformationInput',
            //     value: {
            //         rotateX: 0, // Integer => 0 - 360
            //         rotateY: 0, // Integer => 0 - 360
            //         rotateZ: 0, // Integer => 0 - 360
            //         scaleX: 0, // Integer
            //         scaleY: 0, // Integer
            //         scaleZ: 0, // Integer
            //     }
            // }
        },
    },
}
