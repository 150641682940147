<template>
	<div class="entry order-item" :class="{
		'canceled':order.is_canceled,
		'archived':order.is_archived,
		'pending':order.payment_status==='processing',
		'failed':order.payment_status==='failed'}"
	     @click="orderDetailsOpen=true">
		<div class="cell time">
			<span class="cell-value">{{ date }}</span>
		</div>
		<div class="cell status" v-if="order.payment_status==='processing'">
			<inline-svg :src="require('@/assets/img/clock-outline.svg')" />
			Pending
		</div>
		<div class="cell status" v-else-if="order.payment_status==='failed'">
			<inline-svg :src="require('@/assets/img/circle-error.svg')" />
			Failed
		</div>
		<div class="cell status" v-else-if="order.is_fulfilled">
			<inline-svg :src="require('@/assets/img/finish.svg')" />
			Fulfilled
		</div>
		<div class="cell status" v-else-if="order.is_refunded">
			<inline-svg :src="require('@/assets/img/refund-2.svg')" />
			Refunded
		</div>
		<div class="cell status" v-else-if="order.is_subscription" v-tippy="{theme:'small-black'}" content="New subscription">
			<inline-svg :src="require('@/assets/img/new-subscription-cl.svg')" />
			Paid
		</div>
		<div class="cell status" v-else>
			<inline-svg :src="require('@/assets/img/circle-checked.svg')" />
			Paid
		</div>
		<div class="cell customer">
			<div v-if="order.first_name || order.last_name" class="cell-title">
				{{ order.first_name }} {{ order.last_name }}
			</div>
			<div v-else-if="order.optin_email" class="cell-title">
				{{ order.optin_email }}
			</div>
			<div v-if="order.first_name || order.last_name" class="cell-value">
				{{ order.optin_email }}
			</div>
			<div v-else-if="!order.optin_email" class="cell-title">
				Unknown customer
			</div>
		</div>
		<div class="cell product">
			<span class="name">{{ order.name }}</span>
			<span class="more">{{order.items_count>1?` + ${order.items_count-1} more`:''}}</span>
		</div>
		<div class="cell amount">
			{{formatPrice(order.currency, order.is_canceled ? '0.00' : order.amount)}}
		</div>
		<div class="cell actions">
			<quick-actions-btn :items="[
		            		{label:'Fulfill',icon:require('@/assets/img/finish.svg'),
		            		disabled: order.is_fulfilled||order.is_canceled},
		            		{label:'Update tracking info', disabled: order.is_canceled || !order.is_fulfilled},'',
		            		{label:order.is_archived?'Unarchive':'Archive',icon:require('@/assets/img/trash.svg')},
		            		{label:'Cancel order', disabled: order.is_canceled},
		            		{label:'Refund',disabled:!canRefund},
		            		'',{label:'Delete order',disabled:false,icon:require('@/assets/img/cross.svg')}]"
			                   @menuClick="menuClick" :loading="orderMenuLoading" />
		</div>

		<portal to="popup">
			<popup-backdrop v-if="orderDetailsOpen" @close="orderDetailsOpen=false">
				<order-details-new :orderId="order.id" @close="orderDetailsOpen=false" />
			</popup-backdrop>

			<intermediate-panel v-if="customerOpen" @close="customerOpen=false"
			                    :customer-id="order.customer_id"
			                    :order-id="order.id" panel="customer-details-new"/>

			<intermediate-panel v-if="cancelOrderOpen" @close="cancelOrderOpen=false"
			                    :order-id="order.id" panel="cancel-order" />

			<intermediate-panel v-if="deleteOrderOpen" @close="deleteOrderOpen=false"
			                    :order-id="order.id" panel="delete-order" />

			<intermediate-panel v-if="fulfillOrderOpen" @close="fulfillOrderOpen=false"
			                    :order-id="order.id" panel="fulfill-order" />

			<intermediate-panel v-if="addTrackingInfoOpen" @close="addTrackingInfoOpen=false"
			                    :order-id="order.id" panel="add-tracking-info" />

			<intermediate-panel v-if="refundOpen" @close="refundOpen=false"
			                    :order-id="order.id" panel="refund-order"/>
		</portal>

	</div>
</template>

<script>
import QuickActionsBtn from "@/components/base/quick-actions-btn";
import {formatPrice, getSymbol} from "@/helpers/currencies";
import API from '@/api';
import PopupBackdrop from "@/components/base/popup-backdrop";
import CustomerDetailsNew from "@/views/customers/customer-details-new";
import CancelOrder from "@/views/orders/cancel-order";
import DeleteOrder from "@/views/orders/delete-order";
import FulfillOrder from "@/views/orders/fulfill-order";
import AddTrackingInfo from "@/views/orders/add-tracking-info";
import OrderDetailsNew from "@/views/orders/order-details-new";
import {mapGetters} from "vuex";
import numbers from '@/helpers/numbers'
import {getGatewayById} from "@/helpers/gateways";
import RefundOrder from "@/views/orders/refund-order";
import IntermediatePanel from "@/views/orders/intermediate-panel";
const moment = require('moment-timezone');

export default {
	name: "order-item", components: {
		IntermediatePanel,
		RefundOrder,
		OrderDetailsNew,
		AddTrackingInfo,
		FulfillOrder,
		DeleteOrder,
		CancelOrder, CustomerDetailsNew, PopupBackdrop, QuickActionsBtn}, props: ['order'],
	mixins:[API],
	data(){
		return {
			orderDetailsOpen: false,
			customerOpen: false,
			refundOpen: false,
			orderMenuLoading: false,
			cancelOrderOpen: false,
			deleteOrderOpen: false,
			fulfillOrderOpen: false,
			addTrackingInfoOpen: false,
		}
	},
	computed:{
		...mapGetters(['getOrdersStore']),
		canRefund(){
			return this.gateway.canRefund && !this.order.is_refunded && this.order.reference;
		},
		gateway(){
			return getGatewayById(this.order.gateway_id);
		},
		user() {
			return this.$store.state.userInfo || {};
		},
		date() {
			let d = this.order.created_at;

			let dd = moment(d);
			if (dd.isSame(moment(), 'day'))
				return 'Today, '+dd.format("HH:mm")
			if (dd.isSame(moment(), 'year'))
				return dd.format("DD MMM, HH:mm")
			return dd.format("DD MMM YYYY, HH:mm")
		},
	},
	methods:{
		formatPrice(currency, price) {
			return formatPrice(currency, this.round(price))
		},
		round(n){
			return numbers.round_to_two(n)
		},
		menuClick(e){
			if (e.action==='Archive'||e.action==='Unarchive') {
				this.archiveOrder();
			} else if (e.action==='Cancel order') {
				this.cancelOrderOpen = true;
			} else if (e.action==='Delete order') {
				this.deleteOrderOpen = true;
			} else if (e.action==='Fulfill') {
				this.fulfillOrderOpen = true;
			} else if (e.action==='Refund') {
				this.refundOpen = true;
			} else if (e.action==='Update tracking info') {
				this.addTrackingInfoOpen = true;
			}
		},
		currency(c) {
			return getSymbol(c);
		},
		async archiveOrder(){
			try {
				this.orderMenuLoading = true;

				let order = await this.api.orders.archiveToggle(this.order.id);
				if (order.error) this.$error(order.error);
				else {
					this.order.is_archived = order.is_archived;
					let o = this.getOrdersStore.find(x=>x.id===this.order.id);
					if (o) {
						o.is_archived = order.is_archived;
					}
				}
			} catch (e) {
				this.$error(e)
			} finally {
				this.orderMenuLoading = false;
			}
		},
	}
}
</script>

<style lang="scss" scoped>
.order-item {
	border-radius: 8px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	line-height: 1.5;
	padding: 0 20px;
	opacity: 0;
	animation: appear 1 0.3s forwards;
	background: transparent !important;

	&:hover {
		background: $light-silver !important;
	}
	&.canceled {
		.cell-title, .cell-value, .amount, .name {
			text-decoration: line-through;
		}
	}
	&.archived {
		.cell-title, .cell-value, .status, .amount, .name {
			color: $dark-grey;
		}
	}
	&.pending {
		.status {
			color: #646c7d;
		}
	}
	&.failed {
		.status {
			color: $light-red;
		}
	}
}
</style>
