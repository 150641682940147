export default {
    tag: 'paragraph',
    labelTitle: '',
    labelColor: '',
    id: { value: null },
    content: '<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde, maxime.</p>',
    attributes: {
        basic: {
            margin: { title: 'Margin', comp: 'marginPadding', value: { top: 0, left: 0, right: 0, bottom: 0 } },
            backgroundColor: { title: 'Background color', comp: 'colorPicker', value: 'transparent' },
            // font: { title: 'Default font', comp: 'FontPanel', value: { fontFamily: 'Roboto', fontSize: 14, color: '#636A7B', fontStyling: { bold: false, italic: false, 'line-through': false, underline: false, }, textAlign: 'left' } },
            border: {
                title: 'Border',
                comp: 'border',
                value: {
                    width: 0,
                    color: 'transparent',
                    style: 'solid',
                    sides: { top: true, left: true, right: true, bottom: true },
                    radius: { leftTop: 0, topRight: 0, bottomRight: 0, bottomLeft: 0 },
                }
            },
        },
        advanced: {
            cssId: { title: 'ID', comp: 'TextInput', value: '' },
            cssClasses: { title: 'CSS classes', comp: 'MultiSelectItem', value: [], options: [{ id: 'class1', text: 'class1' }, { id: 'class2', text: 'class2' }, { id: 'class3', text: 'class3' }] },
            padding: { title: 'Padding', comp: 'marginPadding', value: { top: 0, left: 0, right: 0, bottom: 0 } },
        },
    },
}
