import {
    shadows, sticky,
} from './fields/'

export default {
    tag: 'spacer',
    labelTitle: '',
    labelColor: '',
    id: { value: null },
    attributes: {
        basic: {
            margin: { title: 'Margin', comp: 'marginPadding', value: { top: 0, left: 0, right: 0, bottom: 0 } },
            widthNumber: { title: 'Width', comp: 'InputNumber', value: { number: 2 } },
            color: { title: 'Color', comp: 'colorPicker2', value: '#D5E5FF' },
            style: { title: 'Border style', comp: 'selectItem', value: 'solid', options: [{ id: "dotted", text: "Dotted" }, { id: "dashed", text: "Dashed" }, { id: "solid", text: "Solid" }, { id: "double", text: "Double" }, { id: "groove", text: "Groove" }, { id: "ridge", text: "Ridge" }, ] },
            // content: { title: 'Spacer title', comp: 'TextInput', value: '' },
            // alignment: {
            //     title: 'Alignment', comp: 'Alignment', value: { alignSide: 'left' }
            // }
            padding: { title: 'Padding', comp: 'marginPadding', value: { top: 0, left: 0, right: 0, bottom: 0 } },
            shadows
        },
        advanced: {
            cssId: { title: 'ID', comp: 'TextInput', value: '' },
            cssClasses: { title: 'CSS Classes', comp: 'MultiSelectItem', value: [], options: [] },
            sticky

            // animations: {
            //     title: 'Animations',
            //     comp: 'AnimationsInput',
            //     value: {
            //         property: '',
            //         duration: 0,
            //         easing: '',
            //     }
            // },
            // transformation: {
            //     title: 'Transformation',
            //     comp: 'TransformationInput',
            //     value: {
            //         rotateX: 0,
            //         rotateY: 0,
            //         rotateZ: 0,
            //         scaleX: 1,
            //         scaleY: 1,
            //         scaleZ: 1,
            //     }
            // },
        },
    },
}
