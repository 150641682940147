import {
    shadows, border, hiddenFont, sticky
} from './fields/'

export default {
    tag: 'paragraph',
    labelTitle: '',
    labelColor: '',
    id: { value: null },
    content: '<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde, maxime.</p>',
    attributes: {
        basic: {
            margin: { title: 'Margin', comp: 'marginPadding', value: { top: 10, left: 0, right: 0, bottom: 0 } },
            backgroundColor: { title: 'Background color', comp: 'colorPicker2', value: null },
            font: hiddenFont,
            lineHeight: {
                title:'Line spacing',
                comp: 'SmallNumber',
                value: {
                    append: 'x',
                    placeholder: 'normal',
                    number: 1,
                    min: 0
                }
            },
            letterSpacing: {
                title:'Letter spacing',
                comp: 'SmallNumber',
                value: {
                    append: 'px',
                    placeholder: 'normal',
                    number: null,
                }
            },
            border,
            shadows
        },
        advanced: {
            cssId: { title: 'ID', comp: 'TextInput', value: '' },
            cssClasses: { title: 'CSS Classes', comp: 'MultiSelectItem', value: [], options: [{ id: 'paragraph', text: 'paragraph' }] },
            padding: { title: 'Padding', comp: 'marginPadding', value: { top: 10, left: 10, right: 10, bottom: 10 } },
            // animations: {
            //     title: 'Animations',
            //     comp: 'AnimationsInput',
            //     value: {
            //         property: '',
            //         duration: 0,
            //         easing: '',
            //     }
            // },
            textShadows: {
                title: 'Text shadow',
                comp: 'ShadowsInput',
                value: {
                    angle: 0,
                    distance: 0,
                    blur: 0,
                    spread: 0,
                    color: 'transparent',
                    type: '',
                }
            },
            sticky

            // transformation: {
            //     title: 'Transformation',
            //     comp: 'TransformationInput',
            //     value: {
            //         rotateX: 0,
            //         rotateY: 0,
            //         rotateZ: 0,
            //         scaleX: 1,
            //         scaleY: 1,
            //         scaleZ: 1,
            //     }
            // },
        },
    },
}
