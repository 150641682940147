export default (config, axios, token) => {

    var call = async function(path, method = 'GET', data) {
        // data ? data = JSON.stringify(data) : '';
        const url = config.URL + path;
        //console.log('call ', path, method);
        try {
            const response = await axios({
                url,
                method,
                data,
                headers: {
                    'Content-Type':'application/json',
                    'Authorization': 'Bearer ' + token }
            })
            return response.data
        } catch (error) {
            return { error: true, info: error.response }
        }
    }

    return {
        // Shared email templates
        get: async(id=false) =>
            call(id ? 'email-templates/' + id : 'email-templates'),

        create: async(template) =>
            call(`users/${template.user_id}/email-templates`, 'POST', template),
    }
}
