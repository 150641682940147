<template>
<div class="quick-actions-btn" @click.stop @click.prevent :class="{disabled}">
	<tippy :ref="'quick_action_btn_tippy_'+id"
			interactive
			:animate-fill="false"
			placement="bottom-end"
			distant="7"
			theme="menu"
			animation="fade"
			trigger="click">
		<template v-slot:trigger>
			<v-button v-if="!noTooltip" slim flat extra-small class="small-btn" v-tippy="{theme:'small-black'}" :loading="loading"
			          :content="content" :icon="require('@/assets/img/ellipsis.svg')" />
			<v-button v-else slim flat extra-small class="small-btn" :loading="loading"
			          :icon="require('@/assets/img/ellipsis.svg')" />
		</template>
		<div class="menu-items">
			<menu-item v-for="(item,index) in items" :label="item.label || item" :src="item.icon || ''"
			           @click.stop="click(item,$event)" :active="item.active||false" :disabled="item.disabled||false" :key="'menu_item_'+index" />
		</div>
	</tippy>
</div>
</template>

<script>
import VButton from "@/components/base/v-button";
import MenuItem from "@/components/base/menu-item";
let id = 0;
export default {
	name: "quick-actions-btn", components: {MenuItem, VButton},
	props: {
		content: {
			default:'Quick actions',
		},
		disabled:{
			default:false,
			type: Boolean
		},
		noTooltip:{
			default:false,
			type: Boolean
		},
		items:{
			type: Array
		},
		loading: {
			type: Boolean,
			default: false,
		}
	},
	data(){
		return {
			id: id++,
		}
	},
	methods:{
		click(item,ev){
			let action = item.label || item;
			let tippy = this.$refs[`quick_action_btn_tippy_${this.id}`];
			tippy.tippy().hide();
			this.$emit('menuClick', {action,event:ev})
			this.$emit('itemClick', {action,event:ev})
		}
	},

}
</script>

<style lang="scss" scoped>
.quick-actions-btn {
	display: flex;
	.small-btn {
		color: $dark-grey;
		&:hover {
			color: $darker-grey;
		}
	}
	&.disabled {
		pointer-events: none;
		color: $light-grey;
	}

}
</style>
