<template>
<span class="avatar" :class="{small}">
	<span class="x">{{letter}}</span>
</span>
</template>

<script>
export default {
	name: "customer-avatar",
	props:{
		firstName:{
			type: String,
		},
		lastName:{
			type: String,
		},
		img:{
			type: String,
		},
		small:{
			type: Boolean,
			default: false,
		}
	},
	computed:{
		letter(){
			return (this.firstName || this.lastName || 'X').substr(0,1);
		}
	},
}
</script>

<style lang="scss" scoped>
.avatar {
	border-radius: 50%;
	background: orange;
	color: white;
	font-size: 16px;
	height: 40px;
	min-height: 40px;
	width: 40px;
	position: relative;
	display: flex;
	margin-right: 10px;

	&.small {
		height: 20px;
		min-height: 20px;
		width: 20px;
		font-size: 13px;
	}
	.x {
		margin: 0;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}
</style>
