<template>
	<div class="layout-column" :class="$style.base">
		<div class="range-picker" ref="parent">

			<div class="layout-row" v-if="!noReload">
				<v-button class="reload-btn" :class="{loading:loading}" border extra-small v-tippy="{theme:'small-black'}" content="Reload charts"
				          @click="$emit('reload')" :disabled="loading" new-style>
					<svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fill-rule="evenodd" clip-rule="evenodd" d="M4.16088 4.33334H7.33332V6.00001H1.49999V0.166672H3.16666V2.89196C4.60425 1.1521 6.66313 0.166672 8.99999 0.166672C13.6024 0.166672 17.3333 3.89763 17.3333 8.50001C15.6667 8.50001 17.3333 8.50001 15.6667 8.50001C15.6667 4.81811 12.6819 1.83334 8.99999 1.83334C7.0053 1.83334 5.29461 2.73225 4.16088 4.33334ZM13.8391 12.6667H10.6667V11H16.5V16.8333H14.8333V14.108C13.3957 15.8479 11.3369 16.8333 8.99999 16.8333C4.39762 16.8333 0.666656 13.1024 0.666656 8.50001H2.33332C2.33332 12.1819 5.31809 15.1667 8.99999 15.1667C10.9947 15.1667 12.7054 14.2678 13.8391 12.6667Z"
                  fill="currentColor"/>
					</svg>
				</v-button>
			</div>

			<div >


				<v-button :name="'range_picker_range_trigger-'+id" border class="range-selector" ref="dropdown" @click="tippyDropdown.show()" >
					<span>{{currentRangeKey}}</span>
					<inline-svg :src="require('@/assets/img/mainleft_new_step_arrow.svg')"/>
				</v-button>

				<tippy :to="'range_picker_range_trigger-'+id" :ref="'tippy-'+id"
				       theme="transparent" placement="bottom-end" trigger="manual" hideOnClick interactive interactiveBorder="0">
					<div class="mainleft_new_step_dropdown range-picker-dropdown" >
						<ul>
							<li v-for="(range, key) in ranges">
								<a @click="selectRange(key, range)">
									{{key}}
								</a>
							</li>
						</ul>
					</div>
				</tippy>
			</div>

			<div class="layout-column">
				<v-button class="date-selector" border @click="toggle()" ref="start_date">
					{{currentDateRange}}
				</v-button>
			</div>
		</div>

		<div class="container" ref="container"></div>
	</div>
</template>

<script>
import Litepicker from 'litepicker';
import VButton from "@/components/base/v-button";
const moment = require('moment-timezone');

let id = 1;

export default {
	name: "range-picker",
	components: {VButton},
	props: ["startDate", "endDate", "minDate", "maxDate", "rangeName", "loading", "noReload"],

	data(){
		return {
			id: id++,
			tippyDropdown: false,
			dropdownOpen: false,

			currentStartDate: this.startDate,
			currentEndDate: this.endDate,
			picker: null,
			shown: false,

			currentRange: this.rangeName,
		}
	},

	computed: {
    user(){
      return this.$store.state.userInfo
    },
		ranges() {
      return {
				'Today': [()=>moment().startOf('day'), ()=>moment().endOf('day')],
				'Yesterday': [()=>moment().subtract(1, 'days').startOf('day'), ()=>moment().subtract(1, 'days').endOf('day')],
				'Last 7 days': [()=>moment().subtract(6, 'days').startOf('day'), ()=>moment().endOf('day')],
				'Last 30 days': [()=>moment().subtract(29, 'days').startOf('day'), ()=>moment().endOf('day')],
				'This month': [()=>moment().startOf('month'), ()=>moment().endOf('month')],
				'Last month': [()=>moment().subtract(1, 'month').startOf('month'), ()=>moment().subtract(1, 'month').endOf('month')],
				'Last 12 months': [()=>moment().subtract(11, 'months').startOf('month'), ()=>moment().endOf('month')],
				'All time': [()=>moment.utc(this.minDate), ()=>moment().endOf('day')],
			};
		},
		currentRangeKey() {
			for (const [key, value] of Object.entries(this.ranges)) {
				// console.log("value[0]", value[0])
				if (value[0]().isSame(this.currentStartDate, 'day') &&
						value[1]().isSame(this.currentEndDate, 'day')) {
					return key;
				}
			}
			return 'Custom';
		},
		currentDateRange() {
			let start = moment(this.currentStartDate);
			if (start.isSame(moment(), "year")) start = start.format("DD MMM");
			else start = start.format("DD MMM yyyy");

			let end = moment(this.currentEndDate);
			if (end.isSame(moment(), "year")) end = end.format("DD MMM");
			else end = end.format("DD MMM yyyy");

			return start + "-" + end;
		}
	},
	methods: {
		onRangeChanged() {
			this.$emit('rangeChanged', this.currentRange, this.currentStartDate, this.currentEndDate);
		},
		selectRange(key, range) {
			this.currentRange = key;

			this.currentStartDate = moment(range[0]()).startOf("day");
			this.currentEndDate = moment(range[1]()).endOf("day");
			this.tippyDropdown.hide();

      console.log("selectRange this.currentStartDate", this.currentEndDate)

      this.onRangeChanged();
		},
		setupPickers() {
			this.picker = new Litepicker({
				startDate: moment(this.currentStartDate),
				endDate: moment(this.currentEndDate),
				minDate: moment(this.minDate),
				maxDate: moment(this.maxDate),
				minDays: 1,

				parentEl: this.$refs.container,
				inlineMode: false,
				format: 'D MMM, YYYY',
				numberOfMonths: 1,
				numberOfColumns: 1,
				singleMode: false,
				autoApply: true,
				mobileFriendly: false,
				lang: "en-US",
				element: this.$refs.start_date,
				moveByOneMonth: true,
				onShow: () => { this.shown = true; },
				onHide: () => { this.shown = false;},
				onSelect: (date1, date2) => {
					this.currentRange = "Custom";

					this.currentStartDate = moment(date1).startOf("day");
					this.currentEndDate = moment(date2).endOf("day"); // .add(1, 'hour')

          console.log("dt", date1, date2)
          console.log("this.currentStartDate", this.currentStartDate, this.currentEndDate)

          this.onRangeChanged();
				}
			});

		},
		toggle() {
			try {
				if (this.shown)
					this.picker.hide()
				else {
					this.shown = true;//
					if (this.picker) {
						this.picker.destroy();
					}
					this.setupPickers(); // resetup picker in case dates changed.
					this.picker.show();
				}
			}catch (e) {

			}
		}
	},
	mounted() {
    moment.tz.setDefault(this.user.time_zone || "Europe/London");

		this.tippyDropdown = this.$refs['tippy-'+this.id].tippy();
	},
}
</script>
<style lang="scss" module>
.base {
  position: relative;
  :global {
    .litepicker {
      right: 0;
    }
  }
}
</style>
<style lang="scss" scoped>
.range-picker {
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;

	// TODO: Workaround, for cropped dropdown
	min-width: 260px;

	.reload-btn {
		margin-right: 10px;
		padding: 3px !important;
    //height: auto!important;
		border-radius: 7px!important;
		svg {
      width: 16px;
      height: 16px;
      color: #4d4d4d;
			margin: auto!important;
		}
		&.loading svg {
			animation: rotation 2s infinite linear;
		}
		@keyframes rotation {
			from {
				transform: rotate(0deg);
			}
			to {
				transform: rotate(359deg);
			}
		}
	}

	.time-zone {
		display: flex;
		padding: 0 6px;
		vertical-align: middle;
		position: relative;
		line-height: 24px;
		margin-right: 4px;
		cursor: pointer;
		&:hover {
			text-decoration: underline;
		}
	}
	.button {
		border-radius: 6px 0 0 6px;
		display: flex;
		padding: 5px 15px;
		color: #344054;
		font-weight: 600;
		font-size: 12px;
		height: 30px;
		border-color: #D0D5DD;
		box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);

		&:hover {
			color: #344054;
			background: $light-silver;
			border-color: #bcc2ca;
		}
		&.date-selector {
			border-radius: 0 6px 6px 0;
			border-left-width: 0;
		}
		svg {
			display: flex;
			margin-top: 4px;
			margin-left: 6px;
			position: relative;
		}
	}
}
</style>

<style lang="scss">
.range-picker-dropdown {
	min-width: auto;
}
.litepicker .month-item-header {
	font-size: 13px;
}
.litepicker .container__months .month-item:last-child .button-next-month {
	cursor: pointer;
}
.litepicker .container__months .month-item:first-child .button-previous-month {
	cursor: pointer;
}
.litepicker .container__days .day-item {

	&:hover {
		color: #344054;
		box-shadow: inset 0 0 0 1px $blue;
	}
	&.is-in-range {
		background-color: #e2eaf5;
	}
	&.is-end-date, &.is-start-date {
		background-color: $blue;
		color: white;
		&:hover {
			color: white;
		}
	}
	&:hover {
		color: #344054;
	}
}
.litepicker .container__days>div, .litepicker .container__days>a {
	padding: 10px 0;
}


</style>
