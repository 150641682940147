<template>
<div class="filter_condition">
	<div v-if="!editing" class="layout-row flex">
		<v-button label="Change filter" flat slim @click="$emit('back')"
		          :icon="require('@/assets/img/back_bt_arrow.svg')" />
		<div class="flex"></div>
<!--		<v-button label="Remove" flat slim red-->
<!--		          :icon="require('@/assets/img/delete_icon.svg')" />-->
	</div>
	<select-field class="filter_name" clearable label="label"
	              v-model="c.key" small
	              :options="items" caption="Filter" :clearable="false" disabled  />

	<select-field class="filter_operand" label="label" v-model="c.operand" small
	              :options="operands" caption="Operand" :searchable="false" :append-to-body="false"  />

	<select-field v-if="c.key==='country'" class="filter_value" label="text" small
	              :options="countries" caption="Country(s)" searchable clearable multiple
	              :append-to-body="false" v-model="c.values" />


	<div v-else-if="c.key==='currency'"  class="form-group with_top_block">
		<span class="form_group_top_block">Currency(s)</span>

		<v-select class="filter_value" label="text" :reduce="x=>x.id"
		          :clearable="true" :searchable="true" :multiple="true"
		          :options="currencies" v-model="c.values">
			<template v-slot:option="option">
				<span v-html="option.text"></span>
			</template>
			<template v-slot:selected-option="option">
				<span v-html="option.id"></span>
			</template>
		</v-select>
	</div>

<!--	<select-field v-else-if="condition.key==='currency'" class="filter_value" label="text"-->
<!--	              :options="currencies" caption="Currency(s)" searchable clearable :append-to-body="false"  />-->

	<input-field v-else class="filter_value" small label="Value" v-model="c.value" />

	<div class="layout-row flex margin-t-15">
		<v-button label="Cancel" flat slim @click="$emit('cancel')" />
		<v-button class="margin-rl-20" :label="editing?'Save changes':'Add'"
		          :disabled="!c.operand || (!c.value && !c.values && !c.values.length)"
		          slim border @click="$emit('save', c)" />
		<div class="flex"></div>
		<v-button v-if="editing" label="Remove" red flat slim @click="$emit('remove', c)" />
	</div>
</div>
</template>

<script>
import MenuItem from "@/components/base/menu-item";
import InputField from "@/components/base/input-field";
import SelectField from "@/components/base/select-field";
import countries from "@/helpers/countries";
import currencies from "@/helpers/currencies";
import VButton from "@/components/base/v-button";
export default {
	name: "filter-condition", components: {VButton, SelectField, InputField, MenuItem},
	props: ['condition', 'items','editing'],
	data(){
		return {
			c: {},
		}
	},
	// watch:{
	// 	condition:{
	// 		deep:true,
	// 		handler: (val,oVal)=>{
	// 			this.c = JSON.parse(JSON.stringify(this.condition))
	// 		}
	// 	}
	// },
	computed:{
		countries(){
			return countries
		},
		currencies(){
			return currencies
		},
		operands() {
			if (!this.c) return [];
			switch (this.c.key) {
				case "currency":
				case "country":
					return [
						{label:"Is one of",id:"IN"},
						{label:"Is none of",id:"NOT IN"},
					]
				default:
					return [
						{label:"Contains",id:"LIKE"},
						{label:"Does not contain",id:"NOT LIKE"},
						{label:"Equals",id:"="},
					]
			}
		},
	},
	mounted() {
		this.c = JSON.parse(JSON.stringify(this.condition))
	}
}
</script>

<style lang="scss" scoped>
.filter_name {
	margin-top: 20px !important;
}
.filter_condition {
	min-width: 300px;
	padding: 10px;
	.select-field, .input-field, .form-group {
		margin-top: 15px;
	}
	.select-filter {
		display: flex;
		font-size: 13px;
		color: grey;
		margin: 5px 15px;
	}
}
</style>
