<template>
     <td
        :id="advanced.cssId"
        :class="['email_spacer', advanced.cssClasses, responsiveClasses]"
        :style="style"
    ></td>
</template>

<script>
export default {
    name: 'EmailSpacer',
    props: ['details'],
    computed: {
        basic() {
            return this.details.attributes.basic
        },
        advanced() {
            return this.details.attributes.advanced
        },
        responsive() {
            return this.details.attributes.responsive
        },
        responsiveClasses() {
            let c = '';
            if (!this.responsive) return '';
            if (!this.responsive.desktop) c = 'no-desktop';
            if (!this.responsive.mobile) c += ' no-mobile';
            if (!this.responsive.tablet) c += ' no-tablet';
            return c;
        },
        style() {
            var marginBase = this.basic.margin || {};
            var paddingBase = this.basic.padding || {};

	        var margin =
			        parseInt(marginBase.top || 0)+'px '+
			        parseInt(marginBase.right || 0)+'px '+
			        parseInt(marginBase.bottom || 0)+'px '+
			        parseInt(marginBase.left || 0)+'px';

	        var padding =
			        parseInt(paddingBase.top || 0)+'px '+
			        parseInt(paddingBase.right || 0)+'px '+
			        parseInt(paddingBase.bottom || 0)+'px '+
			        parseInt(paddingBase.left || 0)+'px';

            var boxShadow = this.advanced.shadows ?
                this.advanced.shadows.color+ ' ' +
                this.advanced.shadows.angle+'px '+
                this.advanced.shadows.distance+'px '+
                this.advanced.shadows.blur+'px '+
                this.advanced.shadows.spread+'px '+
                this.advanced.shadows.type : false;

            var transform = this.advanced.transformation ?
                'rotateX('+ this.advanced.transformation.rotateX +'deg) ' +
                'rotateY('+ this.advanced.transformation.rotateY +'deg) ' +
                'rotateZ('+ this.advanced.transformation.rotateZ +'deg) ' +
                'scale3d('+this.advanced.transformation.scaleX+', '+this.advanced.transformation.scaleY+', '+this.advanced.transformation.scaleZ+')' : false;

            var transition = this.advanced.animations ?
                this.advanced.animations.duration + 's ' +
                this.advanced.animations.property + ' ' +
                this.advanced.animations.easing : 'initial';

            var obj = {
                height: '0px',
                margin,
                padding,
                borderTopWidth: this.basic.widthNumber ? this.basic.widthNumber.number/2 + 'px' : '1px',
                borderTopColor: this.basic.color ? this.basic.color : '#D5E5FF',
                borderTopStyle: this.basic.style ? this.basic.style : 'solid',
                boxShadow,
                transform,
                transition,
            };
            return obj
        }
    },
}
</script>
