<template>
    <div class="popup_wrpr_inner">
        <div class="popup">
            <div class="popup_header">
                <h3>Delete step</h3>
                <a @click="closePopup" class="close_popup_btn">
                    <inline-svg :src="require('@/assets/img/popup_close.svg')"/>
                </a>
            </div>
            <div class="content" v-if="step == 1">
                <label class="form_steps_label step_1" :class="{'active': (checkboxModel1 && checkboxModel2)}">
                    <span class="icon"></span> Are you sure?
                </label>
                <div class="brdr_content_wrpr">
                    <h3 class="brdr_content_title">I Understand that this will delete:</h3>
	                <v-switch v-model="checkboxModel1" >My funnel step <b>[{{stepName}}]</b> and all its settings.</v-switch>
	                <v-switch :label="`All its products.`" v-model="checkboxModel2" />
                </div>
            </div>
            <div class="content" v-if="step == 2">
                <label class="form_steps_label step_1" :class="{'active': step == 2}">
                    <span class="icon"></span> Deleting your step...
                </label>
                <div class="saving-process">
                    <div class="prog-line">
                        <div class="prog-line-inner" v-bind:style="{width: progressNumber +'%'}"></div>
                    </div>
                    <span class="btm-info">Please wait while the process finishes...</span>
                    <p>P.S. You can close this dialog and let the process to complete at the background</p>
                </div>
            </div>
            <div class="content" v-if="step == 'error'">
                <h3>Delete error</h3>
            </div>
            <div class="popup_btn_wrpr mt_0 active" v-if="step == 1">
                <a
                    @click="progressIncrement"
                    class="btn brdr_btn red_brdr_btn"
                    :class="{'disabled': !(checkboxModel1 && checkboxModel2)}"
                >
                    Delete
                </a>
                <a @click="closePopup" class="btn">Cancel</a>
            </div>
        </div>
    </div>
</template>

<script>
import API from "@/api"
import VSwitch from "@/components/base/v-switch";

export default {
    name: "StepDeletePopup",
	components: {VSwitch},
	props:['funnel', 'funnelId','stepId','stepName'],
    mixins: [API],
    data() {
        return {
            step: 1,
            deleteBtnState: false,
            checkboxModel1: false,
            checkboxModel2: false,
            progressNumber: 0
        }
    },
    computed:{
        isPlus() {
            return this.funnel.funnel_type;
        }
    },
    methods: {
        closePopup() {
            this.$store.commit("closeStepDeletePopup")
        },
        async removeStep() {
            var {id, step} = this.$route.params

            if(this.isPlus) {
                var result = await this.api.wsteps.delete(parseInt(this.funnelId), parseInt(this.stepId));
                if(!result.error) {
                    this.$store.dispatch('REMOVE_STEP', this.stepId);
                    this.$router.push({path: `/wfunnel/${id}`});
                    setTimeout(() => {
                        this.closePopup();
                    }, 200);
                } else {
                    this.step = 'error';
                }
            } else {
                var result = await this.deleteStep(this.stepId);
                if(result.status == '204') {
                    this.$store.dispatch('REMOVE_STEP', this.stepId);
                    this.$router.push({path: `/funnel/${id}`});
                    setTimeout(() => {
                        this.closePopup();
                    }, 200);
                } else {
                    this.step = 'error';
                }
            }
        },
        progressIncrement() {
            this.step = 2;
	        this.removeStep();
            let progressIncrementInterval = setInterval(() => {
                if (this.progressNumber !== 100) {
                    this.progressNumber += 1;
                } else {
                    clearInterval(progressIncrementInterval);
                }
            }, 10);
        }
    },
    // created(){
    //     console.log('Step delete popup this.funnel', this.funnel);
    // }
}
</script>

<style>
    .brdr_box_w_checkbox h3 b {
        margin-right: 3px;
    }
</style>
