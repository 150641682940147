<template>
    <transition name="fade">
        <div class="popup_wrpr popup-backdrop active"
             :class="{fullscreen:isFullScreen, 'no-padding':noPadding,'no-actions':noActions,small,medium,large,relative,'text-only':textOnly}">
            <div class="popup">
                <div class="popup_header">
                    <h3>{{title}}</h3>

                  <div class="layout-row flex-none flex-center gap-10">
                    <template v-if="fullscreen">
                      <v-button :icon="require('@/assets/img/fullscreen.svg')" flat small
                                v-tippy="{theme:'small-black'}" content="Fullscreen"
                                @click="switchFullscreen" v-if="!isFullScreen" />
                      <v-button :icon="require('@/assets/img/fullscreen-exit.svg')" flat small
                                v-tippy="{theme:'small-black'}" content="Exit fullscreen"
                                @click="switchFullscreen" v-else />
                    </template>

                    <v-button :icon="require('@/assets/img/popup_close.svg')" flat small
                              v-tippy="{theme:'small-black'}" content="Close"
                              @click="closePopup" class="" />
                  </div>
                </div>
                <div v-if="subtitle" style="margin: 0 15px 20px;">
                  <span>{{subtitle}}</span>
                </div>

                <div class="content" :class="[noscroll ? 'noscroll' : 'scroll']">
                    <slot name="content"></slot>
                </div>

                <div class="popup_btn_wrpr active actions" v-if="!noActions">
                    <slot name="actions"></slot>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import VButton from "@/components/base/v-button";
export default {
  name: "Popup",
  components: {VButton},
  props: {
    fullscreen:{
      default:false,
      type:Boolean,
    },
    textOnly: {
      default:false,
      type:Boolean
    },
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    noscroll: {
      default: false,
      type: Boolean,
    },
    relative: {
      default: false,
      type: Boolean,
    },
    small: {
      default: false,
      type: Boolean,
    },
    medium: {
      default: false,
      type: Boolean,
    },
    large: {
      default: false,
      type: Boolean,
    },
    noPadding:{
      default:false,
      type:Boolean,
    },
    noActions:{
      default:false,
      type:Boolean,
    }
  },
  data() {
    return {
      isFullScreen:false,
    }
  },
  methods: {
    switchFullscreen() {
      this.isFullScreen = !this.isFullScreen;
    },
    closePopup() {
        this.$emit("close");
    },
  }
}
</script>
<style lang="scss">
    .popup-backdrop {
        visibility: visible;
        opacity: 1;
    }
</style>
<style lang="scss" scoped>
.popup {
  .content {
    flex: 1;
  }
}

.popup_wrpr {
  &.fullscreen {
    .popup {
      border-radius: 0;
      max-width: 100%;
      width: 100%;
      height: 100%;
      max-height: 100%;
      line-height: 1.4;
      margin: 0;
      .popup_header {
        margin-bottom: 0;
      }
      > .content {
        padding: 5px 10px;
      }
      > .actions {
        padding: 10px 20px;
      }
    }
  }
	&.relative {
		.popup {
			position: relative;
		}
	}
	&.no-padding {
		> .popup {
			> .content {
				padding: 0;
			}
		}
	}
	&.text-only {
		.popup_header {
			border: none;
			h3 {
				text-align: center;
				line-height: 1.5;
				margin-top: 10px;
			}
		}

	}
	&.no-actions {

	}
	&.large {
		.popup {
			max-width: 800px;
			line-height: 1.4;
			.popup_header {
				margin-bottom: 0;
			}
			> .content {
				padding: 5px 10px;
			}
			> .actions {
				padding: 10px 20px;
			}
		}
	}
	&.medium {
		.popup {
			max-width: 600px;
			line-height: 1.4;
			.popup_header {
				margin-bottom: 0;
			}
			> .content {
				padding: 5px 10px;
			}
			> .actions {
				padding: 10px 20px;
			}
		}
	}
	&.small {
		.popup {
			max-width: 400px;
			line-height: 1.4;
			.popup_header {
				margin-bottom: 0;
			}
			> .content {
				padding: 5px 10px;
			}
			> .actions {
				padding: 10px 20px;
			}
		}
	}
}
.popup {
	.popup_header {
		padding: 6px 12px;
		margin-bottom: 0;
		h3 {
			font-weight: 600;
			font-size: 16px;
			line-height: 120%;
			letter-spacing: 0;
		}
		.close_popup_btn {
			min-width: 30px;
			min-height: 30px;
			width: 30px;
			height: 30px;
		}
	}
	.close_popup_btn {
		padding: 0;
	}
	.content.noscroll {
		height: 100%;
		width: 100%;
		overflow: hidden;
	}
}
</style>
