export default {
    state: {
        funnel: [],
        stepData: [],
        singleStep: {},
        products: [],
        apps: [],
        appsIncluded: [],
        analytics: {
            graphDataBar: {},
            graphDataBar2: {},
            graphDataLine: {},
            analyticsInfo: {},
            includedData: [],
            widgetStats: []
        },
        orders: [],
        codes: [],
        smsNumbers: [],
        discounts: [],
        ordersMeta: {},
        ordersPage: 0,
        customers: false,
        currentOrder: false, // <-- current open order.
        currentCustomer: false, // <-- current open customer.
    },
    mutations: {
        //CUSTOMERS
        SET_CUSTOMERS(state, value) {
            state.customers = value
        },
        EDIT_CUSTOMER(state, value) {
            if (state.customers) {
                var target = state.customers.findIndex(item => item.id === value.id);
                state.customers.splice(target, 1, value);
            }

            // Update orders too
            if (state.orders && state.orders.length) {
                let orders = state.orders.filter(x => x.customer_id === value.id);
                orders.forEach(x => {
                    x.email = value.optin_email;
                    x.first_name = value.first_name;
                    x.last_name = value.last_name;
                });
            }
        },
        REMOVE_CUSTOMER(state, value) {
            // remove customer from customers list
            if (state.customers && state.customers.length) {
                let target = state.customers.findIndex(item => item.id === value);
                state.customers.splice(target, 1);
            }

            // do the same for orders list (update order customer details)
            if (state.orders && state.orders.length) {
                let orders = state.orders.filter(x => x.customer_id === value);
                orders.forEach(x => {
                    x.email = x.first_name = x.last_name = '';
                });
            }
        },
        //STEPS
        EDIT_STEP(state, value) {
            var target = state.stepData.findIndex(item => item.id === value.id);
            state.stepData.splice(target, 1, value);
        },
        ADD_STEP(state, value) {
            state.stepData.push(value);
        },
        REMOVE_STEP(state, value) {
            var target = state.stepData.findIndex(item => item.id === value);
            state.stepData.splice(target, 1);
        },
        //PRODUCTS
        EDIT_PRODUCT(state, value) {
            var target = state.products.findIndex(item => item.id === value.id);
            state.products.splice(target, 1, value);
        },
        ADD_PRODUCT(state, value) {
            state.products.push(value);
        },
        REMOVE_PRODUCT(state, value) {
            var target = state.products.findIndex(item => item.id === value);
            state.products.splice(target, 1);
        },
        //
        setFunnelStore(state, value) {
            state.funnel = value
        },
        setStepDataStore(state, value) {
            state.stepData = value
        },
        setSingleStepStore(state, value) {
            state.singleStep = value
        },
        setProductsStore(state, value) {
            state.products = value
        },
        setAppsStore(state, value) {
            state.apps = value
        },
        setAppsIncludedStore(state, value) {
            state.appsIncluded = value
        },
        editAppStore(state, value) {
            var target = state.apps.findIndex(app => app.id === value.id);
            if (target >= 0 && state.apps[target])
                state.apps[target].attributes.active = value.attributes.active;
        },
        setGraphDataBarStore(state, value) {
            state.analytics.graphDataBar = value
        },
        setGraphDataBar2Store(state, value) {
            state.analytics.graphDataBar2 = value
        },
        setGraphDataLineStore(state, value) {
            state.analytics.graphDataLine = value
        },
        setAnalyticsInfoStore(state, value) {
            state.analytics.analyticsInfo = value
        },
        setIncludedDataStore(state, value) {
            state.analytics.includedData = value
        },
        setWidgetStatsStore(state, value) {
            state.analytics.widgetStats = value
        },
        setOrdersStore(state, value) {
            state.orders = value
        },
        setCodesStore(state, value) {
            state.codes = value
        },
        setSmsNumbersStore(state, value) {
            state.smsNumbers = value
        },
        setDiscountsStore(state, value) {
            state.discounts = value
        },
        setOrdersMetaStore(state, value) {
            state.ordersMeta = value
        },
        setOrdersPageStore(state, value) {
            state.ordersPage = value
        },
        setCurrentOrder(state, value) {
            state.currentOrder = value
        },
        setCurrentCustomer(state, value) {
            state.currentOrder = value
        },
    },
    actions: {
        //CUSTOMERS
        EDIT_CUSTOMER(context, value) {
            context.commit('EDIT_CUSTOMER', value);
        },
        SET_CUSTOMERS(context, value) {
            context.commit('SET_CUSTOMERS', value);
        },
        REMOVE_CUSTOMER(context, value) {
            context.commit('REMOVE_CUSTOMER', value);
        },
        //STEPS
        EDIT_STEP(context, value) {
            context.commit('EDIT_STEP', value);
        },
        ADD_STEP(context, value) {
            context.commit('ADD_STEP', value);
        },
        REMOVE_STEP(context, value) {
            context.commit('REMOVE_STEP', value);
        },
        //PRODUCTS
        EDIT_PRODUCT(context, value) {
            context.commit('EDIT_PRODUCT', value);
        },
        ADD_PRODUCT(context, value) {
            context.commit('ADD_PRODUCT', value);
        },
        REMOVE_PRODUCT(context, value) {
            context.commit('REMOVE_PRODUCT', value);
        },
        //
        setFunnelStore(context, value) {
            context.commit('setFunnelStore', value)
        },
        setStepDataStore(context, value) {
            context.commit('setStepDataStore', value)
        },
        setSingleStepStore(context, value) {
            context.commit('setSingleStepStore', value)
        },
        setProductsStore(context, value) {
            context.commit('setProductsStore', value)
        },
        setAppsStore(context, value) {
            context.commit('setAppsStore', value)
        },
        setAppsIncludedStore(context, value) {
            context.commit('setAppsIncludedStore', value)
        },
        setGraphDataBarStore(context, value) {
            context.commit('setGraphDataBarStore', value)
        },
        setGraphDataBar2Store(context, value) {
            context.commit('setGraphDataBar2Store', value)
        },
        setGraphDataLineStore(context, value) {
            context.commit('setGraphDataLineStore', value)
        },
        setAnalyticsInfoStore(context, value) {
            context.commit('setAnalyticsInfoStore', value)
        },
        setIncludedDataStore(context, value) {
            context.commit('setIncludedDataStore', value)
        },
        setWidgetStatsStore(context, value) {
            context.commit('setWidgetStatsStore', value)
        },
        setOrdersStore(context, value) {
            context.commit('setOrdersStore', value)
        },
        setCodesStore(context, value) {
            context.commit('setCodesStore', value)
        },
        setSmsNumbersStore(context, value) {
            context.commit('setSmsNumbersStore', value)
        },
        setDiscountsStore(context, value) {
            context.commit('setDiscountsStore', value)
        },
        setOrdersMetaStore(context, value) {
            context.commit('setOrdersMetaStore', value)
        },
        setOrdersPageStore(context, value) {
            context.commit('setOrdersPageStore', value)
        },
        setCurrentOrder(context, value) {
            context.commit('setCurrentOrder', value)
        },
        setCurrentCustomer(context, value) {
            context.commit('setCurrentCustomer', value)
        },

    },
    getters: {
        getFunnelStore(state) {
            return state.funnel
        },
        getStepDataStore(state) {
            return state.stepData
        },
        getSingleStepStore(state) {
            return state.singleStep
        },
        getProductsStore(state) {
            return state.products
        },
        getAppsStore(state) {
            return state.apps
        },
        getAppsIncludedStore(state) {
            return state.appsIncluded
        },
        getGraphDataBarStore(state) {
            return state.analytics.graphDataBar
        },
        getGraphDataBar2Store(state) {
            return state.analytics.graphDataBar2
        },
        getGraphDataLineStore(state) {
            return state.analytics.graphDataLine
        },
        getAnalyticsInfoStore(state) {
            return state.analytics.analyticsInfo
        },
        getIncludedDataStore(state) {
            return state.analytics.includedData
        },
        getWidgetStatsStore(state) {
            return state.analytics.widgetStats
        },
        getDiscountsStore(state){
            return state.discounts
        },
        getOrdersStore(state) {
            return state.orders
        },
        getCodesStore(state) {
            return state.codes
        },
        getCustomersStore(state) {
            return state.customers
        },
        getOrdersMetaStore(state) {
            return state.ordersMeta
        },
        getOrdersPageStore(state) {
            return state.ordersPage
        },
        getCurrentOrder(state) {
            return state.currentOrder
        },
        getCurrentCustomer(state) {
            return state.currentCustomer
        },
    },
}
