<template>
    <td style="width: 100%;" :class="responsiveClasses">
        <v-style >
              {{hoverStyle}}
        </v-style>
        <ul class="email_menu_element" :style="style" :id="advanced.cssId" :class="[('menu_element_' + details.id), advanced.cssClasses]">
            <li v-for="(item, index) in basic.items" :key="index">
                <a :href="item.url" style="text-align: center; display: block;">
                    <span class="text_menu_item">{{item.title || 'Item ' + index}}</span>
                </a>
            </li>
        </ul>
    </td>
</template>

<script>

export default {
    name: 'EmailMenu',
    props: ['details'],
    computed: {
        basic() {
            return this.details.attributes.basic
        },
        advanced() {
            return this.details.attributes.advanced
        },
        responsive() {
            return this.details.attributes.responsive
        },
        responsiveClasses() {
            let c = '';
            if (!this.responsive) return '';
            if (!this.responsive.desktop) c = 'no-desktop';
            if (!this.responsive.mobile) c += ' no-mobile';
            if (!this.responsive.tablet) c += ' no-tablet';
            return c;
        },
        hoverStyle() {
            var hover = this.basic.hoverColor ? this.basic.hoverColor : '#4368E0';

            return ( '.menu_element_' + this.details.id + ' a:hover { color: '+ hover +';}' );
        },
        textDecoration(){
            var fontStyling = this.basic.font ? this.basic.font.fontStyling : false;
            if(fontStyling['line-through'] && fontStyling.underline) {
                return 'underline line-through';
            } else if(fontStyling['line-through']) {
                return 'line-through';
            } else if(fontStyling.underline){
                return 'underline';
            } else {
                return 'inherit';
            }
        },
        style() {
            var border = this.advanced.border;
            var borderTop,
                borderLeft,
                borderRight,
                borderBottom;

            if (border) {
                borderTop = !border.sides.top ? 'none' : border.width + 'px ' + border.style + ' ' + border.color;
                borderLeft = !border.sides.left ? 'none' : border.width + 'px ' + border.style + ' ' + border.color;
                borderRight = !border.sides.right ? 'none' : border.width + 'px ' + border.style + ' ' + border.color;
                borderBottom = !border.sides.bottom ? 'none' : border.width + 'px ' + border.style + ' ' + border.color;
            }

            var borderRadius = border ?
                border.radius.leftTop+'px '+
                border.radius.topRight+'px '+
                border.radius.bottomRight+'px '+
                border.radius.bottomLeft+'px' : false;

            var margin = this.basic.margin ?
                this.basic.margin.top+'px '+
                this.basic.margin.right+'px '+
                this.basic.margin.bottom+'px '+
                this.basic.margin.left+'px' : false;

            var padding = this.advanced.padding ?
                this.advanced.padding.top+'px '+
                this.advanced.padding.right+'px '+
                this.advanced.padding.bottom+'px '+
                this.advanced.padding.left+'px' : false;

            var backgroundColor = this.advanced.backgroundColor || 'transparent';

            var fontFamily = this.basic.font ? this.basic.font.fontFamily : 'inherit';
            var fontSize = this.basic.font ? this.basic.font.fontSize : 'inherit';
            var textAlign = this.basic.font ? this.basic.font.textAlign : 'center';
            var fontStyling = this.basic.font ? this.basic.font.fontStyling : false;
            var color = this.basic.font ? this.basic.font.color : 'inherit';

            var obj = {
                width: '100%',
                margin,
                padding,
                borderRadius,
                borderTop,
                borderLeft,
                borderRight,
                borderBottom,
                backgroundColor,
                color,
                fontFamily,
                fontSize: fontSize + 'px',
                textAlign,
                fontStyling,
                fontWeight: fontStyling.bold ? 'bold' : 'inherit',
                fontStyle: fontStyling.italic ? 'italic' : 'inherit',
                textDecoration: this.textDecoration,
            }
            return obj
        }
    },
}
</script>
