export default (config, axios, token) => {

    var call = async function(path, method = 'GET', data) {
        data ? data = JSON.stringify(data) : '';
        const url = config.URL + path;
        try {
            const response = await axios({
                url,
                method,
                data,
                headers: {
                    'Authorization': 'Bearer ' + token,
                'Content-Type':'application/json'}
            })
            return response.data
        } catch (error) {
            return { error: true, info: error.response }
        }
    }

    return {

        get: async(id, automation = false) =>
            call(automation ? 'funnels/' + id + '/automations/' + automation : 'funnels/' + id + '/automations'),

        create: async(funnelId, sourceId, key='') =>
            call('funnels/' + funnelId + '/automations?source='+sourceId+'&key='+key, 'POST', {}),

        patch: async(id, automation, data) =>
            call('funnels/' + id + '/automations/' + automation, 'PATCH', data),

        delete: async(id, data) =>
            call('funnels/' + id + '/automations', 'DELETE', data),

        //Conditions
        conditions: async(funnelId) =>
            call(`funnels/${funnelId}/automations/conditions`),

        //Entries:
        entriesGet: async(id, automation, entry = false) =>
            call(
                entry ?
                'funnels/' + id + '/automations/' + automation + '/entries/' + entry :
                'funnels/' + id + '/automations/' + automation + '/entries'
            ),

        entriesCreate: async(funnelId, automation, data) =>
            call('funnels/' + funnelId + '/automations/' + automation + '/entries', 'POST', data),

        entriesPatch: async(funnelId, automation, entry) =>
            call(`funnels/${funnelId}/automations/${automation}/entries/${entry.id}`, 'PUT', entry),

        entriesUpdateAll: async(funnelId, automation, entries) =>
            call(`funnels/${funnelId}/automations/${automation}/entries/update-all`, 'PUT', entries),

        entriesDelete: async(funnelId, automation, id) =>
            call(`funnels/${funnelId}/automations/${automation}/entries/${id}`, 'DELETE'),

        //email templates:
        send: async(id, data) =>
            call('funnels/' + id + '/send-test-email', 'POST', data),
        //user:
        userGetAll: async({ userId }) =>
            call('users/' + userId + '/email-templates'),

        userGet: async({ userId, id }) =>
            call('users/' + userId + '/email-templates/' + id),

        userCreate: async({ userId, data }) =>
            call('users/' + userId + '/email-templates', 'POST', data),

        userPatch: async({ userId, id, data }) =>
            call('users/' + userId + '/email-templates/' + id, 'PATCH', data),

        userDelete: async({ userId, id }) =>
            call('users/' + userId + '/email-templates/' + id, 'DELETE'),

        //shared:
        sharedGet: async({ id }) =>
            call(id ? 'email-templates/' + id : 'email-templates'),

        sharedCreate: async({ id, data }) =>
            call('email-templates/' + id, 'POST', data),

        sharedPatch: async({ id, data }) =>
            call('email-templates/' + id, 'PATCH', data),

        sharedDelete: async({ id }) =>
            call('email-templates/' + id, 'DELETE'),

        //AUTOMATION templates:
        templates: async(id) =>
            call('funnels/' + id + '/automations/templates')

    }
}
