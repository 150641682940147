<template>
	<expandable-area title="Notes" :tag="notes&&notes.length?'('+notes.length+')':''" name="order-note">
		<div class="order-notes">
			<order-note :note="note" v-for="note in notes" :key="note.id"
			            @delete="deleteNote(note)" />

			<div class="new-note">
				<div class="img">
					<customer-avatar :first-name="user.member.first_name" small />
				</div>
				<textarea :rows="!noteFocused&&!newNote?1:5" class="flex" placeholder="Leave a note..."
				          @blur="noteFocused=false" @focus="noteFocused=true" v-model="newNote" />
				<v-button label="Post" :loading="saving" @click="createNote()" slim :disabled="!newNote" />
			</div>

			<v-spinner v-if="loading" />
		</div>
	</expandable-area>
</template>

<script>
import ExpandableArea from "@/components/base/expandable-area";
import moment from "moment";
import {getSymbol} from "@/helpers/currencies";
import VButton from "@/components/base/v-button";
import CustomerAvatar from "@/components/base/customer-avatar";
import QuickActionsBtn from "@/components/base/quick-actions-btn";
import OrderNote from "@/views/orders/order-note";
import API from '@/api'
import VSpinner from "@/components/base/v-spinner";

export default {
	name: "order-notes",
	components: {VSpinner, OrderNote, QuickActionsBtn, CustomerAvatar, VButton, ExpandableArea},
	mixins:[API],
	props:['orderId', 'order'],
	data(){
		return {
			saving: false,
			noteFocused: false,
			newNote: '',
			loading: false,
			notes:[],
		}
	},
	computed:{
		user(){
			return this.$store.state.userInfo;
		}
	},
	methods:{
		async createNote(){
			try {
				this.saving = true;

				let note = await this.api.orders.createNote(this.orderId, {
					order_id: this.orderId,
					user_id: parseInt(this.user.id),
					customer_id: this.order.customer_id,
					page_id: this.order.page_id,
					funnel_id: this.order.funnel_id,
					step_id: this.order.step_id,
					value: this.newNote,
				});
				if (note.error) this.$error(note.error);
				else {
					this.notes.push(note);
					this.newNote = ''
				}
			} catch (e) {
				this.$error(e)
			} finally {
				this.saving = false;
			}
		},
		async load(){
			try {
				this.loading = true;
				this.notes = [];

				let notes = await this.api.orders.getNotes(this.orderId);
				if (notes.error) this.$error(notes.error);
				else {
					this.notes = notes;
				}
			} catch (e) {
				this.$error(e)
			} finally {
				this.loading = false;
			}
		},
		async deleteNote(note){
			try {
				this.deleting = true;

				let res = await this.api.orders.deleteNote(this.orderId, note.id);
				if (res.error) this.$error(res.error);
				else {
					let index = this.notes.findIndex(x=>x.id===note.id);
					if (index>=0) this.notes.splice(index, 1);
				}
			} catch (e) {
				this.$error(e)
			} finally {
				this.deleting = false;
			}
		},
	},
	created() {
		this.load()
	}
}
</script>

<style lang="scss" scoped>
.order-notes {
	display: flex;
	flex-direction: column;
	margin-top: 10px;
	position: relative;
	.new-note {
		display: flex;
		flex-direction: row;
		padding: 10px 10px;
		background: $light-blue;
		border-radius: 8px;
		margin-top: 10px;
		textarea {
			transition: .2s ease-in-out all;
			background: transparent;
		}
	}
	.note {
		display: flex;
		flex-direction: column;
		padding: 8px 0;
		margin-left: 10px;
		margin-right: 10px;

		.note-body {
			user-select: text;
			margin-left: 35px;
			font-size: 14px;
			color: $black;
			font-weight: normal;
		}
		.img {
			margin-right: 5px;
			position: relative;
			display: flex;
			flex-direction: column;
			align-items: center;
		}
		.label {
			font-size: 14px;
			font-weight: 600;
			color: $black;
		}
		.sub_label{
			font-size: 14px;
			font-weight: normal;
			color: $dark-grey;
			&.time {
				margin-left: 10px;
			}
			&.error {
				color: #fc585b;
			}
		}

		.retry-btn {
			margin-left: 5px;
		}
		.value {
			text-align: right;
		}
	}

}
</style>
