import {sticky} from "./fields";

export default {
    tag: 'video',
    labelTitle: '',
    labelColor: '',
    id: { value: null },
    content: '<iframe width="100%" height="350" src="https://www.youtube.com/embed/lRTtMcx6rSM" frameborder="0" allowfullscreen></iframe>',
    attributes: {
        basic: {
            margin: { title: 'Margin', comp: 'marginPadding', value: { top: 0, left: 0, right: 0, bottom: 0 } },
            videoURL: { title: 'Video URL', comp: 'TextInput', value: 'https://www.youtube.com/watch?v=lRTtMcx6rSM' },
            videoThumbnail: { title: 'Video Thumbnail', comp: 'hidden', value: 'https://img.youtube.com/vi/lRTtMcx6rSM/sddefault.jpg' },
            videoId: { title: 'Video ID', comp: 'hidden', value: 'lRTtMcx6rSM' },
            autoPlay: { title: 'Auto-play', comp: 'ToggleInput', value: { booleanValue: true } },
            loop: { title: 'Loop infinitely', comp: 'ToggleInput', value: { booleanValue: false } },
            showControls: { title: 'Show controls', comp: 'ToggleInput', value: { booleanValue: false } },
            size: { title: 'Video size', comp: 'SizeValue', placeholder:'auto', value: {width:null,height:400/* null will be treated as 'auto'*/} },
            lazyLoad: { title: 'Lazy load', comp: 'ToggleInput', value: { booleanValue: true } },
            border: {
                title: 'Border',
                comp: 'border',
                value: {
                    width: 0,
                    color: 'transparent',
                    style: 'solid',
                    sides: { top: true, left: true, right: true, bottom: true },
                    radius: { analytics: 0, topRight: 0, bottomRight: 0, bottomLeft: 0 },
                }
            },
        },
        advanced: {
            cssId: { title: 'ID', comp: 'TextInput', value: '' },
            cssClasses: { title: 'CSS Classes', comp: 'MultiSelectItem', value: [], options: [{ id: 'class1', text: 'class1' }, { id: 'class2', text: 'class2' }, { id: 'class3', text: 'class3' }] },
            sticky

        },
    },
}
