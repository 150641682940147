import {
    shadows, sticky,
} from './fields/'

export default {
    tag: 'div', // div, h1-h6, p, img, video ...
    labelTitle: 'My row',
    labelColor: '',
    id: { value: null },
    attributes: {
        basic: {
            margin: { title: 'Margin', comp: 'sectionMargin', value: { top: 0, left: 0, right: 0, bottom: 0 } },
            padding: { title: 'Padding', comp: 'marginPadding', value: { top: 0, left: 0, right: 0, bottom: 0 } },
            cols: {
                title: 'Columns',
                comp: 'selectItem',
                value: 'one',
                options: [
                    { id: "one", text: "One" }, { id: "two", text: "Two" }, { id: "three", text: "Three" },
                    { id: "four", text: "Four" }, { id: "five", text: "Two + One" }, { id: "six", text: "One + Two" }
                ]
            },
            backgroundColor: { title: 'Background color', comp: 'colorPicker2', value: 'transparent' },
            backgroundImage: { title: 'Background image', comp: 'imageUploader', value: '' },
            backgroundStyle: { title: 'Background style', comp: 'selectItem',
                value: 'cover', options:[{id:'auto',text:'Auto (default)'},{id:'cover',text:'Cover'}] },
            backgroundPosition: { title: 'Background position', comp: 'selectItem',
                value: 'top', options:[{id:'top',text:'Top (default)'},{id:'center',text:'Center'},{id:'bottom',text:'Bottom'}] },

            font: { title: 'Font', comp: 'FontPanel', value: { fontFamily: '', fontSize: 14, color: '#303030', fontStyling: { bold: false, italic: false, 'line-through': false, underline: false, }, textAlign: 'initial' } },
            border: {
                title: 'Border',
                comp: 'border',
                value: {
                    width: 0,
                    color: 'transparent',
                    style: 'solid',
                    sides: { top: true, left: true, right: true, bottom: true },
                    radius: { analytics: 0, topRight: 0, bottomRight: 0, bottomLeft: 0 },
                }
            },
            shadows
        },
        advanced: {
            cssId: { title: 'ID', comp: 'TextInput', value: '' }, // !!!Unique!!!
            title: { title: 'Title', comp: 'TextInput', value: 'My row' }, //
            cssClasses: { title: 'CSS Classes', comp: 'MultiSelectItem', value: [], options: [] }, // Array of Strings
            sticky

            // animations: {
            //     title: 'Animations',
            //     comp: 'AnimationsInput', //no options yet..
            //     value: {
            //         property: '', // String => all || transform || width || height ...
            //         duration: 0, // Float => 0.2 ...
            //         easing: '', // String => ease-in-out || ease ...
            //     }
            // },
            // transformation: {
            //     title: 'Transformation',
            //     comp: 'TransformationInput',
            //     value: {
            //         rotateX: 0, // Integer => 0 - 360
            //         rotateY: 0, // Integer => 0 - 360
            //         rotateZ: 0, // Integer => 0 - 360
            //         scaleX: 0, // Integer
            //         scaleY: 0, // Integer
            //         scaleZ: 0, // Integer
            //     }
            // }
        },
    },
}
