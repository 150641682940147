import config from './config/config'
import axios from 'axios'
import ErrorMessage from '@/components/ErrorMessage.vue'
import VSpinner from '@/components/base/v-spinner';
import funnelishApi from '@/api/index'

export default {
    components: { ErrorMessage, VSpinner },
    computed: {
        token() {
            return this.$store.state.token
        },
        api() {
            return funnelishApi(this.token)
        }
    },
    beforeCreate() {
        if (this.$store.state.error) {
            this.$store.dispatch('RESOLVE_ERROR');
        }
    },
    methods: {
        //ERRORS HANDLING
        handleError(result) {
            if (result.status !== 200) {
                console.info('API Error');
                console.log(result);
                this.$store.dispatch('THROW_ERROR');
            }
        },

        //SIGN IN
        signIn: async function(login, password) {
            var bodyFormData = new FormData();
            bodyFormData.set('grant_type', 'password');
            bodyFormData.set('username', login);
            bodyFormData.set('password', password);

            try {
                var result = await axios({
                    method: 'post',
                    url: config.URL + 'users/sign_in',
                    data: bodyFormData,
                    headers: { 'Content-Type': 'multipart/form-data' }
                })
                return result
            } catch (error) {
                this.handleError(error)
                return error
            }
        },

        //GET FUNNEL STATS
        getFunnelStats: async function() {
            try {
                var result = await axios({
                    url: config.URL + 'funnel-stats',
                    headers: { 'Authorization': 'Bearer ' + this.token }
                })
                return result
            } catch (error) {
                this.handleError(error)
                return error
            }
        },
        //GET FUNNEL STEPS
        getFunnelSteps: async function(id, step = false) {
            step = 'funnels/' + id + '/steps';
            try {
                var result = await axios({
                    url: config.URL + step,
                    headers: { 'Authorization': 'Bearer ' + this.token }
                })
                return result
            } catch (error) {
                this.handleError(error)
                return error
            }
        },
        //PATCH STEP
        patchStep: async function(step, data) {
            var raw = JSON.stringify(data);
            try {
                var result = await axios({
                    method: 'PATCH',
                    url: config.URL + 'funnel-steps/' + step,
                    headers: { 'Authorization': 'Bearer ' + this.token, 'Content-Type':'application/json' },
                    data: raw
                })
                return result
            } catch (error) {
                this.handleError(error)
                return error
            }
        },
        //DELETE STEP
        deleteStep: async function(step) {
            try {
                var result = await axios({
                    method: 'DELETE',
                    url: config.URL + 'funnel-steps/' + step,
                    headers: { 'Authorization': 'Bearer ' + this.token },
                })
                return result
            } catch (error) {
                this.handleError(error)
                return error
            }
        },
        //GET PRODUCTS
        getProducts: async function(step) {
            try {
                var result = await axios({
                    url: config.URL + 'funnel-steps/' + step + '/products',
                    headers: { 'Authorization': 'Bearer ' + this.token }
                })
                return result
            } catch (error) {
                this.handleError(error)
                return error
            }
        },
        //GET PRODUCT VARIANTS
        getProductVariants: async function(product) {
            try {
                var result = await axios({
                    url: config.URL + 'products/' + product + '?include=variants',
                    headers: { 'Authorization': 'Bearer ' + this.token }
                })
                return result
            } catch (error) {
                this.handleError(error)
                return error
            }
        },
        //GET AUTOMATIONS
        getAutomations: async function(product) {
            try {
                var result = await axios({
                    url: config.URL + 'automation-briefs?productId=' + product,
                    headers: { 'Authorization': 'Bearer ' + this.token }
                })
                return result
            } catch (error) {
                this.handleError(error)
                return error
            }
        },
        //GET PRODUCT AUTOMATIONS
        getProductAutomations: async function(product) {
            try {
                var result = await axios({
                    url: config.URL + 'product-automations/' + product,
                    headers: { 'Authorization': 'Bearer ' + this.token }
                })
                return result
            } catch (error) {
                this.handleError(error)
                return error
            }
        },
        //PATCH AUTOMATION
        patchAutomation: async function(product, data) {
            var raw = JSON.stringify({ data });
            try {
                var result = await axios({
                    method: 'PATCH',
                    url: config.URL + 'product-automations/' + product,
                    headers: { 'Authorization': 'Bearer ' + this.token },
                    data: raw
                })
                return result
            } catch (error) {
                this.handleError(error)
                return error
            }
        },
        //CREATE AUTOMATION
        createAutomation: async function(data) {
            var raw = JSON.stringify({ data });
            try {
                var result = await axios({
                    method: 'POST',
                    url: config.URL + 'product-automations',
                    headers: { 'Authorization': 'Bearer ' + this.token },
                    data: raw
                })
                return result
            } catch (error) {
                this.handleError(error)
                return error
            }
        },
        //GET FUNNEL AUTOMATIONS
        getFunnelAutomations: async function(id) {
            try {
                var result = await axios({
                    url: config.URL + 'funnels/' + id + '/automations',
                    headers: { 'Authorization': 'Bearer ' + this.token }
                })
                return result
            } catch (error) {
                this.handleError(error)
                return error
            }
        },
        //DELETE AUTOMATION
        deleteAutomation: async function(id) {
            try {
                var result = await axios({
                    method: 'DELETE',
                    url: config.URL + 'automation-briefs/' + id,
                    headers: { 'Authorization': 'Bearer ' + this.token },
                })
                return result
            } catch (error) {
                this.handleError(error)
                return error
            }
        },
        //GET INTEGRATIONS
        getIntegrations: async function() {
            try {
                var result = await axios({
                    url: config.URL + 'integrations',
                    headers: { 'Authorization': 'Bearer ' + this.token }
                })
                return result
            } catch (error) {
                this.handleError(error)
                return error
            }

        },
        //GET ACTIONS
        // TODO: remove this. use integrations.instead
        getAPIActions: async function(caption, action, args = false) {
            if (!args) args = "{ \"id\": 0, \"arg1\": \"1234\", \"arg2\": \"arg22234\" }";
            try {
                var result = await axios({
                    method: 'POST',
                    url: config.URL + 'call-integration-method/' + caption + '/' + action,
                    headers: { 'Authorization': 'Bearer ' + this.token },
                    data: args
                })
                return result
            } catch (error) {
                this.handleError(error)
                return error
            }
        },
        // PATCH PRODUCT
        patchProduct: async function(id, data) {
            if (id) {
                var raw = JSON.stringify(data);
                try {
                    var result = await axios({
                        method: 'PATCH',
                        url: config.URL + 'products/' + id,
                        headers: { 'Authorization': 'Bearer ' + this.token, 'Content-Type':'application/json' },
                        data: raw
                    })
                    return result
                } catch (error) {
                    this.handleError(error)
                    return error
                }
            } else {
                this.createProduct(data);
            }
        },
        //PATCH VARIANTS
        patchVariants: async function(id, data) {
            var raw = JSON.stringify(data);
            try {
                var result = await axios({
                    method: 'PATCH',
                    url: config.URL + 'product-variants/' + id,
                    headers: { 'Authorization': 'Bearer ' + this.token, 'Content-Type':'application/json' },
                    data: raw
                })
                return result
            } catch (error) {
                this.handleError(error)
                return error
            }
        },
        //CREATE PRODUCT
        createProduct: async function(data) {
            var raw = JSON.stringify(data);
            try {
                var result = await axios({
                    method: 'POST',
                    url: config.URL + 'products',
                    headers: { 'Authorization': 'Bearer ' + this.token, 'Content-Type':'application/json' },
                    data: raw
                })
                console.log('created...', result);
                return result
            } catch (error) {
                this.handleError(error)
                return error
            }
        },
        //CLONE PRODUCT
        cloneProduct: async function(data) {
            var raw = JSON.stringify(data);
            try {
                var result = await axios({
                    method: 'POST',
                    url: config.URL + 'products',
                    headers: { 'Authorization': 'Bearer ' + this.token, 'Content-Type':'application/json' },
                    data: raw
                })
                console.log('cloned...', result);
                return result
            } catch (error) {
                this.handleError(error)
                return error
            }
        },
        //DELETE PRODUCT
        deleteProduct: async function(product) {
            try {
                var result = await axios({
                    method: 'DELETE',
                    url: config.URL + 'products/' + product,
                    headers: { 'Authorization': 'Bearer ' + this.token },
                })
                return result
            } catch (error) {
                this.handleError(error)
                return error
            }
        },
        //GET ORDERS
        getOrders: async function(funnel_id, page = 0, per_page = 25, query = '') {
            try {
                var result = await axios({
                    url: config.URL + 'orders?funnel_id=' + funnel_id + '&page=' + page + '&per_page=' + per_page + '&query=' + query,
                    headers: { 'Authorization': 'Bearer ' + this.token }
                })
                return result
            } catch (error) {
                this.handleError(error)
                return error
            }
        },
        //GET APPS
        getApps: async function() {
            try {
                var result = await axios({
                    url: config.URL + 'apps',
                    headers: { 'Authorization': 'Bearer ' + this.token }
                })
                return result
            } catch (error) {
                this.handleError(error)
                return error
            }
        },
        //GET FILTERED APPS
        getFilteredApps: async function(id = '', appId) {
            try {
                var result = await axios({
                    url: config.URL + 'funnel-apps?filter[app_id]=' + appId + '&filter[funnel_id]=' + id,
                    headers: { 'Authorization': 'Bearer ' + this.token }
                })
                return result
            } catch (error) {
                this.handleError(error)
                return error
            }
        },
        //PATCH APP
        patchApp: async function(appId, data) {
            var raw = JSON.stringify({ data });
            try {
                var result = await axios({
                    method: 'PATCH',
                    url: config.URL + 'funnel-apps/' + appId,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.token,
                    },
                    data: raw
                })
                return result
            } catch (error) {
                this.handleError(error)
                return error
            }
        },
        //PATCH USER APP
        patchUserApp: async function(appId, data) {
            var raw = JSON.stringify({ data });
            try {
                var result = await axios({
                    method: 'PATCH',
                    url: config.URL + 'user-apps/' + appId,
                    headers: { 'Authorization': 'Bearer ' + this.token },
                    data: raw
                })
                return result
            } catch (error) {
                this.handleError(error)
                return error
            }
        },
        //GET USER APP
        getUserApp: async function(appId) {
            try {
                var result = await axios({
                    url: config.URL + 'user-apps/' + appId,
                    headers: { 'Authorization': 'Bearer ' + this.token }
                })
                return result
            } catch (error) {
                this.handleError(error)
                return error
            }
        },
        //GET APP HOWTO
        getAppHowto: async function(appId) {
            try {
                var result = await axios({
                    url: config.URL + 'app-howtos/' + appId,
                    headers: { 'Authorization': 'Bearer ' + this.token }
                })
                return result
            } catch (error) {
                this.handleError(error)
                return error
            }
        },
        //GET USERS
        getUsers: async function() {
            try {
                var result = await axios({
                    url: config.URL + 'users',
                    headers: { 'Authorization': 'Bearer ' + this.token }
                })
                return result
            } catch (error) {
                this.handleError(error)
                return error
            }
        },
        //GET SINGLE CUSTOMER
        getCustomerInfo: async function(customerId) {
            try {
                var result = await axios({
                    url: config.URL + 'customers/' + customerId,
                    headers: { 'Authorization': 'Bearer ' + this.token }
                })
                return result
            } catch (error) {
                this.handleError(error)
                return error
            }
        },
        //PATCH CUSTOMER
        patchCustomerInfo: async function(customerId, data) {
            var raw = JSON.stringify({ data });
            try {
                var result = await axios({
                    method: 'PATCH',
                    url: config.URL + 'customers/' + customerId,
                    headers: { 'Authorization': 'Bearer ' + this.token },
                    data: raw
                })
                return result
            } catch (error) {
                this.handleError(error)
                return error
            }
        },
        //GET PAYMENT METHODS
        getPaymentMethods: async function() {
            try {
                var result = await axios({
                    url: config.URL + 'payment-method-groups',
                    headers: { 'Authorization': 'Bearer ' + this.token }
                })
                return result
            } catch (error) {
                this.handleError(error)
                return error
            }
        },
        connectGateway: async function(gateId, data) {
            var raw = JSON.stringify(data);
            try {
                var result = await axios({
                    method: 'POST',
                    url: config.URL + 'gateways/' + gateId + '/connect',
                    headers: {
                        'Authorization': 'Bearer ' + this.token,
                        'Content-Type': 'application/json',
                    },
                    data: raw
                })
                if (result.status !== 200) {
                    throw result
                }
                return result
            } catch (error) {
                throw error.data;
            }
        },
        disconnectGateway: async function(gateId, id, data) {
            var raw = JSON.stringify(data);
            try {
                var result = await axios({
                    method: 'POST',
                    url: config.URL + 'gateways/' + gateId + '/disconnect/' + id,
                    headers: { 'Authorization': 'Bearer ' + this.token },
                    data: raw
                })

                return result
            } catch (error) {
                throw error.response;
            }
        },
        getUserGateways: async function(id = false) {
            var urlstring = id ? '/' + id : '';
            try {
                var result = await axios({
                    url: config.URL + 'user-gateways' + urlstring,
                    headers: { 'Authorization': 'Bearer ' + this.token }
                })
                if (!id) {
                    this.$store.dispatch('SET_GATEWAYS', result.data.data);
                }
                return result
            } catch (error) {
                throw error.response;
            }
        },
        patchUserGateway: async function(id, data) {
            var raw = JSON.stringify(data);
            try {
                var result = await axios({
                    method: 'PATCH',
                    url: config.URL + 'user-gateways/' + id,
                    headers: {
                        'Authorization': 'Bearer ' + this.token,
                        "Content-Type":'application/json'
                    },
                    data: raw
                })
                return result
            } catch (error) {
                throw error.response
            }
        },
        createUserGateway: async function(data) {
            try {
                var raw = JSON.stringify(data);
                var result = await axios({
                    method: 'POST',
                    url: config.URL + 'user-gateways',
                    headers: {
                        'Authorization': 'Bearer ' + this.token,
                        "Content-Type":'application/json'
                    },
                    data: raw
                })
                return result
            } catch (e) {
                throw e.response
            }
        },

    }
}
