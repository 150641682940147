<template>
    <div class="wrapper steps_layout">
        <div class="main">
            <ErrorMessage v-if="error"
                className="sticky-error posfixed"
                :message="errorMessage"
                :title="errorTitle"
                :closable="true"
                @closed="clearError"
            />
            <funnel-steps-panel :funnel="getFunnelStore" :steps="stepData" :current="step" @updateSidebarData="updateSidebarData"/>

            <router-view
                @updateSidebarData="updateSidebarData"
                @redirect="onRedirect"
                :funnel="getFunnelStore"
                :steps="stepData"
                :step="getStepId"
                :id="getId"
            ></router-view>

            <transition name="fade">
                <router-view name="popup"></router-view>
            </transition>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
import API from "@/api"
import FunnelStepsPanel from "@/views/funnels/funnel/funnel-steps-panel";

export default {
    name: "WithStepsLayout",
    mixins: [API],
    props: ['id','step'],
    components: {
	    FunnelStepsPanel,
    },
    data() {
        return {
            error: false,
            errorMessage: '',
            stepData: [],
            funnel: {},
            singleStep: {},
            inited: false
        }
    },
    computed: {
        ...mapGetters(["getFunnelStore", "getStepDataStore, getSingleStepStore"]),
        getId() {
            return this.$route.params.id
        },
        getStepId() {
            return this.$route.params.step
        }
    },
    watch: {
        getFunnelStore(val) {
            console.log('FunnelChanged', val);
        },
    },
    methods: {
        async onRedirect() {
            if(this.inited) {
                console.log('onRedirect: ', this.getStepDataStore, this.stepData);
                var {id, step} = this.$route.params;
                if(this.stepData && this.stepData[0]) {
                    step = this.stepData[0].id;
                    step = this.stepData[0].id
                    var singleRes = await this.api.steps.get(step);
                    var prodRes = await this.api.steps.getProducts(step);

                    this.$store.dispatch('setSingleStepStore', singleRes.data);
                    this.$store.dispatch('setProductsStore', prodRes.data);

                    this.singleStep = singleRes.data;
                } else {
                    step = 'onboarding';
                }

                this.$router.push({path: `/funnel/${id}/steps/${step}`});
            }
        },
        async init() {
            var { id, step, productId } = this.$route.params;

            var response = await this.api.funnels.get(id);
            this.funnel = await response.data;

            var stepRes = await this.api.steps.getAll(id);
            this.stepData = await stepRes.data;

            if(!response.error) {
                this.$store.dispatch('setFunnelStore', this.funnel);
                this.$store.dispatch('setStepDataStore', this.stepData);
            }


            var userRes = await this.api.users.get();
            this.$store.dispatch('setUserInfo', userRes.data);

            if(this.$route.name === 'FunnelStepsSingle') {
                //REDIRECT TO FUNNEL STEP
                if(this.stepData[0]) {
                    step = this.stepData[0].id
                    var singleRes = await this.api.steps.get(step);
                    var prodRes = await this.api.steps.getProducts(step);

                    this.$store.dispatch('setSingleStepStore', singleRes.data);
                    this.$store.dispatch('setProductsStore', prodRes.data);

                    this.singleStep = singleRes.data;
                } else {
                    step = 'onboarding';
                }
                this.inited = true
                this.$router.push({path: `/funnel/${id}/steps/${step}`});
            } else if(this.$route.name === 'FunnelSteps') {

                var singleRes = await this.api.steps.get(step);
                var prodRes = await this.api.steps.getProducts(step);
                console.log(step, singleRes);
                this.$store.dispatch('setSingleStepStore', singleRes.data);
                this.$store.dispatch('setProductsStore', prodRes.data);

                this.singleStep = singleRes.data;
                this.inited = true
            }
        },
        updateSidebarData(data, action) {
            if(data && action === 'add') {
                this.stepData.push(data);
                this.$store.dispatch('setSingleStepStore', data);
            } else if(data && action === 'remove') {
                var target = this.stepData.findIndex(step => parseInt(step.id) === parseInt(data));
                this.stepData.splice(target, 1);
            } else if(data && action === 'edit') {
                var target = this.stepData.findIndex(step => parseInt(step.id) === parseInt(data.id));
                this.stepData.splice(target, 1, data);
                this.$store.dispatch('setSingleStepStore', data);
            }
            this.$store.dispatch('setStepDataStore', this.stepData);
        }
    },
    created() {
        console.log('created init() StepsLayout');
        this.init();
    }
}
</script>
