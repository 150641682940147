<template>
<div class="back-btn" @click="$emit('click')">
	<inline-svg class="arrow" :src="require('@/assets/img/back_bt_arrow.svg')" />
	<span>Back</span>
</div>
</template>

<script>
export default {
	name: "back-btn",

}
</script>

<style lang="scss" scoped>
.back-btn {
	cursor: pointer;
	display: flex;
	flex-direction: row;
	align-items: center;
	position: relative;
	color: $dark-grey;
	font-size: 14px;
	font-weight: normal;
	transition: .1s ease-in-out all;
	.arrow {
		cursor: default;
		position: absolute;
		left: -18px;
		width: 14px;
		height: 14px;
		transition: .1s ease-in-out all;
		//margin-right: 10px;
	}
	&:hover {
		color: $blue;
		.arrow {
			color: $blue;
			left: -20px;
		}
	}
}
</style>
