<template>
	<div class="main orders-page noscroll">

		<div class="main-center funnel_steps_page apps_page noscroll layout-column">
			<div class=" noscroll">
				<div class="funnel_apps layout-column scroll">
					<div class="funnel_apps_header">
						<h3>Orders
							<span class="panel_head_amount" v-if="ordersMeta.count">
								<span v-if="currentPage !== ordersMeta.total_pages">
									{{ currentPage === 1 ? 1 : (currentPage * ordersMeta.per_page) - ordersMeta.per_page }} -
									{{ (currentPage) * ordersMeta.per_page }} from {{ ordersMeta.count }}
								</span>
								<span v-else>
									{{ ordersMeta.count - ordersMeta.per_page }} - {{ ordersMeta.count }} from {{ ordersMeta.count }}
								</span>
							</span>
						</h3>
						<div class="flex"></div>
						<div class="layout-row">
							<v-button flat class="small-btn reload-btn" :class="{'loading':loading}" @click="loadOrders()"
							          :icon="require('@/assets/img/reload.svg')" />

							<v-button @click="exportOrdersOpen = true" border :disabled="!ordersMeta.count"
							          label="Export orders"/>
						</div>
					</div>
					<div class="scroll">
						<div class="main-content">
							<div class="search-container">
								<input type="text" placeholder="Type to search..." class="search-input" v-model="searchValue"
								       @input="searchValueChanged">
								<v-spinner v-if="loading" />
							</div>

							<div class="entries-table">
								<div class="head " v-if="ordersMeta.count">
									<!--							<span class="cell number">#</span>-->
									<span class="cell time sort-by">Time
							<inline-svg class="arrow" :src="require('@/assets/img/arrow-top.svg')" />
							</span>
									<span class="cell status">Status</span>
									<span class="cell customer">Customer</span>
									<span class="cell product">Product</span>
									<span class="cell amount">Amount</span>
									<span class="cell actions"></span>
								</div>
								<div class="panel-body" v-if="ordersMeta.count">
									<order-item :order="item" v-for="(item,index) in orders" :key="item.id" />
								</div>
								<div v-if="!ifError && !ordersMeta.count" class="panel-body panel-body_2 empty-panel">
									<p>No orders have been found</p>
								</div>
							</div>
							<paginate
									v-if="ordersMeta.count"
									:pageCount="ordersMeta.total_pages"
									:pageRange="7"
									:forcePage="currentPage"
									:clickHandler="clickCallback"
									:prevText="'Prev'"
									:nextText="'Next'"
									:containerClass="'pagination pagination-root'"
									:activeClass="'active'"
							>
							</paginate>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- OrderDetails -->
		<router-view/>

<!--		no funnel passed -->
		<ExportOrders v-if="exportOrdersOpen" @closeExportPopup="exportOrdersOpen = false"/>

	</div>
</template>

<script>
import MainSearch from "@/components/MainSearch.vue";
import Loading from "@/components/Loading.vue";
import {mapGetters} from "vuex";
import API from "@/api";
import {saveAs} from "file-saver";

import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import {getSourceLogo} from "@/helpers/gateways";
import {getSymbol} from "@/helpers/currencies";
import OrderItem from "@/views/orders/order-item";
import VSpinner from "@/components/base/v-spinner";
import VButton from "@/components/base/v-button";
import ExportOrders from "@/components/popups/orders/ExportOrders";
import debounce from "debounce";

TimeAgo.addLocale(en);
const timeAgo = new TimeAgo("en-US");

export default {
	name: "Orders",
	title: "All Orders | Funnelish™",
	components: {
		ExportOrders,
		VButton,
		VSpinner,
		OrderItem,
		MainSearch,
		//OrderInfoPopup,
		Loading,
	},
	mixins: [API],
	props: ["id"],
	data() {
		return {
			loading: true,
			searchValue: '',
			ordersMeta: {
				count: 0,
				per_page: 0,
				total_pages: 0
			},
			exportOrdersOpen: false,
			orderPages: [],
			orders: [],
			customer: {},
			page: 0,
			perPage: 25,
			query: "",
		};
	},
	computed: {
		...mapGetters(["getOrderInfoPopup", "ifError"]),
		currentPage() {
			let page = this.$route.query.page
			return page ? parseInt(page) : 1
		},
		currency() {
			let c = this.$store.state.userInfo.currency_code;
			if (!c) return '$';
			return getSymbol(c);
		}
	},
	methods: {
    updateSearch: debounce((self, v)=>{
      // Reset current page before query to start from first page.
      if (self.$route.query?.page!=1 || self.$route.query?.query!=v) {
        self.$router.push({path: self.$router.path, query: {page: 1, query: v}});
        self.loadOrders();
      }
    }, 1000, false),

    searchValueChanged(ev) {
      this.updateSearch(this, ev.target.value)
		},
		//UI
		clickCallback(pageNum) {
			this.$router.push({path: 'orders', query: {page: pageNum, query: this.searchValue}});
			this.loadOrders();
		},
		async loadOrders() {
			try {
				this.loading = true;
				// this.orders = [];

				let {orders, meta} = await this.api.orders.getAll(this.currentPage - 1, this.perPage, this.searchValue);
				this.orders = orders;
				this.ordersMeta = meta;
				this.$store.dispatch("setOrdersStore", orders);
			} catch (e) {
				this.$error(e)
			} finally {
				this.loading = false;
			}
		},
	},
	mounted() {
		this.loadOrders();
	},
}
</script>

<style lang="scss" scoped>
.orders-page {
	.main-content {
		padding: 60px 40px 80px;
		max-width: 2000px;
		margin: 0 auto;
	}
	.orders-table {
		max-width: 1100px;
		margin: 0 auto;

		.panel-head {
			display: flex;
			justify-content: normal;
			align-items: center;
			padding: 0 20px;

			&__source {
				width: 180px;
			}
			&__customer {
				flex-grow: 1;
			}
			&__time {
				width: 170px;
			}
			&__amount {
				width: 150px;
				text-align: right;
			}
		}
		.order-entry {
			display: flex;
			justify-content: normal;
			align-items: center;
			&__source {
				width: 180px;
			}
			&__customer {
				flex-grow: 1;
				flex-shrink: 1;
				flex: auto;
				display: flex;
				flex-direction: column;
			}
			&__time {
				width: 180px;
			}
			&__amount {
				width: 150px;
				text-align: right;
				white-space: nowrap;
			}
		}
		.panel-item-cell {
			width: auto;
		}
	}
}

.funnel_apps {
	.search-container {
		max-width: 1100px;
		margin: 0 auto 30px;
	}
}

.blue_notification_box {
	max-width: 1100px;
	margin-left: auto;
	margin-right: auto;
}

.orders_pannel .panel-item:hover {
	background: #EEF4FF;
}
</style>
