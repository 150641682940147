export default (config, axios, token) => {

    var call = async function(path, method = 'GET', data) {
        data ? data = JSON.stringify({ data }) : '';
        const url = config.URL + path;
        try {
            const response = await axios({
                url,
                method,
                data,
                headers: { 'Authorization': 'Bearer ' + token }
            })
            return response.data
        } catch (error) {
            return { error: true, info: error.response }
        }
    }

    return {
        get: async(query) =>
            call(`search?query=${query}`),
    }
}