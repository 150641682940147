<template>
	<expandable-area title="Timeline" name="order-timeline">
		<div class="order-timeline">
			<loading v-if="loading" />
			<div v-else class="entry" v-for="entry in timeline" :key="entry.id">
				<div class="img">


					<img v-if="entry.type===1" :src="require('@/assets/img/circle-error.svg')"  />
					<img v-else-if="entry.type===3" :src="require('@/assets/img/warning_icon.svg')"  />
					<img v-else-if="entry.type===4" :src="require('@/assets/img/refund-2.svg')"  />
					<img v-else-if="entry.type===5" :src="require('@/assets/img/cancel-subscription.svg')"  />

					<img v-else-if="entry.type===7" :src="require('@/assets/img/send_icon_email.svg')"  />
					<img v-else-if="entry.type===8" :src="require('@/assets/img/finish.svg')"  />
					<img v-else-if="entry.type===9" :src="require('@/assets/img/check_image.svg')"  />
					<img v-else :src="require('@/assets/img/info_icon.svg')"  />

					<span class="line"></span>
				</div>
				<div class="layout-column flex">
					<span class="label">{{entry.name}}
					<span class="label" v-if="entry.type===9 && value(entry).gatewayId">
						through {{gatewayName(value(entry).gatewayId)}}
					</span>
					</span>
					<span class="sub_label">{{time(entry.created_at)}}</span>
				</div>
				<div class="layout-column flex value">
					<span v-if="value(entry).amount" class="label">
						<span v-if="entry.type===4" class="sign">-</span>
						{{formatPrice(order.currency, value(entry).amount)}}
					</span>
					<span v-if="value(entry).ref" class="sub_label">
						{{value(entry).ref}}
					</span>

					<span v-if="value(entry).desc" class="sub_label">{{value(entry).desc}}</span>
					<span v-if="value(entry).error" class="sub_label error">{{value(entry).error}}</span>
				</div>
				<div class="layout-column flex-none" v-if="value(entry).can_retry">
					<quick-actions-btn :items="[{label:'Retry','icon':require('@/assets/img/circle-arrow-right.svg')}]"  />
				</div>
			</div>
		</div>
	</expandable-area>
</template>

<script>
import ExpandableArea from "@/components/base/expandable-area";
import moment from "moment";
import {formatPrice, getSymbol} from "@/helpers/currencies";
import VButton from "@/components/base/v-button";
import Loading from "@/components/Loading";
import API from '@/api';
import {getGatewayNameById} from "@/helpers/gateways";
import QuickActionsBtn from "@/components/base/quick-actions-btn";
import numbers from "@/helpers/numbers";

export default {
	name: "order-timeline", components: {QuickActionsBtn, Loading, VButton, ExpandableArea},
	props:['order','orderId'],
	mixins: [API],
	data(){
		return {
			loading: false,
			timeline: [],
			eventTypes:[
				{id:1, name:'error', label:'Error'},
				{id:2, name:'info', label:'Info'},
				{id:3, name:'warning', label:'Warning'},
				{id:4, name:'refund', label:'Refund'},
				{id:5, name:'cancel', label:'Cancel'},
				{id:6, name:'action', label:'Action'},
				{id:7, name:'email', label:'Email'},
				{id:8, name:'fulfillment', label:'Fulfillment'},
			],
		}
	},
	computed:{
	},
	methods:{
		formatPrice(currency, price) {
			return formatPrice(currency, this.roundPrice(price))
		},
		roundPrice(n) {
			return numbers.round_to_two(n)
		},
		gatewayName(id) {
			if (this.order.app_id===1)
				return "PayPal Plugin"
			else if (this.order.app_id===8)
				return getGatewayNameById(id).name
			else
				return "Clickfunnels";
		},
		typeIcon(t){
			let eventType = this.eventTypes.find(x=>x.id===t)
			if (!eventType) return '';
			return eventType.name
		},
		value(entry){
			if (entry.value) {
				try {
					return JSON.parse(entry.value);
				} catch (e){}
			}
			return {};
		},
		time(t){
			return moment(t).format("DD MMM YYYY, HH:mm:ss")
		},
		async load(){
			try {
				this.loading = true;
				this.timeline = [];

				let timeline = await this.api.orders.getTimeline(this.orderId);
				if (timeline.error) this.$error(timeline.error);
				else {
					this.timeline = timeline;
				}
			} catch (e) {
				this.$error(e)
			} finally {
				this.loading = false;
			}
		},
	},
	created() {
		this.load()
	}
}
</script>

<style lang="scss" scoped>
.order-timeline {
	display: flex;
	flex-direction: column;
	margin-top: 10px;
	user-select: text;
	.entry {
		display: flex;
		flex-direction: row;
		padding: 8px 0;

		.img {
			margin-right: 10px;
			position: relative;
			display: flex;
			flex-direction: column;
			align-items: center;

			img {
				height: 20px;
				width: 20px;
			}
		}
		&:not(:last-child) .line {
			height: 100%;
			width: 2px;
			border-radius: 3px;
			background: rgba(197, 199, 210, 0.45);
			margin: 10px 0;
			min-height: 15px;
		}
		.label {
			font-size: 14px;
			font-weight: 600;
			color: $black;
		}
		.sub_label{
			font-size: 14px;
			margin-top: 7px;
			font-weight: normal;
			color: $dark-grey;
			&.error {
				color: #fc585b;
			}
		}

		.retry-btn {
			margin-left: 5px;
		}
		.value {
			text-align: right;
		}
	}
}
</style>
