export default {
    tag: 'social',
    labelTitle: '',
    labelColor: '',
    id: { value: null },
    attributes: {
        basic: {
            margin: { title: 'Margin', comp: 'marginPadding', value: { top: 0, left: 0, right: 0, bottom: 0 } },
            items: {
                title: 'Social Network Selection',
                comp: 'SocialNetworks',
                value: [
                    { title: 'Twitter', status: true, url: '', icon: 'https://images.funnelish.com/default/social/tw_icon.png' },
                    { title: 'LinkedIn', status: true, url: '', icon: 'https://images.funnelish.com/default/social/in_icon.png' },
                    { title: 'Facebook', status: true, url: '', icon: 'https://images.funnelish.com/default/social/face_b_icon.png' },
                    { title: 'YouTube', status: true, url: '', icon: 'https://images.funnelish.com/default/social/youtube_icon.png' },
                    { title: 'Instagram', status: true, url: '', icon: 'https://images.funnelish.com/default/social/instagram_icon.png' },
                ]
            },
        },
        advanced: {
            cssId: { title: 'ID', comp: 'TextInput', value: '' },
            cssClasses: { title: 'CSS Classes', comp: 'MultiSelectItem', value: [], options: [{ id: 'class1', text: 'class1' }, { id: 'class2', text: 'class2' }, { id: 'class3', text: 'class3' }] },
            // align: { title: 'Align', comp: 'TextAlign', value: 'center' },
            backgroundColor: { title: 'Background color', comp: 'colorPicker', value: 'transparent' },
            padding: { title: 'Padding', comp: 'marginPadding', value: { top: 0, left: 0, right: 0, bottom: 0 } },
            border: {
                title: 'Border',
                comp: 'border',
                value: {
                    width: 0,
                    color: 'transparent',
                    style: 'solid',
                    sides: { top: true, left: true, right: true, bottom: true },
                    radius: { leftTop: 0, topRight: 0, bottomRight: 0, bottomLeft: 0 },
                }
            },
        },
    },
}
