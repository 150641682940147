const EMPTY_STATE = 'email_emptyState';

module.exports = {
    install(Vue, options = {}) {
        if (!Vue._installedPlugins.find(plugin => plugin.Store)) {
            throw new Error("VuexUndoRedo plugin must be installed after the Vuex plugin.")
        }
        Vue.mixin({
            data() {
                return {
                    email_saved: 0,
                    email_undone: [],
                    email_newMutation: true,
                    email_popMeOnce: true,
                    email_ignoreMutations: options.ignoreMutations || [],
                    email_doneState: [],
                    email_stackLength: 50
                };
            },
            created() {
                if (this.$store) {
                    var { editor, page } = this.$store.state.email;
                    this.email_doneState.push({ editor: JSON.stringify(editor), page: JSON.stringify(page) });

                    this.$store.subscribe(mutation => {
                        if (
                            mutation.type !== EMPTY_STATE &&
                            this.email_ignoreMutations.indexOf(mutation.type) === -1 &&
                            mutation.type.indexOf('EMAIL_') !== -1
                        ) {
                            if (
                                this.email_doneState.length > this.email_stackLength ||
                                mutation.type === 'EMAIL_APPLY_TEMPLATE'
                            ) this.email_doneState.shift();

                            this.email_doneState.push({
                                editor: JSON.stringify(this.$store.state.email.editor),
                                page: JSON.stringify(this.$store.state.email.page)
                            });
                            this.email_saved++
                        }
                    });
                }
            },
            computed: {
                email_canRedo() {
                    return this.email_undone.length;
                },
                email_canUndo() {
                    return this.email_doneState.length - 1;
                }
            },
            methods: {
                email_redo() {
                    if (this.email_doneState.length >= this.email_stackLength) this.email_doneState.shift()

                    let commit = this.email_undone.pop();
                    this.email_doneState.push(commit);
                    this.email_newMutation = false;
                    this.$store.commit(EMPTY_STATE, commit);
                    this.email_newMutation = true;
                    commit = null;

                    this.email_saved += 1
                },
                email_undo() {
                    if (this.email_undone.length >= this.email_stackLength) this.email_undone.shift()

                    this.email_undone.push(this.email_doneState.pop());
                    this.email_newMutation = false;
                    this.email_popMeOnce = false;
                    this.$store.commit(EMPTY_STATE, this.email_doneState[this.email_doneState.length - 1]);
                    this.email_newMutation = true;

                    this.email_saved -= 1
                }
            }
        });
    },
}