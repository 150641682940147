<template>
	<div class="expandable-area" :class="{closed}">
		<h3 class="expandable-title" @click="statusToggle()">
			{{title}}
			<span class="tag">{{tag}}</span>
			<inline-svg class="arrow" :src="require('@/assets/img/chevron-top.svg')" />
		</h3>

		<div class="area-content">
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {
	name: "expandable-area",
	props: {
		name: {
			type: String,
		},
		title: {
			type: String,
		},
		tag: {
			type: String,
		},
		closeByDefault:{
			type: Boolean,
		}
	},
	data(){
		return {
			closed: false,
		}
	},
	methods:{
		statusToggle(){
			this.closed = !this.closed;
			localStorage.setItem(`expandable-area.${this.name}.closed`, this.closed+'');
		},
	},
	mounted() {
		let c = localStorage.getItem(`expandable-area.${this.name}.closed`);
		if (!c && this.closeByDefault) this.closed = true
		else this.closed = c==="true"
	}
}
</script>

<style lang="scss" scoped>

.expandable-area {
	border: none;
	border-radius: 0;
	margin: 0 0 20px;

	.area-content {
		padding: 0 15px 15px;
		height: auto;
	}

	&.closed {
		.area-content {
			display: none;
		}
		.expandable-title {
			svg.arrow {
				transform: rotate(180deg);
			}
		}
	}
	.expandable-title {
		font-weight: 500;
		font-size: 16px;
		line-height: 150%;
		letter-spacing: 0.02em;
		color: #25242d;
		display: flex;
		flex-direction: row;
		position: relative;
		cursor: pointer;
		padding: 8px 15px;
		background: #f7f7f7;
		border-radius: 8px;
		opacity: 1;
		align-items: center;

		.tag {
			margin-left: 5px;
			font-weight: normal;
			color: $dark-grey;

		}
		svg.arrow {
			width: 18px;
			right: 20px;
			float: right;
			position: absolute;
			transition: 0.15s all ease-in-out;
		}

		&:hover {
			background: #f1f1f1;
		}
	}
}
</style>
