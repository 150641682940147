let shadows = {
    title: 'Shadow',
    comp: 'Shadow',
    value: []
        // 	angle: 0,
        // 	distance: 0,
        // 	blur: 5,
        // 	spread: 0,
        // 	color: '#252A32',
        // 	type: '',
}

let buttonShadows = {
    title: 'Text shadow',
    comp: 'Shadow',
    value: []
        //	angle: 0,
        //	distance: 0,
        //	blur: 0,
        //	color: 'transparent',
}

let textShadows = {
    title: 'Text shadow',
    comp: 'ShadowsInput',
    value: {
        angle: 0,
        distance: 0,
        blur: 0,
        spread: 0,
        color: 'transparent',
        type: '',
    }
}

let border = {
    title: 'Border',
    comp: 'border',
    value: {
        width: null,
        color: null,
        style: 'solid',
        sides: { top: true, left: true, right: true, bottom: true },
        radius: { topLeft: null, topRight: null, bottomRight: null, bottomLeft: null },
    }
}

let sticky = {
    title: 'Sticky',
    comp: 'sticky',
    value: {
        enabled: false,
        position: 'top',
        distance: 0,
    }
}

let margin = {
    title: 'Margin',
    comp: 'marginPadding',
    value: { top: 10, left: 0, right: 0, bottom: 0 }
}

let padding = {
    title: 'Padding',
    comp: 'marginPadding',
    value: { top: 5, left: 50, right: 50, bottom: 5 }
}

let font = {
    title: 'Headline font',
    comp: 'FontPanel',
    value: {
        fontFamily: '',
        fontSize: 18,
        color: null, // When null it falls-back to the CORE.CSS styles.
        fontStyling: {
            bold: false,
            italic: false,
            'line-through': false,
            underline: false,
        },
        textAlign: 'center'
    }
}

let hiddenFont = {
    title: 'Hidden font',
    comp: 'hidden',
    value: {
        fontFamily: '',
        fontSize: 18,
        mobileFontSize: null,
        tabletFontSize: null,
        color: null, // When null it falls-back to the CORE.CSS styles.
        fontStyling: {
            bold: false,
            italic: false,
            'line-through': false,
            underline: false,
        },
        textAlign: 'center',
        mobileTextAlign: null,
        tabletTextAlign: null,
    }
}

let font2 = {
    title: 'Sub-headline font',
    comp: 'FontPanel',
    value: {
        fontFamily: '',
        fontSize: 14,
        color: '#F6F9FE',
        fontStyling: {
            bold: false,
            italic: false,
            'line-through': false,
            underline: false,
        },
        textAlign: 'center'
    }
}

let font3 = { // formElement
    title: 'Value font',
    comp: 'FontPanel',
    value: {
        fontFamily: null,
        fontSize: 14,
        color: '#252A32',
        fontStyling: {
            bold: false,
            italic: false,
            'line-through': false,
            underline: false,
        },
        textAlign: 'left'
    }
}

let font4 = { //link
    title: 'Font',
    comp: 'FontPanel',
    value: {
        fontFamily: '',
        fontSize: 14,
        color: '#4368e0',
        fontStyling: {
            bold: false,
            italic: false,
            'line-through': false,
            underline: false,
        },
        textAlign: 'center'
    }
}

let linkUrl = {
    title: 'Click action',
    comp: 'ClickAction',
    value: {
        url: '',
        target: '_blank',
        mainToggle: 'No action',
        mainSelectOptions: [
            { id: 'No action', text: 'No action' },
            { id: 'Submit step', text: 'Submit step' },
            { id: 'Go to next step', text: 'Go to next step' },
            { id: 'Go to URL', text: 'Go to URL' },
            { id: 'Go to section/row', text: 'Go to section/row' },
        ]
    }
}

let title = {
    title: 'Title',
    comp: 'TextInput',
    value: ''
}

let cssId = {
    title: 'ID',
    comp: 'TextInput',
    value: ''
}

let cssClasses = {
    title: 'CSS Classes',
    comp: 'MultiSelectItem',
    value: [],
    options: []
}

let displayType = {
    title: 'Display type',
    comp: 'selectItem',
    value: 'inline-block',
    options: [
        { id: "inline-block", text: "Fluid" },
        { id: "block", text: "Fill Width" },
    ]
}

let id = {
    value: null
}

let animations = {
    title: 'Animations',
    comp: 'AnimationsInput',
    value: {
        property: '',
        duration: 0,
        easing: '',
    }
}

let transformation = {
    title: 'Transformation',
    comp: 'TransformationInput',
    value: {
        rotateX: 0,
        rotateY: 0,
        rotateZ: 0,
        scaleX: 1,
        scaleY: 1,
        scaleZ: 1,
    }
}

let backgroundColor = {
    title: 'Background color',
    comp: 'colorPicker2',
    value: null,
}

let backgroundColorButton = {
    title: 'Background color',
    comp: 'colorPicker2',
    value: '#4368E0'
}

let props = {
    title: 'Props',
    comp: 'FormElementProps',
    value: {
        required: true,
        showLabel: true,
        auto_optin:true,
        dataType: '',
        dataType2: '',
        placeholder: '',
        placeholder2: '',
        value: '',
        optionsType: 'countries',
        options: [],
    }
}

let inputType = {
    title: 'Input type',
    comp: 'InputType',
    value: 'input'
}

let label = {
    title: 'Label',
    comp: 'hidden',
    value: 'Label'
}

let label2 = {
    title: 'Label2',
    comp: 'hidden',
    value: 'Label 2'
}

let labelFont = {
    title: 'Label font',
    comp: 'FontPanel',
    value: {
        fontFamily: null,
        fontSize: 14,
        color: '#252A32',
        fontStyling: {
            bold: false,
            italic: false,
            'line-through': false,
            underline: false,
        },
        textAlign: 'left'
    }
}



let clickAction = {
    title: 'Click action',
    comp: 'ClickAction',
    value: {
        element: '',
        target: '',
        url: '',
        mainToggle: 'No action',
        mainSelectOptions: [
            { id: 'No action', label: 'No action', description: '' },
            { id: 'Submit step', label: 'Submit step', description: '' },
            { id: 'Go to next step', label: 'Go to next step', description: '' },
            { id: 'Go to URL', label: 'Go to URL', description: '' },
            { id: 'Go to section/row', label: 'Go to section/row', description: '' },
        ]
    }
}

let size = {
    title: 'Image size',
    comp: 'SizeValue',
    placeholder: 'auto',
    value: {
        width: null,
        height: null /* null will be treated as 'auto'*/
    }
}

let image = {
    title: 'Image',
    comp: 'imageUploader',
    value: ''
}

let alternateText = {
    title: 'Alternate text',
    comp: 'TextInput',
    value: ''
}

let hoverColor = {
    title: 'Hover color',
    comp: 'colorPicker2',
    value: '#4368e0'
}


export {
    sticky,
    shadows,
    buttonShadows,
    textShadows,
    border,
    margin,
    padding,
    font,
    hiddenFont,
    font2,
    font3,
    font4,
    linkUrl,
    title,
    cssId,
    cssClasses,
    displayType,
    id,
    animations,
    transformation,
    backgroundColor,
    backgroundColorButton,
    props,
    inputType,
    label,
    label2,
    labelFont,
    clickAction,
    size,
    image,
    alternateText,
    hoverColor,
}
