import {sticky} from "./fields";

export default {
    tag: 'social',
    labelTitle: '',
    labelColor: '',
    id: { value: null },
    attributes: {
        basic: {
            align: { title: 'Alignment', comp: 'alignment', value: {mobile:null,desktop:'center',tablet:null} },
            margin: { title: 'Margin', comp: 'marginPadding', value: { top: 10, left: 0, right: 0, bottom: 0 } },
            items: {
                title: 'Social networks',
                comp: 'SocialNetworks',
                value: [
                    { title: 'Twitter', status: true, url: '', icon: 'tw_icon.svg' },
                    { title: 'LinkedIn', status: true, url: '', icon: 'in_icon.svg' },
                    { title: 'Facebook', status: true, url: '', icon: 'face_b_icon.svg' },
                    { title: 'YouTube', status: true, url: '', icon: 'youtube_icon.svg' },
                    { title: 'Instagram', status: true, url: '', icon: 'instagram_icon.svg' },
                ]
            },
            color: { title: 'Color', comp: 'colorPicker2', value: null },
            hoverColor: { title: 'Hover color', comp: 'colorPicker2', value: null },
        },
        advanced: {
            cssId: { title: 'ID', comp: 'TextInput', value: '' },
            cssClasses: { title: 'CSS Classes', comp: 'MultiSelectItem', value: [], options: [{ id: 'social', text: 'social' },] },
            backgroundColor: { title: 'Background color', comp: 'colorPicker2', value: null },
            padding: { title: 'Padding', comp: 'marginPadding', value: { top: 10, left: 10, right: 10, bottom: 10 } },
            border: {
                title: 'Border',
                comp: 'border',
                value: {
                    width: 0,
                    color: 'transparent',
                    style: 'solid',
                    sides: { top: true, left: true, right: true, bottom: true },
                    radius: { analytics: 0, topRight: 0, bottomRight: 0, bottomLeft: 0 },
                }
            },
            sticky

        },
    },
}
