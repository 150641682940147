import Vue from 'vue'
import Vuex from 'vuex'
import VuexUndoRedo from "@/modules/page-editor/src/store/undoRedoPlugin";

import editor, {applyTemplate} from '@/modules/page-editor/src/store/editor'

Vue.use(Vuex)
Vue.use(VuexUndoRedo, { ignoreMutations: ['PAGE_SET_STEP','PAGE_SET_PRODUCTS','PAGE_SET_VIEW', 'PAGE_SET_SAVED_ASSET_GROUPS','PAGE_SET_COMPOSITION_GROUPS', 'PAGE_SET_VIEW_AND_WIDTH', 'PAGE_SET_WIDTH', 'PAGE_SET_CODEPOPUPOPEN', 'PAGE_SET_INITIAL', 'PAGE_SET_TAB', 'PAGE_SET_ID', 'PAGE_SET_TABID', 'PAGE_SET_POPUP', 'PAGE_CLEARUP', 'PAGE_SET_CURRENT_BLOCK_WRAPPER'] });


export default {
    state: {
        view: 'desktop',
        tab: null,
        id: null,
        currentBlock: {},
        pageWidth: 1200,
        savedAssetGroups:[],
        compositionGroups:[],
        currentBlockWrapper: null,
        templates: [],
        products: [],
        step: {},
        currentTemplate: {
            id: false,
            name: false,
            seo_description: false,
            seo_keywords: '',
            seo_title: false,
            url: "",
        },
        customCode: {
            css: '/* CSS */\n    ',
            html: '<!-- HTML -->\n    ',
            head_js: '',
            body_js: '',
        },
        editor: editor.state,
        codePopupOpen: false,
        paymentMethodsPopupOpen: false,
        page: {
            basic: {
                backgroundColor: '#fff',
                backgroundImage: '',
                padding: { top: 68, left: 30, right: 30, bottom: 200 },
                font: {
                    fontStyling: { bold: false, italic: false, 'line-through': false, underline: false },
                    color: '#252A32',
                    fontFamily: 'Roboto',
                    fontSize: 14,
                },
                border: {
                    radius: { bottomLeft: 0, bottomRight: 0, analytics: 0, topRight: 0 },
                    sides: { bottom: true, left: true, right: true, top: true },
                    color: 'transparent',
                    style: 'solid',
                    width: 0
                }
            },
            advanced: {
                id: '',
                cssClasses: []
            }
        },
        initial: {
            funnel: null,
            step: null,
            token: null,
            user: null,
            page: null
        },
        emptyStateData: {config:{}},
    },
    mutations: {
        PAGE_SET_SAVED_ASSET_GROUPS(state, value) {
            state.savedAssetGroups = value
        },
        PAGE_SET_COMPOSITION_GROUPS(state, value) {
            state.compositionGroups = value
        },
        PAGE_SET_PRODUCTS(state, value) {
            state.products = value
        },
        PAGE_SET_STEP(state, value) {
            state.step = value
        },
        PAGE_SET_CURRENT_BLOCK_WRAPPER(state, value) {
            state.currentBlockWrapper = value;
        },
        /**
         * @param state
         * @param tab
         * @param id
         * @param block we use direct block to improve performance and prevent unnecessary re-renders.
         * @constructor
         */
        PAGE_SET_TABID(state, { tab, id, block }) {
            state.tab = tab
            state.id = id
            // if (state.currentBlock) {
            //     Vue.set(state.currentBlock, 'selected', false);
            // }
            // let s = performance.now();

            // Doing an update this way reduces re-renders and does not cost much (less than 1ms).
            let e = state.editor;
            e.content.forEach(x=>Vue.set(x,'selected', false))
            e.rows.forEach(x=>Vue.set(x,'selected', false))
            e.children.forEach(x=>Vue.set(x,'selected', false))

            let p = state.editor.popup;
            if (p) {
                p.content.forEach(x=>Vue.set(x,'selected', false))
                p.rows.forEach(x=>Vue.set(x,'selected', false))
                p.children.forEach(x=>Vue.set(x,'selected', false))
            }

            if (block) {
                Vue.set(block,'selected', true);
            }
            state.currentBlock = block;

            // console.log("EDIT done "+(performance.now()-s)+'ms')
        },
        PAGE_SET_TAB(state, value) {
            state.tab = value
        },
        PAGE_SET_POPUP(state, value) {
            state.editor.popup = value
        },
        PAGE_SET_ID(state, value) {
            state.id = value

        },
        PAGE_SET_PAGE_PROPS(state, value) {
            Vue.set(state.page[value.type], value.name, value.item)
            // state.page[value.type][value.name] = value.item
        },
        PAGE_SET_POPUP_PROPS(state, value) {
            if (!state.editor.popup) {
                console.error("PAGE_SET_POPUP_PROPS: no popup.")
                return
            }

            if (!state.editor.popup.attributes)
                Vue.set(state.editor.popup, 'attributes', {})
            if (!state.editor.popup.attributes[value.type])
                Vue.set(state.editor.popup.attributes, value.type, {})

            Vue.set(state.editor.popup.attributes[value.type], value.name, value.item)
        },
        PAGE_SET_VIEW(state, value) {
            state.view = value
            switch (value) {
                case "mobile":
                    state.pageWidth = 420;//500;
                    break
                case "tablets":
                    state.pageWidth = 850;
                    break
                default:
                    state.pageWidth = 1200;
                    break
            }
        },
        PAGE_SET_VIEW_AND_WIDTH(state, value) {
            state.view = value.view
            state.pageWidth = value.width
        },
        PAGE_SET_WIDTH(state, value) {
            state.pageWidth = value
        },
        SET_PAYMENTMETHODPOPUP_OPEN(state, value) {
            state.paymentMethodsPopupOpen = value
        },
        PAGE_SET_CODEPOPUPOPEN(state, value) {
            state.codePopupOpen = value
        },
        PAGE_SET_CUSTOM_CODE(state, { html, css, head_js, body_js }) {
            state.customCode = { html, css, head_js, body_js };
        },
        PAGE_SET_INITIAL(state, value) {
            state.initial = value
        },
        PAGE_APPLY_TEMPLATE(state, value) {

            // save this for later (undo/redo) empty-state.
            state.emptyStateData = JSON.parse(JSON.stringify(value));

            applyTemplate(state,value)
        },
        PAGE_SETTINGS_UPDATE(state, value) {
            state.currentTemplate.seo_description = value.seo_description || false;
            state.currentTemplate.seo_keywords = value.seo_keywords || '';
            state.currentTemplate.seo_title = value.seo_title || false;
        },
        ...editor.mutations
    },
    actions: {
        PAGE_SET_PRODUCTS(context, value) {
            context.commit('PAGE_SET_PRODUCTS', value);
        },
        PAGE_SET_SAVED_ASSET_GROUPS(context, value) {
            context.commit('PAGE_SET_SAVED_ASSET_GROUPS', value);
        },
        PAGE_SET_COMPOSITION_GROUPS(context, value) {
            context.commit('PAGE_SET_COMPOSITION_GROUPS', value);
        },
        PAGE_SET_STEP(context, value) {
            context.commit('PAGE_SET_STEP', value);
        },
        PAGE_SET_CURRENT_BLOCK_WRAPPER(context, value) {
            context.commit('PAGE_SET_CURRENT_BLOCK_WRAPPER', value);
        },
        PAGE_SET_PAGE_PROPS(context, value) {
            context.commit('PAGE_SET_PAGE_PROPS', value);
        },
        PAGE_SET_POPUP_PROPS(context, value) {
            context.commit('PAGE_SET_POPUP_PROPS', value);
        },
        PAGE_SET_VIEW(context, value) {
            context.commit('PAGE_SET_VIEW', value)
        },
        PAGE_SET_VIEW_AND_WIDTH(context, value) {
            context.commit('PAGE_SET_VIEW_AND_WIDTH', value)
        },
        SET_PAYMENTMETHODPOPUP_OPEN(context, value) {
            context.commit('SET_PAYMENTMETHODPOPUP_OPEN', value)
        },
        PAGE_SET_CODEPOPUPOPEN(context, value) {
            context.commit('PAGE_SET_CODEPOPUPOPEN', value)
        },
        PAGE_SET_CUSTOM_CODE(context, value) {
            context.commit('PAGE_SET_CUSTOM_CODE', value);
        },
        PAGE_APPLY_TEMPLATE(context, value) {
            if (!value.clear && typeof value.config !== 'object' && value.config.length) {
                try {
                    value.config = JSON.parse(value.config);
                } catch (e) {
                    console.error('Page data seems corrupt couldn\'t read it! try again later or create a new page.');
                    console.error(e);
                    console.error('JSON',value.config)
                }
            }

            context.commit('PAGE_APPLY_TEMPLATE', value);
        },
        PAGE_SETTINGS_UPDATE(context, value) {
            context.commit('PAGE_SETTINGS_UPDATE', value);
        },
        PAGE_SET_WIDTH(context, value) {
            context.commit('PAGE_SET_WIDTH', value);
        },
        PAGE_SET_INITIAL(context, value) {
            context.commit('PAGE_SET_INITIAL', value);
        },
        ...editor.actions
    },
    getters: {
        savedAssetGroups(state) {
            return state.savedAssetGroups;
        },
        compositionGroups(state) {
            return state.compositionGroups;
        },
        products(state) {
            return state.products;
        },
        step(state) {
            return state.step;
        },
        current_view(state) {
            return state.view;
        },
        current_template(state) {
            return state.currentTemplate;
        },
        current_wrapper(state) {
            return state.currentBlockWrapper
        },
        page_id(state) {
            return state.id
        },
        page_tab(state) {
            return state.tab
        },
        page_width(state) {
            return state.pageWidth
        },
        page_props({ page }) {
            return page // returns page attribute.
        },
        ...editor.getters
    }
}
