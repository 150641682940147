import {
    shadows, sticky,
} from './fields/'

export default {
    tag: 'progressBar',
    labelTitle: 'progress bar',
    labelColor: '',
    id: { value: null },
    attributes: {
        basic: {
            margin: { title: 'Margin', comp: 'marginPadding', value: { top: 15, left: 0, right: 0, bottom: 0 } },
            valueNumber: { title: 'Value', comp: 'InputNumber', value: { number: 60, append: '%' } },
            labelText: { title: 'Label text', comp: 'TextInput', value: '60%' },
            progressColor1: { title: 'Progress color #1', comp: 'colorPicker2', value: '#2BC253' },
            progressColor2: { title: 'Progress color #2', comp: 'colorPicker2', value: '#54F054' },
            backgroundColor: { title: 'Background color', comp: 'colorPicker2', value: '#555555' },
            height: { title: 'Height', comp: 'InputNumber', value: { number: 20 } },
            labelPosition: { title: 'Label position', comp: 'selectItem', value: 'center', options: [{ id: "center", text: "Center" }, { id: "left", text: "Left" }, { id: "right", text: "Right" }] },
            style: { title: 'Style', comp: 'selectItem', value: 'stripes', options: [{ id: "stripes", text: "Stripes" }, { id: "simple", text: "Simple" }] },
            animated: { title: 'Use animation?', comp: 'ToggleInput', value: false },
            font: { title: 'Label font', comp: 'FontPanel', value: { fontFamily: '', fontSize: 18, color: '#ffffff', fontStyling: { bold: false, italic: false, 'line-through': false, underline: false, } } },
            border: {
                title: 'Border',
                comp: 'border',
                value: {
                    width: 0,
                    color: 'transparent',
                    style: 'solid',
                    sides: { top: true, left: true, right: true, bottom: true },
                    radius: { analytics: 0, topRight: 0, bottomRight: 0, bottomLeft: 0 },
                }
            },
            shadows
        },
        advanced: {
            cssId: { title: 'ID', comp: 'TextInput', value: '' },
            cssClasses: { title: 'CSS Classes', comp: 'MultiSelectItem', value: [], options: [{ id: 'progress-bar', text: 'progress-bar' }] },
            // animations: {
            //     title: 'Animations',
            //     comp: 'AnimationsInput',
            //     value: {
            //         property: '',
            //         duration: 0,
            //         easing: '',
            //     }
            // },
            padding: { title: 'Padding', comp: 'marginPadding', value: { top: 10, left: 10, right: 10, bottom: 10 } },
            textShadows: {
                title: 'Text shadow',
                comp: 'ShadowsInput',
                value: {
                    angle: 0,
                    distance: 0,
                    blur: 0,
                    spread: 0,
                    color: 'transparent',
                    type: '',
                }
            },
            sticky

            // transformation: {
            //     title: 'Transformation',
            //     comp: 'TransformationInput',
            //     value: {
            //         rotateX: 0,
            //         rotateY: 0,
            //         rotateZ: 0,
            //         scaleX: 1,
            //         scaleY: 1,
            //         scaleZ: 1,
            //     }
            // },
        },
    },
}
