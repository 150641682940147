import {sticky} from "./fields";

export default {
    tag: 'timer',
    labelTitle: '',
    labelColor: '',
    id: {value: null},
    attributes: {
        basic: {
            align: { title: 'Alignment', comp: 'alignment', value: {mobile:null,desktop:'center',tablet:null} },
            margin: { title: 'Margin', comp: 'marginPadding', value: { top: 0, left: 0, right: 0, bottom: 0 } },
            hours: { title: 'Hours', comp: 'InputNumber', value: { number: 1, append:'hours' } },
            hoursLabel: { title: 'Hours label', comp: 'TextInput', value: 'Hours' },
            minutes: { title: 'Minutes', comp: 'InputNumber', value: { number: 45, append:'minutes' } },
            minutesLabel: { title: 'Minutes label', comp: 'TextInput', value: 'Minutes' },
            seconds: { title: 'Seconds', comp: 'InputNumber', value: { number: 15, append:'seconds' } },
            secondsLabel: { title: 'Seconds label', comp: 'TextInput', value: 'Seconds' },
            expireAction: { title: 'Expire action', comp: 'ExpireAction', value: { url: '', mainToggle: ''} },
            numberFont: { title: 'Numbers font', comp: 'FontPanel', value: { fontFamily: null, fontSize: 36, color: '#636A7B', fontStyling: { bold: false, italic: false, 'line-through': false, underline: false, }} },
            labelsFont : { title: 'Labels font', comp: 'FontPanel', value: { fontFamily: null, fontSize: 16, color: '#b1b2b4', fontStyling: { bold: false, italic: false, 'line-through': false, underline: false, }} },
            numbersBorder: {
                title: 'Numbers border',
                comp: 'border',
                value: {
                    width: 0,
                    color: null,
                    style: 'solid',
                    sides: { top: true, left: true, right: true, bottom: true },
                    radius: { topLeft: 0, topRight: 0, bottomRight: 0, bottomLeft: 0 },
                }
            },
            numbersBgColor: { title: 'Numbers background color', comp: 'colorPicker2', value: null },
        },
        advanced: {
            cssId: { title: 'ID', comp: 'TextInput', value: '' },
            cssClasses: { title: 'CSS Classes', comp: 'MultiSelectItem', value: [], options: [] },
            padding: { title: 'Padding', comp: 'marginPadding', value: { top: 10, left: 10, right: 10, bottom: 10 } },
            backgroundColor: { title: 'Background color', comp: 'colorPicker2', value: null },
            border: {
                title: 'Border',
                comp: 'border',
                value: {
                    width: 0,
                    color: null,
                    style: 'solid',
                    sides: { top: true, left: true, right: true, bottom: true },
                    radius: { topLeft: 0, topRight: 0, bottomRight: 0, bottomLeft: 0 },
                }
            },
            sticky

            // clickAction: {title: 'Click action', comp: 'ClickAction', value: { url: '', toggle: 'no action'} },
            // alternateText: { title: 'Alternate text', comp: 'TextInput', value: '' },
            // alignment: {
            //     title: 'Alignment', comp: 'Alignment', value: { alignSide: 'left' }
            // },
            // separator: { title: 'Separator', comp: 'TextInput', value: ':' },
            // animations: {
            //     title: 'Animations',
            //     comp: 'AnimationsInput',
            //     value: {
            //         property: '',
            //         duration: 0,
            //         easing: '',
            //     }
            // },
            // shadows: {
            //     title: 'Shadows',
            //     comp: 'ShadowsInput',
            //     value: {
            //         angle: 0,
            //         distance: 0,
            //         blur: 0,
            //         spread: 0,
            //         color: 'transparent',
            //         type: '',
            //     }
            // },
            // transformation: {
            //     title: 'Transformation',
            //     comp: 'TransformationInput',
            //     value: {
            //         rotateX: 0,
            //         rotateY: 0,
            //         rotateZ: 0,
            //         scaleX: 1,
            //         scaleY: 1,
            //         scaleZ: 1,
            //     }
            // },
        },
    },
}
