import Vue from 'vue'
import Vuex from 'vuex'
import statistic from './statistic'
import automationSteps from './automation-steps'
import errorHandle from './errorHandle'
import dataStorage from './dataStorage'
import email from '@/modules/email-editor/src/store'
import page from '@/modules/page-editor/src/store'
import customers from './customers'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: localStorage.getItem('user-token') || false,
        user: localStorage.getItem('user') || false,
        accountId: localStorage.getItem('accountId') || false,
        userInfo: { },

        staff: [],

        notificationsOpen: false,
        appSettingsPopup: false,
        productSettingsPopup: false,
        AddNewFunnelPopup: false,
        OrderInfoPopup: false,
        FunnelSettingPopup: false,
        // CustomerPopup: false,
        automationPopup: {
            show: false,
            flag: true
        },
        allSteps:[],
        allFunnels:[],
        funnelFolders:[],
        allSubFunnels:[],
        allConditions:[],
        activateAutomationPopup: false,
        ConnectNewDomainPopup: false,
        AddNewStepPopup: false,
        FunnelDeletePopup: false,
        StepDeletePopup: false,
        ProductDeletePopup: false,
        AutomationsDeletePopup: false,
        CustomerDeletePopup: false,
        CreateAutomationPopup: false,
        AddNewActionPopup: false,
        AnotherConditionPopup: false,
        AddNewWaitPopup: false,
        AddEndAutomationPopup: false,
        GoogleSheetsPopup: false,
        AddRowGSheetsPopup: false,
        CloneFunnelPopup: false,
        CloneProductPopup: false,
        ModalEndAutomationPopup: false,
        AddNewStepPopupPlus: false,
        // CancelOrderPopupOpen: false,
        gateways: false,
        currentKey: -1,
    },
    mutations: {
        SET_STAFF(state, value) {
            state.staff = value;
        },
        SET_ALL_STEPS(state, value) {
            state.allSteps = value;
        },
        SET_ALL_FUNNELS(state, value) {
            state.allFunnels = value;
        },
        SET_FUNNEL_FOLDERS(state, value) {
            state.funnelFolders = value;
        },
        SET_ALL_SUB_FUNNELS(state, value) {
            state.allSubFunnels = value;
        },
        SET_ALL_CONDITIONS(state, value) {
            state.allConditions = value;
        },
        notificationsOpen(state) {
            state.notificationsOpen = !state.notificationsOpen;
        },
        showSettingsPopup(state) {
            state.appSettingsPopup = true
        },
        showProductSettingsPopup(state) {
            state.productSettingsPopup = true
        },
        closeProductSettingsPopup(state) {
            state.productSettingsPopup = false
        },
        closeSettingsPopup(state) {
            state.appSettingsPopup = false
        },
        showAddNewFunnelPopup(state) {
            state.AddNewFunnelPopup = true
        },
        closeAddNewFunnelPopup(state) {
            state.AddNewFunnelPopup = false
        },
        showOrderInfoPopup(state) {
            state.OrderInfoPopup = true
        },
        closeOrderInfoPopup(state) {
            state.OrderInfoPopup = false
        },
        showFunnelSettingPopup(state) {
            state.FunnelSettingPopup = true
        },
        closeFunnelSettingPopup(state) {
            state.FunnelSettingPopup = false
        },
        showAutomationPopup(state, value = true) {
            state.automationPopup.show = true
            state.automationPopup.flag = value
        },
        closeAutomationPopup(state) {
            state.automationPopup.show = false
        },
        showActivateAutomationPopup(state) {
            state.activateAutomationPopup = true
        },
        closeActivateAutomationPopup(state) {
            state.activateAutomationPopup = false
        },
        // showCustomerPopup(state) {
        //     state.CustomerPopup = true
        // },
        // closeCustomerPopup(state) {
        //     state.CustomerPopup = false
        // },
        showConnectNewDomainPopup(state) {
            state.ConnectNewDomainPopup = true
        },
        closeConnectNewDomainPopup(state) {
            state.ConnectNewDomainPopup = false
        },
        showAddNewStepPopup(state) {
            state.AddNewStepPopup = true
        },
        closeAddNewStepPopup(state) {
            state.AddNewStepPopup = false
        },
        showFunnelDeletePopup(state) {
            state.FunnelDeletePopup = true
        },
        closeFunnelDeletePopup(state) {
            state.FunnelDeletePopup = false
        },
        showStepDeletePopup(state) {
            state.StepDeletePopup = true
        },
        closeStepDeletePopup(state) {
            state.StepDeletePopup = false
        },
        showProductDeletePopup(state) {
            state.ProductDeletePopup = true
        },
        closeProductDeletePopup(state) {
            state.ProductDeletePopup = false
        },
        showAutomationsDeletePopup(state) {
            state.AutomationsDeletePopup = true
        },
        closeAutomationsDeletePopup(state) {
            state.AutomationsDeletePopup = false
        },
        showCustomerDeletePopup(state) {
            state.CustomerDeletePopup = true
        },
        closeCustomerDeletePopup(state) {
            state.CustomerDeletePopup = false
        },
        showCreateAutomationPopup(state) {
            state.CreateAutomationPopup = true
        },
        closeCreateAutomationPopup(state) {
            state.CreateAutomationPopup = false
        },
        showAddNewActionPopup(state) {
            state.AddNewActionPopup = true
        },
        closeAddNewActionPopup(state) {
            state.AddNewActionPopup = false
        },
        showAnotherConditionPopup(state) {
            state.AnotherConditionPopup = true
        },
        closeAnotherConditionPopup(state) {
            state.AnotherConditionPopup = false
        },
        showAddNewWaitPopup(state) {
            state.AddNewWaitPopup = true
        },
        closeAddNewWaitPopup(state) {
            state.AddNewWaitPopup = false
        },
        showAddEndAutomationPopup(state) {
            state.AddEndAutomationPopup = true
        },
        closeAddEndAutomationPopup(state) {
            state.AddEndAutomationPopup = false
        },
        showGoogleSheetsPopup(state) {
            state.GoogleSheetsPopup = true
        },
        closeGoogleSheetsPopup(state) {
            state.GoogleSheetsPopup = false
        },
        showAddRowGSheetsPopup(state) {
            state.AddRowGSheetsPopup = true
        },
        closeAddRowGSheetsPopup(state) {
            state.AddRowGSheetsPopup = false
        },
        showCloneFunnelPopup(state) {
            state.CloneFunnelPopup = true
        },
        closeCloneFunnelPopup(state) {
            state.CloneFunnelPopup = false
        },
        showCloneProductPopup(state) {
            state.CloneProductPopup = true
        },
        closeCloneProductPopup(state) {
            state.CloneProductPopup = false
        },
        showModalEndAutomationPopup(state) {
            state.ModalEndAutomationPopup = true
        },
        closeModalEndAutomationPopup(state) {
            state.ModalEndAutomationPopup = false
        },
        showAddNewStepPopupPlus(state) {
            state.AddNewStepPopupPlus = true
        },
        closeAddNewStepPopupPlus(state) {
            state.AddNewStepPopupPlus = false
        },
        SET_TOKEN(state, value) {
            state.token = value
        },
        SET_USER(state, value) {
            state.user = value
        },
        SET_GATEWAYS(state, value) {
            state.gateways = value
        },
        setUserInfo(state, value) {
            state.userInfo = value;
        },
        setCurrentKey(state, value) {
            state.currentKey = value
        },
    },
    actions: {
        notificationsOpenToggle(context) {
            context.commit('notificationsOpen')
        },
        SET_ALL_STEPS(context, value) {
            context.commit('SET_ALL_STEPS', value)
        },
        SET_ALL_FUNNELS(context, value) {
            context.commit('SET_ALL_FUNNELS', value)
        },
        SET_FUNNEL_FOLDERS(context, value) {
            context.commit('SET_FUNNEL_FOLDERS', value)
        },
        SET_ALL_SUB_FUNNELS(context, value) {
            context.commit('SET_ALL_SUB_FUNNELS', value)
        },
        SET_ALL_CONDITIONS(context, value) {
            context.commit('SET_ALL_CONDITIONS', value)
        },
        SET_STAFF(context, value) {
            context.commit('SET_STAFF', value)
        },
        SET_TOKEN(context, value) {
            context.commit('SET_TOKEN', value)
        },
        SET_USER(context, value) {
            context.commit('SET_USER', value)
        },
        SET_GATEWAYS(context, value) {
            context.commit('SET_GATEWAYS', value);
        },
        setUserInfo(context, value) {
            context.commit('setUserInfo', value);
        },
        setCurrentKey(context, value) {
            context.commit('setCurrentKey', value);
        },
    },
    getters: {
        getStaff(state) {
            return state.staff
        },
        getAllSteps(state) {
            return state.allSteps
        },
        getAllFunnels(state) {
            return state.allFunnels
        },
        getFunnelFolders(state) {
            return state.funnelFolders
        },
        getAllSubFunnels(state) {
            return state.allSubFunnels
        },
        getAllConditions(state) {
            return state.allConditions
        },
        getAppSettingsPopup(state) {
            return state.appSettingsPopup
        },
        getProductSettingsPopup(state) {
            return state.productSettingsPopup
        },
        getAddNewFunnelPopup(state) {
            return state.AddNewFunnelPopup
        },
        getOrderInfoPopup(state) {
            return state.OrderInfoPopup
        },
        getFunnelSettingPopup(state) {
            return state.FunnelSettingPopup
        },
        getAutomationPopup(state) {
            return state.automationPopup
        },
        getActivateAutomationPopup(state) {
            return state.activateAutomationPopup
        },
        // getCustomerPopup(state) {
        //     return state.CustomerPopup
        // },
        getConnectNewDomainPopup(state) {
            return state.ConnectNewDomainPopup
        },
        getAddNewStepPopup(state) {
            return state.AddNewStepPopup
        },
        getFunnelDeletePopup(state) {
            return state.FunnelDeletePopup
        },
        getStepDeletePopup(state) {
            return state.StepDeletePopup
        },
        getProductDeletePopup(state) {
            return state.ProductDeletePopup
        },
        getAutomationsDeletePopup(state) {
            return state.AutomationsDeletePopup
        },
        getCustomerDeletePopup(state) {
            return state.CustomerDeletePopup
        },
        getCreateAutomationPopup(state) {
            return state.CreateAutomationPopup
        },
        getAddNewActionPopup(state) {
            return state.AddNewActionPopup
        },
        getAnotherConditionPopup(state) {
            return state.AnotherConditionPopup
        },
        getAddNewWaitPopup(state) {
            return state.AddNewWaitPopup
        },
        getAddEndAutomationPopup(state) {
            return state.AddEndAutomationPopup
        },
        getGoogleSheetsPopup(state) {
            return state.GoogleSheetsPopup
        },
        getAddRowGSheetsPopup(state) {
            return state.AddRowGSheetsPopup
        },
        getCloneFunnelPopup(state) {
            return state.CloneFunnelPopup
        },
        getCloneProductPopup(state) {
            return state.CloneProductPopup
        },
        getModalEndAutomationPopup(state) {
            return state.ModalEndAutomationPopup
        },
        getAddNewStepPopupPlus(state) {
            return state.AddNewStepPopupPlus
        },
        getCurrentKey(state) {
            return state.currentKey
        },
    },
    modules: {
        customers,
        statistic,
        automationSteps,
        errorHandle,
        dataStorage,
        email,
        page,
    }
})
