import {
    shadows, buttonShadows, // textShadows,
    border, margin, padding, font, font2, linkUrl, title, cssId, cssClasses, id, backgroundColorButton, sticky
} from './fields/'

let hoverFont = {
    comp: 'FontPanel',
    value: {
        fontFamily: null,
        fontSize: null,
        color: null, // When null it falls-back to the CORE.CSS styles.
        fontStyling: {
            bold: null,
            italic: null,
            'line-through': null,
            underline: null,
        },
        textAlign: null
    }
}


export default {
    tag: 'button',
    labelTitle: '',
    labelColor: '',
    id,
    editLock: false,
    attributes: {
        basic: {
            align: { title: 'Alignment', comp: 'alignment', value: {mobile:null,desktop:'center',tablet:null} },
            margin,
            content: { title: 'Headline', comp: 'TextInput', value: 'Call To Action' },
            subHeadline: { title: 'Sub-headline', comp: 'TextInput', value: '' },
            linkUrl,
            font,
            font2,
            lineSpacing: { title: 'Lines Spacing', comp: 'InputNumber', value: {number:10, append:'px'} },
            backgroundColor: backgroundColorButton,
            border,
            shadows,
            textShadows: buttonShadows
        },
        hover: {
            backgroundColor: {title: 'Background color', comp: 'colorPicker2', value: null},
            border: {title: 'Border', comp: 'border', value: {
                    width: null,
                    color: null,
                    style: 'solid',
                    sides: { top: true, left: true, right: true, bottom: true },
                    radius: { topLeft: null, topRight: null, bottomRight: null, bottomLeft: null },
                }
            },
            font: {title: 'Headline font', ...hoverFont},
            font2: {title: 'Sub-headline font', ...hoverFont},
            shadows,
            textShadows: buttonShadows
        },
        advanced: {
            title,
            cssId,
            cssClasses,
            padding:{
                title: 'Padding',
                comp: 'marginPadding',
                value: { top: 15, left: 30, right: 30, bottom: 15 }
            },
            sticky

        },
    },
}

