export default (config, axios, token) => {

    var call = async function(path, method = 'GET', data, string = true,) {
        if (string) data ? data = JSON.stringify(data) : '';

        const url = config.URL + path;
        try {
            const response = await axios({
                url,
                method,
                data,
                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type':'application/json' }
            })
            return response.data
        } catch (error) {
            return { error: true, info: error.response }
        }
    }

    return {
        //STEPS
        get: async(id) =>
            call('funnel-steps/' + id),

        getAll: async(id) =>
            call('funnels/' + id + '/steps'),

        create: async(data) =>
            call('funnel-steps', 'POST', data),

        patch: async(id, data) =>
            call('funnel-steps/' + id, 'PATCH', data, true),

        clone: async(data) =>
            call('clone-funnel-steps', 'POST', data),

        delete: async(id) =>
            call('funnel-steps/' + id, 'DELETE'),

        //PRODUCTS
        getProducts: async(id) =>
            call('funnel-steps/' + id + '/products'),

        patchProduct: async(id, data) =>
            call('products/' + id, 'PATCH', data),

        createProduct: async(data) =>
            call('products', 'POST', data),

        cloneProduct: async(data) =>
            call('products', 'POST', data),

        deleteProduct: async(id) =>
            call('products/' + id, 'DELETE'),

        //VARIANTS
        getVariants: async(id) =>
            call('products/' + id + '?include=variants'),

        patchVariants: async(id, data) =>
            call('product-variants/' + id, 'PATCH', data),

        getOptions: async(id) =>
            call('product-variant-options?productId=' + id),

        //AUTOMATIONS
        getAutomations: async(id) =>
            call('automation-briefs?productId=' + id),

        getProductAutomations: async(id) =>
            call('product-automations/' + id),

        getFunnelAutomations: async(id) =>
            call('funnels/' + id + '/automations'),

        patchAutomation: async(id, data) =>
            call('product-automations/' + id, 'PATCH', data, false),

        createAutomation: async(data) =>
            call('product-automations', 'POST', data, false),

        deleteAutomation: async(id) =>
            call('automation-briefs/' + id, 'DELETE'),

        //INTEGRATIONS
        getIntegrations: async() =>
            call('integrations'),

        patchIntegration: async(id, data) =>
            call('user-integrations/' + id, 'PATCH', data),

        getActions: async(caption, action, args = "{ \"id\": 0, \"arg1\": \"1234\", \"arg2\": \"arg22234\" }") =>
            call('call-integration-method/' + caption + '/' + action, 'POST', args, false)








    }
}
