export default {
    tag: 'button',
    labelTitle: '',
    labelColor: '',
    id: { value: null },
    attributes: {
        basic: {
            margin: { title: 'Margin', comp: 'marginPadding', value: { top: 0, left: 0, right: 0, bottom: 0 } },
            content: { title: 'Button headline', comp: 'TextInput', value: 'Button' },
            subHeadline: { title: 'Button sub-headline', comp: 'TextInput', value: '' },
            linkUrl: { title: 'Click action', comp: 'ClickAction', value: { url: '', toggle: 'no action' } },
            font: { title: 'Headline font', comp: 'FontPanel', value: { fontFamily: 'Roboto', fontSize: 14, color: '#F6F9FE', fontStyling: { bold: false, italic: false, 'line-through': false, underline: false, }, textAlign: 'center'} },
            font2: { title: 'Sub-headline font', comp: 'FontPanel', value: { fontFamily: 'Roboto', fontSize: 14, color: '#F6F9FE', fontStyling: { bold: false, italic: false, 'line-through': false, underline: false, }, textAlign: 'center'} },
            backgroundColor: { title: 'Background color', comp: 'colorPicker', value: '#4368E0' },
        },
        advanced: {
            cssId: { title: 'ID', comp: 'TextInput', value: '' },
            cssClasses: { title: 'CSS Classes', comp: 'MultiSelectItem', value: [], options: [] },
            displayType: { title: 'Display type', comp: 'selectItem', value: 'inline-block', options: [{ id: "inline-block", text: "Fluid" }, { id: "block", text: "Fill Width" }, ] },
            padding: { title: 'Padding', comp: 'marginPadding', value: { top: 10, left: 30, right: 30, bottom: 10 } },
            border: {
                title: 'Border',
                comp: 'border',
                value: {
                    width: 0,
                    color: 'transparent',
                    style: 'solid',
                    sides: { top: true, left: true, right: true, bottom: true },
                    radius: { leftTop: 4, topRight: 4, bottomRight: 4, bottomLeft: 4 },
                }
            },
        },
    },
}
