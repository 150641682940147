<template>
	<div class="condition_entry" @click="show">
		<span class="key">{{c.key}}</span>
		<span class="spc">&nbsp;&nbsp;</span>
		<span class="operand">{{operand}}</span>
		<span class="spc">&nbsp;&nbsp;</span>
		<span class="value">{{val}}</span>

		<tippy :ref="'condition_entry_tippy_'+id"
		       interactive
		       :animate-fill="false"
		       placement="bottom-end"
		       distant="7"
		       theme="menu"
		       animation="fade"
		       @hidden="onHidden"
		       trigger="manual">
			<div class="">

				<!-- `isOpen` is important to re-trigger mount()	-->
				<filter-condition v-if="isOpen"
						:condition="c" :editing="true"
						:items="items"
						@save="update" @cancel="cancel" @remove="remove" />
			</div>
		</tippy>
	</div>



</template>

<script>
import FilterCondition from "@/views/funnels/funnel-plus/funnel/analytics/filter_condition";
let id = 1;
export default {
	name: "condition_entry", components: {FilterCondition},
	props:['c','items','conditions'],
	data(){
		return {
			id: id++,
			tippy: null,
			isOpen: false,
			cond:{},
		}
	},
	methods:{
		remove(c){
			let i = this.conditions.findIndex(x=>x.id===c.id) // find by reference.
			if (i>=0) {
				this.conditions.splice(i, 1)
			} else {
				console.error("couldn't find item index", c)
			}
			let ss = JSON.stringify(this.conditions)
			localStorage.setItem("filterConditions", ss)

			this.tippy.tippy().hide();

			this.$emit('change');

		},
		update(cond) {
			let c = JSON.parse(JSON.stringify(cond))

			let i = this.conditions.findIndex(x=>x.id===c.id) // find by reference.
			if (i>=0) {
				this.conditions.splice(i, 1, c)
			} else {
				this.conditions.push(c)
			}
			let ss = JSON.stringify(this.conditions)
			localStorage.setItem("filterConditions", ss)

			this.tippy.tippy().hide();

			this.$emit('change');
		},
		onHidden() {
			this.isOpen = false;
			// this.tippy.tippy().show();
		},
		show() {
			this.isOpen = true;
			this.tippy.tippy().show();
		},
		cancel(){
			this.tippy.tippy().hide();
		},
	},
	computed:{
		val(){
			if (['currency','country'].includes(this.c.key)) {
				let v = this.c.values || [];
				return `(${v.join(' or ')})`
			}
			return this.c.value
		},
		operand() {
			let ops = {'IN':'is one of','NOT IN':'is none of',
				'LIKE':'contains','NOT LIKE':'does not contain','=':'equals'}
			return ops[this.c.operand];
		},

	},
	mounted() {
		this.tippy = this.$refs[`condition_entry_tippy_${this.id}`]
	}

}
</script>

<style lang="scss" scoped>
.condition_entry {
	margin: 2px 5px;
	padding: 3px 10px;
	background: #F9FAFB;
	cursor: pointer;
	color: #636A7B;
	box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
	border-radius: 8px;
	overflow: hidden;
	border: 1px #D0D5DD solid;
	font-weight: 600;
	font-size: 12px;
	line-height: 20px;

	&:hover {
		text-decoration: underline;
	}
	.key {
		font-weight: 500;
	}
	.value {
		font-weight: 500;
	}
	.operand {
		//padding: 0 10px;
		font-style: italic;
	}
	.spc {

	}
}
</style>
