export default (config, axios, token) => {

    var call = async function(path, method = 'GET', data) {
        data ? data = JSON.stringify(data) : '';
        const url = config.URL + path;
        try {
            const response = await axios({
                url,
                method,
                data,
                headers: { 'Authorization': 'Bearer ' + token }
            })
            return response.data
        } catch (error) {
            return { error: true, info: error.response }
        }
    }

    var upload = async function(path, method = 'POST', data) {
        const url = config.URL + path;
        try {
            const response = await axios({
                url,
                method,
                headers: { 'Authorization': 'Bearer ' + token },
                data,
                redirect: 'follow'
            })
            return response.data
        } catch (error) {
            return { error: true, info: error.response }
        }
    }

    return {
        // Images
        get: async() =>
            call('images'),

        create: async(funnelId=0, data, folder = 0) =>
            upload(`images?folder_id=${folder||0}&funnel_id=${funnelId||0}` , 'POST', data),

        delete: async(image) =>
            call('images/' + image, 'DELETE'),

        // Image Folders
        foldersGet: async() =>
            call('image-folders'),

        foldersCreate: async(data) =>
            call('image-folders', 'POST', data),

        foldersPatch: async(folder, data) =>
            call('image-folders/' + folder, 'PATCH', data),

        foldersDelete: async(folder) =>
            call('image-folders/' + folder, 'DELETE'),
    }
}
