<template>
	<td>
    <ul class="email_social_element" v-if="basic.items" :style="style" :id="advanced.cssId" :class="[advanced.cssClasses, responsiveClasses]">
        <li v-for="(item, index) in basic.items" :key="index" v-if="item.status">
            <a :href="item.url" :class="item.title">
                <img :src="imgSrc(item.icon)"/>
            </a>
        </li>
    </ul>

    <!-- заглушка -->
    <ul class="email_social_element" v-else :style="style" :id="advanced.cssId" :class="[advanced.cssClasses, responsiveClasses]">
        <li>
            <a>
                <img src="https://img.funnelish.com/default/social/tw_icon.png">
            </a>
        </li>
        <li>
            <a>
                <img src="https://img.funnelish.com/default/social/in_icon.png">
            </a>
        </li>
        <li>
            <a>
                <img src="https://img.funnelish.com/default/social/face_b_icon.png">
            </a>
        </li>
        <li>
            <a>
                <img src="https://img.funnelish.com/default/social/youtube_icon.png">
            </a>
        </li>
        <li>
            <a>
                <img src="https://img.funnelish.com/default/social/instagram_icon.png">
            </a>
        </li>
    </ul>
	</td>
</template>

<script>
export default {
    name: 'EmailSocial',
    props: ['details'],
    computed: {
        basic() {
            return this.details.attributes.basic
        },
        advanced() {
            return this.details.attributes.advanced
        },
        responsive() {
            return this.details.attributes.responsive
        },
        responsiveClasses() {
            let c = '';
            if (!this.responsive) return '';
            if (!this.responsive.desktop) c = 'no-desktop';
            if (!this.responsive.mobile) c += ' no-mobile';
            if (!this.responsive.tablet) c += ' no-tablet';
            return c;
        },
        style() {
	        var marginBase = this.basic.margin || {};
	        var paddingBase = this.basic.padding || {};

	        var margin =
			        parseInt(marginBase.top || 0)+'px '+
			        parseInt(marginBase.right || 0)+'px '+
			        parseInt(marginBase.bottom || 0)+'px '+
			        parseInt(marginBase.left || 0)+'px';

	        var padding =
			        parseInt(paddingBase.top || 0)+'px '+
			        parseInt(paddingBase.right || 0)+'px '+
			        parseInt(paddingBase.bottom || 0)+'px '+
			        parseInt(paddingBase.left || 0)+'px';

            var border = this.advanced.border;
            var borderTop,
                borderLeft,
                borderRight,
                borderBottom;

            if (border) {
                borderTop = !border.sides.top ? 'none' : border.width + 'px ' + border.style + ' ' + border.color;
                borderLeft = !border.sides.left ? 'none' : border.width + 'px ' + border.style + ' ' + border.color;
                borderRight = !border.sides.right ? 'none' : border.width + 'px ' + border.style + ' ' + border.color;
                borderBottom = !border.sides.bottom ? 'none' : border.width + 'px ' + border.style + ' ' + border.color;
            }

            var borderRadius = border ?
                border.radius.leftTop+'px '+
                border.radius.topRight+'px '+
                border.radius.bottomRight+'px '+
                border.radius.bottomLeft+'px' : false;

            var backgroundColor = this.advanced.backgroundColor || 'transparent';

            var obj =
                'margin: ' +  margin + ';' +
                'padding: ' + padding + ';' +
                'background-color: ' + backgroundColor + ';' +
                'border-left: '+ borderLeft + ';' +
                'border-top: '+ borderTop + ';' +
                'border-right: '+ borderRight + ';' +
                'border-bottom: '+ borderBottom + ';' +
                'border-radius: '+ borderRadius + ';';
            return obj
        }
    },
    methods:{
      imgSrc(src) {
        return src?.replace('images.funnelish.com', 'img.funnelish.com') || ''
      },
    }
}
</script>
