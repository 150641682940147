import {sticky} from "./fields";

export default {
    tag: 'videoNative',
    labelTitle: '',
    labelColor: '',
    id: { value: null },
    content: '',
    attributes: {
        basic: {
            margin: { title: 'Margin', comp: 'marginPadding', value: { top: 0, left: 0, right: 0, bottom: 0 } },
            props: {
                title: 'Props', comp: 'videoProps',
                value: {
                    focalPoint: 5,
                    autoplay:true,
                    loop:true,
                    muted:true,
                    lazyload:true,
                    videoUrl:'https://videos.funnelish.com/3/1714110798-0a520dad-b852-4d61-bda7-3de568e2ad99.mp4',
                    defaultThumbnail:"https://img.funnelish.com/3/thumbnails/1714110798-0a520dad-b852-4d61-bda7-3de568e2ad99mp4.jpg",
                    useVideoThumbnail:false,
                    thumbnailVideoUrl:null,
                    thumbnailVideoThumbnail:null,
                }
            },
            size: { title: 'Video size', comp: 'SizeValue', placeholder:'auto', value: {width:null,height:null/* null will be treated as 'auto'*/} },
            border: {
                title: 'Border',
                comp: 'border',
                value: {
                    width: 0,
                    color: 'transparent',
                    style: 'solid',
                    sides: { top: true, left: true, right: true, bottom: true },
                    radius: { analytics: 0, topRight: 0, bottomRight: 0, bottomLeft: 0 },
                }
            },
        },
        advanced: {
            cssId: { title: 'ID', comp: 'TextInput', value: '' },
            cssClasses: { title: 'CSS Classes', comp: 'MultiSelectItem', value: [], options: [] },
            sticky

        },
    },
}
