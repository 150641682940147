<template>
    <div v-if="(bannerData && bannerData.title)" class="banner">
        <h2>{{bannerData.title}}</h2>
        <p v-html="bannerData.message"></p>
    </div>
</template>

<script>

export default {
    name: "DashboardBanner",
    components: {
    },
    props: {
        bannerData: Object,
        thisBanerClose: Function
    },
    data: function() {
        return {
        };
    },
    computed: {
    },
    methods: {

    },
}
</script>

<style scoped lang="scss">

.banner {
	background: #f6f9fe;
	border-radius: 16px;
	position: relative;
	padding: 10px 80px 15px;
	color: #636b7c;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	margin-top: 15px;
	h2 {
		color: inherit;
		position: relative;
		font-size: 18px;
		margin: 0;
	}
	h2:before {
		display: block;
		content: "👋";
		position: absolute;
		font-weight: normal;
		font-size: 26px;
		line-height: 39px;
		color: #636A7B;
		right: 100%;
		top: 50%;
		transform: translate(-20px, -50%);
	}
	p {
		color: inherit;
		font-weight: normal;
		font-size: 16px;
		line-height: 1.5;
		margin: 5px 0 0 0;
	}
	.banner-hero {
		display: block;
		height: 212px;
		width: auto;
		position: absolute;
		right: 60px;
		top: 50%;
		transform: translateY(-50%);
	}
}
</style>
