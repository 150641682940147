import {
    shadows,
    textShadows,
    border,
    margin,
    padding,
    font,
    font2,
    linkUrl,
    title,
    cssId,
    cssClasses,
    displayType,
    id,
    backgroundColorButton, sticky
} from './fields/'

export default {
    tag: 'formButton',
    labelTitle: 'Form button',
    labelColor: '',
    id,
    attributes: {
        basic: {
            margin,
            content: { title: 'Headline', comp: 'TextInput', value: 'Call To Action' },
            subHeadline: { title: 'Sub-headline', comp: 'TextInput', value: '' },
            font,
            font2,
            backgroundColor: backgroundColorButton,
            border,
            shadows
        },
        advanced: {
            title,
            cssId,
            cssClasses,
            displayType,
            padding,
            textShadows,
            sticky

        },
    },
}
