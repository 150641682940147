export default {
    state: {
        error: false
    },
    mutations: {
        THROW_ERROR(state) {
            state.error = true;
        },
        RESOLVE_ERROR(state) {
            state.error = false;
        }
    },
    actions: {
        THROW_ERROR(context) {
            context.commit('THROW_ERROR');
        },
        RESOLVE_ERROR(context) {
            context.commit('RESOLVE_ERROR')
        }
    },
    getters: {
        ifError(state) {
            return state.error
        }
    }
}