<template>
    <div class="brdr_content_wrpr danger_box" :class='className'>
		<h3 class="brdr_content_title">{{ title ? title : 'Something went wrong on this page'}}</h3>
		<p>{{ message ? message : 'Please try again later' }}</p>
		<div class="btn_wrpr" v-if="location && url">
			<router-link :to="url" class="btn brdr_btn red_brdr_btn">Back to {{location}}</router-link>
		</div>
        <div class="btn_wrpr" v-if="closable">
			<a @click.prevent="closeMe" class="btn brdr_btn red_brdr_btn">Close</a>
		</div>
	</div>
</template>

<script>
export default {
    name: 'ErrorMessage',
    props: ['title', 'message', 'location', 'url', 'closable', 'className'],
    methods: {
        closeMe() {
            this.$emit('closed');
            this.$store.dispatch("RESOLVE_ERROR");
        }
    }
}
</script>