<template>
	<div class="funnel_steps_templates layout-row noscroll flex">
		<div class="templates-container layout-column flex noscroll">
			<input type="text" placeholder="Type to search a template..." class="main-search-input" style="max-width: 100%;"
			       v-model="search">
			<div class="templates scroll" style="position: relative">
				<span class="rows-break">
					Your saved templates <b>({{filteredSavedTemplates.length}})</b>
				</span>
				<div class="row">
					<p class="no-templates" v-if="!filteredSavedTemplates.length">
						You have no saved templates yet.
					</p>
					<template v-else>
						<div :class="['item build_from_scratch_btn',{'status': activeItem===1}]"
						     @click="selectTemplate(1)">
							<div class="image">
								<inline-svg :src="require('@/assets/img/plus_circle_icon.svg')"/>
							</div>
							<div class="title">Build from scratch</div>
						</div>

						<div v-if="sourcePage" :class="['item ',{'status': activeItem===-1}]"
						     @click="selectTemplate(-1)">
							<div class="image">
								<img :src="sourcePage.thumbnail_hash" alt="">
							</div>
							<div class="title">Duplicate main variant</div>
						</div>

						<div class="item" v-for="(item, index) in filteredSavedTemplates" :key="item.id"
						     :class="{'status': activeItem === 'U.'+item.id, 'deleting':item.deleting}"
						     @click="selectTemplate('U.'+item.id)">
							<div class="image">
								<img :src="item.thumbnail_url" alt="">
								<div class="badge" v-if="item.badge">
									<div class="icon">
										<inline-svg :src="item.badge"/>
									</div>
								</div>
							</div>
							<div class="title">{{ item.name }}</div>
							<a class="xs_delete_btn" v-tippy content="Delete saved template" @click="deleteTemplate(item)">
								<svg fill="none" viewBox="0 0 10 10" height="16" width="16" xmlns="http://www.w3.org/2000/svg">
									<path xmlns="http://www.w3.org/2000/svg" d="M8.43749 1.25H6.5625V0.625002C6.5625 0.279689 6.2828 0 5.93749 0H4.0625C3.71718 0 3.43749 0.279689 3.43749 0.625002V1.25H1.5625C1.21719 1.25 0.9375 1.52969 0.9375 1.87501V2.50001C0.9375 2.845 1.21719 3.12469 1.56188 3.12501H8.43783C8.78282 3.12469 9.06252 2.845 9.06252 2.50001V1.87499C9.0625 1.52969 8.78281 1.25 8.43749 1.25ZM5.93749 1.25H4.0625V0.937496C4.0625 0.764999 4.20249 0.624988 4.37499 0.624988H5.62499C5.79749 0.624988 5.93749 0.764985 5.93749 0.937496V1.25ZM1.56249 8.75001C1.56249 9.44032 2.12217 10 2.81249 10H7.1875C7.87781 10 8.43749 9.44032 8.43749 8.75001V3.75H1.56249V8.75001ZM6.25001 4.68751C6.25001 4.51501 6.39001 4.375 6.5625 4.375C6.735 4.375 6.875 4.515 6.875 4.68751V8.4375C6.875 8.61 6.735 8.75001 6.5625 8.75001C6.39001 8.75001 6.25001 8.61 6.25001 8.4375V4.68751ZM4.6875 4.68751C4.6875 4.51501 4.82749 4.375 5 4.375C5.17252 4.375 5.3125 4.515 5.3125 4.68751V8.4375C5.3125 8.61 5.1725 8.75001 5 8.75001C4.82751 8.75001 4.6875 8.61 4.6875 8.4375V4.68751ZM3.12499 4.68751C3.12499 4.51501 3.26498 4.375 3.43749 4.375C3.61 4.375 3.75 4.515 3.75 4.68751V8.4375C3.75 8.61 3.61 8.75001 3.43749 8.75001C3.26498 8.75001 3.12499 8.61 3.12499 8.4375V4.68751Z" fill="#FB595B"></path>
								</svg>
							</a>
							<v-spinner v-if="item.deleting" />
						</div>
					</template>
				</div>
				<span class="rows-break">
					Other templates <b>({{templates.length}})</b>
				</span>
				<div class="row">
<!--					<div class="col" :key="0">-->
						<div :class="['item build_from_scratch_btn',{'status': activeItem===1}]"
						     @click="selectTemplate(1)">
							<div class="image">
								<inline-svg :src="require('@/assets/img/plus_circle_icon.svg')"/>
							</div>
							<div class="title">Build from scratch</div>
						</div>

						<div v-if="sourcePage" :class="['item ',{'status': activeItem===-1}]"
						     @click="selectTemplate(-1)">
							<div class="image">
								<img :src="sourcePage.thumbnail_hash" alt="">
							</div>
							<div class="title">Duplicate main variant</div>
						</div>

<!--					</div>-->
<!--					<div class="col" v-for="(item, index) in filteredTemplates" :key="item.id">-->
						<div class="item" v-for="(item, index) in filteredTemplates" :key="item.id"
						     :class="{'status': activeItem === item.id}" @click="selectTemplate(item.id)">
							<div class="image">
								<img :src="item.step.thumbnail_hash" alt="">
								<div class="badge" v-if="item.badge">
									<div class="icon">
										<inline-svg :src="item.badge"/>
									</div>
								</div>
							</div>
							<div class="title">{{ item.name }}</div>
						</div>
<!--					</div>-->
				</div>

				<loading v-if="loading" />
			</div>
		</div>
		<div class="template-preview flex-none layout-column noscroll">
			<div class="banner_sidebar scroll">
				<div class="image" v-if="template">
					<img :src="template.step&&template.step.thumbnail_hash ? template.step.thumbnail_hash:template.thumbnail_url" alt="">
					<div class="badge" v-if="template.badge">
						<inline-svg :src="template.badge"/>
					</div>
				</div>
				<div v-else class="image empty">
					<div class="">
						<inline-svg :src="require('@/assets/img/plus_circle_icon.svg')"/>
					</div>
					<div class="title">Build from scratch</div>
				</div>
				<div class="content" v-if="template">
					<div class="title">{{ template.name }}</div>
					<div class="name"><span>by</span> {{ template.author_name || "Funnelish team" }}</div>
					<div class="desc">{{ template.description }}</div>
					<div class="tags">
						<div class="tag" v-for="(tag,index) in tags" :key="index">
							{{ tag }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex"
import API from "@/api"
import VButton from "@/components/base/v-button";
import Loading from "@/components/Loading";
import VSpinner from "@/components/base/v-spinner";

export default {
	name: "StepTemplates",
	components: {VSpinner, Loading, VButton},
	mixins: [API],

	// sourcePage: if given it will be added to templates.
	props:['sourcePage'],
	data: function () {
		return {
			loading: false,
			deleting: false,
			search: '',
			activeItem: 1,
			savedTemplates: [],
			templates: [
				{
					id: 1,
					name: 'Empty template',
					badge: '',
					author_name: 'None',
					description: 'Start building your page from scratch...',
					tags: 'empty page',
				},
			],
		};
	},
	computed: {
		filteredTemplates() {
			return this.templates.filter(template => {
				return template.id>1 && template.name && template.name.toLowerCase().includes(this.search.toLowerCase())
			})
		},
		filteredSavedTemplates() {
			return this.savedTemplates.filter(template => {
				return template.id>1 && template.name && template.name.toLowerCase().includes(this.search.toLowerCase())
			})
		},
		user(){
			return this.$store.state.userInfo
		},
		template() {
			switch (this.activeItem) {
				case -1:
					return {
						id: -1,
						source_page_id: this.sourcePage.id,
						name: 'Duplicate your main variant',
						img: '',
						badge: '',
						author_name: this.user.member.first_name || '[You]',
						description: 'Funnelish will duplicate your main page into a new A/B test variation.',
						tags: '',
						step:{
							thumbnail_hash: this.sourcePage.thumbnail_hash,
						}
					};
				case 1:
					return {
						id: 1,
						name: 'Empty template',
						source_page_id: 1,
						img: '',
						badge: '',
						author_name: 'None',
						description: 'Start building your page from scratch...',
						tags: 'empty page',
					};
					default: {
						if ((''+this.activeItem).startsWith('U.')) { // user template
							let id = parseInt(this.activeItem.replace('U.',''))
							return this.savedTemplates.find(t => t.id === id);
						}
						return this.templates.find(t => {
							return t.id === this.activeItem;
						});
					}
			}
		},
		tags() {
			let tags = this.template.tags;
			if (!tags) return [];
			return tags.split(",").filter(x=>x)
		},
	},
	methods: {
		selectTemplate(itemId) {
			this.activeItem = itemId; // can be string starting with U.
			this.$emit('selected', itemId);
			this.$emit('selectedPage', this.template.source_page_id);
		},
		async init() {
			try {
				this.loading = true;
				let ts = await this.api.wsteps.getTemplates();
				let sts = await this.api.wsteps.getSavedTemplates();

				this.templates = ts;
				this.savedTemplates = sts;
			} catch (e) {
				this.$warn(e);
			} finally {
				this.loading = false;
			}
		},
		async deleteTemplate(t) {
			try {
				this.$set(t, "deleting", true);
				let res = await this.api.wsteps.deleteSavedTemplate(t.id);
				if (res && res.error) throw res;
				let index = this.savedTemplates.findIndex(x=>x.id===t.id);
				if (index >= 0) this.savedTemplates.splice(index, 1);
			} catch (e) {
				this.$warn(e);
			} finally {
				this.$set(t, "deleting", false);
			}
		}
	},
	created() {
		this.init();
	}
}
</script>

<style lang="scss" scoped>
.funnel_steps_templates {
	display: grid;
	grid-auto-flow: column;
	grid-template-columns: calc(100% - 300px) 300px;

	.xs_delete_btn {
		width: 26px;
		height: 26px;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		left: 5px;
		top: 5px;
		border-radius: 5px;
		&:hover {
			background: rgba(90, 93, 97, 0.17);
		}
	}

	.rows-break {
		margin-top: 5px;
		&:not(:first-child) {
			margin-top: 25px;
		}
	}
	.no-templates {
		color: $grey;
		margin: 15px 20px;
		text-align: center;
		width: 100%;
	}
	.template-preview {
		margin: 0;
		width: 300px;
		min-width: 300px;
		padding: 10px;
	}

	.templates-container {
		.main-search-input {
			margin: 15px 0;
		}
		.templates {
			padding: 15px;
			background: #FFFFFF;
			border: 1px solid $light-grey;
			border-radius: 12px;
			flex-direction: row;
			flex-wrap: wrap;
			display: flex;

			.item.build_from_scratch_btn {
				border: 1px dashed #C5C7D2;
				display: flex;
				flex-direction: column;
				justify-content: center;

				.image {
					display: flex;
					align-items: center;
					justify-content: center;
				}

				.title {
					color: #636A7B;
				}

				&.status {
					border: 1px solid #4368E0;
				}
				&:hover {
					border: 1px solid #4368E0;
				}
			}

			.row .col {
				display: flex;
				flex-direction: column;
			}

			.item {
				flex: 1;
				cursor: pointer;
				transition: 0.15s all ease-in-out;
			}

			.row {
				display: flex;
				flex-wrap: wrap;
				margin-left: -15px;
				margin-right: -15px;
			}

			.row .col {
				width: 33.3333333%;
				min-width: 178px;
				padding-left: 15px;
				padding-right: 15px;
			}

			.item {
				position: relative;
				border: none;
				border-radius: 5px;
				padding: 0;
				min-width: 180px;
				width: 200px;
				height: 200px;
				max-width: 180px;
				max-height: 200px;
				margin: 10px;
				display: flex;
				flex-direction: column;
				overflow: hidden;
				box-shadow: 0 5px 25px RGB(0 0 0 / 16%);

				&:hover {
					box-shadow: 0 5px 25px RGB(0 0 0 / 16%), 0 0 0 2px $sharp-blue;
				}
				&.status {
					box-shadow: 0 5px 25px RGB(0 0 0 / 16%), 0 0 0 2px $sharp-blue;
				}
				&.deleting {
					opacity: 0.6;
					pointer-events: none;
				}
				&:not(.build_from_scratch_btn) {
					.image {
						width: 100%;
						height: 100%;
					}
				}
				.image {
					position: relative;
					overflow: hidden;

					img {
						width: 100%;
						object-fit: cover;
					}

					.badge {
						position: absolute;
						left: 4px;
						bottom: 6px;
					}
				}

				.title {
					font-weight: 500;
					font-size: 13px;
					line-height: 150%;
					text-align: center;
					letter-spacing: 0.02em;
					color: #252A32;
					margin-top: 2px;
					padding: 7px;
				}
			}

			// .templates .item
		}

		// .templates
	}
}

.funnel_steps_templates .top_info_box {
	top: 0;
}

.templates .row .col {
	display: flex;
	flex-direction: column;
}

.templates .item {
	flex: 1;
	cursor: pointer;
	transition: 0.15s all ease-in-out;
}

.templates .item.build_from_scratch_btn .image {
	display: flex;
	align-items: center;
	justify-content: center;
}

.templates .item.build_from_scratch_btn .title {
	color: #636A7B;
}

.templates .item.build_from_scratch_btn {
	border: 1px dashed #C5C7D2;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.templates .item.build_from_scratch_btn:hover {
	border: 1px solid #4368E0;
}

.template-preview .banner_sidebar {
	padding: 15px;
	background: #FFFFFF;
	border: 1px solid $sharp-blue;
	border-radius: 12px;


	.image {
		position: relative;
		width: 100%;
		border: 1px solid $grey;
		border-radius: 8px;
		height: 200px;
		overflow: hidden;
		&.empty {
			border: 1px dashed #C5C7D2;
			display: flex;
			flex-direction: column;
			justify-content: center;
			text-align: center;
			align-items: center;
			.title {
				margin-top: 15px;
				font-size: 14px;
				color: #626b7a;
			}
		}
		img {
			width: 100%;
			object-fit: cover;
		}
		.badge {
			position: absolute;
			left: 14.5px;
			bottom: 12px;
		}
	}

	.content {
		height: auto;
		padding: 10px 0 0;

		.title {
			font-weight: 500;
			font-size: 15px;
			line-height: 150%;
			text-align: center;
			letter-spacing: 0.02em;
			color: #4368E0;
		}

		.name {
			font-weight: 500;
			font-size: 12px;
			line-height: 150%;
			letter-spacing: 0.02em;
			color: #C5C7D2;
			text-align: center;

			span {
				color: #252A32;
			}
		}

		.desc {
			margin-top: 10px;
			margin-bottom: 15px;
			font-weight: 500;
			font-size: 13px;
			line-height: 150%;
			letter-spacing: 0.02em;
			color: #636A7B;

			span {
				color: #252A32;
			}
		}

		.tags {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 10px;
		}

		.tag {
			font-size: 11px;
			line-height: 150%;
			text-align: center;
			letter-spacing: 0.02em;
			color: #252A32;
			padding: 2px 5px;
			background: #D5E5FF;
			border-radius: 4px;
			margin-bottom: 10px;
			margin-right: 10px;

			&:last-child {
				margin-left: 0;
			}
		}

		.btn {
			display: table;
			margin: 0 auto;
		}
	}

	// .content

}

</style>
