<template>
    <div class="info_btn" @mouseover="tooltipMouseover" @mouseleave="tooltipActive = false">
        <inline-svg :src="require('@/assets/img/info_icon.svg')"/>
        <div class="tooltip with_func" ref="thisTooltip" :style="{top:tooltipTop + 'px', left: tooltipLeft +'px'}">
            <slot></slot>
        </div>
    </div>                    
</template>

<script>

export default {
    name: 'Tooltip',
    props: ['side'],
    data() {
        return {
            tooltipLeft: 0,
            tooltipTop: 0,
            tooltipActive: false,
            thisSide: this.side || ''
        }
    },
    methods: {
        tooltipMouseover(e){
            this.tooltipActive = true;
            if(this.thisSide == 'right') {
                this.tooltipLeft = e.target.getBoundingClientRect().left + pageXOffset + 25;
            } else {
                this.tooltipLeft = e.target.getBoundingClientRect().left + pageXOffset - (this.$refs.thisTooltip.offsetWidth + 5);
            }

            this.tooltipTop = e.target.getBoundingClientRect().top + (pageYOffset + 10) - (this.$refs.thisTooltip.offsetHeight / 2);
        }
    }
}
</script>