<template>
	<div class="chart simple-chart" :class="[theme, {'large':large, 'small':small}]"
       v-resize @resize="onResize()">
		<div class="__title layout-row" v-if="!simpleChart">
			<span class="">{{title}}</span>
			<info-icon v-if="$slots.info">
				<slot name="info"></slot>
			</info-icon>
			<div class="delta" :class="[deltaClass,{'huge-delta':large}]">{{sign}} {{ Math.abs(delta) }}%</div>
		</div>
		<div class="__totals layout-row" v-if="!simpleChart">
			<span class="total">{{valueFormatted}}</span>
			<div class="flex"></div>
			<span class="old-total">{{oldValueFormatted}}</span>
		</div>
		<div class="__dates layout-row" v-if="!simpleChart">
			<span class="date">{{currentData ? currentData.date : ""}}</span>
			<div class="flex"></div>
			<span class="old-date">{{currentData ? currentData.oldDate : ""}}</span>
		</div>
		<div style="position: relative" >
			<TrendChart v-if="!loading && !isEmpty" class="inner-chart" :datasets="dataset" :min="0" :grid="grid"
			            padding="5 5 2" :interactive="true" @mouse-move="onMouseMove" />
			<TrendChart v-else class="inner-chart" :datasets="emptyDataset" :min="0" :grid="grid"
			            padding="5 5 2" :interactive="true" @mouse-move="onMouseMove" />
		</div>
		<div class="__dates layout-row">
			<span class="date">{{startDate ? startDate : ""}}</span>
			<div class="flex"></div>
			<span class="old-date">{{endDate ? endDate : ""}}</span>
		</div>

		<v-spinner class="" v-if="loading" />
	</div>
</template>

<script>
import numbers from "@/helpers/numbers";
import VSpinner from "@/components/base/v-spinner";
import InfoIcon from "@/components/base/info-icon";
import TrendChart from "@/components/base/trend-chart/trend-chart";
const moment = require('moment-timezone');

export default {
	name: "chart",
	components: {TrendChart, InfoIcon, VSpinner},
	props: {
		simpleChart:{
			type:Boolean,
			default:false,
		},
		theme:{
			type:String,
			default:'blue',
		},
		large: {
			default: false,
			type: Boolean,
		},
		small: {
			default: false,
			type: Boolean,
		},
		loading: {
			default: false,
			type: Boolean,
		},
		title: {
			default: "",
			type: String,
		},
		total: {
			default: 0,
			type: Number,
		},
		oldTotal: {
			default: 0,
			type: Number,
		},
		prefix: {
			default: "",
			type: String,
		},
		suffix: {
			default: "",
			type: String,
		},
		roundValue:{
			default: false,
			type: Boolean,
		},
		isMoney:{
			default: false,
			type: Boolean,
		},
		isEmpty: {
			default: false,
			type: Boolean,
		},
		data: {
			type: Array,
			default: () => [{value:0},{value:0}],
		},
		oldData: {
			type: Array,
			default: () => [{value:0},{value:0}],
		},
		startDate: {
			type: String,
		},
		endDate: {
			type: String,
		},
	},
	data() {
		return {
			currentData: null,
		}
	},
	computed: {
		valueFormatted() {
			return this.formatValue(this.value);
		},
		oldValueFormatted() {
			return this.formatValue(this.oldValue);
		},
		value() {
			return this.currentData ? this.currentData.value : this.total;
		},
		oldValue() {
			return this.currentData ? this.currentData.oldValue : this.oldTotal;
		},
		grid() {
			return {
				verticalLines: this.large,
			};
		},
		dataset() {
			return [{
				data: this.oldData,
				smooth: false,
				showPoints: true,
				fill: false,
				className: `chart-curve__old-data`
			},{
				data: this.data,
				smooth: false,
				showPoints: true,
				fill: false,
				className: `chart-curve`
			},
			];
		},
		emptyDataset() {
			let x = [];
			for (let i=30; i>0;i--) {
				x.push({date:moment().subtract(i,'days'), value:0})
			}
			return [{
				data: x,
				smooth: false,
				showPoints: true,
				fill: false,
				className: `chart-curve__old-data`
			},{
				data: x,
				smooth: false,
				showPoints: true,
				fill: false,
				className: `chart-curve`
			},
			];
		},
		sign: function() {
			return this.delta >= 0 ? "+" : "-";
		},
		deltaClass: function() {
			return this.delta > 0 ? "delta-up" : this.delta < 0 ? "delta-down" : "delta-neutral";
		},
		delta: function () {
			let value = this.value || 0;
			let oldValue = this.oldValue || 0;
			let difference = (value-oldValue);

			if (!oldValue && value) return this.fixPer(100); // use current value as the change value.

			let per = difference/oldValue*100;

			return this.fixPer(per);
		}
	},
	methods: {
		onResize() {
			if (window) window.dispatchEvent(new Event('resize'));
		},
		formatValue(value) {
			let t = value || 0;
			let v = this.roundValue ? numbers.round_to_zero(t) : numbers.round_to_two(t);
			t = this.isMoney ? this.formatPrice(v) : v;
			return `${this.prefix} ${t}${this.suffix}`
		},
		formatPrice(value) {
			let val = (value/1).toFixed(2)//.replace('.', ',')
			return numbers.numberWithCommas(val)
		},
		fixPer(precentage) {
			let n = 0;
			if (this.roundToZero)
				n = numbers.round_to_zero(precentage);
			else
				n = numbers.round_to_two(precentage);

			if (isNaN(n))
				return 0;
			else
				return n;// Math.abs(n);
		},
		onMouseMove(params) {
			if (!params) {
				this.currentData = null;
				return;
			}
			this.currentData = {}

			if (params.data[1]) { // old data
				this.currentData.date = params.data[1].brief;
				this.currentData.value = params.data[1].value;
			}
			if (params.data[0]) {
				this.currentData.oldDate = params.data[0].brief;
				this.currentData.oldValue = params.data[0].value;
			}
		}
	},
	created() {
		// this.data = [0, 3, 4, 50, 585, 685, 899, 333]
	},
}
</script>
<style lang="scss">
.chart {
	.info_icon {
		svg {
			width: 16px;
		}
	}
}
</style>
<style lang="scss" scoped>
.chart {
	display: flex;
	flex-direction: column;
	border-radius: 5px;
	margin: 0;
	min-width: 300px;
	height: 200px;
	position: relative;
	background: #F6F9FE;
	padding: 10px 15px;
	width: 100%;

	&.white{
		background: none;
		border: none;
		&.small {
			>.__title {
				font-size: 12px;
				font-weight: 400;
				letter-spacing: 0.24px;
				color: #636A7B;
			}
			>.__totals {
				.total {
					font-size: 18px;
					font-weight: 400;
				}
				.old-total{
					font-weight: 400;
					font-size: 14px;
				}
			}
		}
	}
	&.large {
		>.__title {
      margin-bottom: 6px;
      font-size: 16px;
      align-items: baseline;
      margin-top: 0;
		}
		>.__totals {
			margin: 0 4px;
			span.total {
				font-size: 24px;
				font-weight: 400;
			}
			span.old-total{
				font-weight: 400;
				font-size: 18px;
			}
		}
		>.__dates {
			font-size: 14px;
		}
	}
	&.small {
		min-width: 100px;
		height: 175px;
		padding: 10px;
	}
	>.__title {
    font-size: 14px;
		font-weight: 400;
		color: slategrey;
		white-space: nowrap;
    align-items: baseline;
    margin: 0 4px 6px;

    .delta {
			margin-left: 5px;
			flex: 1;
		}
	}
	>.__totals {
		margin: 0 4px;
		span.total {
			font-size: 20px;
			font-weight: 400;
			left: 0;
			color: $black;
		}
		span.old-total{
			right: 0;
			font-weight: 400;
			font-size: 16px;
			color: lightslategrey;
		}
	}
	>.__dates {
		margin: 2px 4px 3px;
		color: lightslategrey;
		font-size: 12px;
		font-weight: 300;
		height: 14px;
		max-height: 14px;
		min-height: 14px;
		span.date {
			left: 0;
		}
		span.old-date {
			right: 0;
		}
	}
}



</style>
<style lang="scss">

.simple-chart {
	position: relative;
	.vtc {
		margin-right: 2px;
		width: 100%;
		height: 90px;
	}
	&.large {
		border-radius: 10px;
		height: 250px;
		background: #F6F9FE;
		padding: 15px 15px;

		.line {
			stroke: rgba(0, 0, 0, 0.06);
		}

		.chart-curve {
			.point:last-child {
				r: 2;
			}
		}

		.vtc {
			height: 120px;
		}
	}
	&.small {
		.vtc {
			height: 60px;
		}
	}
	.stroke {
		stroke-width: 1.3;
	}
	.fill {
		opacity: 0;
	}
	.active-line {
		stroke: rgba(0, 0, 0, 0.2);
	}
	.point {
		display: none;
		r: 1.6;
	}
	.point.is-active {
		display: block;
	}
}

.chart-curve {
	.stroke {
		stroke: #4468e0;
	}
	.fill {
		fill: transparent;
	}
	.point {
		fill: #4468e0;
		stroke: #4468e0;
	}
	.point:last-child {
		display: block;
	}
	&__old-data {
		.point {
			fill: #afb5ba;
			stroke: #afb5ba;
		}
		.point:last-child {
			display: none;
		}
		.stroke {
			stroke: #afb5ba;
		}
	}
}

</style>
