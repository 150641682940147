<template>
  <div v-if="info" :class="{'select-button':true, 'selected':shouldBeChecked, 'no-icon': noIcon}"
       v-tippy :content="info"
       @click="$emit('click')">
    <label>
      <input type="radio" :value="value" :checked="shouldBeChecked" @change="updateInput">
      <div class="item">
        <div class="icon" v-if="!noIcon">
          <img :src=src alt="">
        </div>
        <div class="title">{{label}}</div>
      </div>
	    <span v-if="!!tag" class="__tag">{{ tag }}</span>
    </label>
  </div>
	<div v-else :class="{'select-button':true, 'selected':shouldBeChecked, 'no-icon': noIcon}"
	     @click="$emit('click')">
		<label>
			<input type="radio" :value="value" :checked="shouldBeChecked" @change="updateInput">
			<div class="item">
				<div class="icon" v-if="!noIcon">
					<inline-svg v-if="isSvg" :src="src" />
					<img v-else :src=src alt="">
				</div>
				<div class="title">{{label}}</div>
				<div v-if="subtitle" class="subtitle">{{subtitle}}</div>
			</div>
			<span v-if="!!tag" class="__tag">{{ tag }}</span>
		</label>
	</div>
</template>

<script>

import InfoIcon from "@/components/base/info-icon";
export default {
  name: "SelectButton",
  components: {
	  InfoIcon
  },
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
	  src: {},
	  tag: {
		  type: String,
	  },
	  isSvg:{
			type:Boolean,
	  },
	  info: {
		  default: ''
	  },
	  value: {
		  default: null
	  },
	  modelValue: {
		  default: null
	  },
	  noIcon: {
		  default: false,
		  type: Boolean
	  },
    label: {
      type: String,
      required: true
    },
	  subtitle:{
			type: String,
		  default: ''
	  }
  },
  data: function() {
    return {};
  },
  computed: {
    shouldBeChecked() {
      return this.modelValue === this.value
    }
  },
  methods: {
    updateInput() {
      this.$emit('change', this.value)
    }
  }
}
</script>
<style lang="scss" scoped>
.select-button {
	outline: none;
	position: relative;

	.__tag {
		position: absolute;
		top: 5px;
		right: 5px;
		background: $blue;
		color: white;
		padding: 2px 8px;
		border-radius: 8px;
		font-size: 11px;
	}
	.icon {
		width: 40px;
		height: 40px;
	}
	&.no-icon {
		.icon {
			display: none;
		}
		.item {
			padding: 10px 15px;
			.title {
				margin-top: 0;
			}
		}
	}
	.subtitle {
		color: $sharp-blue;
		font-size: 11px;
		margin-top: 3px;
		font-weight: 500;
	}
}
.info-icon {
	position: absolute;
	top: 5px;
	right: 5px;
}
</style>
