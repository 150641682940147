//var randomstring = require("randomstring");
import randomstring from 'randomstring'
import {
    shadows, sticky,
} from './fields/'

export default {
    tag: 'twoStepForm',
    labelTitle: '',
    labelColor: '',
    id: { value: null },
    // children: [],
    step1Children: [],
    step2Children: [],
    content: {
        formHeader: [
            { step: 1, title: 'Shipping', subtitle: 'Your shipping details' },
            { step: 2, title: 'Your Info', subtitle: 'Your billing details' },
        ],
    },
    attributes: {
        basic: {
            margin: { title: 'Margin', comp: 'marginPadding', value: { top: 0, left: 0, right: 0, bottom: 0 } },
            previewSteps: { title: 'Preview steps', comp: 'StepToggler', value: 1 },
            font: { title: 'Headline font', comp: 'FontPanel', value: { fontFamily: '', fontSize: 18, color: '#252A32', fontStyling: { bold: false, italic: false, 'line-through': false, underline: false, }, textAlign: 'left' } },
            font2: { title: 'Sub-headline font', comp: 'FontPanel', value: { fontFamily: '', fontSize: 13, color: '#C5C7D2', fontStyling: { bold: false, italic: false, 'line-through': false, underline: false, }, textAlign: 'left' } },
            backgroundColor: { title: 'Background color', comp: 'colorPicker2', value: '#FFFFFF' },
            // color: { title: 'Label text color', comp: 'colorPicker', value: '#252A32' },
            border: {
                title: 'Border',
                comp: 'border',
                value: {
                    width: 2,
                    color: '#449CF4',
                    style: 'solid',
                    sides: { top: true, left: true, right: true, bottom: true },
                    radius: { analytics: 16, topRight: 16, bottomRight: 16, bottomLeft: 16 },
                }
            },
            shadows
        },
        advanced: {
            cssId: { title: 'ID', comp: 'TextInput', value: '' },
            cssClasses: { title: 'CSS Classes', comp: 'MultiSelectItem', value: [], options: [{ id: 'class1', text: 'class1' }, { id: 'class2', text: 'class2' }, { id: 'class3', text: 'class3' }] },
            padding: { title: 'Padding', comp: 'marginPadding', value: { top: 30, left: 30, right: 30, bottom: 30 } },
            sticky

            // animations: {
            //     title: 'Animations',
            //     comp: 'AnimationsInput',
            //     value: {
            //         property: '',
            //         duration: 0,
            //         easing: '',
            //     }
            // },
            // transformation: {
            //     title: 'Transformation',
            //     comp: 'TransformationInput',
            //     value: {
            //         rotateX: 0,
            //         rotateY: 0,
            //         rotateZ: 0,
            //         scaleX: 1,
            //         scaleY: 1,
            //         scaleZ: 1,
            //     }
            // },
        },
    },
}
