<template>
	<expandable-area title="Fulfillment" name="order-fulfillment">
		<div class="order-fulfillment">
			<div class="layout-row margin-b-20">
				<div class="flex layout-column">
					<span class="label">Tracking number:</span>
					<span class="value">{{ fulfillment.tracking_number }}</span>
				</div>
				<div class="flex layout-column">
					<span class="label">Shipping carrier</span>
					<span class="value">{{ fulfillment.shipping_carrier }}</span>
				</div>
			</div>

			<div class="layout-column" v-if="fulfillment.tracking_url">
				<span class="label">Tracking URL</span>
				<span class="value">{{ fulfillment.tracking_url }}</span>
			</div>
		</div>
	</expandable-area>
</template>

<script>
import ExpandableArea from "@/components/base/expandable-area";
import moment from "moment";
import {getSymbol} from "@/helpers/currencies";
import InputField from "@/components/base/input-field";

export default {
	name: "order-fulfillment",
	components: {InputField, ExpandableArea},
	props:['order', 'fulfillment', 'loading'],
	data(){
		return {
		}
	},
	computed:{
	},
	methods:{
	},

}
</script>

<style lang="scss" scoped>
.order-fulfillment {
	display: flex;
	flex-direction: column;
	margin-top: 10px;
	position: relative;

	.label {
		font-size: 13px;
		font-weight: 500;
		color: $dark-grey;
	}
	.value {
		margin-top: 5px;
		font-size: 14px;
		font-weight: 600;
		color: $black;
	}

}
</style>
