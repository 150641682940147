<template>
	<popup class="code_popup" fullscreen :title="insertCode?'Custom HTML':'Custom CSS'" @close="closeThisPopup">
		<template v-slot:content>
      <div class="code-container">
        <code-editor v-model:value="rawCode" language="html" v-if="insertCode" />
        <code-editor v-model:value="rawCodeCSS" language="css" v-else />
      </div>
		</template>
		<template v-slot:actions>
			<v-button new-style @click="closeThisPopup" border label="Cancel" />
      <v-button new-style @click="save" label="Save changes" />
		</template>
	</popup>
</template>

<script>
import Popup from '@/components/base/popup';
import VButton from '@/components/base/v-button';
import CodeEditor from "@/components/base/code-editor.vue";

export default {
	name: 'CodePopup',
	components: {
    CodeEditor,
		Popup,
		VButton,
	},
	props: {
		insertCode: {
			type: Boolean,
			default: false,
		},
		html: {
			type: String,
			default: '',
		},
	}, // ['insertCode', 'html'],
	data() {
		return {
			rawCode: '',
			rawCodeCSS: '',
			showMail: false,
			tabActive: 1,
		}
	},
	computed: {
		getCSS() {
			if (this.$store.state.email.customCode.css !== '') {
				return this.$store.state.email.customCode.css
			}
			return '/* CSS */\n'
		},
	},
	methods: {
		save() {
			if (this.insertCode) {
				this.$emit('done', this.rawCode);
			} else
				this.$emit('done', {
					css: this.rawCodeCSS,
				});
			},
		closeThisPopup() {
			this.$emit('close');
		},
	},
	mounted() {
		if (!this.insertCode) {
			this.rawCodeCSS = this.getCSS;
		} else
			this.rawCode = this.html;
	},
}
</script>

<style lang="scss">
.code_popup {
	cursor: default;
	@include default-font();
  .code-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
  }
	.popup {
		min-height: 300px;
    height: 500px;
	}
	.content {
		padding: 0;
	}
	#tabs-nav {
		margin-bottom: 5px;
	}
}

</style>
<style lang="scss" scoped>
.code_popup {
}
</style>
