import {
    shadows, buttonShadows, border, margin, padding, font, title, cssId, cssClasses, id, sticky
} from './fields/'

export default {
    tag: 'container',
    labelTitle: '',
    labelColor: '',
    id,
    editLock: false,
    attributes: {
        basic: {
            margin,
            orientation: { title: 'Orientation', comp: 'orientation',
                value: {mobile:null,desktop:'row',tablet:null},
                options: [{ id: 'row', text: 'Horizontal' }, { id: 'column', text: 'Vertical' }] },
            align: { title: 'Items alignment', comp: 'alignment', value: {mobile:null,desktop:'start',tablet:null} },
            boxes: {title:'Boxes', comp: 'containerProps', value: [
                    {width:'',type:"%",content:'1', children:[]},
                    // {width:'',type:"%",content:'2', children:[]},
                ]},
            font,
            backgroundColor: {title: 'Background color', comp: 'colorPicker2', value: ''},
            border,
            shadows,
            textShadows: buttonShadows
        },
        // hover: {
        //     backgroundColor: {title: 'Background color', comp: 'colorPicker', value: null},
        //     border: {title: 'Border', comp: 'border', value: {
        //             width: null,
        //             color: null,
        //             style: 'solid',
        //             sides: { top: true, left: true, right: true, bottom: true },
        //             radius: { topLeft: null, topRight: null, bottomRight: null, bottomLeft: null },
        //         }
        //     },
        //     font: {title: 'Headline font', ...hoverFont},
        //     font2: {title: 'Sub-headline font', ...hoverFont},
        //     shadows,
        //     textShadows: buttonShadows
        // },
        advanced: {
            title,
            cssId,
            cssClasses,
            padding: {
                title: 'Padding',
                comp: 'marginPadding',
                value: { top: 0, left: 0, right: 0, bottom: 0 }
            },
            sticky

        },
    },
}
