import {
    shadows, textShadows, border, margin, padding, font, hiddenFont, cssId, cssClasses, id, backgroundColor, sticky
} from './fields/'

export default {
    tag: 'headline',
    labelTitle: '',
    labelColor: '',
    id,
    content: '<h2>Default headline item</h2>',
    attributes: {
        basic: {
            margin,
            backgroundColor,
            font: hiddenFont,
            lineHeight: {
                title:'Line spacing',
                comp: 'SmallNumber',
                value: {
                    append: 'x',
                    placeholder: 'normal',
                    number: 1,
                    min: 0
                }
            },
            letterSpacing: {
                title:'Letter spacing',
                comp: 'SmallNumber',
                value: {
                    append: 'px',
                    placeholder: 'normal',
                    number: null,
                }
            },
            border,
            shadows
        },
        advanced: {
            cssId,
            cssClasses,
            padding: {
                title: 'Padding',
                comp: 'marginPadding',
                value: { top: 5, left: 5, right: 5, bottom: 5 }
            },
            textShadows,
            sticky

        },
    },
}
