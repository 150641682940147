export default (config, axios, token) => {

    var call = async function(path, method = 'GET', data) {
        // data ? data = JSON.stringify({ data }) : '';
        const url = config.URL + path;
        try {
            const response = await axios({
                url,
                method,
                data,
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json',
                }
            })
            return response.data
        } catch (error) {
            return { error: true, info: error.response }
        }
    }
    var downloadCsv = async function(path, method = 'GET', data) {
        // data ? data = JSON.stringify( data ) : '';
        const url = config.URL + path;
        try {
            const response = await axios({
                url,
                method,
                data,
                headers: { 'Authorization': 'Bearer ' + token }
            })
            return response.data
        } catch (error) {
            return { error: true, info: error.response }
        }
    }

    return {

        get: async(id) =>
            call('orders/' + id),

        getAll: async(page = 0, per_page = 25, query = '') =>
            call('orders?page=' + page + '&per_page=' + per_page + '&query=' + query),

        getFunnel: async(id, page = 0, per_page = 25, query = '') =>
            call('orders?funnel_id=' + id + '&page=' + page + '&per_page=' + per_page + '&query=' + query),

        export: async(data) =>
            call('exports', 'POST', data),

        download: async(id, funnelId) =>
            downloadCsv('download-export', 'POST', { id:id, funnel_id:funnelId }),

        getTimeline: async(id) =>
            call(`orders/${id}/timeline`),

        getNotes: async(id) =>
            call(`orders/${id}/notes`),
        updateNote: async(id, data) =>
            call(`orders/${id}/notes`,'PUT', data),
        deleteNote: async(orderId, noteId) =>
            call(`orders/${orderId}/notes/${noteId}`,'DELETE'),
        createNote: async(orderId, data) =>
            call(`orders/${orderId}/notes`,'POST', data),

        archiveToggle: async(id) =>
            call(`orders/${id}/archive`,'PUT'),

        fulfill: async(id, data) =>
            call(`orders/${id}/fulfill`,'POST', data),
        cancel: async(id, refund=false, reason='') =>
            call(`orders/${id}/cancel?refund=${refund}&reason=${encodeURIComponent(reason)}`,'PUT'),
        deleteOrder: async(id) =>
            call(`orders/${id}`,'DELETE'),
        refund: async(id, reason='') =>
            call(`orders/${id}/refund?reason=${encodeURIComponent(reason)}`,'PUT'),
        getFulfillment: async(id) =>
            call(`orders/${id}/fulfillment`,'GET'),
        addTrackingInfo: async(id, data) =>
            call(`orders/${id}/add-tracking-info`,'PUT', data),
    }
}
