import {
    shadows, buttonShadows, border, margin, padding, font, title, cssId, cssClasses, id, sticky
} from './fields/'

export default {
    tag: 'faq',
    labelTitle: 'FAQ',
    labelColor: '',
    id,
    editLock: false,
    attributes: {
        basic: {
            margin,
            // orientation: { title: 'Orientation', comp: 'orientation',
            //     value: {mobile:null,desktop:'row',tablet:null},
            //     options: [{ id: 'row', text: 'Horizontal' }, { id: 'column', text: 'Vertical' }] },
            align: { title: 'Arrow position', comp: 'alignment', value: {mobile:null,desktop:'start',tablet:null} },
            // boxes: {title:'Boxes', comp: 'containerProps', value: [
            //         {width:'',type:"%",content:'1', children:[]},
            //         // {width:'',type:"%",content:'2', children:[]},
            //     ]},
            isActive: { title: 'Is active?', comp: 'ToggleInput', value: { booleanValue: true } },
            title: { title: 'Title', comp: 'hidden', value: 'Default title' },

            font:{
                title: 'Title font',
                comp: 'FontPanel',
                value: {
                    fontFamily: '',
                    fontSize: 16,
                    color: null, // When null it falls-back to the CORE.CSS styles.
                    fontStyling: {
                        bold: true,
                        italic: false,
                        'line-through': false,
                        underline: false,
                    },
                    textAlign: 'left'
                }
            },
            backgroundColor: {title: 'Background color', comp: 'colorPicker2', value: ''},
            headerBackgroundColor: {title: 'Header background color', comp: 'colorPicker2', value: ''},
            headerBorder:{
                title: 'Header border',
                comp: 'border',
                value: {
                    width: null,
                    color: null,
                    style: 'solid',
                    sides: { top: true, left: true, right: true, bottom: true },
                    radius: { topLeft: null, topRight: null, bottomRight: null, bottomLeft: null },
                }
            },
            border,
            headerShadows:{
                title: 'Header shadow',
                comp: 'Shadow',
                value: []
            },
            shadows,
            textShadows: buttonShadows
        },
        hover:{
            font:{
                title: 'Title font',
                comp: 'FontPanel',
                value: {
                    fontFamily: '',
                    fontSize: 16,
                    color: null, // When null it falls-back to the CORE.CSS styles.
                    fontStyling: {
                        bold: true,
                        italic: false,
                        'line-through': false,
                        underline: false,
                    },
                    textAlign: null
                }
            },
            headerBackgroundColor: {title: 'Header background color', comp: 'colorPicker2', value: ''},
            headerBorder:{
                title: 'Header border',
                comp: 'border',
                value: {
                    width: null,
                    color: null,
                    style: 'solid',
                    sides: { top: true, left: true, right: true, bottom: true },
                    radius: { topLeft: null, topRight: null, bottomRight: null, bottomLeft: null },
                }
            },
            headerShadows:{
                title: 'Header shadow',
                comp: 'Shadow',
                value: []
            },
            textShadows: buttonShadows
        },
        active:{
            font:{
                title: 'Title font',
                comp: 'FontPanel',
                value: {
                    fontFamily: '',
                    fontSize: 16,
                    color: null, // When null it falls-back to the CORE.CSS styles.
                    fontStyling: {
                        bold: true,
                        italic: false,
                        'line-through': false,
                        underline: false,
                    },
                    textAlign: null
                }
            },
            headerBackgroundColor: {title: 'Header background color', comp: 'colorPicker2', value: ''},
            headerBorder:{
                title: 'Header border',
                comp: 'border',
                value: {
                    width: null,
                    color: null,
                    style: 'solid',
                    sides: { top: true, left: true, right: true, bottom: true },
                    radius: { topLeft: null, topRight: null, bottomRight: null, bottomLeft: null },
                }
            },
            headerShadows:{
                title: 'Header shadow',
                comp: 'Shadow',
                value: []
            },
            textShadows: buttonShadows
        },
        advanced: {
            title,
            cssId,
            cssClasses,
            headerPadding: {
                title: 'Header padding',
                comp: 'marginPadding',
                value: { top: 10, left: 10, right: 10, bottom: 10 }
            },
            padding: {
                title: 'Padding',
                comp: 'marginPadding',
                value: { top: 20, left: 10, right: 10, bottom: 20 }
            },
            sticky

        },
    },
}
