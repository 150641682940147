export default {
    state: {
        customers: [],
        orders: [],
    },
    mutations: {
        setOrders(state, orders) {
            state.orders = orders;
        },
        setCustomers(state, customers) {
            state.customers = customers;
        },
    },
    actions: {
        setCustomers(context) {
            context.commit('setCustomers');
        },
        setOrders(context) {
            context.commit('setOrders');
        },
    },
    getters: {
        getCustomers(state) {
            return state.customers
        },
        getOrders(state) {
            return state.orders
        },
    }
}
