<template>
    <div class="wrapper email-wrapper">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: "EmailLayout"
}
</script>

<style scoped>
.wrapper.email-wrapper {
    z-index: 999;
    position: relative;
    padding-left: 0;
}
</style>